import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { UserBIScenario } from "../common/logger/Logger";
import { useLogger } from "../common/logger/LoggerContext";
import { enableAddToCalenderSelector } from "../core/slices/ecsSlice";
import { eventAddToCalendarAsyncAction } from "../core/slices/eventSlice";
import {
  ButtonV9 as Button,
  CalendarAddRegular,
  TextV9 as Text,
  mergeClasses,
  MenuV9 as Menu,
  MenuTriggerV9 as MenuTrigger,
  MenuPopoverV9 as MenuPopover,
  MenuListV9 as MenuList,
} from "../shared";
import { Breakpoint } from "../styles/Grid";
import { AddToCalenderMenuItems } from "../utilities/addToCalendarUtils";
import { useBreakpoint } from "../utilities/hooks/useBreakpoints";
import { eventAddToCalenderStyles } from "../styles/EventAddToCalendar";
import { buttonStyles } from "../styles/ButtonStyles";

export const EventAddToCalenderButton: React.FunctionComponent = () => {
  const buttonClasses = buttonStyles();
  const eventAddToCalenderClasses = eventAddToCalenderStyles();
  const { t: i18n } = useTranslation();
  const logger = useLogger()?.logger;
  const enableAddToCalender = useSelector(enableAddToCalenderSelector);
  const { isBreakpointAndDown } = useBreakpoint();
  const isMobileView = isBreakpointAndDown(Breakpoint.Medium);
  const dispatch = useDispatch();

  const onClickAddToCalendar = () => {
    logger?.logUiTelemetry(
      "addToCalendar",
      UserBIScenario.EventAddToCalenderButton,
      "click",
      "none",
      "EventAddToCalenderButton"
    );
  };

  return (
    <>
      {enableAddToCalender && (
        <Menu
          positioning={
            isMobileView
              ? { align: "start", offset: { crossAxis: 0, mainAxis: 8 } }
              : { align: "end", offset: { crossAxis: 0, mainAxis: 8 } }
          }
        >
          <MenuTrigger>
            <Button
              icon={<CalendarAddRegular />}
              aria-label={i18n("button_add_to_calender")}
              onClick={onClickAddToCalendar}
              className={mergeClasses(
                isMobileView
                  ? buttonClasses.largeButton
                  : eventAddToCalenderClasses.webAddToCalButton,
                buttonClasses.fluid
              )}
            >
              <Text
                className={mergeClasses(
                  buttonClasses.text,
                  eventAddToCalenderClasses.addToCalButtonText
                )}
              >
                {i18n("button_add_to_calender")}
              </Text>
            </Button>
          </MenuTrigger>
          <MenuPopover>
            <MenuList>
              <AddToCalenderMenuItems
                onClick={(type) => {
                  dispatch(eventAddToCalendarAsyncAction(type));
                }}
                i18n={i18n}
              />
            </MenuList>
          </MenuPopover>
        </Menu>
      )}
    </>
  );
};

import { makeStyles, tokens } from "../shared";

export const formOptionStyles = makeStyles({
  itemLabel: {
    color: tokens.colorNeutralForeground2,
  },
  multiChoiceLabel: {
    marginBottom: "8px",
  },
});

import React from "react";
import { useHistory, useLocation } from "react-router";
import { useAuthenticationService } from "../core/auth/auth-context";
import { useBreakpoint } from "../utilities/hooks/useBreakpoints";
import { Breakpoint } from "../styles/Grid";
import { routes } from "../common/constants";
import { useLogger } from "../common/logger/LoggerContext";
import { UserBIScenario } from "../common/logger/Logger";
import { TopNavBarWeb } from "./TopNavBarWeb";
import { TopNavBarMobile } from "./TopNavBarMobile";
import { LoggerLevels } from "../common/logger/interface";
import { useTranslation } from "react-i18next";
import {
  DialogActionsV9 as DialogActions,
  DialogBodyV9 as DialogBody,
  DialogContentV9 as DialogContent,
  DialogSurfaceV9 as DialogSurface,
  DialogTitleV9 as DialogTitle,
  DialogV9 as Dialog,
  ButtonV9 as Button,
  useBoolean,
  getWindow,
} from "../shared";
import { LoginState } from "../core/auth/authenticationService.interface";
import { useSelector } from "react-redux";
import { loginStateSelector } from "../core/slices/userSlice";
import { enableWorkAccountProfilePagesSelector } from "../core/slices/ecsSlice";
import {
  getErrorResponse,
  isErrorResponsePopupWindowError,
} from "../core/slices/error";
import { buttonStyles } from "../styles/ButtonStyles";
import { generateSearchParams } from "../utilities/common/generateSearchParams";
import { PortalLocationState } from "../core/history/history.interface";

export const TopNavBar: React.FunctionComponent = () => {
  const buttonClasses = buttonStyles();
  const { authenticationService } = useAuthenticationService();
  const history = useHistory<PortalLocationState>();
  const location = useLocation<PortalLocationState>();
  const window = getWindow();
  const { t: i18n } = useTranslation();
  const { isBreakpointAndDown } = useBreakpoint();
  const mobileView = isBreakpointAndDown(Breakpoint.Medium);
  const logger = useLogger()?.logger;
  const [
    popupErrorDialogIsVisible,
    { setTrue: openPopupErrorDialog, setFalse: dismissPopupErrorDialog },
  ] = useBoolean(false);

  const loginState = useSelector(loginStateSelector);
  const enableWorkAccountProfilePages = useSelector(
    enableWorkAccountProfilePagesSelector
  );

  const enableManageAccount =
    loginState === LoginState.Social ||
    (loginState === LoginState.Work && enableWorkAccountProfilePages);

  const popupBlockedDialog = (
    <Dialog
      open={popupErrorDialogIsVisible}
      onOpenChange={dismissPopupErrorDialog}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>
            {i18n("errors.auth_errors.popup_error_title")}
          </DialogTitle>
          <DialogContent>
            {i18n("errors.auth_errors.popup_error_body_logout")}
          </DialogContent>
          <DialogActions>
            <Button
              appearance="primary"
              className={buttonClasses.fluid}
              onClick={dismissPopupErrorDialog}
              aria-label={i18n("button_close")}
            >
              {i18n("button_close")}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );

  const onClickSignIn = () => {
    history.push({
      pathname: routes.login,
      search: generateSearchParams(location),
      state: {
        redirectPath: location.state?.from ?? {
          pathname: location.pathname,
          search: generateSearchParams(location),
          hash: location.hash,
        },
      },
    });
  };

  const onClickSignOut = () => {
    logger?.logUiTelemetry(
      "auth",
      UserBIScenario.Logout,
      "click",
      "none",
      "TopNavBar"
    );
    // TODO: Move this to user slice as action.
    authenticationService.logout(history.location.pathname).catch((err) => {
      logger?.logTrace(LoggerLevels.error, `Logout failed with error ${err}`);

      const errorResponse = getErrorResponse("logout", err);
      const isPopupError = isErrorResponsePopupWindowError(errorResponse);
      if (isPopupError) {
        openPopupErrorDialog();
      }
    });
  };

  // To add Manage account BI
  const onClickManageAccount = () => {
    logger?.logUiTelemetry(
      "manageAccount",
      UserBIScenario.ManageAccount,
      "click",
      "none",
      "TopNavBar"
    );

    const searchParams = generateSearchParams(location);
    const url = searchParams
      ? `${routes.profile}?${searchParams}`
      : routes.profile;

    window?.open(url, "_blank");
  };

  return (
    <>
      {popupBlockedDialog}
      {mobileView ? (
        <TopNavBarMobile
          enableManageAccount={enableManageAccount}
          onClickSignIn={onClickSignIn}
          onClickSignOut={onClickSignOut}
          onClickManageAccount={onClickManageAccount}
        />
      ) : (
        <TopNavBarWeb
          enableManageAccount={enableManageAccount}
          onClickSignIn={onClickSignIn}
          onClickSignOut={onClickSignOut}
          onClickManageAccount={onClickManageAccount}
        />
      )}
    </>
  );
};

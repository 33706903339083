import React from "react";
import { Helmet } from "react-helmet";
import { mergeClasses } from "../../shared";
import { removeHTML } from "../../utilities/common/utils";
import { defaultImageUrl } from "../../common/constants";
import { IEvent, EventType } from "../../core/slices/eventTypes.interface";
import { useSelector } from "react-redux";
import {
  enableSponsorsSelector,
  enableVODSelector,
} from "../../core/slices/ecsSlice";
import {
  eventSessionsSelector,
  isMultiSessionEventSelector,
} from "../../core/slices/sessionSlice";
import { flexStyles } from "../../styles/FlexStyles";
import { IEventInfo } from "./IEventInfo";
import { TopInfo } from "./TopInfo";
import { About } from "./About";
import { Speakers } from "./Speakers";
import { Sessions } from "./Sessions";
import { Sponsors } from "./Sponsors";
import Recording from "./Recording";
import { Breakpoint } from "../../styles/Grid";
import { useBreakpoint } from "../../utilities/hooks/useBreakpoints";
import { EventUser } from "../../core/slices/userTypes.interface";
import { eventSpeakersSelector } from "../../core/slices/eventSlice";
const RECORDING_HOST_VIEW = "EventPage";

export const EventInfo: React.FunctionComponent<{
  eventInfo: IEventInfo;
  currentEvent: IEvent;
}> = ({ eventInfo, currentEvent }) => {
  let descCleaned = "";
  if (currentEvent && currentEvent.description) {
    descCleaned = removeHTML(currentEvent.description);
  }
  const flexClasses = flexStyles();
  const { isBreakpointAndDown } = useBreakpoint();
  const sessions = useSelector(eventSessionsSelector);
  const isMobileView = isBreakpointAndDown(Breakpoint.Medium);
  const enableSponsors = useSelector(enableSponsorsSelector);
  const enableVOD = useSelector(enableVODSelector);
  const speakers: EventUser[] | undefined = useSelector(eventSpeakersSelector);
  const isMultiSessionEvent = useSelector(isMultiSessionEventSelector);

  return currentEvent ? (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        isMobileView ? flexClasses.columnGap32px : flexClasses.columnGap40px
      )}
    >
      <Helmet>
        <meta name="description" content={descCleaned} />
        <meta
          name="description"
          property="og:description"
          content={descCleaned}
        />
        <meta name="title" property="og:title" content={currentEvent.title} />
        <meta property="og:type" content="website" />
        <meta name="image" property="og:image" content={defaultImageUrl} />
        <meta property="og:link" content={window.location.href} />
      </Helmet>
      {enableVOD && !isMultiSessionEvent && sessions[0] && (
        <Recording
          event={currentEvent}
          session={sessions[0]}
          hostView={RECORDING_HOST_VIEW}
        />
      )}
      {isMobileView && <TopInfo eventInfo={eventInfo} event={currentEvent} />}
      {currentEvent.description && (
        <About descriptionText={currentEvent.description} />
      )}
      <Speakers speakers={speakers} />
      {(currentEvent.type === EventType.WEBINAR ||
        currentEvent.type === EventType.TOWNHALL) &&
        isMultiSessionEvent && <Sessions currentEvent={currentEvent} />}

      {enableSponsors && <Sponsors currentEvent={currentEvent} />}
    </div>
  ) : null;
};

import { ILogger } from "./interface";
import * as React from "react";

export interface ILoggerContext {
  logger: ILogger;
}

const defaultLoggerContext: ILoggerContext = {
  // Never type because this should never be undefined in our app,
  // the context value is set at the very top level of the React app.
  // Note: If we set the value here, a Logger instance would be
  // created on import of the file, which is not ideal for UTs.
  logger: undefined as never,
};

export const LoggerContext: React.Context<ILoggerContext> =
  React.createContext<ILoggerContext>(defaultLoggerContext);

export const useLogger = (): ILoggerContext => React.useContext(LoggerContext);

export interface IWithLoggerProps {
  logger: ILogger;
}

export type WithLoggerProps<T = {}> = T & IWithLoggerProps;

export function withLoggerContext<OriginalProps>(
  Component: React.ComponentType<OriginalProps & IWithLoggerProps>
): React.ComponentType<OriginalProps> {
  const LoggerConsumer = (props: OriginalProps): JSX.Element => {
    return (
      <LoggerContext.Consumer>
        {(value: ILoggerContext): JSX.Element => {
          const loggerProps: IWithLoggerProps = {
            logger: value.logger,
          };
          return <Component {...props} {...loggerProps} />;
        }}
      </LoggerContext.Consumer>
    );
  };

  return LoggerConsumer;
}

import * as React from "react";
import { noop } from "lodash";

interface IPortalFormValidatorContext {
  errorCount: number;
  addErrorCount: () => void;
  reduceErrorCount: () => void;
}

interface IPortalFormValidatorContextProviderProps {
  children: React.ReactNode;
}

const PortalFormValidatorContext =
  React.createContext<IPortalFormValidatorContext>({
    errorCount: 0,
    addErrorCount: noop,
    reduceErrorCount: noop,
  });

export const usePortalFormValidatorContext = (): IPortalFormValidatorContext =>
  React.useContext(PortalFormValidatorContext);

export const PortalFormValidatorProvider: React.FunctionComponent<
  IPortalFormValidatorContextProviderProps
> = ({ children }) => {
  const [errorCount, setErrorCount] = React.useState(0);
  const addErrorCount = React.useCallback(
    () => setErrorCount((prevState) => prevState + 1),
    [setErrorCount]
  );
  const reduceErrorCount = React.useCallback(
    () => setErrorCount((prevState) => prevState - 1),
    [setErrorCount]
  );

  return (
    <PortalFormValidatorContext.Provider
      value={{
        errorCount,
        addErrorCount,
        reduceErrorCount,
      }}
    >
      {children}
    </PortalFormValidatorContext.Provider>
  );
};

export const useInputIsInvalid: (
  currentIsInvalid: boolean
) => [boolean, (state: boolean) => void] = (currentIsInvalid: boolean) => {
  const [isInvalid, setIsInvalid] = React.useState(currentIsInvalid);
  const { addErrorCount, reduceErrorCount } = usePortalFormValidatorContext();
  React.useEffect(() => {
    if (isInvalid) {
      addErrorCount();
    }
    return () => {
      if (isInvalid) {
        reduceErrorCount();
      }
    };
  }, [addErrorCount, isInvalid, reduceErrorCount]);

  return [isInvalid, setIsInvalid];
};

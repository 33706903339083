import { makeStyles, shorthands, tokens, zIndexes } from "../shared";

export const topNavBarWebHeight = "60px";
const avatarWidth = 44; // px, Avatar size (Avatar size large).
const avatarNameEmailSpacing = 15; // px, Spacing between Avatar and name/email (Flex gap.medium).

export const topNavBarWebStyles = makeStyles({
  root: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: tokens.colorNeutralBackground1,
    zIndex: zIndexes.fixedMenu,
    boxShadow: tokens.shadow2,
    height: topNavBarWebHeight,
    ...shorthands.padding("0px", "20px", "0px", "20px"),
  },
  spacer: {
    height: topNavBarWebHeight,
  },
  accountWebContent: {
    ...shorthands.padding("0px"),
    ...shorthands.border("none"),
    ...shorthands.borderRadius("0px"),
    "text-decoration": "none",
    color: tokens.colorNeutralForeground1,
    fontWeight: tokens.fontWeightRegular,
    cursor: "pointer",
    ":hover": {
      color: tokens.colorNeutralForeground1,
    },
  },
  userProfileTriggerButton: {
    ...shorthands.borderWidth("0px"),
    ...shorthands.padding("0px"),
    minWidth: "auto",
  },
  userProfileContainer: {
    width: "320px",
    backdropFilter: "blur(50px)",
    backgroundColor: "rgba(252, 252, 252, 0.85)",
    ...shorthands.borderColor("rgba(0, 0, 0, 0.05)"),
  },
  loadingUserProfileContainer: {
    width: "320px",
    backdropFilter: "blur(50px)",
    backgroundColor: tokens.colorNeutralBackground2,
    ...shorthands.borderColor("rgba(0, 0, 0, 0.05)"),
  },
  userProfileButtonWrapper: {
    marginLeft: (avatarWidth + avatarNameEmailSpacing).toString() + "px",
  },
  userProfileAvatar: {
    width: "44px",
    height: "44px",
  },
  userProfileTextContainer: {
    ...shorthands.overflow("auto"),
  },
  userProfileEmail: {
    color: tokens.colorNeutralForeground3,
  },
});

import { ICache } from "./cache.interface";
import { ILogger, LoggerLevels } from "../../../common/logger/interface";
import { Logger } from "../../../common/logger/Logger";

export class LocalStorageTokenCache<T> implements ICache<T> {
  private logger: ILogger;
  private cache: Map<string, T & { cacheExpiration?: number }>;

  public constructor() {
    this.cache = new Map();
    this.logger = Logger.getInstance();
  }
  public put(key: string, value: T, expiration?: number): boolean {
    const valueWithExpiration = { ...value, cacheExpiration: expiration };
    try {
      // populate in-memory storage
      this.cache.set(key, valueWithExpiration);
      const serializedValue = JSON.stringify(valueWithExpiration);
      localStorage.setItem(key, serializedValue);
      return true;
    } catch (err) {
      this.logger.logTrace(
        LoggerLevels.error,
        `Error writing key ${key} to local storage: ${err}`
      );
      if (this.cache.has(key)) {
        this.cache.delete(key);
      }
      return false;
    }
  }

  public get(key: string): T | null {
    if (this.cache.has(key)) {
      const value = this.cache.get(key) as T & { cacheExpiration?: number };
      // existence check is redundant because of the .has() check but it shuts the
      // compiler up
      if (value && !this.hasExpired(value)) {
        const { cacheExpiration, ...valueWithoutExpiration } = value;
        return valueWithoutExpiration as T;
      } else {
        return null;
      }
    } else {
      //local storage is yet uninitialized/unpopulated
      if (!localStorage) return null;
      const valueFromBrowserStorage = localStorage.getItem(key);
      // Not present
      if (!valueFromBrowserStorage) {
        return null;
      }
      try {
        const deserializedValue = JSON.parse(valueFromBrowserStorage) as T & {
          cacheExpiration?: number;
        };
        if (this.hasExpired(deserializedValue)) {
          return null;
        }
        this.cache.set(key, deserializedValue);
        const { cacheExpiration, ...valueWithoutExpiration } =
          deserializedValue;
        return valueWithoutExpiration as T;
      } catch (err) {
        this.logger.logTrace(
          LoggerLevels.error,
          `Error deserializing value for key ${key} from local storage: ${err}`
        );
        return null;
      }
    }
  }

  public delete(key: string): void {
    if (this.cache.has(key)) {
      localStorage.removeItem(key);
      this.cache.delete(key);
    }
  }

  private hasExpired(value: T & { cacheExpiration?: number }): boolean {
    if (!value.cacheExpiration) {
      return false;
    }
    return value.cacheExpiration <= Date.now();
  }
}

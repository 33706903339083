import { makeStyles } from '@griffel/react';
import { typographyStyles } from '@fluentui/react-theme';
export const title2ClassNames = {
    root: 'fui-Title2'
};
/**
 * Styles for the root slot
 */ export const useTitle2Styles = makeStyles({
    root: typographyStyles.title2
});

/**
 * https://react.i18next.com/latest/using-with-hooks#configure-i-18-next
 * I18next is the core of the i18n functionality while react-i18next extends and glues it to react.
 */

import i18n, {
  FormatFunction,
  InterpolationOptions,
  ModuleType,
} from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi, { BackendOptions, RequestCallback } from "i18next-http-backend";
import { localeMap } from "./localeMap";
import { ILogger, LoggerLevels } from "../../common/logger/interface";
import { Logger } from "../../common/logger/Logger";
type LangType = typeof import("../locales/en-US/resources.json");

/**
 * If user specifies locale in url - example: somesite.com?locale=es
 * the urlParam being locale=es. We pass in the name as locale so we can get the
 * value. If no urlPrams then '' returned.
 * @param urlParams
 * @param name
 * @returns
 */

export function getUrlParameter(urlParams: string, name: string): string {
  const params = new URLSearchParams(urlParams);
  const param = params.get(name);
  return param ? param : "";
}

// regex to match the country-LANGUAGE format
// eg: en-US, fr-CA, zh-cn
const REG = /^([a-zA-Z]{2})-([a-zA-Z]{2})$/;

export function getLocale(): string {
  const urlParams = window && window.location ? window.location.search : "";
  const currLocale = toLocaleCase(getUrlParameter(urlParams, "locale"));
  const navigatorLocale = navigator.languages
    ? navigator.languages[0]
    : navigator.language;
  // locale in url to take priority over browser locale
  return currLocale
    ? localeMap[currLocale] || currLocale
    : localeMap[navigatorLocale] || navigatorLocale;
}

export function loadLocaleBundle(): Promise<LangType> {
  const logger: ILogger = Logger.getInstance();
  let locale = getLocale();
  if (!localeMap[locale]) {
    const language = locale.split("-")[0];
    locale = localeMap[language];
  }
  logger.logTrace(
    LoggerLevels.info,
    `[Localization Service] Found locale ${locale}`
  );
  if (locale !== "en-US") {
    return import(
      /* webpackChunkName: "en" */ `../locales/${locale}/resources.json`
    )
      .then((data) => data.default) // ES6 default import
      .catch((err) => {
        logger.logTrace(
          LoggerLevels.error,
          `[Localization Service] error ${err}`
        );
        return import(
          /* webpackChunkName: "en" */ "../locales/en-US/resources.json"
        );
      });
  }
  return import(/* webpackChunkName: "en" */ "../locales/en-US/resources.json");
}

export function toLocaleCase(locale: string): string {
  const match = locale.match(REG);
  let country = "";
  let language = "";

  if (!match || match.length === 1) {
    return locale;
  }

  if (match.length > 2) {
    language = match[1];
    country = match[2];
  } else {
    language = match[1];
  }
  return `${language.toLowerCase()}-${country.toUpperCase()}`;
}

const backendOptions: BackendOptions = {
  request: (
    _options: BackendOptions,
    _url: string,
    _payload: {} | string,
    callback: RequestCallback
  ) => {
    try {
      loadLocaleBundle().then((data) => {
        callback(null, {
          data: JSON.stringify(data),
          status: 200,
        });
      });
    } catch (e) {
      console.error(e);
      callback(null, {
        data: "",
        status: 500,
      });
    }
  },
};

const format: FormatFunction = (
  value: number | Date,
  format?: string,
  lng?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: InterpolationOptions & { [key: string]: any }
): string => {
  let valueFormatParams;
  if (options && options.formatParams && options.interpolationkey) {
    valueFormatParams = options.formatParams[options.interpolationkey];
  }

  if (format === "datetime") {
    return formatDate(value, lng, valueFormatParams);
  }
  return value ? value.toString() : "";
};

function formatDate(
  value: number | Date,
  lng?: string,
  options?: Intl.DateTimeFormatOptions
): string {
  return value ? new Intl.DateTimeFormat(lng, options).format(value) : "";
}

const languageDetector = {
  type: "languageDetector" as ModuleType,
  init: () => {},
  detect: () => getLocale(),
  cacheUserLanguage: () => {},
};

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    react: {
      useSuspense: false,
    },
    fallbackLng: "en",
    load: "currentOnly",
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format,
    },
    //There are few ways that translations can be loaded at runtime.
    //backend helps specify Backend Options that can be used to look at the source of translations
    //some options are:
    //1. Use a backend plugin and just specify loadpath - https://www.i18next.com/how-to/add-or-load-translations#load-using-a-backend-plugin
    //2. Use a backedn plugin that serves direct from CDN - https://github.com/locize/i18next-locize-backend
    //3. The option used here is to dynamically load the translation preference provided by either the users browser settings
    //or by the locale param in the url. To make this possible we resort to the approact in lines 68 to 83 to loadLocaleBundle
    //that reads the locale from navigator.language or locale param.
    backend: backendOptions,
  });

export default i18n;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  DatedSessionsMap,
  eventSessionsSelector,
  sessionsByDaySelector,
} from "../core/slices/sessionSlice";
import { IEventSession } from "../core/slices/session.interface";
import { SessionCardList } from "./SessionCard";
import { useTranslation } from "react-i18next";
import { ButtonV9 as Button, TextV9 as Text, mergeClasses } from "../shared";
import { eventSessionsStyles } from "../styles/EventSessions";
import { flexAlignStyles, flexStyles } from "../styles/FlexStyles";
import { portalTextStyles } from "../styles/PortalText";

// Component for Header,Buttons & SearchBar
const SessionPageHeader: React.FunctionComponent<{
  sessions: IEventSession[];
}> = ({ sessions }) => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const portalTextClasses = portalTextStyles();
  const { t: i18n } = useTranslation();
  return (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.rowGapMedium,
        flexAlignClasses.alignItemEnd
      )}
    >
      <Text
        id="eventSessionsCount"
        as="h2"
        className={portalTextClasses.larger}
        weight="semibold"
        aria-label={i18n("sessions", { count: sessions?.length })}
      >
        {`Sessions (${sessions ? sessions.length : i18n("tba")})`}
      </Text>
    </div>
  );
};

export const EventSessions: React.FunctionComponent = () => {
  const flexClasses = flexStyles();
  const eventSessionsClasses = eventSessionsStyles();
  const sessions: IEventSession[] = useSelector(eventSessionsSelector);
  const sessionsByDate: DatedSessionsMap | null = useSelector(
    sessionsByDaySelector
  );
  const [dateSelected, setDateSelected] = useState<string | null>(null);

  const filteredSessions =
    dateSelected && sessionsByDate ? sessionsByDate[dateSelected] : sessions;

  const startDate: string[] = [];

  const dates = [];

  const onClickDate = (date: string): void => {
    setDateSelected(date === dateSelected ? null : date);
  };
  const dates_id = [];
  for (const i in sessionsByDate) {
    dates_id.push(`button-${i}`);
  }

  let num = 0;
  for (const date in sessionsByDate) {
    const sessionCountByDate = `${date} (${sessionsByDate[date].length})`;
    const selected = date === dateSelected;
    dates.push(
      <Button
        id={dates_id[num]}
        key={date}
        className={
          selected ? eventSessionsClasses.dateButtonSelected : undefined
        }
        onClick={() => onClickDate(date)}
      >
        {sessionCountByDate}
      </Button>
    );
    startDate.push(date);
    num++;
  }

  return (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexClasses.columnGapMedium
      )}
    >
      {sessions.length > 0 && <SessionPageHeader sessions={sessions} />}
      <div className={mergeClasses(flexClasses.root, flexClasses.rowGapMedium)}>
        {dates}
      </div>
      <SessionCardList sessions={filteredSessions} />
    </div>
  );
};

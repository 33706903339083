import { makeStyles, shorthands, tokens } from "../shared";

export const eventNavBarWebStyles = makeStyles({
  root: {
    ...shorthands.borderBottom(
      "1px",
      "solid",
      tokens.colorNeutralBackgroundDisabled
    ),
  },
  container: {
    marginTop: "10px",
    marginBottom: "10px",
  },
});

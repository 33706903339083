import { makeStyles, tokens } from "../shared";

export const reportProblemDialogStyles = makeStyles({
  title: {
    fontWeight: tokens.fontWeightBold,
  },
  content: {
    marginBottom: "20px",
  },
  formElement: {
    "> :not(:first-child)": {
      marginTop: "12px",
    },
  },
});

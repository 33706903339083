import { makeStyles, shorthands, tokens } from "../shared";
import { Breakpoint, mediaQueryBreakpointDown } from "./Grid";

export const profilePageNavStyle = makeStyles({
  root: {
    ...shorthands.padding("8px", "0px"),
  },
  item: {
    backgroundColor: "transparent",
    ...shorthands.border("2px", "solid", "transparent"),
    [mediaQueryBreakpointDown(Breakpoint.Medium)]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    ":hover": {
      backgroundColor: tokens.colorNeutralBackground2,
    },
  },
  itemActive: {
    backgroundColor: tokens.colorNeutralBackground2,
  },
  itemContent: {
    color: tokens.colorNeutralForeground2,
    ...shorthands.border("1px", "solid", "transparent"),
    ...shorthands.padding("8px"),
  },
});

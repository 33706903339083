import { makeStyles, shorthands, tokens, zIndexes } from "../shared";

export const topNavBarMobileHeight = "48px"; //px

export const topNavBarMobileStyles = makeStyles({
  root: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: tokens.colorNeutralBackground1,
    zIndex: zIndexes.fixedMenu,
    boxShadow: tokens.shadow2,
    height: topNavBarMobileHeight,
    ...shorthands.padding("0", "14px", "0", "10px"),
  },
  spacer: {
    height: topNavBarMobileHeight,
  },
  userMenuCloseButton: {
    ...shorthands.padding(0),
    minWidth: 0,
    verticalAlign: "top",
  },
  userMenuDialogSurface: {
    animationTimingFunction: tokens.curveDecelerateMax,
    animationDuration: tokens.durationNormal,
    animationName: {
      from: {
        transform: "translateX(-200px)",
        opacity: "0",
      },
      to: {
        transform: "translateX(0px)",
        opacity: "1",
      },
    },
    animationFillMode: "forwards",
    ...shorthands.padding("20px"),
    ...shorthands.margin("0px"),
    width: "260px",
    height: "100%",
    ...shorthands.borderRadius("0px"),
  },
  userInfoWrapper: {
    ...shorthands.overflow("hidden"),
  },
  userChevronIcon: {
    color: tokens.colorNeutralForeground1,
  },
  userEmailText: {
    color: tokens.colorNeutralForeground3,
  },
  divider: {
    color: tokens.colorNeutralForeground3,
  },
  userInfoLoadingWrapper: {
    ...shorthands.overflow("auto"),
  },
  profileLink: {
    color: tokens.colorNeutralForeground2BrandHover,
    textDecorationLine: "none",
    ":hover": {
      textDecorationLine: "none",
    },
  },
  loadingUserDisplayName: {
    width: "100px",
  },
  loadingUserEmailText: {
    width: "150px",
  },
});

import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Route, Switch } from "react-router-dom";
import { LandingPage } from "./LandingPage";
import { Profile } from "./profile/ProfilePage";
import { Event } from "./EventPage";
import { EventRegistrationPage } from "./EventRegistrationPage";
import { RecurringWebinarInstances } from "./registration/RecurringWebinarInstances";
import { EventLoginPage } from "./EventLoginPage";
import { ILogger } from "../common/logger/interface";
import { ShortcutServiceContextProvider } from "../common/shortcut-service/ShortcutServiceContextProvider";
import { IShortcutService } from "../common/shortcut-service/interface";
import { ErrorBoundary } from "../common/errors/ErrorBoundary";
import { TopNavBar } from "./TopNavBar";
import {
  AuthenticationUnsuccessful,
  EventFetchUnsuccessful,
  PageNotFound,
  EventCancelled,
  TooManyRequests,
} from "./errorPages/ErrorPages";
import { UserDeleted } from "./successPages/SuccessPage";
import { routes } from "../common/constants";
import { useDispatch, useSelector } from "react-redux";
import { IEcsClient } from "../core/ecs/interface";
import {
  initOpenReportProblemDialogShortcutAsyncAction,
  isAuthServiceInitializedSelector,
} from "../core/slices/userSlice";
import { ReportProblemDialog } from "./ReportProblemDialog";
import { FluentProvider, mergeClasses } from "../shared";
import { Footer } from "./Footer";
import { Ecs } from "./Ecs";
import { eventSelector } from "../core/slices/eventSlice";
import { generateTheme } from "../styles/theme/customTheme";
import { ValidateEventIdRoute } from "./ValidateEventIdRoute";
import { UrlEncodedPathHandler } from "./UrlEncodedPathHandler";
import { DownloadLogsDialog } from "./DownloadLogsDialog";
import { flexItemStyles, flexStyles } from "../styles/FlexStyles";
import { appStyles } from "../styles/App";
import { SessionPage } from "./SessionPage";
import { useInitiateWorkLoginParamHandlerHook } from "../utilities/hooks/useInitiateWorkLoginParamHandlerHook";
import { useRedirectLoginErrorHandlerHook } from "../utilities/hooks/useRedirectLoginErrorHandlerHook";
import { AuthContext } from "../core/auth/auth-context";
import { AuthenticationService } from "../core/auth/authenticationService";
import { LoggerContext } from "../common/logger/LoggerContext";

const App: React.FunctionComponent<{
  logger: ILogger;
  shortcutService: IShortcutService;
  ecsClient: IEcsClient;
}> = ({ logger, shortcutService, ecsClient }) => {
  return (
    <AuthContext.Provider
      value={{ authenticationService: AuthenticationService.getInstance() }}
    >
      <LoggerContext.Provider value={{ logger }}>
        <ErrorBoundary>
          <Ecs ecsClient={ecsClient}>
            <IsAuthServiceInitializedHandler>
              <AppInternal shortcutService={shortcutService} />
            </IsAuthServiceInitializedHandler>
          </Ecs>
        </ErrorBoundary>
      </LoggerContext.Provider>
    </AuthContext.Provider>
  );
};

export default App;

const AppInternal: React.FunctionComponent<{
  shortcutService: IShortcutService;
}> = ({ shortcutService }) => {
  const appClasses = appStyles();
  const flexClasses = flexStyles();
  const flexItemClasses = flexItemStyles();
  const dispatch = useDispatch();
  const {
    i18n: { resolvedLanguage },
  } = useTranslation();

  dispatch(initOpenReportProblemDialogShortcutAsyncAction(shortcutService));
  useRedirectLoginErrorHandlerHook();
  useInitiateWorkLoginParamHandlerHook();

  const navBar = (
    <Switch>
      <Route
        path={[
          routes.fetchError,
          routes.tooManyRequests,
          routes.notFound,
          routes.eventCanceled,
          routes.profile,
          `${routes.event}/:id/${routes.registration}`,
          `${routes.event}/:id/${routes.webinarSeries}`,
          `${routes.event}/:id/${routes.attendee}/:regId/cancel`,
          `${routes.event}/:id/${routes.attendee}/:regId`,
          `${routes.event}/:id/${routes.session}/:sessionId`,
          `${routes.event}/:id`,
        ]}
        component={TopNavBar}
      />
    </Switch>
  );

  const pageContent = (
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <Route exact path={routes.event} component={LandingPage} />
      <Route path={routes.userDeleted} component={UserDeleted} />
      <Route path={routes.authError} component={AuthenticationUnsuccessful} />
      <Route path={routes.profile} component={Profile} />
      <ValidateEventIdRoute
        path={`${routes.event}/:id/${routes.registration}`}
        component={EventRegistrationPage}
      />
      <ValidateEventIdRoute
        path={`${routes.event}/:id/${routes.webinarSeries}`}
        component={RecurringWebinarInstances}
      />
      <ValidateEventIdRoute
        path={`${routes.event}/:id/${routes.session}/:sessionId`}
        component={SessionPage}
      />
      <ValidateEventIdRoute path={`${routes.event}/:id`} component={Event} />
      <Route path={routes.eventCanceled} component={EventCancelled} />
      <Route path={routes.fetchError} component={EventFetchUnsuccessful} />
      <Route path={routes.tooManyRequests} component={TooManyRequests} />
      <Route path={routes.notFound} component={PageNotFound} />
      <Route path={routes.userDeleted} component={UserDeleted} />
      <Route path={routes.login} component={EventLoginPage} />
      <PageNotFound />
    </Switch>
  );

  const pageFooter = <Footer />;

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: resolvedLanguage ? resolvedLanguage.split("-")[0] : "en",
        }}
      />
      <ShortcutServiceContextProvider shortcutService={shortcutService}>
        <EventColoredThemeHandlerv9>
          <UrlEncodedPathHandler>
            <div
              className={mergeClasses(
                "page-wrapper",
                flexClasses.root,
                flexClasses.column,
                appClasses.root
              )}
            >
              {navBar}
              <div
                className={mergeClasses(
                  "page-content",
                  flexClasses.root,
                  flexClasses.column,
                  flexItemClasses.grow,
                  flexClasses.fill
                )}
              >
                {pageContent}
              </div>
              {pageFooter}
            </div>
            <ReportProblemDialog />
            <DownloadLogsDialog />
          </UrlEncodedPathHandler>
        </EventColoredThemeHandlerv9>
      </ShortcutServiceContextProvider>
    </>
  );
};

/* istanbul ignore next */
const EventColoredThemeHandlerv9: React.FunctionComponent = ({ children }) => {
  const currentEvent = useSelector(eventSelector);
  const eventTheme = currentEvent?.theme;

  const theme = generateTheme(eventTheme?.primaryColor);
  return <FluentProvider theme={theme}>{children}</FluentProvider>;
};

const IsAuthServiceInitializedHandler: React.FunctionComponent = ({
  children,
}) => {
  const isAuthenicationServiceInitialized = useSelector(
    isAuthServiceInitializedSelector
  );

  return <>{isAuthenicationServiceInitialized && children}</>;
};

import { makeStyles, shorthands, tokens } from "../shared";
import { Breakpoint, mediaQueryBreakpointUp } from "./Grid";
import { topNavBarWebHeight } from "./TopNavBarWeb";

export const profilePageStyles = makeStyles({
  root: {
    [mediaQueryBreakpointUp(Breakpoint.Large)]: {
      backgroundColor: tokens.colorNeutralBackground2,
    },
  },
  container: {
    marginBottom: "30px",
    marginTop: "15px",
    [mediaQueryBreakpointUp(Breakpoint.Large)]: {
      marginTop: "30px",
    },
  },
  navBar: {
    flexBasis: "320px",
    position: "sticky",
    top: 0,
    height: `calc(100vh - ${topNavBarWebHeight}px)`,
    backgroundColor: tokens.colorNeutralBackground3,
    boxShadow: tokens.shadow4,
  },
});

export const profilePageHeaderMobileStyles = makeStyles({
  root: {
    height: "48px",
    position: "relative",
  },
  backPath: {
    textDecorationLine: "none",
    color: tokens.colorNeutralForeground1,
    minWidth: 0,
  },
  header: {
    ...shorthands.margin("0px"),
  },
});

import { IShortcutService } from "../shortcut-service/interface";

export enum LoggerLevels {
  info = "inf",
  debug = "dbg",
  log = "log",
  warn = "warn",
  error = "err",
}
export declare type Gesture =
  | "click"
  | "touch"
  | "scroll"
  | "hover"
  | "drop"
  | "none";

export declare type Outcome = "none" | "init" | "success" | "failure";

export declare type ScenarioType =
  | "auth"
  | "clickButton"
  | "event"
  | "eventRegistration"
  | "renderComponent"
  | "shareEvent"
  | "triggerComponent"
  | "triggerNavigation"
  | "asyncAction"
  | "manageAccount"
  | "profileAvatar"
  | "exportAccount"
  | "deleteAccount"
  | "validation"
  | "privacyLink"
  | "seeMoreLink"
  | "addToCalendar";

/**
 * Details bag for logs
 * @param error Message
 * @param details Details (callstack, source, stringified details)
 */
export interface ITelemetryDetails {
  error?: Error;
  details?: string;
  method?: string;
  data?: ITelemetryData; // An optional field that can be used for contextual data
}

export type ITelemetryDataBag =
  | string
  | number
  | boolean
  | IObjectMap<string | number | boolean | ITelemetryData>;

// Interface for key value data.
export type ITelemetryData = IObjectMap<string | number | boolean>;

export interface ILogger {
  initShortcut(shortcutService: IShortcutService): void;

  download(): void;

  sessionId: string;
  teamsSessionId: string;
  deployment: string;
  version: string;
  userAgent: string;

  getDeviceInfo(): IDeviceInfo;

  getCommonPropertiesToLog?: () => ITelemetryData;

  /**
   * Used to log scenario relative data
   * @param name Scenario name
   * @param eventData Data of the event
   * @param completeScenario if the scenario should be stopped
   */
  logScenario: (
    name: string,
    eventData: IScenarioEventData,
    completeScenario: boolean
  ) => void;

  /**
   * Used to log an exception/error when callstack is available
   * @param message Message
   * @param details Details (callstack, source, stringified details)
   */
  logException: (message: string, details: ITelemetryDetails) => void;

  /**
   * Used to log a performance signal
   * @param source Metric
   * @param result Result (true/false)
   * @param duration Time measurement in MS
   * @param details Other details as needed
   */
  logPerformance: (
    source: string,
    result: boolean,
    duration: number,
    correlationVector?: string,
    details?: ITelemetryDetails,
    scenario?: IScenarioLogger
  ) => void;

  /**
   * Used to log a UI Event
   * @param scenarioType The type of scenario
   * @param Scenario The action scenario
   * @param Gesture The action gesture
   * @param actionOutcome The action outcome
   * @param sourceElement The element in the component
   * @param dataBag Other details as needed
   */
  logUiTelemetry: (
    scenarioType: ScenarioType,
    scenario: string,
    gesture: Gesture,
    actionOutcome: Outcome,
    sourceElement: string,
    dataBag?: ITelemetryDataBag
  ) => void;

  /**
   * Used to log a user Action
   * @param scenarioType The type of scenario
   * @param Scenario The action scenario
   * @param actionOutcome The action outcome
   * @param dataBag Other details as needed
   */
  logAction: (
    scenarioType: ScenarioType | ScenarioName,
    scenario: string,
    actionOutcome?: Outcome,
    dataBag?: ITelemetryDataBag
  ) => void;

  /**
   * Used to log an API Action
   * @param nonUiComponent The non UI component
   * @param event The event
   * @param details Other details as needed
   */
  logNonUiTelemetry: (
    nonUiComponent: string,
    outcome: Outcome,
    details?: ITelemetryDataBag
  ) => void;

  /**
   * Used to log trace telemetry
   * @param level The log level to specify
   * @param message The message to log
   * @param options Additional telemetry details to log
   */
  logTrace: (
    level: LoggerLevels,
    message: string,
    options?: ITelemetryDetails
  ) => void;

  /**
   * Creates a scenario logger
   * @param name The name of the scenario
   */
  createScenario(
    name: ScenarioName,
    eventData?: Partial<IScenarioEventData>
  ): IScenarioLogger | null;

  /**
   * Finds an active scenario logger
   * @param name The name of the scenario
   */
  findScenario(name: ScenarioName): IScenarioLogger | null;

  /**
   * Insert to active scenario loggers
   * @param scenario The scenario
   */
  insertScenario(scenario: IScenarioLogger | null): boolean;

  /**
   * Stop an active scenario logger
   * @param name The name of the scenario
   * @param eventData The event data
   */
  stopScenario(
    name: ScenarioName,
    eventData?: Partial<IScenarioEventData>
  ): void;

  /**
   * Fail an active scenario logger
   * @param name The name of the scenario
   * @param eventData The event data
   */
  failScenario(
    name: ScenarioName,
    eventData?: Partial<IScenarioEventData>
  ): void;

  getLogs(): string;
}

export type ScenarioName = string;

/**
 * Only require to capture message and data. All other fields are calculated
 */
export interface IScenarioEventData {
  id: string;
  step: string;
  status?: string;
  delta: number;
  elapsed: number;
  stepDelta: number;
  sequence: number;
  message?: string;
  data?: ITelemetryData;
}

export interface IScenarioLogger {
  id: string;
  name: string;
  isScenarioStopped(): boolean;
  mark(
    step: string,
    status?: string,
    eventData?: Partial<IScenarioEventData>
  ): void;
  stop(eventData?: Partial<IScenarioEventData>): void;
  fail(eventData?: Partial<IScenarioEventData>): void;
}

export interface IScenarioLoggerJsonObject {
  id: string;
  name: string;
  eventData: IScenarioEventData;
  sequence: number;
  isScenarioComplete: boolean;
  intervalTimestamp: number;
  relativeTimestamp: number;
}

export interface IDeviceInfo {
  browserName?: string;
  browserVer?: string;
  osName?: string;
  osVer?: string;
}
export interface IObjectMap<T> {
  [key: string]: T;
}

import {
  createAsyncThunk,
  createSlice,
  createAction,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Guid } from "./CMDTypes";
import { fetchAMSImage } from "./eventAPI";
import { IError, getErrorResponse } from "./error";
import { RootState } from "../store/store";
import { imageMap, IImageResponse } from "./image.interface";

export interface IEventImagesState {
  images: imageMap;
  imageError: IError | undefined;
}

const getImageAction: string = "images/getImage";
export const getImageAsyncAction = createAsyncThunk<
  IImageResponse,
  Guid,
  { rejectValue: IError }
>(
  getImageAction,
  async (imageID: Guid, { rejectWithValue }) => {
    try {
      const imageData = await fetchAMSImage(imageID);
      const response: IImageResponse = {
        id: imageID,
        imageData: imageData,
      };
      return response;
    } catch (error) {
      const err: IError = getErrorResponse(getImageAction, error);
      return rejectWithValue(err);
    }
  },
  {
    // check to see if we already have the image store
    condition: (imageID: Guid, { getState, extra }) => {
      const { images } = getState() as RootState;
      if (imageID in images.images) {
        return false;
      }
    },
  }
);

const initialState: IEventImagesState = {
  images: {},
  imageError: undefined,
};

export const resetEventImagesAction = createAction<boolean>("images/reset");

export const imagesSlice = createSlice({
  name: "images",
  initialState: initialState,
  reducers: {
    clearImageError: (state) => {
      state.imageError = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        resetEventImagesAction,
        (state: IEventImagesState, { payload }: PayloadAction<boolean>) => {
          if (!process.env.noop_api && payload) {
            Object.assign(state, initialState);
          }
        }
      )
      .addCase(
        getImageAsyncAction.fulfilled,
        (
          state: IEventImagesState,
          { payload }: PayloadAction<IImageResponse>
        ) => {
          if (!process.env.noop_api && payload) {
            state.imageError = undefined;
            state.images[payload.id] = payload.imageData;
          }
        }
      )
      .addCase(getImageAsyncAction.rejected, (state, action) => {
        state.imageError = action.payload;
      });
  },
});

// selectors

export const imageSelector =
  (imageID: Guid | null) =>
  (state: RootState): string | undefined | null => {
    if (state.images?.images && imageID) {
      return state.images?.images[imageID];
    } else {
      return null;
    }
  };

export const imagesSelector = (state: RootState): imageMap | undefined =>
  state.images?.images;

export const eventImageErrorSelector = (state: RootState): IError | undefined =>
  state.images?.imageError;

// reducer
export default imagesSlice.reducer;

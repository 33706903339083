import React, { useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { eventSelector, logoImageSelector } from "../../core/slices/eventSlice";
import { eventSessionsSelector } from "../../core/slices/sessionSlice";
import {
  registrationPageStyles,
  registrationFormStyles,
} from "../../styles/EventRegistration";
import { logoImageStyles } from "../../styles/LogoImage";
import { webinarInstancesStyles } from "../../styles/RecurringWebinarInstances";
import { IEventSession } from "../../core/slices/session.interface";
import {
  TextV9 as Text,
  ImageV9 as Image,
  mergeClasses,
  ButtonV9 as Button,
  RadioGroup,
  Radio,
  RadioProps,
} from "../../shared";
import { routes } from "../../common/constants";
import { HeroImage } from "../HeroImage";
import ScrollToTop from "../../utilities/common/utils";
import { gridStyles } from "../../styles/Grid";
import { flexAlignStyles, flexStyles } from "../../styles/FlexStyles";
import { portalTextStyles } from "../../styles/PortalText";
import { generateSearchParams } from "../../utilities/common/generateSearchParams";
import { PortalLocationState } from "../../core/history/history.interface";

/**
 * Recurring Webinar component should display all the available sessions with their dates.
 * The user must select one in order to continue to the Registration Form
 *
 */
export const RecurringWebinarInstances: React.FunctionComponent = () => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const portalTextClasses = portalTextStyles();
  const gridClasses = gridStyles();
  const webinarInstancesClasses = webinarInstancesStyles();
  const logoImageClasses = logoImageStyles();
  const registrationPageClasses = registrationPageStyles();
  const registrationFormClasses = registrationFormStyles();
  const history = useHistory<PortalLocationState>();
  const location = useLocation<PortalLocationState>();
  const { id } = useParams<{ id: string }>();
  const sessions: IEventSession[] = useSelector(eventSessionsSelector);
  const logoImage = useSelector(logoImageSelector);
  const currentEvent = useSelector(eventSelector);
  const { t: i18n } = useTranslation();
  const [sessionSelected, setSessionSelected] = useState("");
  ScrollToTop();
  const routeToRegistration: () => void = () => {
    /* istanbul ignore else */
    if (sessionSelected) {
      history.push({
        pathname: `${routes.event}/${id}/${routes.registration}`,
        search: generateSearchParams(location),
      });
    }
  };

  const changeHandler: (value: string | number | undefined) => void = (
    value
  ) => {
    /* istanbul ignore else */
    if (value) {
      setSessionSelected(value as string);
    }
  };

  const sessionsOptions: RadioProps[] = [];
  let webinarInstances = null;
  let sessionCounter = 1;
  if (sessions.length > 0) {
    sessions.forEach((session) => {
      let disableInstance = false;
      const currentTime = new Date();
      const startTime = new Date(session.sessionTime.startTime);
      const endTime = new Date(session.sessionTime.endTime);
      if (endTime.getTime() < currentTime.getTime()) {
        disableInstance = true;
      }
      const startTimeText = i18n("{{value, datetime}}", {
        value: startTime,
        formatParams: {
          value: {
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
          },
        },
      });
      const endTimeText = i18n("{{value, datetime}}", {
        value: endTime,
        formatParams: {
          value: {
            hour: "numeric",
            minute: "numeric",
          },
        },
      });

      sessionsOptions.push({
        id: "session" + sessionCounter,
        key: session.id,
        label: disableInstance
          ? startTimeText + " - " + endTimeText + " " + i18n("instance_ended")
          : startTimeText + " - " + endTimeText,
        value: session.id,
        disabled: disableInstance,
      });
      sessionCounter += 1;
    });

    webinarInstances = (
      <div className={webinarInstancesClasses.root}>
        <RadioGroup id={currentEvent?.id} layout="vertical">
          {sessionsOptions.map((eachSession: RadioProps) => (
            <Radio
              key={eachSession.key}
              value={eachSession.value}
              label={eachSession.label}
              aria-disabled={eachSession.disabled}
              aria-checked={!eachSession.disabled}
              data-testid={eachSession.id}
              onChange={(e, data) => {
                changeHandler(data.value as string);
              }}
            />
          ))}
        </RadioGroup>
      </div>
    );
  }

  const webinarInstancesDisplay = (
    <div className={mergeClasses(gridClasses.row, "repeat-webinar-form")}>
      <div
        className={mergeClasses(
          gridClasses.col,
          gridClasses.xs12,
          gridClasses.lg8,
          gridClasses.offsetLg2,
          webinarInstancesClasses.cardArea
        )}
      >
        <div
          className={mergeClasses(
            "registration-form-card",
            registrationFormClasses.card
          )}
        >
          <div
            className={mergeClasses(
              gridClasses.col,
              gridClasses.xs12,
              gridClasses.md10,
              gridClasses.offsetMd1
            )}
          >
            <div
              className={mergeClasses(
                "registration-form-header",
                registrationFormClasses.header
              )}
            >
              <div className={mergeClasses(gridClasses.row)}>
                <div
                  className={mergeClasses(
                    gridClasses.col,
                    gridClasses.xs12,
                    gridClasses.md10
                  )}
                >
                  <div className={webinarInstancesClasses.headerWrapper}>
                    <Image
                      src={logoImage.image}
                      className={mergeClasses(
                        logoImageClasses.root,
                        registrationFormClasses.headerImage
                      )}
                    />
                    <Text className={portalTextClasses.large} weight="bold">
                      {currentEvent?.title}
                    </Text>
                    <div
                      className={mergeClasses(
                        flexClasses.root,
                        flexAlignClasses.justifyContentCenter,
                        flexAlignClasses.alignItemCenter,
                        webinarInstancesClasses.dropInBackground
                      )}
                    >
                      <Text className={portalTextClasses.small}>
                        {i18n("drop_in")}
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={mergeClasses(
                flexClasses.root,
                flexClasses.column,
                flexClasses.columnGapLarge,
                webinarInstancesClasses.registrationForm
              )}
            >
              <div
                className={mergeClasses(
                  flexClasses.root,
                  flexClasses.rowGapMedium
                )}
              >
                <Text
                  className={mergeClasses(
                    portalTextClasses.large,
                    webinarInstancesClasses.numberCircle
                  )}
                  weight="bold"
                >
                  {1}
                </Text>
                &nbsp;&nbsp;
                <Text className={portalTextClasses.large} weight="bold">
                  {i18n("choose_a_date")}
                </Text>
              </div>

              {webinarInstances}
              <Button
                className={mergeClasses(
                  "register-button",
                  webinarInstancesClasses.continueToRegistrationButton
                )}
                disabled={sessionSelected.length === 0}
                appearance="primary"
                onClick={routeToRegistration}
              >
                {i18n("continue_to_registration_information")}
              </Button>
              <div
                className={mergeClasses(
                  flexClasses.root,
                  flexClasses.rowGapMedium
                )}
              >
                <Text
                  className={mergeClasses(
                    portalTextClasses.large,
                    webinarInstancesClasses.numberCircle
                  )}
                  weight="bold"
                >
                  {2}
                </Text>
                &nbsp;&nbsp;
                <Text className={portalTextClasses.large} weight="bold">
                  {i18n("registration_information")}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const recurringInstances = (
    <div role="main">
      <HeroImage />
      <div
        className={mergeClasses(
          gridClasses.container,
          registrationPageClasses.container
        )}
      >
        {webinarInstancesDisplay}
      </div>
    </div>
  );

  return recurringInstances;
};

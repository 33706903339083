import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  Redirect,
  useLocation,
} from "react-router-dom";
import React from "react";
import { ProfileAccountInfo } from "./ProfileAccountInfo";
import { ProfilePublicProfile } from "./ProfilePublicProfile";
import { profilePageStyles } from "../../styles/ProfilePage";
import { useEffect } from "react";
import {
  authenticatedSelector,
  getMeAsyncAction,
} from "../../core/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { Breakpoint } from "../../styles/Grid";
import { mergeClasses } from "../../shared";
import { routes } from "../../common/constants";
import { ProfilePageNav } from "./ProfilePageNav";
import { ProfileAccountSettings } from "./../profile/ProfileAccountSettings";
import {
  enableAccountSettingsPageSelector,
  enablePublicProfilePageSelector,
} from "../../core/slices/ecsSlice";
import { useBreakpoint } from "../../utilities/hooks/useBreakpoints";
import { ProfilePageHeaderMobile } from "./ProfilePageHeaderMobile";
import { useTranslation } from "react-i18next";
import {
  flexAlignStyles,
  flexItemStyles,
  flexStyles,
} from "../../styles/FlexStyles";
import { generateSearchParams } from "../../utilities/common/generateSearchParams";
import { PortalLocationState } from "../../core/history/history.interface";

export const Profile: React.FunctionComponent = () => {
  const flexItemClasses = flexItemStyles();
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const profilePageClasses = profilePageStyles();
  const { t: i18n } = useTranslation();
  const { path } = useRouteMatch();
  const isAuthenticated = useSelector(authenticatedSelector);
  const enablePublicProfilePage = useSelector(enablePublicProfilePageSelector);
  const enableAccountSettingsPage = useSelector(
    enableAccountSettingsPageSelector
  );
  const dispatch = useDispatch();
  const history = useHistory<PortalLocationState>();
  const location = useLocation<PortalLocationState>();
  const { isBreakpointAndDown } = useBreakpoint();
  const mobileView = isBreakpointAndDown(Breakpoint.Medium);

  useEffect(() => {
    // get our identity
    if (isAuthenticated) {
      dispatch(getMeAsyncAction(true));
    }
  }, [isAuthenticated, dispatch]);

  if (!isAuthenticated) {
    history.push({
      pathname: routes.login,
      search: generateSearchParams(location),
      state: {
        redirectPath: {
          pathname: location.pathname,
          search: generateSearchParams(location),
          hash: location.hash,
        },
      },
    });
  }

  const profilePageNav = (
    <div
      role="navigation"
      className={mergeClasses(
        "profile-page-nav-bar",
        profilePageClasses.navBar
      )}
    >
      <ProfilePageNav />
    </div>
  );

  const profileRouteContent = mobileView ? (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexClasses.fill
      )}
    >
      <ProfilePageHeaderMobile header={i18n("profile_header")} />
      <ProfilePageNav />
    </div>
  ) : (
    <Redirect
      to={{
        pathname: `${path}/account-info`,
        search: generateSearchParams(location),
      }}
    />
  );

  const content = (
    <div
      className={mergeClasses(
        "profile-page",
        profilePageClasses.root,
        flexClasses.root,
        flexItemClasses.grow
      )}
    >
      <div
        className={mergeClasses(
          flexClasses.root,
          flexItemClasses.grow,
          flexAlignClasses.justifyContentStretch
        )}
      >
        {!mobileView && profilePageNav}
        <div
          className={mergeClasses(flexClasses.root, flexClasses.fill)}
          role="main"
        >
          <Switch>
            <Route exact path={`${path}/`}>
              {profileRouteContent}
            </Route>
            <Route
              path={`${path}/account-info`}
              component={ProfileAccountInfo}
            />
            {enablePublicProfilePage && (
              <Route
                path={`${path}/public-profile`}
                component={ProfilePublicProfile}
              />
            )}
            {enableAccountSettingsPage && (
              <Route
                path={`${path}/account-settings`}
                component={ProfileAccountSettings}
              />
            )}
            <Redirect to={routes.notFound} />
          </Switch>
        </div>
      </div>
    </div>
  );

  return content;
};

import { topNavBarWebHeight } from "./TopNavBarWeb";
import { makeStyles, shorthands } from "../shared";

export const eventLayoutStyles = makeStyles({
  root: {
    height: `calc(100vh - ${topNavBarWebHeight}px)`,
    width: "100vw",
  },
  content: {
    height: "100%",
    overflowY: "auto",
    /**
     * This so that Fluent UI's screen reader elements which uses position absolute doesn't place the element
     * relative to the window and stretch the height of the window.
     * Due to the right side bar and the content's scroll bar being left of it, we want the height of
     * the window same as the browser window.
     */
    position: "relative",
  },
  engagementRail: {
    width: "320px",
    height: "100%",
    ...shorthands.overflow("auto"),
  },
});

import { makeStyles, shorthands, tokens } from "../shared";
import { Breakpoint, mediaQueryBreakpointDown } from "./Grid";

export const eventNavStyles = makeStyles({
  item: {
    ...shorthands.padding("0px"),
    ":hover": {
      backgroundColor: "transparent",
    },
    ">.fui-MenuItem__content": {
      ...shorthands.padding("0px"),
    },
    [mediaQueryBreakpointDown(Breakpoint.Medium)]: {
      ...shorthands.border("2px", "solid", "transparent"),
    },
  },
  itemContent: {
    color: tokens.colorNeutralForeground2,
    textDecorationLine: "none",
    ":hover": {
      ...shorthands.borderBottom("4px", "solid", tokens.colorNeutralStroke2),
    },
    [mediaQueryBreakpointDown(Breakpoint.Medium)]: {
      ...shorthands.border("1px", "solid", "transparent"),
      ":hover": {
        ...shorthands.borderBottom("1px", "solid", "transparent"),
      },
      ...shorthands.padding("8px", "0px"),
      fontSize: "17px",
      fontWeight: tokens.fontWeightRegular,
    },
  },
  itemContentActive: {
    fontWeight: tokens.fontWeightSemibold,
    ...shorthands.borderBottom("4px", "solid", tokens.colorBrandStroke1),
    [mediaQueryBreakpointDown(Breakpoint.Medium)]: {
      color: tokens.colorBrandBackground,
    },
  },
});

import { makeStyles } from "../shared";

const profileEditAccountFieldsGap = 24;
const profileEditAccountFieldsGapBottom = 20;
export const profileEditAccountFieldsStyles = makeStyles({
  root: {
    // Using !important because the gutter style of the grid system which includes a media query is more specific, and has more priority.
    // Griffel from Fluent UI v9 also does not allow specifying class name for makeStyles classes to give it more priority.
    marginLeft: `${-profileEditAccountFieldsGap / 2}px !important`,
    marginRight: `${-profileEditAccountFieldsGap / 2}px !important`,
    marginBottom: `${-profileEditAccountFieldsGapBottom}px`,
    "> *": {
      paddingLeft: `${profileEditAccountFieldsGap / 2}px !important`,
      paddingRight: `${profileEditAccountFieldsGap / 2}px !important`,
      marginBottom: `${profileEditAccountFieldsGapBottom}px`,
    },
  },
});

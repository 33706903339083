import { routes } from "../common/constants";
import {
  AuthzEndpointEnv,
  AuthzEndpoints,
} from "../core/auth/authenticationService.interface";

const baseUrl = process.env.PUBLIC_URL + "/";

const authUrlPopup = baseUrl + routes.authPopup + "/";
const authUrlRedirect = baseUrl + routes.authRedirect + "/";
export const isGCCHOrDodCloud = (): boolean => {
  return (
    process.env.REACT_APP_AUTHZ_ENDPOINT_ENV === "GCCH" ||
    process.env.REACT_APP_AUTHZ_ENDPOINT_ENV === "DOD"
  );
};
export const isGCCHCloud = (): boolean => {
  return process.env.REACT_APP_AUTHZ_ENDPOINT_ENV === "GCCH";
};
export const isDODCloud = (): boolean => {
  return process.env.REACT_APP_AUTHZ_ENDPOINT_ENV === "DOD";
};
export const SOCIAL_MSAL_CONFIG = {
  auth: {
    clientId: "aa0284f0-ed5a-4930-a1b4-c0ae771d4a16",
    authority:
      "https://mstvirtualevents.b2clogin.com/mstvirtualevents.onmicrosoft.com/B2C_1A_slsignup_signin",
    knownAuthorities: ["mstvirtualevents.b2clogin.com"],
    redirectUri: authUrlPopup,
    postLogoutRedirectUri: baseUrl,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    iframeHashTimeout: 10000,
  },
};

export const WORK_MSAL_CONFIG = {
  auth: {
    clientId: "392c0cd5-b73d-42f5-9e94-49904793f11c",
    authority: isGCCHOrDodCloud()
      ? "https://login.microsoftonline.us/common/"
      : "https://login.microsoftonline.com/common/",
    redirectUri: authUrlPopup,
    postLogoutRedirectUri: baseUrl,
    navigateToLoginRequestUrl: true,
    knownAuthorities: [],
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    iframeHashTimeout: 10000,
  },
};
export const MSAL_REDIRECT_URI_FOR_REDIRECT_LOGIN = authUrlRedirect;

export const DELETE_MSAL_CONFIG = {
  auth: {
    clientId: "aa0284f0-ed5a-4930-a1b4-c0ae771d4a16",
    authority:
      "https://mstvirtualevents.b2clogin.com/mstvirtualevents.onmicrosoft.com/B2C_1A_sldelete_my_account",
    knownAuthorities: ["mstvirtualevents.b2clogin.com"],
    redirectUri: baseUrl,
    postLogoutRedirectUri: baseUrl,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    iframeHashTimeout: 10000,
  },
};

export const SCOPES_BY_LOGIN_TYPE = {
  social: {
    cmd_services:
      "https://mstvirtualevents.onmicrosoft.com/7fc21101-d09b-4343-8eb3-21187e0431a4/Events.ReadWrite",
    skype_spaces: "https://api.spaces.skype.com/user_impersonation",
  },
  work: {
    cmd_services: "7fc21101-d09b-4343-8eb3-21187e0431a4/Events.ReadWrite email",
    skype_spaces: "https://api.spaces.skype.com/user_impersonation",
  },
};

export const PROD_AUTHZ_ENDPOINTS: AuthzEndpoints = {
  VISITOR_SKYPE_TOKEN:
    "https://teams.microsoft.com/api/authsvc/v1.0/authz/visitor",
  AUTH_SKYPE_TOKEN: "https://teams.microsoft.com/api/authsvc/v1.0/authz/",
};

export const GCC_AUTHZ_ENDPOINTS: AuthzEndpoints = {
  VISITOR_SKYPE_TOKEN:
    "https://teams.microsoft.com/api/authsvc/v1.0/authz/visitor",
  AUTH_SKYPE_TOKEN: "https://teams.microsoft.com/api/authsvc/v1.0/authz/",
};

export const GCCH_AUTHZ_ENDPOINTS: AuthzEndpoints = {
  VISITOR_SKYPE_TOKEN:
    "https://gov.teams.microsoft.us/api/authsvc/v1.0/authz/visitor",
  AUTH_SKYPE_TOKEN: "https://gov.teams.microsoft.us/api/authsvc/v1.0/authz/",
};

export const DOD_AUTHZ_ENDPOINTS: AuthzEndpoints = {
  VISITOR_SKYPE_TOKEN:
    "https://dod.teams.microsoft.us/api/authsvc/v1.0/authz/visitor",
  AUTH_SKYPE_TOKEN: "https://dod.teams.microsoft.us/api/authsvc/v1.0/authz/",
};

export const AUTHZ_ENDPOINT_ENV: AuthzEndpointEnv = process.env
  .REACT_APP_AUTHZ_ENDPOINT_ENV as AuthzEndpointEnv;

// Needed for visitor skype token request to authz.
// For now this is MSFT tenant ID
export const VISITOR_SKYPE_TOKEN_TENANT =
  "72f988bf-86f1-41af-91ab-2d7cd011db47";

export const SOCIAL_ACCOUNT_ID_TOKEN_ENVIRONMENT =
  "mstvirtualevents.b2clogin.com";
export const WORK_ACCOUNT_ID_TOKEN_ENVIRONMENT = "login.windows.net";
export const WORK_ACCOUNT_ID_TOKEN_ENVIRONMENT_GOV = "login.microsoftonline.us";

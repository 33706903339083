import React from "react";
import { EventNav } from "./EventNav";
import { eventNavBarWebStyles } from "../styles/EventNavBarWeb";
import { eventSelector } from "../core/slices/eventSlice";
import { useSelector } from "react-redux";
import { EventType } from "../core/slices/eventTypes.interface";
import { mergeClasses } from "../shared";
import { gridStyles } from "../styles/Grid";

interface IEventNavBarWebProps {
  urlPath: string;
}

// Event navigation below the hero image and event title in non mobile view.
export const EventNavBarWeb: React.FunctionComponent<IEventNavBarWebProps> = ({
  urlPath,
}) => {
  const eventNavBarWebClasses = eventNavBarWebStyles();
  const gridClasses = gridStyles();
  const currentEvent = useSelector(eventSelector);
  if (
    !currentEvent ||
    currentEvent.type === EventType.WEBINAR ||
    currentEvent.type === EventType.TOWNHALL
  ) {
    return null;
  }
  return (
    <div
      id="eventNavBarWeb"
      role="navigation"
      className={mergeClasses("event-nav-bar-web", eventNavBarWebClasses.root)}
    >
      <div
        className={mergeClasses(
          gridClasses.container,
          eventNavBarWebClasses.container
        )}
      >
        <EventNav urlPath={urlPath} />
      </div>
    </div>
  );
};

import { makeStyles, shorthands, tokens } from "../shared";

export const formFieldsStyles = makeStyles({
  validationMessage: {
    fontSize: tokens.fontSizeBase300,
    paddingLeft: "12px",
  },
  itemLabel: {
    lineHeight: tokens.lineHeightBase200,
    display: "block",
  },
  itemInput: {
    ...shorthands.borderBottom("1px", "solid", tokens.colorNeutralStroke1),
    ":hover": {
      ...shorthands.borderBottom("1px", "solid", tokens.colorNeutralStroke1),
    },
  },
  dropdownPlaceholder: {
    "> button": {
      color: tokens.colorNeutralForeground1,
      fontWeight: tokens.fontWeightSemibold,
    },
  },
  itemOption: {
    color: tokens.colorNeutralForeground3,
    fontSize: tokens.fontSizeBase200,
  },
  itemTextArea6Rows: {
    "> textarea": {
      lineHeight: tokens.lineHeightBase200,
      minHeight: "108px", //line height (16px) * number of rows (6) + padding(12)
    },
  },
  borderlessInputText: {
    ...shorthands.borderColor(tokens.colorSubtleBackground),
    ":hover": {
      ...shorthands.borderColor(tokens.colorSubtleBackground),
    },
  },
  borderlessTextArea: {
    ...shorthands.borderColor(tokens.colorSubtleBackground),
    ":hover": {
      ...shorthands.borderColor(tokens.colorSubtleBackground),
    },
  },
  borderlessDropdown: {
    ...shorthands.borderColor(tokens.colorSubtleBackground),
    ":hover": {
      ...shorthands.borderColor(tokens.colorSubtleBackground),
    },
  },
  borderlessDisabled: {
    backgroundColor: tokens.colorNeutralBackground1,
  },
});

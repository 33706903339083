import { makeStyles, shorthands, tokens } from "../shared";
import { Breakpoint, mediaQueryBreakpointUp } from "./Grid";

export const CreateWebinarCardStyles = makeStyles({
  topSectionOfCreateWebinarCard: {
    [mediaQueryBreakpointUp(Breakpoint.Large)]: {
      backgroundColor: tokens.colorNeutralBackground1,
      ...shorthands.borderRadius(tokens.borderRadiusLarge),
      ...shorthands.border("1px", "solid", tokens.colorNeutralStroke2),
      boxSizing: "border-box",
      ...shorthands.padding("2px", "2px"),
      ...shorthands.margin("20px", "0px", "0px", "0px"),
      width: "100%",
      marginRight: "0px",
    },
  },
  detailsSection: {
    ...shorthands.padding("15px", "20px", "15px", "20px"),
    backgroundColor: tokens.colorNeutralBackground1,
    [mediaQueryBreakpointUp(Breakpoint.Large)]: {
      ...shorthands.borderTop("0px", "solid", tokens.colorNeutralStroke2),
    },
  },
  descriptionSection: {
    ...shorthands.padding("15px", "0px", "0px", "0px"),
  },
  createWebinarButtonSection: {
    ...shorthands.padding("0px", "20px", "15px", "20px"),
  },
  descriptionText: {
    ...shorthands.margin("0px", "4px", "0px", "0px"),
  },
});

import { ILogger, LoggerLevels } from "../logger/interface";
import {
  IShortcutService,
  IShortcutServiceConfiguration,
  ShortcutCommandHandler,
  ShortcutCommandIdentifier,
} from "./interface";

export class ShortcutService implements IShortcutService {
  private logger: ILogger;

  private commandHandlers: { [commandId: string]: ShortcutCommandHandler } = {};

  constructor(private config: IShortcutServiceConfiguration) {
    this.logger = config.logger;
    this.init();
  }

  private init(): void {
    this.config.addEventListener(
      "keydown",
      this.onHandleKeyDown.bind(this),
      true
    );
  }

  public assignCommandHandler(
    commandId: ShortcutCommandIdentifier,
    commandHandler: ShortcutCommandHandler
  ): void {
    this.commandHandlers[commandId] = commandHandler;
    this.logger.logTrace(
      LoggerLevels.info,
      `ShortcutService: commandHandler assigned for commandId = ${commandId}`
    );
  }

  /**
   * Handle key down events.
   *
   * @param {e} - The keypressed event arguments.
   * @returns whether the key down event was handled.
   */
  private onHandleKeyDown = (e: KeyboardEvent): boolean => {
    if (!this.isAnyModifierPressed(e)) {
      return false;
    }

    const keyCode = e.keyCode || e.which;
    const keyCodeFor1 = 49; // keyCode for the "1" button
    const keyCodeFor2 = 50; // keyCode for the "2" button
    if (e.ctrlKey && e.shiftKey && e.altKey && keyCode === keyCodeFor1) {
      this.commandHandlers["DownloadLogsFiles"]();
      return true;
    } else if (e.ctrlKey && e.shiftKey && e.altKey && keyCode === keyCodeFor2) {
      this.commandHandlers["OpenReportProblemDialog"]();
      return true;
    }

    return false;
  };

  /**
   * Checks if any of the key modifiers (ctrl, shift alt, meta) is pressed.
   *
   * @param {e} - The keypressed event arguments.
   * @returns true if any of the modifiers is pressed, false - otherwise.
   */
  private isAnyModifierPressed(e: KeyboardEvent): boolean {
    return e.ctrlKey || e.shiftKey || e.altKey || e.metaKey;
  }
}

import { makeStyles, shorthands, tokens } from "../shared";
const eventInfoSponsorListGap = 32;

export const sponsorCardStyles = makeStyles({
  wrapper: {
    backgroundColor: tokens.colorNeutralBackground1,
    aspectRatio: "5 / 3",
    ...shorthands.borderRadius("6px"),
    objectFit: "contain",
  },
  list: {
    ...shorthands.border("1px", "solid", tokens.colorNeutralStroke2),
    ...shorthands.borderRadius("4px"),
    paddingLeft: "24px",
    paddingRight: "24px",
  },
  listRow: {
    // Using !important because the gutter style of the grid system which includes a media query is more specific, and has more priority.
    // Griffel from Fluent UI v9 also does not allow specifying class name for makeStyles classes to give it more priority.
    marginLeft: `${-eventInfoSponsorListGap / 2}px !important`,
    marginRight: `${-eventInfoSponsorListGap / 2}px !important`,
    "> *": {
      paddingLeft: `${eventInfoSponsorListGap / 2}px !important`,
      paddingRight: `${eventInfoSponsorListGap / 2}px !important`,
      paddingTop: `${eventInfoSponsorListGap / 2}px`,
      paddingBottom: `${eventInfoSponsorListGap / 2}px`,
    },
  },
  logo: {
    maxWidth: "100%",
    width: "auto",
    maxHeight: "100%",
  },
});

import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  enableAccountSettingsPageSelector,
  enablePublicProfilePageSelector,
} from "../../core/slices/ecsSlice";
import {
  MenuListV9 as MenuList,
  MenuItemV9 as MenuItem,
  mergeClasses,
  PersonFilled,
  PersonRegular,
  PersonBoardRegular,
  PersonBoardFilled,
  SettingsRegular,
  SettingsFilled,
  TextV9 as Text,
} from "../../shared";
import { profilePageNavStyle } from "../../styles/ProfilePageNav";
import { flexStyles } from "../../styles/FlexStyles";
import { generateSearchParams } from "../../utilities/common/generateSearchParams";
import { History, Location } from "history";
import { PortalLocationState } from "../../core/history/history.interface";
import { iconStyles } from "../../styles/IconStyles";

export const ProfilePageNav: React.FunctionComponent = () => {
  const flexClasses = flexStyles();
  const profilePageNavClasses = profilePageNavStyle();
  const iconClasses = iconStyles();
  const { t: i18n } = useTranslation();
  const enablePublicProfilePage = useSelector(enablePublicProfilePageSelector);
  const enableAccountSettingsPage = useSelector(
    enableAccountSettingsPageSelector
  );

  const history: History = useHistory<PortalLocationState>();
  const location: Location = useLocation<PortalLocationState>();
  const currentPath = location.pathname;
  const basePath = "/profile";

  const onClickNavItem = React.useCallback(
    (path: string) => {
      history.push({
        pathname: path,
        search: generateSearchParams(location),
      });
    },
    [history, location]
  );

  const navItems = [
    {
      id: "edit_account_tab",
      name: i18n("account_header"),
      iconRegular: <PersonRegular className={iconClasses.large} />,
      iconFilled: <PersonFilled className={iconClasses.large} />,
      to: `${basePath}/account-info`,
    },
  ];

  if (enablePublicProfilePage) {
    navItems.push({
      id: "public_profile_tab",
      name: i18n("public_profile_header"),
      iconRegular: <PersonBoardRegular className={iconClasses.large} />,
      iconFilled: <PersonBoardFilled className={iconClasses.large} />,
      to: `${basePath}/public-profile`,
    });
  }

  if (enableAccountSettingsPage) {
    navItems.push({
      id: "account_settings_tab",
      name: i18n("account_settings_header"),
      iconRegular: <SettingsRegular className={iconClasses.large} />,
      iconFilled: <SettingsFilled className={iconClasses.large} />,
      to: `${basePath}/account-settings`,
    });
  }

  return (
    <MenuList className={profilePageNavClasses.root}>
      {navItems.map((navItem) => (
        <MenuItem
          key={navItem.id}
          className={mergeClasses(
            profilePageNavClasses.item,
            /* istanbul ignore next */
            navItem.to === currentPath && profilePageNavClasses.itemActive
          )}
        >
          <span
            id={navItem.id}
            onClick={() => onClickNavItem(navItem.to)}
            className={mergeClasses(
              flexClasses.root,
              flexClasses.rowGapSmall,
              profilePageNavClasses.itemContent
            )}
          >
            {navItem.to === currentPath ? (
              <>
                {navItem.iconFilled}
                <Text weight="semibold">{navItem.name}</Text>
              </>
            ) : (
              <>
                {navItem.iconRegular}
                <Text>{navItem.name}</Text>
              </>
            )}
          </span>
        </MenuItem>
      ))}
    </MenuList>
  );
};

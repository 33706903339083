import React from "react";
import {
  SkeletonV9 as Skeleton,
  SkeletonItem,
  makeStyles,
  mergeClasses,
  shorthands,
} from "../shared";
import { skeletonItemStyles } from "../styles/SkeletonStyles";

export const playerStyles = makeStyles({
  container: {
    ...shorthands.border("0px"),
    aspectRatio: "16 / 9",
    width: "100%!important",
    height: "unset!important",
  },
  hidden: { display: "none" },
});

export const PlayerLoading: React.FunctionComponent = () => {
  const playerClasses = playerStyles();
  const skeletonItemClasses = skeletonItemStyles();
  return (
    <Skeleton data-testid="player-loading-component" animation="pulse">
      <SkeletonItem
        className={mergeClasses(
          playerClasses.container,
          skeletonItemClasses.root
        )}
      />
    </Skeleton>
  );
};

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ImageV9 as Image,
  ButtonV9 as Button,
  DialogActionsV9 as DialogActions,
  DialogBodyV9 as DialogBody,
  DialogContentV9 as DialogContent,
  DialogSurfaceV9 as DialogSurface,
  DialogTitleV9 as DialogTitle,
  DialogTriggerV9 as DialogTrigger,
  DialogV9 as Dialog,
  mergeClasses,
  TextV9 as Text,
} from "../../shared";
import { getLastKnownEvent } from "../../utilities/common/utils";
import authUnsuccessful from "../../assets/authentication-unsuccessful.svg";
import eventFetchUnsuccessful from "../../assets/event_fetch_unsuccessful.svg";
import fileNotFound from "../../assets/file_not_found.svg";
import eventCancelled from "../../assets/event_cancelled.svg";
import tooManyRequestsImage from "../../assets/too_many_requests.svg";
import { useSelector } from "react-redux";
import {
  IError,
  ErrorType,
  isErrorResponseSilentAuthInteractionRequiredError,
  isErrorResponseNetworkError,
  isErrorResponseLicenseError,
  isErrorResponsePopupWindowError,
  isErrorResponseUserCancelledError,
  isErrorCrossCloudRequestUnauthorizedError,
  getErrorResponse,
} from "../../core/slices/error";
import { eventErrorSelector } from "../../core/slices/eventSlice";
import {
  redirectLoginErrorSelector,
  userErrorSelector,
} from "../../core/slices/userSlice";
import { useHistory, useLocation } from "react-router-dom";
import { routes } from "../../common/constants";
import { useAuthenticationService } from "../../core/auth/auth-context";
import { useLogger } from "../../common/logger/LoggerContext";
import { LoggerLevels } from "../../common/logger/interface";
import { errorPageStyles } from "../../styles/ErrorPages";
import { Breakpoint } from "../../styles/Grid";
import { useBreakpoint } from "../../utilities/hooks/useBreakpoints";
import { Logger, Scenario } from "../../common/logger/Logger";
import { flexAlignStyles, flexStyles } from "../../styles/FlexStyles";
import { portalTextStyles } from "../../styles/PortalText";
import { generateSearchParams } from "../../utilities/common/generateSearchParams";
import { PortalLocationState } from "../../core/history/history.interface";

/**
 * The different components created to show specific error pages as per the scenarios
 * can be present here
 */

export const AuthenticationUnsuccessful: React.FunctionComponent = () => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const errorPageClass = errorPageStyles();
  const portalTextClass = portalTextStyles();
  const { t: i18n } = useTranslation();
  const history = useHistory<PortalLocationState>();
  const location = useLocation<PortalLocationState>();
  const locationState: PortalLocationState = location.state;
  const { authenticationService } = useAuthenticationService();
  const eventError: IError | undefined = useSelector(eventErrorSelector);
  const meError: IError | undefined = useSelector(userErrorSelector);
  const redirectLoginError: IError | undefined = useSelector(
    redirectLoginErrorSelector
  );
  const [logoutPopupErrorDialogIsVisible, setLogoutPopupErrorDialogIsVisible] =
    useState(false);
  const logger = useLogger()?.logger;
  const { isBreakpointAndDown } = useBreakpoint();
  const isMobileView = isBreakpointAndDown(Breakpoint.Medium);

  const route = window.location.href;
  const scenario = logger?.createScenario(Scenario.ErrorPages, {
    data: { detailText: route },
  });
  scenario?.stop();
  const useErrorStrings = () => {
    if (
      eventError &&
      isErrorResponseSilentAuthInteractionRequiredError(eventError)
    ) {
      return {
        title: i18n("errors.service_errors.silent_auth_failed_title"),
        body: i18n("errors.service_errors.silent_auth_failed_body"),
        button: (
          <Button
            id="signInButton"
            className={errorPageClass.signInbutton}
            onClick={loginButtonHandler}
          >
            {i18n("sign_out")}
          </Button>
        ),
      };
    } else if (eventError && isErrorResponsePopupWindowError(eventError)) {
      return {
        title: i18n("errors.auth_errors.popup_error_title"),
        body: i18n("errors.auth_errors.popup_error_body_acquire_token"),
        button: (
          <Button
            data-testid="backButton"
            className={errorPageClass.signInbutton}
            onClick={() => {
              locationState?.from
                ? history.push(locationState.from)
                : history.goBack();
            }}
          >
            {i18n("back")}
          </Button>
        ),
      };
    } else if (eventError && isErrorResponseUserCancelledError(eventError)) {
      return {
        title: i18n("errors.auth_errors.user_cancelled_error_title"),
        button: (
          <Button
            data-testid="backButton"
            className={errorPageClass.signInbutton}
            onClick={() => {
              locationState?.from
                ? history.push(locationState.from)
                : history.goBack();
            }}
          >
            {i18n("back")}
          </Button>
        ),
      };
    } else if (
      (eventError && isErrorResponseLicenseError(eventError)) ||
      (redirectLoginError && isErrorResponseLicenseError(redirectLoginError))
    ) {
      return {
        title: i18n("errors.service_errors.user_license_not_present_title"),
        body: i18n("errors.service_errors.user_license_not_present_body"),
        button: (
          <Button
            id="signInButton"
            className={errorPageClass.signInbutton}
            onClick={loginButtonHandler}
          >
            {i18n("sign_in_another_account")}
          </Button>
        ),
      };
    } else if (
      (eventError && isErrorCrossCloudRequestUnauthorizedError(eventError)) ||
      (meError && isErrorCrossCloudRequestUnauthorizedError(meError)) ||
      (redirectLoginError && isErrorResponseLicenseError(redirectLoginError))
    ) {
      return {
        title: i18n("errors.service_errors.cross_cloud_request_error_title"),
        body: i18n("errors.service_errors.cross_cloud_request_error_body"),
        button: (
          <Button
            id="signInButton"
            className={errorPageClass.signInbutton}
            onClick={loginButtonHandler}
          >
            {i18n("sign_in_another_account")}
          </Button>
        ),
      };
    } else if (redirectLoginError) {
      return {
        title: i18n("errors.auth_errors.redirect_login_error_title"),
        button: (
          <Button
            id="signInButton"
            className={errorPageClass.signInbutton}
            onClick={onClickLoginRedirectError}
          >
            {i18n("errors.auth_errors.redirect_login_error_button")}
          </Button>
        ),
      };
    } else {
      return {
        title: i18n("errors.service_errors.authentication_unsuccessful_title"),
        body: i18n("errors.service_errors.authentication_unsuccessful_body"),
        button: (
          <Button
            id="signInButton"
            className={errorPageClass.signInbutton}
            onClick={loginButtonHandler}
          >
            {i18n("sign_in_another_account")}
          </Button>
        ),
      };
    }
  };

  const logoutPopupBlockedDialog = (
    <Dialog
      open={logoutPopupErrorDialogIsVisible}
      onOpenChange={() => setLogoutPopupErrorDialogIsVisible(false)}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>
            {i18n("errors.auth_errors.popup_error_title")}
          </DialogTitle>
          <DialogContent>
            {i18n("errors.auth_errors.popup_error_body_login")}
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button
                appearance="secondary"
                onClick={
                  /* istanbul ignore next */ () =>
                    setLogoutPopupErrorDialogIsVisible(false)
                }
              >
                {i18n("button_close")}
              </Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );

  const loginButtonHandler = React.useCallback(() => {
    /* istanbul ignore if */
    if (authenticationService.userIsAuthenticated()) {
      authenticationService
        .logout()
        .then(() => {
          history.replace({
            pathname: routes.login,
            search: generateSearchParams(location),
            state: {
              redirectPath: locationState?.from,
            },
          });
        })
        .catch((err) => {
          logger?.logTrace(
            LoggerLevels.error,
            `Login failed with error ${err}`
          );

          const errorResponse = getErrorResponse("logout", err);
          const isPopupError = isErrorResponsePopupWindowError(errorResponse);

          if (isPopupError) {
            setLogoutPopupErrorDialogIsVisible(true);
          }
        });
    } else {
      const LKE = getLastKnownEvent();
      if (LKE && !locationState) {
        const pathname = `${routes.event}/${LKE}`;
        history.replace({
          pathname: routes.login,
          search: generateSearchParams(location),
          state: {
            redirectPath: {
              pathname,
              search: generateSearchParams(location),
            },
          },
        });
      } else {
        history.replace({
          pathname: routes.login,
          search: generateSearchParams(location),
          state: {
            redirectPath: locationState?.from,
          },
        });
      }
    }
  }, [authenticationService, history, location, locationState, logger]);

  const onClickLoginRedirectError = React.useCallback(() => {
    if (
      locationState?.from &&
      locationState?.from.pathname === `/${routes.authRedirect}/`
    ) {
      // If user came from the auth redirect page,
      // one step further back should be the page on our app they came from.
      // (Eg. login page)
      history.go(-2);
    } else {
      // If user didn't come form the auth redirect page,
      // it means msal was able to redirect to the "redirectStartPage"
      // before the error happened.
      loginButtonHandler();
    }
  }, [history, locationState?.from, loginButtonHandler]);

  const { title, body, button } = useErrorStrings();
  const authUnsuccessFul = (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexAlignClasses.alignItemCenter,
        errorPageClass.authErrorContainer
      )}
    >
      {logoutPopupBlockedDialog}
      <Image
        src={authUnsuccessful}
        fit="contain"
        alt={i18n("auth_unsuccessful")}
        className={errorPageClass.authErrorImage}
      />
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.column,
          flexClasses.columnGapSmaller,
          isMobileView
            ? /* istanbul ignore next */ errorPageClass.authUnsuccessfulWrapperMobile
            : errorPageClass.authUnsuccessfulWrapper,
          errorPageClass.mobileTextPadding
        )}
      >
        <Text
          id="authentication-unsuccessful-title"
          className={portalTextClass.large}
          weight="bold"
          align="center"
        >
          {title}
        </Text>
        {body && (
          <Text id="authentication-unsuccessful-content" align="center">
            {body}
          </Text>
        )}
      </div>
      {button}
    </div>
  );

  return authUnsuccessFul;
};

export const EventFetchUnsuccessful: React.FunctionComponent = () => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const errorPageClass = errorPageStyles();
  const portalTextClass = portalTextStyles();
  const { t: i18n } = useTranslation();
  const logger = Logger.getInstance();
  const route = window.location.href;

  const eventError: IError | undefined = useSelector(eventErrorSelector);
  const errorText = i18n("event_fetch_unsuccessful");
  const tryAgainText = i18n("event_fetch_try_again_later");
  const { isBreakpointAndDown } = useBreakpoint();
  const isMobileView = isBreakpointAndDown(Breakpoint.Medium);

  let detailText = "";
  if (eventError && isErrorResponseNetworkError(eventError)) {
    detailText = `Code: ${eventError.errorCode}`;
  } else if (eventError && eventError.type === ErrorType.CMD_SERVICES) {
    if (eventError.status) {
      detailText = `Code: ${eventError.status}`;
    }
    if (eventError.responseErrorMessage) {
      detailText += ` ${eventError.responseErrorMessage}`;
    }
    detailText = detailText.trim();
  }

  const scenario = logger.createScenario(Scenario.ErrorPages, {
    data: { detailText: `${detailText} ${route}` },
  });
  scenario?.stop();

  const eventFetchUnsuccessFul = (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexAlignClasses.alignItemCenter,
        errorPageClass.eventFetchUnsuccessfulContainer
      )}
    >
      <Image
        src={eventFetchUnsuccessful}
        fit="contain"
        className={errorPageClass.eventFetchUnsuccessfulImage}
      />
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.column,
          flexClasses.columnGapSmaller,
          isMobileView
            ? /* istanbul ignore next */ errorPageClass.authUnsuccessfulWrapperMobile
            : errorPageClass.authUnsuccessfulWrapper,
          errorPageClass.mobileTextPadding
        )}
      >
        <Text className={portalTextClass.large} weight="bold" align="center">
          {errorText}
        </Text>
        <Text align="center">{tryAgainText}</Text>
        <Text className={portalTextClass.small} align="center">
          {detailText}
        </Text>
      </div>
    </div>
  );

  return eventFetchUnsuccessFul;
};

export const TooManyRequests: React.FunctionComponent = () => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const errorPageClass = errorPageStyles();
  const portalTextClass = portalTextStyles();
  const { t: i18n } = useTranslation();
  const errorTitle = i18n("too_many_requests");
  const tooManyRequestsInfo = i18n("too_many_requests_body");
  const { isBreakpointAndDown } = useBreakpoint();
  const isMobileView = isBreakpointAndDown(Breakpoint.Medium);
  const logger = Logger.getInstance();
  const route = window.location.href;
  const scenario = logger.createScenario(Scenario.ErrorPages, {
    data: { detailText: `${errorTitle}" ${route}` },
  });
  scenario?.stop();

  const tooManyRequests = (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexAlignClasses.alignItemCenter,
        errorPageClass.pageTooManyRequestsContainer
      )}
    >
      <Image
        src={tooManyRequestsImage}
        fit="contain"
        className={errorPageClass.pageTooManyRequestsImage}
      />
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.column,
          flexClasses.columnGapSmaller,
          isMobileView
            ? /* istanbul ignore next */ errorPageClass.authUnsuccessfulWrapperMobile
            : errorPageClass.authUnsuccessfulWrapper,
          errorPageClass.mobileTextPadding
        )}
      >
        <Text className={portalTextClass.large} weight="bold" align="center">
          {errorTitle}
        </Text>
        <Text align="center">{tooManyRequestsInfo}</Text>
      </div>
    </div>
  );

  return tooManyRequests;
};

export const PageNotFound: React.FunctionComponent = () => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const errorPageClass = errorPageStyles();
  const portalTextClass = portalTextStyles();
  const { t: i18n } = useTranslation();
  const errorTitle = i18n("page_not_available");
  const pageNotFoundInfo = i18n("page_not_available_body");
  const { isBreakpointAndDown } = useBreakpoint();
  const isMobileView = isBreakpointAndDown(Breakpoint.Medium);
  const logger = Logger.getInstance();
  const route = window.location.href;
  const scenario = logger.createScenario(Scenario.ErrorPages, {
    data: { detailText: `${errorTitle}" ${route}` },
  });
  scenario?.stop();

  const pageNotFound = (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexAlignClasses.alignItemCenter,
        errorPageClass.pageNotFoundContainer
      )}
    >
      <Image
        src={fileNotFound}
        fit="contain"
        className={errorPageClass.pageNotFoundImage}
      />
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.column,
          flexClasses.columnGapSmaller,
          isMobileView
            ? /* istanbul ignore next */ errorPageClass.authUnsuccessfulWrapperMobile
            : errorPageClass.authUnsuccessfulWrapper,
          errorPageClass.mobileTextPadding
        )}
      >
        <Text className={portalTextClass.large} weight="bold" align="center">
          {errorTitle}
        </Text>
        <Text align="center">{pageNotFoundInfo}</Text>
      </div>
    </div>
  );

  return pageNotFound;
};

export const EventCancelled: React.FunctionComponent = () => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const errorPageClass = errorPageStyles();
  const portalTextClass = portalTextStyles();
  const { t: i18n } = useTranslation();
  const eventCancelledTitle = i18n("event_has_been_canceled");
  const eventCancelledBody = i18n("event_has_been_canceled_body");
  const { isBreakpointAndDown } = useBreakpoint();
  const isMobileView = isBreakpointAndDown(Breakpoint.Medium);
  const logger = Logger.getInstance();
  const route = window.location.href;
  const scenario = logger.createScenario(Scenario.ErrorPages, {
    data: {
      detailText: `${eventCancelledTitle} ${route}`,
    },
  });
  scenario?.stop();

  const eventCancelledDisplay = (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexAlignClasses.alignItemCenter,
        errorPageClass.eventCancelledContainer
      )}
    >
      <Image
        src={eventCancelled}
        fit="contain"
        className={errorPageClass.eventCancelledErrorImage}
      />
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.column,
          flexClasses.columnGapSmaller,
          isMobileView
            ? /* istanbul ignore next */ errorPageClass.authUnsuccessfulWrapperMobile
            : errorPageClass.authUnsuccessfulWrapper,
          errorPageClass.mobileTextPadding
        )}
      >
        <Text className={portalTextClass.large} weight="bold" align="center">
          {eventCancelledTitle}
        </Text>
        <Text align="center">{eventCancelledBody}</Text>
      </div>
    </div>
  );

  return eventCancelledDisplay;
};

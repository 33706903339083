import React from "react";
import { ButtonV9 as Button, mergeClasses, TextV9 as Text } from "../../shared";
import { SpeakerCard, SpeakerCardGrid } from "../SpeakerCard";
import { EventUser } from "../../core/slices/userTypes.interface";
import { useTranslation } from "react-i18next";
import {
  flexAlignStyles,
  flexItemStyles,
  flexStyles,
} from "../../styles/FlexStyles";
import { speakerProfileCardStyles } from "../../styles/EventSpeakers";
import { portalListStyles } from "../../styles/PortalList";
import { speakerCardGridStyles } from "../../styles/SpeakerCard";
import { buttonStyles } from "../../styles/ButtonStyles";

export const Speakers: React.FunctionComponent<{
  speakers: EventUser[];
}> = ({ speakers }) => {
  const flexClasses = flexStyles();
  const maxSpeakerDisplayCount = 6;
  const [clamped, setClamped] = React.useState(true);
  const numOfSpeakers = speakers?.length;

  const onShowMoreClick = () => {
    setClamped(!clamped);
  };

  const displayCount = clamped ? maxSpeakerDisplayCount : numOfSpeakers;

  const SpeakersView = numOfSpeakers >= 3 ? SpeakersGrid : SpeakersList;
  return (
    <div
      id="eventInfoSpeakersStack"
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexClasses.columnGap16px
      )}
    >
      {numOfSpeakers > 0 && (
        <SpeakersHeader
          speakers={speakers}
          clamped={clamped}
          onShowMoreClick={onShowMoreClick}
        />
      )}
      {numOfSpeakers > 0 && (
        <SpeakersView speakers={speakers} displayCount={displayCount} />
      )}
    </div>
  );
};

const SpeakersList: React.FunctionComponent<{
  speakers: EventUser[];
}> = ({ speakers }) => {
  const portalListClasses = portalListStyles();
  const speakerProfileCardClasses = speakerProfileCardStyles();
  const [expandedSpeakerId, setExpandedSpeakerId] = React.useState<
    string | null
  >(null);

  const onExpandDescriptor = React.useCallback(
    (speakerId: string) => {
      setExpandedSpeakerId(speakerId === expandedSpeakerId ? null : speakerId);
    },
    [expandedSpeakerId]
  );

  return (
    <ul className={portalListClasses.root} data-testid="speakersList">
      {speakers.map((speaker) => (
        <li key={speaker.id}>
          <div
            className={mergeClasses(
              "speaker-profile-card",
              speakerProfileCardClasses.root
            )}
          >
            <SpeakerCard
              speaker={speaker}
              isExpanded={expandedSpeakerId === speaker.id}
              onExpand={onExpandDescriptor}
            />
          </div>
        </li>
      ))}
    </ul>
  );
};

// Speakers View When there are more than 2 speakers
const SpeakersGrid: React.FunctionComponent<{
  speakers: EventUser[];
  displayCount: number;
}> = ({ speakers, displayCount }) => {
  const speakerCardGridClasses = speakerCardGridStyles();
  return (
    <div data-testid="speakersGrid">
      <div className={speakerCardGridClasses.speakersGrid}>
        {speakers.slice(0, displayCount).map((speaker, index) => (
          <SpeakerCardGrid key={index} speaker={speaker} />
        ))}
      </div>
    </div>
  );
};

const SpeakersHeader: React.FunctionComponent<{
  speakers: EventUser[];
  clamped: boolean;
  onShowMoreClick: () => void;
}> = ({ speakers, clamped, onShowMoreClick }) => {
  const flexAlignClasses = flexAlignStyles();
  const flexItemClasses = flexItemStyles();
  const flexClasses = flexStyles();
  const buttonClasses = buttonStyles();
  const { t: i18n } = useTranslation();
  const speakersText = speakers
    ? `${i18n("speakers")} (${speakers?.length})`
    : i18n("speakers") + "(" + i18n("tba") + ")";
  return (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.rowGapMedium,
        flexAlignClasses.alignItemEnd
      )}
    >
      <Text
        id="speakersCount"
        as="h2"
        size={600}
        weight="bold"
        aria-label={speakersText}
      >
        {speakersText}
      </Text>
      <div className={mergeClasses(flexClasses.row, flexItemClasses.rowPush)}>
        {speakers?.length > 6 && (
          <Button
            appearance="transparent"
            data-testid={
              clamped ? "speakersShowMoreButton" : "speakersShowLessButton"
            }
            onClick={onShowMoreClick}
            className={buttonClasses.showMoreButton}
          >
            {clamped ? i18n("show_more") : i18n("show_less")}
          </Button>
        )}
      </div>
    </div>
  );
};

import { History, Location } from "history";
import { routes } from "../common/constants";
import {
  CmdServicesResponseErrorCode,
  ErrorType,
  IError,
  isErrorResponseInvalidEventIdFormat,
  isErrorResponseLicenseError,
  isErrorResponsePopupWindowError,
  isErrorResponseSilentAuthInteractionRequiredError,
  isErrorResponseSkypeTokenBadTenantId,
  isErrorResponseUserCancelledError,
  isErrorCrossCloudRequestUnauthorizedError,
  isErrorResponseAcquireTokenRedirectRequiredError,
} from "../core/slices/error";
import { generateSearchParams } from "./common/generateSearchParams";
import { PortalLocationState } from "../core/history/history.interface";

export const eventErrorHandler: (
  errorResponse: IError | undefined,
  isAuthenticated: boolean,
  enableRedirectAcquireToken: boolean,
  history: History<PortalLocationState>,
  location: Location<PortalLocationState>
) => boolean = (
  errorResponse: IError | undefined,
  isAuthenticated: boolean,
  enableRedirectAcquireToken: boolean,
  history: History<PortalLocationState>,
  location: Location<PortalLocationState>
) => {
  const searchParams = generateSearchParams(location);

  if (!errorResponse) {
    return false;
  }

  if (isErrorResponseInvalidEventIdFormat(errorResponse)) {
    history.replace({
      pathname: routes.notFound,
      search: generateSearchParams(location),
    });
    return true;
  }

  if (
    enableRedirectAcquireToken &&
    isErrorResponseAcquireTokenRedirectRequiredError(errorResponse)
  ) {
    // Do nothing, when we get this error, we will acquire token redirect and redirect away from our app.
    return true;
  }

  if (
    isErrorResponseSilentAuthInteractionRequiredError(errorResponse) ||
    isErrorResponsePopupWindowError(errorResponse) ||
    isErrorResponseUserCancelledError(errorResponse) ||
    isErrorResponseLicenseError(errorResponse) ||
    isErrorCrossCloudRequestUnauthorizedError(errorResponse)
  ) {
    history.replace({
      pathname: routes.authError,
      search: searchParams,
      state: {
        from: {
          pathname: location.pathname,
          search: searchParams,
          hash: location.hash,
        },
      },
    });
    return true;
  }

  if (isErrorResponseSkypeTokenBadTenantId(errorResponse)) {
    // Skype token error with 400 or 404 means the request data (tenant ID) is invalid or not found,
    // meaning the event GUID in the route is non-existent.
    history.replace({
      pathname: routes.notFound,
      search: searchParams,
    });
    return true;
  }

  if (errorResponse.type === ErrorType.CMD_SERVICES) {
    switch (errorResponse.status) {
      case 503:
      case 429:
        history.replace({
          pathname: routes.tooManyRequests,
          search: searchParams,
        });
        return true;

      case 400:
        if (
          errorResponse.responseErrorCode ===
          CmdServicesResponseErrorCode.EVENT_CANCELED
        ) {
          history.replace({
            pathname: routes.eventCanceled,
            search: searchParams,
          });
          return true;
        }
        break;

      case 401:
        // Unauthenticated user tried to view private event, send them straight to login page
        history.replace({
          pathname: routes.login,
          search: searchParams,
          state: {
            redirectPath: {
              pathname: location.pathname,
              search: searchParams,
              hash: location.hash,
            },
            showWorkLoginOnly: true,
          },
        });
        return true;

      case 403:
        if (isAuthenticated) {
          history.replace({
            pathname: routes.authError,
            search: searchParams,
            state: {
              from: {
                pathname: location.pathname,
                search: searchParams,
                hash: location.hash,
              },
            },
          });
        } else {
          // Unauthenticated user tried to view private event, send them straight to login page
          history.replace({
            pathname: routes.login,
            search: searchParams,
            state: {
              redirectPath: {
                pathname: location.pathname,
                search: searchParams,
                hash: location.hash,
              },
              showWorkLoginOnly: true,
            },
          });
        }
        return true;
      case 404:
        history.replace({
          pathname: routes.notFound,
          search: searchParams,
        });
        return true;
    }
  }

  // Fallback error handling.
  history.replace({
    pathname: routes.fetchError,
    search: searchParams,
  });
  return true;
};

import { removeHTML, removeEmojis } from "./common/utils";

export function createSocialLink(
  url: string,
  title: string,
  description: string | undefined,
  imageID: string,
  eventID: string,
  style: string
): string {
  const titleEncoded = encodeURIComponent(title);
  let descCleaned = "";
  if (description) {
    const descRemovedHtml = removeHTML(description);
    descCleaned = removeEmojis(descRemovedHtml);
  }
  // to not exceed maxQueryString limits, we truncate to 120 characters
  // Note: LinkedIn has trouble with elipsesi, so we use ___
  if (descCleaned.length > 120) {
    descCleaned = descCleaned.slice(0, 120) + "___";
  }
  const descEncoded = encodeURIComponent(descCleaned);
  const urlEncoded = encodeURIComponent(url);
  let host = process.env.PUBLIC_URL;
  // the PUBLIC_URL env is not set for local
  if (!host) {
    host = "https://local.events.teams.microsoft.com";
  }

  if (descCleaned.length > 0)
    return `${host}/social?name=${titleEncoded}&description=${descEncoded}&url=${urlEncoded}&image=${imageID}&style=${style}&eventID=${eventID}`;

  return `${host}/social?name=${titleEncoded}&url=${urlEncoded}&image=${imageID}&style=${style}&eventID=${eventID}`;
}

export function createSocialImageLink(
  imageID: string,
  eventID: string
): string {
  let host = process.env.PUBLIC_URL;
  // the PUBLIC_URL env is not set for local
  if (!host) {
    host = "https://local.events.teams.microsoft.com";
  }
  const socialImageLink = `${host}/socialImage?imageID=${imageID}&eventID=${eventID}`;
  return socialImageLink;
}

export function generateTwitterShareLink(
  url: string,
  title: string,
  description: string | undefined
): string {
  const urlEncoded = encodeURIComponent(url);
  let titlePlusDescription = encodeURIComponent(`${title}`);
  if (description) {
    titlePlusDescription = encodeURIComponent(
      `${title}: ${removeHTML(description)}`
    );
  }
  const link = `https://twitter.com/intent/tweet?url=${urlEncoded}&text=${titlePlusDescription}`;
  return link;
}

export function generateLinkedinShareLink(
  url: string,
  title: string,
  description: string | undefined,
  imageID: string,
  eventID: string
): string {
  const socialLink = createSocialLink(
    url,
    title,
    description,
    imageID,
    eventID,
    "fb"
  );
  const urlEncoded = encodeURIComponent(socialLink);
  const link = `https://www.linkedin.com/sharing/share-offsite/?url=${urlEncoded}`;
  // leaving commented for further LinkedIn debugging
  return link;
}

export function generateFacebookShareLink(
  url: string,
  title: string,
  description: string | undefined,
  imageID: string,
  eventID: string
): string {
  const socialLink = createSocialLink(
    url,
    title,
    description,
    imageID,
    eventID,
    "fb"
  );
  const link =
    "https://www.facebook.com/dialog/feed?app_id=376168834146552&redirect_uri=" +
    encodeURIComponent(socialLink) +
    "&link=" +
    encodeURIComponent(socialLink);
  return link;
}

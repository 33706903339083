import React from "react";
import {
  ButtonV9 as Button,
  PortalTwitterIcon,
  PortalGithubIcon,
  PortalAnchorV9 as PortalAnchor,
  mergeClasses,
  AvatarV9 as Avatar,
  PersonFilled,
  tokens,
  TextV9 as Text,
  ChatRegular,
  MailRegular,
  CallRegular,
  PortalLinkedInIcon,
} from "../../shared";
import { EventUser } from "../../core/slices/userTypes.interface";
import { useSelector } from "react-redux";
import { imageSelector } from "../../core/slices/imageSlice";
import { useTranslation } from "react-i18next";
import { profileCardStyles } from "../../styles/ProfileCard";
import { flexAlignStyles, flexStyles } from "../../styles/FlexStyles";
import { portalTextStyles } from "../../styles/PortalText";
import { iconStyles } from "../../styles/IconStyles";

export const ProfileCard: React.FunctionComponent<{
  user: EventUser;
  fluid?: boolean;
}> = ({ user, fluid }) => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const portalTextClasses = portalTextStyles();
  const profileCardClasses = profileCardStyles();
  const iconclassess = iconStyles();
  const { t: i18n } = useTranslation();
  const profileImage = useSelector(imageSelector(user.profileImage));
  const displayName = user.displayName || "";
  const firstName = user.firstName || "";
  const lastName = user.lastName || "";
  const realName = `${firstName} ${lastName}`.trim();
  const pronoun = ""; // TODO: Pronoun is not in EventUser yet.
  const organization = user.userBio?.company || "";
  const industry = ""; // TODO: Industry is not in EventUser yet.
  const location = ""; // TODO: Location is not in Eventuser yet.
  const email = user.email;
  const phone = ""; // TODO: Phone is not in EventUser yet.
  const interests: string[] = []; // TODO: Interest not in EventUser yet.
  const linkedin = user.userBio?.socialMediaLinks?.["linkedIn"] || "";
  const twitter = user.userBio?.socialMediaLinks?.["twitter"] || "";
  const github = user.userBio?.socialMediaLinks?.["github"] || "";

  return (
    <div
      className={mergeClasses(
        "profile-card",
        profileCardClasses.root,
        fluid === true && profileCardClasses.fluidwidth,
        fluid === false && profileCardClasses.notfluidwidth
      )}
    >
      <Section>
        <div
          className={mergeClasses(flexClasses.root, flexClasses.rowGapSmall)}
        >
          <Avatar
            image={
              /* istanbul ignore next */
              profileImage ? { src: profileImage } : undefined
            }
            size={72}
            icon={<PersonFilled primaryFill={tokens.colorNeutralForeground3} />}
          />
          <div
            className={mergeClasses(
              flexClasses.root,
              flexClasses.column,
              flexClasses.columnGap4px
            )}
          >
            <div
              className={mergeClasses(
                "profile-card-name",
                flexClasses.root,
                flexClasses.column,
                flexClasses.columnGap4px
              )}
            >
              <div
                className={mergeClasses(
                  flexClasses.root,
                  flexClasses.rowGapSmaller,
                  flexAlignClasses.alignItemCenter
                )}
              >
                <Text weight="bold" data-testid="profileCardDisplayName">
                  {displayName}
                </Text>
                {!!pronoun && (
                  /* istanbul ignore next */
                  <Text
                    as="span"
                    className={mergeClasses(
                      portalTextClasses.small,
                      profileCardClasses.pronounText
                    )}
                  >
                    {pronoun}
                  </Text>
                )}
              </div>

              {!!realName && (
                <Text
                  className={mergeClasses(
                    portalTextClasses.small,
                    profileCardClasses.realNameText
                  )}
                >
                  {`(${realName})`}
                </Text>
              )}
            </div>

            <div
              className={mergeClasses(
                "profile-card-details",
                flexClasses.root,
                flexClasses.column
              )}
            >
              {!!organization && (
                <Text className={portalTextClasses.small}>{organization}</Text>
              )}
              {!!industry && (
                /* istanbul ignore next */
                <Text className={portalTextClasses.small}>{industry}</Text>
              )}
              {!!location && (
                /* istanbul ignore next */
                <Text className={portalTextClasses.small}>{location}</Text>
              )}
            </div>
          </div>
        </div>

        <div
          className={mergeClasses(flexClasses.root, flexClasses.rowGapSmall)}
        >
          <Button
            size="small"
            icon={<ChatRegular />}
            className={profileCardClasses.messageButton}
          >
            {i18n("profile_card_message_button")}
          </Button>
          {email && (
            <PortalAnchor
              className={profileCardClasses.anchor}
              href={`mailto:${email}`}
            >
              {
                <Button
                  size="small"
                  appearance="transparent"
                  icon={<MailRegular />}
                />
              }
            </PortalAnchor>
          )}
          {phone && (
            /* istanbul ignore next */
            <PortalAnchor
              className={profileCardClasses.anchor}
              href={`tel:${phone}`}
            >
              {
                <Button
                  size="small"
                  appearance="transparent"
                  icon={<CallRegular />}
                />
              }
            </PortalAnchor>
          )}
        </div>
      </Section>

      {(email || phone) && (
        <Section title={i18n("profile_card_header_contact")}>
          <div
            className={mergeClasses(
              flexClasses.root,
              flexClasses.column,
              flexClasses.columnGapSmall,
              flexAlignClasses.alignItemFlexStart
            )}
          >
            {email && (
              <div
                className={mergeClasses(
                  flexClasses.root,
                  flexClasses.rowGapMedium,
                  flexAlignClasses.alignItemCenter
                )}
              >
                <MailRegular className={iconclassess.large} />
                <PortalAnchor
                  className={profileCardClasses.anchor}
                  href={`mailto:${email}`}
                >
                  {email}
                </PortalAnchor>
              </div>
            )}
            {phone && (
              /* istanbul ignore next */
              <div
                className={mergeClasses(
                  flexClasses.root,
                  flexClasses.rowGapMedium,
                  flexAlignClasses.alignItemCenter
                )}
              >
                <CallRegular className={iconclassess.large} />
                <PortalAnchor
                  className={profileCardClasses.anchor}
                  href={`tel:${phone}`}
                >
                  {phone}
                </PortalAnchor>
              </div>
            )}
          </div>
        </Section>
      )}

      {interests && !!interests.length && (
        /* istanbul ignore next */
        <Section title={i18n("profile_card_header_interests")}>
          <Interests interests={interests} />
        </Section>
      )}

      {(linkedin || twitter || github) && (
        <Section title={i18n("profile_card_header_online_presence")}>
          <div
            className={mergeClasses(
              flexClasses.root,
              flexClasses.column,
              flexClasses.columnGapSmall,
              flexAlignClasses.alignItemFlexStart
            )}
          >
            {linkedin && (
              <div
                className={mergeClasses(
                  flexClasses.root,
                  flexClasses.rowGapMedium
                )}
              >
                <PortalLinkedInIcon className={profileCardClasses.socialIcon} />
                <PortalAnchor
                  className={profileCardClasses.anchor}
                  href={linkedin}
                >
                  {linkedin}
                </PortalAnchor>
              </div>
            )}
            {twitter && (
              <div
                className={mergeClasses(
                  flexClasses.root,
                  flexClasses.rowGapMedium
                )}
              >
                <PortalTwitterIcon className={profileCardClasses.socialIcon} />
                <PortalAnchor
                  className={profileCardClasses.anchor}
                  href={twitter}
                >
                  {twitter}
                </PortalAnchor>
              </div>
            )}
            {github && (
              <div
                className={mergeClasses(
                  flexClasses.root,
                  flexClasses.rowGapMedium
                )}
              >
                <PortalGithubIcon className={profileCardClasses.socialIcon} />
                <PortalAnchor
                  className={profileCardClasses.anchor}
                  href={github}
                >
                  {github}
                </PortalAnchor>
              </div>
            )}
          </div>
        </Section>
      )}
    </div>
  );
};

const Section: React.FunctionComponent<{ title?: string }> = ({
  title,
  children,
}) => {
  const flexClasses = flexStyles();
  const portalTextClasses = portalTextStyles();
  const profileCardClasses = profileCardStyles();
  return (
    <div
      className={mergeClasses(
        "profile-card-section",
        flexClasses.root,
        flexClasses.column,
        flexClasses.columnGapMedium,
        profileCardClasses.section
      )}
    >
      {title && (
        <Text className={portalTextClasses.small} weight="bold">
          {title}
        </Text>
      )}
      {children}
    </div>
  );
};

/* istanbul ignore next */
const Interests: React.FunctionComponent<{
  interests: string[];
}> = ({ interests }) => {
  const flexClasses = flexStyles();
  const portalTextClasses = portalTextStyles();
  const profileCardClasses = profileCardStyles();
  return (
    <div className={mergeClasses(flexClasses.root, flexClasses.wrap)}>
      {interests.map((item) => (
        <>
          <Text
            as="span"
            className={mergeClasses(
              portalTextClasses.small,
              profileCardClasses.interestsText
            )}
          >
            {item}
          </Text>
        </>
      ))}
    </div>
  );
};

import { makeStyles, tokens, shorthands } from "../shared";

export const profileCardStyles = makeStyles({
  root: {
    backgroundColor: tokens.colorNeutralBackground1,
    boxShadow: tokens.shadow16,
  },
  section: {
    ...shorthands.padding("16px", "24px"),
    "&:not(:last-child)": {
      ...shorthands.borderBottom("1px", "solid", tokens.colorNeutralStroke3),
    },
  },
  fluidwidth: {
    width: "100%",
  },
  notfluidwidth: {
    width: /* istanbul ignore next */ undefined,
  },
  anchor: {
    fontSize: tokens.fontSizeBase200,
    lineHeight: tokens.lineHeightBase200,
    fontWeight: tokens.fontWeightRegular,
  },
  pronounText: {
    color: tokens.colorNeutralForeground3,
    backgroundColor: tokens.colorNeutralBackground5,
    ...shorthands.padding("2px", "8px"),
    ":hover": {
      backgroundColor: tokens.colorNeutralBackground2,
    },
  },
  realNameText: {
    color: tokens.colorNeutralForeground3,
  },
  messageButton: {
    fontWeight: tokens.fontWeightSemibold,
  },
  interestsText: {
    color: tokens.colorNeutralForeground3,
    backgroundColor: tokens.colorNeutralBackground2,
    ...shorthands.margin("6px", "2px"),
    ...shorthands.padding("0px", "8px", "0px", "8px"),
    ...shorthands.border("1px", "solid", tokens.colorNeutralStroke1),
    ...shorthands.borderRadius(tokens.borderRadiusCircular),
    maxHeight: "20px",
  },
  socialIcon: {
    height: "20px",
    width: "20px",
  },
});

import type { IDisposable } from '@ms/utilities-disposables/lib/Disposable';
import { Scope } from '@ms/utilities-disposables/lib/Scope';

export class Async implements IDisposable {
  private _scope: Scope;
  private _timeoutMap: Map<number, IDisposable>;

  constructor(owner?: {} | null) {
    this._scope = new Scope();
    this._timeoutMap = new Map();
  }

  public setTimeout(callback: () => void, timeout: number): number {
    const timeoutId = setTimeout(() => {
      this._timeoutMap.delete(timeoutId);
      callback();
    }, timeout);

    const disposable = this._scope.attach({
      dispose: () => {
        clearTimeout(timeoutId);
      }
    });
    this._timeoutMap.set(timeoutId, disposable);

    return timeoutId;
  }

  public clearTimeout(timeoutId: number): void {
    const disposable = this._timeoutMap.get(timeoutId);

    if (disposable) {
      this._timeoutMap.delete(timeoutId);
      disposable.dispose();
    }
  }

  public dispose(): void {
    this._scope.dispose();
  }
}

import { makeStyles, tokens, shorthands, zIndexes } from "../shared";
import { Breakpoint, mediaQueryBreakpointUp } from "./Grid";

export const eventPageStyles = makeStyles({
  container: {
    marginTop: "30px",
    marginBottom: "30px",
  },
  titleEventRegBarImage: {
    zIndex: zIndexes.foreground,
    width: "40px",
    height: "40px",
    [mediaQueryBreakpointUp(Breakpoint.Medium)]: {
      width: "72px",
      height: "72px",
    },
  },
  eventTitle: {
    fontWeight: tokens.fontWeightBold,
    lineHeight: tokens.lineHeightHero700,
    color: tokens.colorNeutralForeground1,
  },
  rightGroup: {
    [mediaQueryBreakpointUp(Breakpoint.Medium)]: {
      float: "right",
    },
  },
});

export const titleEventRegBarStyles = makeStyles({
  root: {
    paddingTop: "12px",
    paddingBottom: "12px",
    backgroundColor: tokens.colorNeutralBackground1,
    [mediaQueryBreakpointUp(Breakpoint.Large)]: {
      ...shorthands.borderBottom("1px", "solid", tokens.colorNeutralStroke2),
    },
  },
});

import type { IDisposable } from '@ms/utilities-disposables/lib/Disposable';
import { Scope } from '@ms/utilities-disposables/lib/Scope';

export class EventGroup implements IDisposable {
  private _scope: Scope;
  private _targetMap: WeakMap<{}, EventTarget>;
  private _disposableMap: WeakMap<{}, Map<string, IDisposable>>;

  constructor(owner?: {} | null) {
    this._scope = new Scope();
    this._targetMap = new WeakMap();
    this._disposableMap = new WeakMap();
  }

  public on(
    target: {},
    eventName: string,

    listener: (args?: any) => void
  ): void {
    let eventTarget: EventTarget;

    if (isEventTarget(target)) {
      eventTarget = target;
    } else {
      const mappedTarget = this._targetMap.get(target);
      if (!mappedTarget) {
        eventTarget = new EventTarget();
        this._targetMap.set(target, eventTarget);
      } else {
        eventTarget = mappedTarget;
      }
    }

    eventTarget.addEventListener(eventName, listener);

    let targetDisposables = this._disposableMap.get(target);
    if (!targetDisposables) {
      targetDisposables = new Map();
      this._disposableMap.set(target, targetDisposables);
    }

    const disposable = this._scope.attach({
      dispose: () => {
        eventTarget.removeEventListener(eventName, listener);
      }
    });

    targetDisposables.set(eventName, disposable);
  }

  public off(
    target: {},
    eventName: string,

    listener: (args?: any) => void
  ): void {
    let eventTarget: EventTarget | undefined;

    if (isEventTarget(target)) {
      eventTarget = target;
    } else {
      const mappedTarget = this._targetMap.get(target);
      if (!mappedTarget) {
        // No need.
      } else {
        eventTarget = mappedTarget;
      }
    }

    const targetDisposables = this._disposableMap.get(target);
    if (!targetDisposables) {
      // No need.
    }

    if (targetDisposables) {
      const disposable = targetDisposables.get(eventName);

      if (disposable) {
        disposable.dispose();
        targetDisposables.delete(eventName);
      }

      if (targetDisposables.size === 0) {
        this._disposableMap.delete(target);
      }
    }

    if (eventTarget) {
      eventTarget.removeEventListener(eventName, listener);
    }
  }

  public dispose(): void {
    this._scope.dispose();
  }
}

function isEventTarget(target: {} | EventTarget): target is EventTarget {
  return !!(target as EventTarget).addEventListener;
}

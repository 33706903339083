import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import {
  IEventCancelRegPayload,
  cancelEventRegisterAsyncAction,
  eventSelector,
  registeredSelector,
  userIsRegistered,
} from "../../core/slices/eventSlice";
import { authenticatedSelector } from "../../core/slices/userSlice";
import {
  TextV9 as Text,
  ButtonV9 as Button,
  DialogContentV9 as DialogContent,
  DialogTitleV9 as DialogTitle,
  DialogV9 as Dialog,
  DialogSurfaceV9 as DialogSurface,
  DialogBodyV9 as DialogBody,
  DialogActionsV9 as DialogActions,
  mergeClasses,
} from "../../shared";
import { useTranslation } from "react-i18next";
import { IEvent } from "../../core/slices/eventTypes.interface";
import { UserAction } from "./UserAction";
import { UserBIScenario } from "../../common/logger/Logger";
import { useLogger } from "../../common/logger/LoggerContext";
import {
  IRegistrationWarningPopUp,
  RegistrationWarningPopUp,
} from "./RegistrationWarningPopUp";
import { UserRegistrationStatus } from "../../core/slices/eventTypes.interface";
import { buttonStyles } from "../../styles/ButtonStyles";
import { portalTextStyles } from "../../styles/PortalText";
import { flexStyles } from "../../styles/FlexStyles";
import { portalResponsiveDialogStyles } from "../../styles/PortalResponsiveDialog";
import { generateSearchParams } from "../../utilities/common/generateSearchParams";
import { routes } from "../../common/constants";
import { PortalLocationState } from "../../core/history/history.interface";

/**
 * Component to be able to Cancel Registration either via url where it is called directly from EventPage
 * or via the Cancel Registration CommandBarBtn from EventRegistrationButton.tsx
 *
 */
export const CancelRegistration: React.FunctionComponent = () => {
  const flexClasses = flexStyles();
  const portalResponsiveDialogClasses = portalResponsiveDialogStyles();
  const portalTextClasses = portalTextStyles();
  const buttonClasses = buttonStyles();
  const { id, regId } = useParams<{ id: string; regId: string }>();
  const history = useHistory<PortalLocationState>();
  const location = useLocation<PortalLocationState>();
  const [userChoosingCancel, setUserChoosingCancel] = useState(false);
  const currentEvent: IEvent | undefined = useSelector(eventSelector);
  const isAuthenticated = useSelector(authenticatedSelector);
  const isRegistered = useSelector(userIsRegistered);
  const registration = useSelector(registeredSelector);
  const dispatch = useDispatch();
  const { t: i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  const logger = useLogger()?.logger;

  useEffect(() => {
    //route back to event page if the user successfully cancelled their registration or
    //decided to keep their registration
    if (!isOpen && history.location.pathname !== `${routes.event}/${id}`) {
      history.push({
        pathname: `${routes.event}/${id}`,
        search: generateSearchParams(location),
        state: {
          userAction: userChoosingCancel
            ? UserAction.CancelledRegistration
            : null,
        },
      });
    }
    //implementation to return focus back to parent element when user escapes or clicks
    //No, Keep Registration
    if (!isOpen && !userChoosingCancel) {
      if (document.activeElement) {
        //TODO: Think of another strategy for accessing modal from parent component so focus
        //can return to parent element when using keyboard for navigation
        const registrationStatusMenu = document.getElementById(
          "registration-status-text"
        );
        if (registrationStatusMenu) {
          registrationStatusMenu.focus();
        }
      }
    }
  }, [id, userChoosingCancel, history, isOpen, location]);

  const cancelRegistration: () => void = async () => {
    logger?.logUiTelemetry(
      "eventRegistration",
      UserBIScenario.CancelRegistration,
      "click",
      "none",
      "EventRegistrationPage"
    );
    setIsOpen(false);
    setUserChoosingCancel(true);
    const eventId = currentEvent && currentEvent.id ? currentEvent.id : "";
    const payload: IEventCancelRegPayload = {
      eventId: eventId,
      regId: regId,
    };
    await dispatch(cancelEventRegisterAsyncAction(payload));
  };

  const closeCancellation: () => void = () => {
    setIsOpen(false);
    setUserChoosingCancel(false);
  };

  let errorModal = null;
  let confirmationDialog = null;

  if (registration?.status === UserRegistrationStatus.CANCELED) {
    const warningFields: IRegistrationWarningPopUp = {
      title: i18n("errors.validation_errors.not_registered_for_event_title"),
      errorMessage: i18n(
        "errors.validation_errors.not_registered_for_event_body"
      ),
      showError: true,
    };
    errorModal = <RegistrationWarningPopUp {...warningFields} />;
  } else {
    confirmationDialog = (
      <Dialog
        open={isOpen && (isAuthenticated ? isRegistered : true)}
        onOpenChange={closeCancellation}
      >
        <DialogSurface
          className={mergeClasses(
            flexClasses.root,
            flexClasses.column,
            portalResponsiveDialogClasses.dialogSurface
          )}
        >
          <DialogBody
            className={mergeClasses(
              flexClasses.root,
              flexClasses.column,
              portalResponsiveDialogClasses.dialogBody
            )}
            data-testid="cancelRegistrationPopUp"
          >
            <DialogTitle data-testid="dialog-header">
              {i18n("cancel_your_registration")}
            </DialogTitle>
            <DialogContent>
              <Text
                data-testid="cancelRegistrationPopUpBody"
                className={portalTextClasses.eventTitle}
              >
                {i18n("lose_access_to_event", {
                  eventTitle: currentEvent?.title,
                })}
              </Text>
            </DialogContent>
            <DialogActions
              className={portalResponsiveDialogClasses.dialogActions}
            >
              <Button
                data-testid="yesCancelRegistration"
                appearance="secondary"
                className={buttonClasses.fluid}
                onClick={() => cancelRegistration()}
                aria-label={i18n("yes_cancel_registration")}
              >
                {i18n("yes_cancel_registration")}
              </Button>
              <Button
                data-testid="noKeepRegistration"
                appearance="primary"
                className={buttonClasses.fluid}
                onClick={() => closeCancellation()}
                aria-label={i18n("no_keep_registration")}
              >
                {i18n("no_keep_registration")}
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  }

  return registration?.status === UserRegistrationStatus.CANCELED
    ? errorModal
    : confirmationDialog;
};

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../styles/EventPage.scss";
import { useLogger } from "../common/logger/LoggerContext";
import {
  eventPageViewCountAsyncAction,
  IEventPageViewCountPayload,
  isOrganizerSelector,
} from "../core/slices/eventSlice";
import { useParams, useRouteMatch } from "react-router-dom";
import { IPageType, PageViewType } from "../core/slices/eventTypes.interface";
import { routes } from "../common/constants";

export const PageViewCount: React.FunctionComponent<{}> = () => {
  const isOrganizer = useSelector(isOrganizerSelector);
  const { id } = useParams<{ id: string }>();
  const { path } = useRouteMatch();
  const logger = useLogger()?.logger;
  logger?.logUiTelemetry(
    "renderComponent",
    "ShowEvent",
    "none",
    "success",
    "Event"
  );
  const makePageViewType = () => {
    let pageType;
    /* istanbul ignore else */
    if (path !== `${routes.event}/:id/${routes.registration}`) {
      pageType = PageViewType.EVENTPAGE;
    } else {
      pageType = PageViewType.REGISTRATIONPAGE;
    }

    return pageType;
  };
  const page: IPageType = {
    type: makePageViewType(),
  };
  const payload: IEventPageViewCountPayload = {
    eventId: id,
    pageType: page,
  };

  const dispatch = useDispatch();

  /* istanbul ignore next */
  useEffect(() => {
    if (!isOrganizer) {
      dispatch(eventPageViewCountAsyncAction(payload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

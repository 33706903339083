import { isDisposable, hook, IDisposable } from './Disposable';

/**
 * Represents a scope which can be used to manage component lifetime.
 *
 * @export
 * @interface IScope
 */
export interface IScope {
  /**
   * Whether or not this scope is in a disposed state.
   *
   * @type {boolean}
   */
  isDisposed: boolean;

  /**
   * Attaches an object to the lifetime of this scope.
   * Disposing this scope will subsequently dispose the object.
   *
   * @template T the type of the object.
   * @param {T} disposable the object to be attached to this scope.
   * @returns {T} the object, now marked as disposable
   */
  attach<T extends IDisposable>(disposable: T): T;
  attach<T>(instance: T): T & IDisposable;
}

interface IDisposablesById {
  [id: string]: IDisposable;
}

/**
 * Lifetime manager for scoping components.
 *
 * @export
 * @class Scope
 * @implements {IDisposable}
 *
 * @example
 *  const scope = new Scope();
 *
 *  let instance = new (scope.attached(MyComponent))();
 *
 *  scope.dispose();
 */
export class Scope implements IScope, IDisposable {
  /**
   * Whether or not this scope has been disposed.
   *
   * @type {boolean}
   */
  public isDisposed: boolean;

  /**
   * A mapping of objects to be disposed within this scope.
   *
   * @private
   * @type {IDisposablesById}
   */
  private readonly _disposables: IDisposablesById;

  /**
   * The last id assigned to a disposable.
   *
   * @private
   * @type {number}
   */
  private _lastDisposableId: number;

  /**
   * Creates an instance of Scope.
   */
  constructor() {
    this._disposables = {};
    this._lastDisposableId = 0;
    this.isDisposed = false;
  }

  /**
   * Attaches an object to the lifetime of this scope.
   * Disposing this scope will subsequently dispose the object.
   *
   * @template T the type of the object.
   * @param {T} disposable the object to be attached to this scope.
   * @returns {T} the object, now marked as disposable
   */
  public attach<T extends IDisposable>(disposable: T): T;
  public attach<T>(instance: T): T & IDisposable;
  public attach(instance: {}): IDisposable {
    const id = `${++this._lastDisposableId}`;

    const disposable = hook(instance, () => {
      delete this._disposables[id];
    });

    this._disposables[id] = disposable;
    return disposable;
  }

  /**
   * Disposes this scope and any attached objects.
   */
  public dispose(): void {
    if (!this.isDisposed) {
      this.isDisposed = true;
    }

    const disposables = this._disposables;
    for (const id of Object.keys(disposables)) {
      const disposable = disposables[id];

      if (disposable && isDisposable(disposable)) {
        disposable.dispose();
      }

      delete disposables[id];
    }
  }
}

import { createLightTheme, Theme, BrandVariants, Brands } from "../../shared";

enum ThemeColor {
  Default = "default",
  Teal = "teal",
  Red = "red",
  Orange = "orange",
  Yellow = "yellow",
  Green = "green",
  Blue = "blue",
  Pink = "pink",
  Grey = "grey",
}

export const generateTheme = (
  primaryColor: string = primaryColorsMapping[ThemeColor.Default]
): Theme => {
  const themeColor: ThemeColor =
    (Object.keys(primaryColorsMapping).find(
      (color) => primaryColorsMapping[color as ThemeColor] === primaryColor
    ) as ThemeColor) || ThemeColor.Default;

  const brandColors: BrandVariants = brandColorsMapping[themeColor];

  const customBrandColorTheme: Theme = generateBrandColorScheme(
    createLightTheme(brandColors),
    brandColors,
    themeColor
  );
  return customBrandColorTheme;
};

const generateBrandColorScheme = (
  theme: Theme,
  colors: BrandVariants,
  themeColor: ThemeColor
): Theme => {
  const customBrandColorSchemeMapping = brandColorSchemeMapping[themeColor];
  const customBrandColorScheme = Object.keys(
    customBrandColorSchemeMapping
  ).reduce((result, key) => {
    const variant: Brands = customBrandColorSchemeMapping[key];
    result[key] = colors[variant];
    return result;
  }, {} as Record<string, string>);

  return {
    ...theme,
    ...customBrandColorScheme,
  };
};

const brandColorsMapping = {
  [ThemeColor.Default]: {
    10: "#2b2b40",
    20: "#2f2f4a",
    30: "#333357",
    40: "#383966",
    50: "#3d3e78",
    60: "#444791",
    70: "#4f52b2",
    80: "#5b5fc7", //primary
    90: "#7579eb",
    100: "#7f85f5",
    110: "#9299f7",
    120: "#aab1fa",
    130: "#b6bcfa",
    140: "#c5cbfa",
    150: "#dce0fa",
    160: "#e8ebfa",
  },
  [ThemeColor.Red]: {
    10: "#541C27",
    20: "#611D2B",
    30: "#701E2F",
    40: "#822132",
    50: "#942338",
    60: "#A3263A",
    70: "#BA2941",
    80: "#C72E42", //primary
    90: "#D93848",
    100: "#E54C56",
    110: "#ED646A",
    120: "#F57D81",
    130: "#FA9D9F",
    140: "#FABEBF",
    150: "#FAD7D7",
    160: "#FAE3E3",
  },
  [ThemeColor.Orange]: {
    10: "#612C0E",
    20: "#6B300E",
    30: "#73330E",
    40: "#80370D",
    50: "#8C3B0B",
    60: "#9E4009",
    70: "#B54809", //primary
    80: "#D6550B",
    90: "#ED600E",
    100: "#F07026",
    110: "#F58447",
    120: "#FA9866",
    130: "#FAAF8C",
    140: "#FCC5AC",
    150: "#FCD9CA",
    160: "#FCE7DE",
  },
  [ThemeColor.Yellow]: {
    10: "#543B05",
    20: "#5E4205",
    30: "#694904",
    40: "#735003",
    50: "#805803",
    60: "#8F6201", //primary
    70: "#9E6C00",
    80: "#AD7702",
    90: "#BD8306",
    100: "#D19111",
    110: "#E8A11C",
    120: "#F5B038",
    130: "#FABF61",
    140: "#FCD390",
    150: "#FCE2BB",
    160: "#FCEDD7",
  },
  [ThemeColor.Green]: {
    10: "#1B422A",
    20: "#1B4A2C",
    30: "#1A522E",
    40: "#1B5E33",
    50: "#1C6B38",
    60: "#1C7A3C", //primary
    70: "#238744",
    80: "#2F9651",
    90: "#3FA65A",
    100: "#4DB261",
    110: "#61C271",
    120: "#77D186",
    130: "#96D99F",
    140: "#B3E3B8",
    150: "#D1EDD3",
    160: "#E4F5E4",
  },
  [ThemeColor.Teal]: {
    10: "#114045",
    20: "#11494F",
    30: "#125159",
    40: "#135C66",
    50: "#146875",
    60: "#157685", //primary
    70: "#168391",
    80: "#1B93A6",
    90: "#25A4BA",
    100: "#36B3C9",
    110: "#50BED4",
    120: "#6DC9DE",
    130: "#8DD8EB",
    140: "#ADE1F0",
    150: "#D0ECF5",
    160: "#E1F4FA",
  },
  [ThemeColor.Blue]: {
    10: "#1D3354",
    20: "#1E385E",
    30: "#1F3D6B",
    40: "#1F467D",
    50: "#1E4D8F",
    60: "#1D59A8",
    70: "#1E68C9", //primary
    80: "#2774D9",
    90: "#3C7DE5",
    100: "#4D8CEB",
    110: "#5D98F0",
    120: "#76A8F5",
    130: "#91BEFA",
    140: "#B4D5FA",
    150: "#CFE5FA",
    160: "#E1EEFA",
  },
  [ThemeColor.Pink]: {
    10: "#52213C",
    20: "#5C2243",
    30: "#692449",
    40: "#782551",
    50: "#8C275B",
    60: "#A32966",
    70: "#BF2A72", //primary
    80: "#D1347D",
    90: "#E03F85",
    100: "#EB5491",
    110: "#F06296",
    120: "#F27CA5",
    130: "#F59DB7",
    140: "#FABBCB",
    150: "#FAD4DC",
    160: "#FAE3E7",
  },
  [ThemeColor.Grey]: {
    10: "#000000",
    20: "#0F0F0F",
    30: "#1A1A1A",
    40: "#242424", //primary
    50: "#303030",
    60: "#3B3B3B",
    70: "#454545",
    80: "#545454",
    90: "#696969",
    100: "#7D7D7D",
    110: "#919191",
    120: "#A6A6A6",
    130: "#BABABA",
    140: "#CFCFCF",
    150: "#E0E0E0",
    160: "#F0F0F0",
  },
};

const primaryColorsMapping = {
  [ThemeColor.Default]: brandColorsMapping[ThemeColor.Default][80],
  [ThemeColor.Red]: brandColorsMapping[ThemeColor.Red][80],
  [ThemeColor.Orange]: brandColorsMapping[ThemeColor.Orange][70],
  [ThemeColor.Yellow]: brandColorsMapping[ThemeColor.Yellow][60],
  [ThemeColor.Green]: brandColorsMapping[ThemeColor.Green][60],
  [ThemeColor.Teal]: brandColorsMapping[ThemeColor.Teal][60],
  [ThemeColor.Blue]: brandColorsMapping[ThemeColor.Blue][70],
  [ThemeColor.Pink]: brandColorsMapping[ThemeColor.Pink][70],
  [ThemeColor.Grey]: brandColorsMapping[ThemeColor.Grey][40],
};

const brandColorSchemeMapping: Record<ThemeColor, Record<string, Brands>> = {
  [ThemeColor.Default]: {
    colorBrandBackground: 80,
    colorBrandBackground1: 160,
    colorBrandBackgroundHover: 70,
    colorBrandBackgroundPressed: 60,
    colorBrandForeground: 80,
    colorBrandForeground1: 80,
  },
  [ThemeColor.Red]: {
    colorBrandBackground: 80,
    colorBrandBackground1: 160,
    colorBrandBackgroundHover: 70,
    colorBrandBackgroundPressed: 60,
    colorBrandForeground: 80,
    colorBrandForeground1: 80,
  },
  [ThemeColor.Orange]: {
    colorBrandBackground: 70,
    colorBrandBackground1: 160,
    colorBrandBackgroundHover: 60,
    colorBrandBackgroundPressed: 50,
    colorBrandForeground: 70,
    colorBrandForeground1: 70,
  },
  [ThemeColor.Yellow]: {
    colorBrandBackground: 60,
    colorBrandBackground1: 160,
    colorBrandBackgroundHover: 50,
    colorBrandBackgroundPressed: 40,
    colorBrandForeground: 60,
    colorBrandForeground1: 60,
  },
  [ThemeColor.Green]: {
    colorBrandBackground: 60,
    colorBrandBackground1: 160,
    colorBrandBackgroundHover: 50,
    colorBrandBackgroundPressed: 40,
    colorBrandForeground: 60,
    colorBrandForeground1: 60,
  },
  [ThemeColor.Teal]: {
    colorBrandBackground: 60,
    colorBrandBackground1: 160,
    colorBrandBackgroundHover: 50,
    colorBrandBackgroundPressed: 40,
    colorBrandForeground: 60,
    colorBrandForeground1: 60,
  },
  [ThemeColor.Blue]: {
    colorBrandBackground: 70,
    colorBrandBackground1: 160,
    colorBrandBackgroundHover: 60,
    colorBrandBackgroundPressed: 50,
    colorBrandForeground: 70,
    colorBrandForeground1: 70,
  },
  [ThemeColor.Pink]: {
    colorBrandBackground: 70,
    colorBrandBackground1: 160,
    colorBrandBackgroundHover: 60,
    colorBrandBackgroundPressed: 50,
    colorBrandForeground: 70,
    colorBrandForeground1: 70,
  },
  [ThemeColor.Grey]: {
    colorBrandBackground: 40,
    colorBrandBackground1: 160,
    colorBrandBackgroundHover: 30,
    colorBrandBackgroundPressed: 20,
    colorBrandForeground: 40,
    colorBrandForeground1: 40,
  },
};

export const zIndexes = {
  background: 0,
  debug: 999999999,
  fixedMenu: 2,
  foreground: 1,
  menuItem: 3,
  overlay: 1000,
  overlayPriority: 1001,
};

import {
  iconFilledClassName,
  iconRegularClassName,
  makeStyles,
  shorthands,
  tokens,
} from "../shared";

export const eventSharingStyles = makeStyles({
  shareEventLinkText: {
    color: tokens.colorNeutralForeground3,
    marginBottom: "5px",
  },
  clipboardIcon: {
    color: tokens.colorBrandForeground1,
    ":hover": {
      [`& .${iconFilledClassName}`]: {
        display: "block",
      },
      [`& .${iconRegularClassName}`]: {
        display: "none",
      },
    },
  },
  clipboardIconWrapper: {
    backgroundColor: "transparent",
    ...shorthands.borderColor("transparent"),
    ":hover": {
      backgroundColor: "transparent",
      ...shorthands.borderColor("transparent"),
    },
  },
  shareEventInput: {
    ...shorthands.borderColor("transparent"),
    ":hover": {
      ...shorthands.borderColor("transparent"),
    },
    backgroundColor: tokens.colorNeutralBackground3,
  },
});

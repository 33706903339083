/**
 * Create a queue to store diagnostics events. The size of this queue is defined when it is initialized.
 */

export class DiagnosticsEventQueue<T> {
  private static QUEUE_CAPACITY = 1000;

  private diagnosticEvents: T[] = [];
  private queueCapacity: number;

  public constructor(private maxQueueLength?: number) {
    this.queueCapacity = this.maxQueueLength
      ? this.maxQueueLength
      : DiagnosticsEventQueue.QUEUE_CAPACITY;
  }

  /**
   * Resize queue
   * @param newCapacity - new queue capacity. Should be a positive integer
   */
  public setQueueCapacity(newCapacity: number): void {
    if (newCapacity < 1 || this.queueCapacity === newCapacity) {
      return;
    }
    this.queueCapacity = newCapacity;
    if (this.diagnosticEvents.length > newCapacity) {
      this.diagnosticEvents = this.diagnosticEvents.slice(
        this.diagnosticEvents.length - newCapacity
      );
    }
  }

  /**
   * Get the dianostic events queue.
   * @returns this diagnostics event queue
   */
  public getEvents(): T[] {
    return this.diagnosticEvents;
  }

  /**
   * Push item to the queue.
   * If the queue is full, remove the first event and then add the new one.
   */
  public enqueueEvent(event: T): void {
    if (this.diagnosticEvents.length < this.queueCapacity) {
      this.diagnosticEvents.push(event);
    } else {
      this.dequeueEvent();
      this.diagnosticEvents.push(event);
    }
  }

  /**
   * Push several items to the queue while respecting the max size (dequeuing as needed).
   */
  public enqueueEvents(events: T[]): void {
    if (events && events.length > 0) {
      events.forEach((event) => {
        this.enqueueEvent(event);
      });
    }
  }

  /**
   * Remove all the items in this queue.
   */
  public flush(): void {
    this.diagnosticEvents = [];
  }

  /**
   * Remove first items in this queue when the queue is full.
   * @returns the first diagnostics event in the queue.
   */
  private dequeueEvent(): T | undefined {
    return this.diagnosticEvents.shift();
  }
}

import React from "react";
import { useTranslation } from "react-i18next";
import { UserBIScenario } from "../common/logger/Logger";
import { useLogger } from "../common/logger/LoggerContext";
import { AccessType, IEvent } from "../core/slices/eventTypes.interface";
import {
  useBoolean,
  Popover,
  PopoverTrigger,
  PopoverSurface,
  ButtonV9 as Button,
  mergeClasses,
  TextV9 as Text,
  ShareFilled,
  bundleIcon,
  ShareRegular,
  DialogContentV9 as DialogContent,
  DialogTitleV9 as DialogTitle,
  DialogV9 as Dialog,
  DialogSurfaceV9 as DialogSurface,
  DialogBodyV9 as DialogBody,
} from "../shared";
import { eventShareEventButtonStyle } from "../styles/EventShareEventButton";
import { EventSharing } from "./EventSharing";
import { useBreakpoint } from "../utilities/hooks/useBreakpoints";
import { Breakpoint } from "../styles/Grid";
import { flexAlignStyles, flexStyles } from "../styles/FlexStyles";
import { buttonStyles } from "../styles/ButtonStyles";
import { portalResponsiveDialogStyles } from "../styles/PortalResponsiveDialog";

export const EventShareEventButton: React.FunctionComponent<{
  event: IEvent;
  eventUrl: string;
}> = ({ event, eventUrl }) => {
  const { isBreakpointAndDown } = useBreakpoint();
  const isMobileView = isBreakpointAndDown(Breakpoint.Medium);
  // indicates whether organizer has enabled social sharing for public events
  const isSocialShareEnabled =
    !!event.communicationProperties?.isSocialShareEnabled;

  if (event.access.accessType !== AccessType.PUBLIC || !isSocialShareEnabled) {
    return null;
  }

  return isMobileView ? (
    <EventShareEventButtonMobile event={event} eventUrl={eventUrl} />
  ) : (
    <EventShareEventButtonWeb event={event} eventUrl={eventUrl} />
  );
};

const EventShareEventButtonMobile: React.FunctionComponent<{
  event: IEvent;
  eventUrl: string;
}> = ({ event, eventUrl }) => {
  const portalResponsiveDialogClasses = portalResponsiveDialogStyles();
  const flexClasses = flexStyles();
  const buttonClasses = buttonStyles();
  const eventShareEventButtonClasses = eventShareEventButtonStyle();
  const logger = useLogger()?.logger;
  const { t: i18n } = useTranslation();
  const [isDialogOpen, { setTrue: openDialog, setFalse: dismissDialog }] =
    useBoolean(false);
  const eventID: string = event.id;

  const onClickButton = () => {
    openDialog();
    logger?.logUiTelemetry(
      "shareEvent",
      UserBIScenario.ClickShareButton,
      "click",
      "none",
      "EventPage"
    );
  };

  return (
    <>
      <Button
        className={mergeClasses(buttonClasses.fluid, buttonClasses.largeButton)}
        onClick={onClickButton}
        icon={<ShareRegular />}
      >
        {i18n("share_this_event")}
      </Button>
      <Dialog open={isDialogOpen} onOpenChange={dismissDialog}>
        <DialogSurface
          className={mergeClasses(
            flexClasses.root,
            flexClasses.column,
            portalResponsiveDialogClasses.dialogSurface
          )}
        >
          <DialogBody
            className={mergeClasses(
              flexClasses.root,
              flexClasses.column,
              portalResponsiveDialogClasses.dialogBody
            )}
          >
            <DialogTitle
              className={eventShareEventButtonClasses.mobileDialogTitle}
            >
              {i18n("share_this_event")}
            </DialogTitle>
            <DialogContent>
              <EventSharing
                eventTitle={event.title}
                eventUrl={eventUrl}
                eventDescription={event.description}
                promoImageID={event.theme.promoImage}
                eventID={eventID}
              />
            </DialogContent>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </>
  );
};

const EventShareEventButtonWeb: React.FunctionComponent<{
  event: IEvent;
  eventUrl: string;
}> = ({ event, eventUrl }) => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const eventShareEventButtonClasses = eventShareEventButtonStyle();
  const logger = useLogger()?.logger;
  const { t: i18n } = useTranslation();

  const ShareIcon = bundleIcon(ShareFilled, ShareRegular);

  return (
    <Popover
      positioning={{
        position: "above",
        align: "end",
        offset: { crossAxis: 0, mainAxis: 8 },
      }}
      trapFocus
    >
      <PopoverTrigger>
        <Button
          onClick={() =>
            logger?.logUiTelemetry(
              "shareEvent",
              UserBIScenario.ClickShareButton,
              "click",
              "none",
              "EventPage"
            )
          }
          icon={<ShareIcon />}
          appearance="outline"
          className={eventShareEventButtonClasses.webPopOverButton}
        />
      </PopoverTrigger>
      <PopoverSurface className={eventShareEventButtonClasses.webPopupContent}>
        <div
          className={mergeClasses(
            flexClasses.root,
            flexClasses.column,
            flexClasses.columnGapMedium,
            flexAlignClasses.alignItemFlexStart,
            eventShareEventButtonClasses.webPopupWrapper
          )}
        >
          <Text weight="bold">{i18n("share_this_event")}</Text>
          <EventSharing
            eventTitle={event.title}
            eventUrl={eventUrl}
            eventDescription={event.description}
            promoImageID={event.theme.promoImage}
            eventID={event.id}
          />
        </div>
      </PopoverSurface>
    </Popover>
  );
};

import React from "react";
import { EventUser } from "../core/slices/userTypes.interface";
import { useSelector } from "react-redux";
import { SpeakerProfile } from "./SpeakerProfile";
import { speakerProfileCardStyles } from "../styles/EventSpeakers";
import { eventSpeakersSelector } from "../core/slices/eventSlice";
import { mergeClasses } from "../shared";
import { portalListStyles } from "../styles/PortalList";

export const EventSpeakers: React.FunctionComponent = () => {
  const portalListClasses = portalListStyles();
  const speakerProfileCardClasses = speakerProfileCardStyles();
  const speakers: EventUser[] = useSelector(eventSpeakersSelector);

  return (
    <ul className={portalListClasses.root}>
      {speakers.map((speaker) => (
        <li key={speaker.id}>
          <div
            className={mergeClasses(
              "speaker-profile-card",
              speakerProfileCardClasses.root
            )}
          >
            <SpeakerProfile speaker={speaker} />
          </div>
        </li>
      ))}
    </ul>
  );
};

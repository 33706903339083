import { makeStyles, shorthands, tokens } from "../shared";

export const speakerProfileCardStyles = makeStyles({
  root: {
    backgroundcolor: tokens.colorNeutralBackground1,
    ...shorthands.border("1px", "solid", tokens.colorNeutralStroke2),
    ...shorthands.borderRadius(tokens.borderRadiusXLarge),
    ...shorthands.padding("16px"),
  },
});

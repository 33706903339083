import { isValidEmail } from "../../utilities/common/utils";

interface IPortalFormValidator {
  isValid: boolean;
  message: string;
}

export type IPortalFormValidatorFn<T> = (value: T) => IPortalFormValidator;

const valueLengthLimitation = 255;

export const lengthValidator = (value: string): IPortalFormValidator => ({
  isValid: value.length < valueLengthLimitation,
  message: "errors.validation_errors.length_limit_validation",
});

export const requiredStringValidator = (
  value: string
): IPortalFormValidator => ({
  isValid: !!value.trim(),
  message: "errors.validation_errors.required_field_validation",
});

export const emailValidator = (value: string): IPortalFormValidator => {
  return {
    isValid: isValidEmail(value),
    message: "errors.validation_errors.email_validation",
  };
};

export const linkedinProfileUrlValidator = (
  value: string
): IPortalFormValidator => {
  const regexp = new RegExp(
    /^((?:https?:)?\/\/(?:[\w]+\.)?linkedin\.com\/in\/(?<permalink>[\w\-_À-ÿ%]+)\/?)$/
  );
  return {
    isValid: !value.trim() || regexp.test(value.trim()),
    message: "errors.validation_errors.linkedin_validation",
  };
};

export const twitterUserUrlValidator = (
  value: string
): IPortalFormValidator => {
  const regexp = new RegExp(
    /^((?:https?:)?\/\/(?:[A-z]+\.)?twitter\.com\/@?(?!home|share|privacy|tos)(?<username>[A-z0-9_]+)\/?)$/
  );
  return {
    isValid: !value.trim() || regexp.test(value.trim()),
    message: "errors.validation_errors.twitter_validation",
  };
};

export const githubUserUrlValidator = (value: string): IPortalFormValidator => {
  const regexp = new RegExp(
    /^((?:https?:)?\/\/(?:www\.)?github\.com\/(?<login>[A-z0-9_-]+)\/?)$/
  );
  return {
    isValid: !value.trim() || regexp.test(value.trim()),
    message: "errors.validation_errors.github_validation",
  };
};

import { makeStyles } from "../shared";

export const iconStyles = makeStyles({
  smallest: {
    fontSize: "7px",
    height: "7px",
    width: "7px",
  },
  smaller: {
    fontSize: "10px",
    height: "10px",
    width: "10px",
  },
  small: {
    fontSize: "12px",
    height: "12px",
    width: "12px",
  },
  medium: {
    fontSize: "16px",
    height: "16px",
    width: "16px",
  },
  large: {
    fontSize: "20px",
    height: "20px",
    width: "20px",
  },
  larger: {
    fontSize: "32px",
    height: "32px",
    width: "32px",
  },
  largest: {
    fontSize: "40px",
    height: "40px",
    width: "40px",
  },
});

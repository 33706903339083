import React from "react";
import {
  TextV9 as Text,
  PortalAnchorV9 as PortalAnchor,
  mergeClasses,
} from "../shared";
import { useTranslation } from "react-i18next";
import { footerStyles } from "../styles/Footer";
import { useSelector } from "react-redux";
import { eventSelector } from "../core/slices/eventSlice";
import { microsoftLinks } from "../common/constants";
import { useLocation } from "react-router";
import { routes } from "../common/constants";
import { EventState } from "../core/slices/eventTypes.interface";
import { UserBIScenario } from "../common/logger/Logger";
import { useLogger } from "../common/logger/LoggerContext";
import { Breakpoint, gridStyles } from "../styles/Grid";
import { flexAlignStyles, flexStyles } from "../styles/FlexStyles";
import { portalTextStyles } from "../styles/PortalText";
import { PortalLocationState } from "../core/history/history.interface";
import { useBreakpoint } from "../utilities/hooks/useBreakpoints";

const shouldRenderFooter = (pathname: string): boolean => {
  return pathname !== routes.authError;
};

export const Footer: React.FunctionComponent = () => {
  const footerClasses = footerStyles();
  const gridClasses = gridStyles();
  const flexClasses = flexStyles();
  const flexAlignClasses = flexAlignStyles();
  const portalTextClasses = portalTextStyles();
  const { isBreakpointAndDown } = useBreakpoint();
  const mobileView = isBreakpointAndDown(Breakpoint.Medium);

  const { t: i18n } = useTranslation();
  const logger = useLogger()?.logger;
  const currentEvent = useSelector(eventSelector);
  const organizerProvidedLink = currentEvent?.organization?.privacyLink;
  const organizerName = currentEvent?.organization?.tenantName;
  const organizer = currentEvent?.organization;
  const { pathname } = useLocation<PortalLocationState>();

  if (
    !currentEvent ||
    currentEvent.state === EventState.CANCELED ||
    !shouldRenderFooter(pathname)
  ) {
    return null;
  }

  return (
    <div
      role="contentinfo"
      className={
        mobileView
          ? mergeClasses(
              "footer",
              footerClasses.root,
              footerClasses.footerMobileStyles
            )
          : mergeClasses("footer", footerClasses.root)
      }
    >
      <div
        className={mergeClasses(
          gridClasses.container,
          flexClasses.root,
          flexAlignClasses.justifyContentCenter
        )}
      >
        <div
          className={mergeClasses(
            "footer-content",
            flexClasses.root,
            flexClasses.column,
            flexAlignClasses.alignItemCenter,
            footerClasses.contentStyles
          )}
        >
          <Text
            align="center"
            className={mergeClasses(
              portalTextClasses.small,
              footerClasses.organizerText
            )}
            weight="semibold"
          >
            {/* Temporary fix if the organizer name  is also MSFT just show one link in footer*/}
            {organizerName !== "Microsoft" &&
              i18n("privacy_provided_by", { organizer: "Microsoft" })}
            {organizerName !== "Microsoft" && (
              <PortalAnchor
                aria-label={i18n("privacy_policy")}
                href={microsoftLinks.microsoftPrivacyPolicy}
                onClick={() =>
                  logger?.logUiTelemetry(
                    "privacyLink",
                    UserBIScenario.PrivacyPolicyLink,
                    "click",
                    "none",
                    "Footer"
                  )
                }
              >
                {<>{i18n("privacy_policy")}</>}
              </PortalAnchor>
            )}
            &nbsp;
            {organizerName &&
              i18n("privacy_provided_by", {
                organizer: organizerName,
              })}
            {organizerProvidedLink && (
              <PortalAnchor
                aria-label={i18n("privacy_policy")}
                href={organizerProvidedLink}
                onClick={() =>
                  logger?.logUiTelemetry(
                    "privacyLink",
                    UserBIScenario.OrgnizerPrivacyPolicyLink,
                    "click",
                    "none",
                    "Footer"
                  )
                }
              >
                {<>{i18n("privacy_policy")}</>}
              </PortalAnchor>
            )}
          </Text>
          {!organizer && (
            <Text
              className={mergeClasses(
                portalTextClasses.smaller,
                footerClasses.tenantPrivacyStatement
              )}
              align="center"
            >
              {i18n("tenant_privacy_statement_not_available")}
            </Text>
          )}
          <Text
            className={mergeClasses(
              portalTextClasses.smaller,
              footerClasses.privacyStatement
            )}
            align="center"
          >
            {i18n("privacy_statement")}
          </Text>
        </div>
      </div>
    </div>
  );
};

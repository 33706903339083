import React, { useEffect, useState } from "react";
import {
  IEvent,
  EventType,
  AccessType,
} from "../../core/slices/eventTypes.interface";
import { useDispatch, useSelector } from "react-redux";
import {
  getRecordingPlaybackInfoAsyncAction,
  recordingPlaybackErrorSelector,
  recordingPlaybackSelector,
} from "../../core/slices/sessionSlice";
import {
  IEventSession,
  RecordingHostType,
  RecordingInfo,
  RecordingPublishStatus,
} from "../../core/slices/session.interface";
import {
  attendeeIdSelector,
  isInternalPresenterSelector,
  isOrganizerSelector,
  vodHintSelector,
  presenterKeySelector,
} from "../../core/slices/eventSlice";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  isAttendeeNotFoundError,
  isConditionalAccessPolicyError,
  isExpiredRecordingError,
} from "../../core/slices/recordingError";
import {
  MessageBar,
  MessageBarBody,
  mergeClasses,
  PortalAnchorV9 as PortalAnchor,
} from "../../shared";
import { Trans, useTranslation } from "react-i18next";
import { AmpPlayer } from "../../vod/amp/AmpPlayer";
import { flexAlignStyles, flexStyles } from "../../styles/FlexStyles";
import { OnePlayer } from "../../vod/onePlayer/OnePlayer";
import { useAuthenticationService } from "../../core/auth/auth-context";
import { PlayerLoading } from "../../vod/PlayerLoading";

import { AccountInfo } from "@azure/msal-browser";
import { IBadgerFetchResponse } from "../../core/auth/authenticationService.interface";
import { routes } from "../../common/constants";
import { generateSearchParams } from "../../utilities/common/generateSearchParams";
import { PortalLocationState } from "../../core/history/history.interface";
import { recordingStyles } from "../../styles/Recording";

const Recording: React.FunctionComponent<{
  event: IEvent;
  session: IEventSession;
  hostView: string;
}> = ({ event, session, hostView }) => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const recordingClasses = recordingStyles();
  const { t: i18n } = useTranslation();
  const dispatch = useDispatch();
  const { regId: attendeeIdParam } = useParams<{ regId: string }>();

  const authenticationService =
    useAuthenticationService().authenticationService;
  const attendeeId = useSelector(attendeeIdSelector);
  const presenterKey = useSelector(presenterKeySelector);
  const vodHint = useSelector(vodHintSelector);
  const playbackInfo = useSelector(recordingPlaybackSelector);
  const playbackInfoError = useSelector(recordingPlaybackErrorSelector);
  const isOrganizer = useSelector(isOrganizerSelector);
  const isInternalPresenter = useSelector(isInternalPresenterSelector);
  const history = useHistory<PortalLocationState>();
  const location = useLocation<PortalLocationState>();

  const recording: RecordingInfo | undefined = session?.recordings?.[0];

  // registration id is required for public webinars for non-organizers/non-presenters
  const isRegistrationIdRequired =
    event.type === EventType.WEBINAR &&
    event.access.accessType === AccessType.PUBLIC &&
    !isOrganizer &&
    !isInternalPresenter;

  const isRecordingViewable =
    !!session &&
    !!recording &&
    recording.publishProgress.status === RecordingPublishStatus.Published &&
    (!isRegistrationIdRequired ||
      !!attendeeIdParam ||
      !!attendeeId ||
      !!presenterKey);

  // Note: Used to prevent player from loading with previously fetched playback info.
  const [isPlaybackInfoFetchCompleted, setIsPlaybackInfoFetchCompleted] =
    React.useState<boolean>(false);
  const [account, setAccount] = useState<AccountInfo | null>(null);
  const [badgerToken, setBadgerToken] = useState<IBadgerFetchResponse | null>(
    null
  );
  useEffect(() => {
    const fetchRecordingPlaybackInfo = async () => {
      setIsPlaybackInfoFetchCompleted(false);
      setBadgerToken(null);
      setAccount(null);
      let token = undefined;
      let account = null;
      if (recording?.hostType === RecordingHostType.SPE) {
        const organizerTenantId = event.id.split("@")[1];
        account = authenticationService.getAccount();
        if (
          (event.access.accessType === AccessType.PUBLIC &&
            account?.tenantId === organizerTenantId) ||
          event.access.accessType === AccessType.PRIVATE
        ) {
          setAccount(account);
        } else {
          account = null;
          token = await authenticationService.getBadgerToken();
          setBadgerToken(token);
        }
      }

      await dispatch(
        getRecordingPlaybackInfoAsyncAction({
          eventId: event.id,
          sessionId: session.id,
          recordingId: recording.id,
          registrationId: attendeeIdParam || attendeeId,
          presenterKey,
          badgerToken: token?.token,
          account,
        })
      );
      setIsPlaybackInfoFetchCompleted(true);
    };
    isRecordingViewable && fetchRecordingPlaybackInfo();
  }, [
    attendeeId,
    attendeeIdParam,
    authenticationService,
    dispatch,
    event,
    isRecordingViewable,
    presenterKey,
    recording,
    session.id,
  ]);
  const onClickSignIn = () => {
    history.push({
      pathname: routes.login,
      search: generateSearchParams(location),
      state: {
        redirectPath: location.state?.from ?? {
          pathname: location.pathname,
          search: generateSearchParams(location),
          hash: location.hash,
        },
      },
    });
  };
  const getRecordingExpiredAlert = () => {
    return (
      <MessageBar icon={null}>
        <MessageBarBody
          className={mergeClasses(
            flexClasses.root,
            flexAlignClasses.justifyContentCenter
          )}
        >
          {i18n("recording_expired_alert_message")}
        </MessageBarBody>
      </MessageBar>
    );
  };

  const displaySigninHint = () => {
    return (
      <MessageBar icon={null}>
        <MessageBarBody
          className={mergeClasses(
            flexClasses.root,
            flexAlignClasses.justifyContentCenter
          )}
        >
          <Trans i18nKey="recording_signin_hint_message">
            <PortalAnchor
              className={recordingClasses.loginHintLink}
              aria-label={`${i18n("signin_hint_message")}`}
              onClick={onClickSignIn}
            ></PortalAnchor>
          </Trans>
        </MessageBarBody>
      </MessageBar>
    );
  };

  if (!isRecordingViewable) {
    const isUserSignedIn = authenticationService.userIsAuthenticated();

    return recording &&
      recording.publishProgress.status === RecordingPublishStatus.Expired
      ? getRecordingExpiredAlert()
      : vodHint && !isUserSignedIn && !attendeeId && !presenterKey
      ? displaySigninHint()
      : null;
  }

  if (!isPlaybackInfoFetchCompleted) {
    return <PlayerLoading />;
  }

  if (!!playbackInfoError && isExpiredRecordingError(playbackInfoError)) {
    return getRecordingExpiredAlert();
  }

  if (!!playbackInfoError && isAttendeeNotFoundError(playbackInfoError)) {
    return (
      <MessageBar intent="error">
        <MessageBarBody>
          {i18n("recording_attendee_not_found_alert_message")}
        </MessageBarBody>
      </MessageBar>
    );
  }

  if (
    !!playbackInfoError &&
    isConditionalAccessPolicyError(playbackInfoError)
  ) {
    return (
      <MessageBar intent="error">
        <MessageBarBody>
          {playbackInfoError.responseErrorMessage}
        </MessageBarBody>
      </MessageBar>
    );
  }

  if (!!playbackInfoError) {
    return (
      <MessageBar intent={"error"}>
        <MessageBarBody>
          {i18n("recording_playback_error_message")}
        </MessageBarBody>
      </MessageBar>
    );
  }

  return recording.hostType === RecordingHostType.SPE ? (
    <OnePlayer
      publishTimeStamp={recording.publishProgress.timeStamp}
      playbackInfo={playbackInfo}
      badgerToken={badgerToken?.token}
      account={account}
      hostView={hostView}
    />
  ) : (
    <AmpPlayer
      publishTimeStamp={recording.publishProgress.timeStamp}
      hasTranscript={recording.hasTranscript}
      playbackInfo={playbackInfo}
    />
  );
};
export default Recording;

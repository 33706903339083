import { makeStyles, shorthands, tokens } from "../shared";

export const publicProfileStyles = makeStyles({
  header: {
    ...shorthands.margin("0px"),
  },
  formCard: {
    ...shorthands.borderRadius(tokens.borderRadiusXLarge),
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.padding("24px", "0px"),
  },
  contentWrapper: {
    ...shorthands.padding("0px", "24px"),
  },
  divider: {
    width: 0,
    ...shorthands.borderRight("1px", "solid", tokens.colorNeutralStroke2),
  },
});

import React from "react";
import { EventEngagementRail } from "./EventEngagementRail";
import { Breakpoint } from "../styles/Grid";
import { useSelector } from "react-redux";
import { displayEngagementRailSelector } from "../core/slices/eventSlice";
import { useBreakpoint } from "../utilities/hooks/useBreakpoints";
import { eventLayoutStyles } from "../styles/EventLayout";
import { mergeClasses } from "../shared";
import { flexItemStyles, flexStyles } from "../styles/FlexStyles";

export const EventLayout: React.FunctionComponent = ({ children }) => {
  const flexItemClasses = flexItemStyles();
  const flexClasses = flexStyles();
  const eventLayoutClasses = eventLayoutStyles();
  const displayEngagementRail = useSelector(displayEngagementRailSelector);

  const { isBreakpointAndDown } = useBreakpoint();
  const mobileView = isBreakpointAndDown(Breakpoint.Medium);

  const content = children;

  if (mobileView || !displayEngagementRail) {
    return <>{content}</>;
  }

  return (
    <div
      className={mergeClasses(
        "event-layout",
        eventLayoutClasses.root,
        flexClasses.root
      )}
    >
      <div className={flexItemClasses.grow}>
        <div className={eventLayoutClasses.content}>{content}</div>
      </div>
      <div className={flexItemClasses.shrink}>
        <div className={eventLayoutClasses.engagementRail}>
          <EventEngagementRail />
        </div>
      </div>
    </div>
  );
};

import FileSaver from "file-saver";

/**
 * @param {String} filename The file name to be downloaded
 * @param {String} data The string data that is the body of the blob file
 */
export function forceDownloadFile(
  filename: string,
  data: string,
  type?: string
): void {
  const blob = new Blob([data], { type });
  FileSaver.saveAs(blob, filename);
}

/**
 * This is to work around a chrome bug which can end up with large memory usage.
 * check out  https://bugs.chromium.org/p/v8/issues/detail?id=2869
 * and http://teams.microsoft.com/l/message/19:skypespaces_ddd88489c4064637ad2592915ee16e81@thread.skype/1520973359176?tenantId=72f988bf-86f1-41af-91ab-2d7cd011db47&groupId=af55e84c-dc67-4e48-9005-86e0b07272f9&parentMessageId=1520973359176&teamName=Microsoft%20Teams&channelName=Performance&createdTime=1520973359176
 * for the motivations behind this bug
 */
export function unleakString(str: string): string {
  if (str === null || str === undefined || str.length === 0) {
    return str;
  }
  return ` ${str}`.substr(1);
}

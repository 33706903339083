import { ScenarioName } from "./interface";

const LOGGER_SCENARIO_SESSION_KEY_PREFIX = "logger.loggerScenario";

/**
 * Build key to be used cache a {@link IScenarioLogger} to {@link SessionStorage}.
 */
export const buildLoggerScenarioKey = (scenarioName: ScenarioName): string => {
  return `${LOGGER_SCENARIO_SESSION_KEY_PREFIX}.${scenarioName}`;
};

import { Async } from '../utilities/Async';
import { EventGroup } from '../utilities/EventGroup';
import { Scope } from '@ms/utilities-disposables/lib/Scope';
import type { IDisposable } from '@ms/utilities-disposables/lib/Disposable';

import type { ICommand, ICommandOptions } from '../interfaces/Commands';
import { type IResult, type IErrorResult, type IQosError, ErrorCodes } from '../interfaces/Results';
import type {
  IAcknowledgeMessage,
  ICommandMessage,
  IResultMessage,
  IMessagePort,
  IMessenger,
  INotification,
  INotificationMessage
} from '../interfaces/Messenger';

/**
 * Parameters used to instantiate a Messenger.
 */
export interface IMessengerParams {
  /**
   * Resources that should be cleaned up if initialization fails.
   */
  initResources: IDisposable;

  /**
   * The delay in milliseconds to wait for channel initialization.
   */
  initTimeoutMs?: number;

  /**
   * A default timeout for acknowledging commands, in milliseconds.
   */
  ackTimeoutMs?: number;

  /**
   * A default timeout for waiting for command results, in milliseconds.
   */
  resultTimeoutMs?: number;

  /**
   * Initializer for the port that will be used for communication. Exact mechanism of acquisition varies.
   */
  init(resolve: (port: IMessagePort) => void, reject: (error: IQosError) => void): void;

  /**
   * Handler for commands sent by the other context. This function should check the `command` field to determine the appropriate action.
   * For unknown commands, the handler shall return `undefined`.
   */
  onCommand?(command: ICommand): Promise<IResult> | IResult | undefined;

  /**
   * Handler for notifications sent by the other context.
   */
  onNotification?(notification: INotification): void;
}

/**
 * @internal
 */
export interface IMessengerDependencies {
  /**
   * Test hook to override the `Async` constructor.
   * @internal
   */
  asyncType?: typeof Async;

  /**
   * Test hook to override the `EventGroup` constructor.
   * @internal
   */
  eventGroupType?: typeof EventGroup;
}

/**
 * A record for a pending command invocation.
 * @internal
 */
interface ISignalRecord {
  /**
   * ID of the timeout for the 'acknowledge' message. If this timeout fires before the 'acknowledge' message is received,
   * the result of the command invocation will be an error with isExpected: false and error.code: `ErrorCodes.acknowledgeTimeout`.
   */
  acknowledgeTimeout: number;

  /**
   * ID of the timeout for the 'result' message. If this timeout fires before the 'acknowledge' message is received,
   * the result of the command invocation will be an error with isExpected: false and error.code: `ErrorCodes.resultTimeout`.
   */
  resultTimeout: number;

  /**
   * Callback to be invoked on completion or failure of the command.
   * @param result - The result of command invocation.
   */
  resolve(result: IResult): void;
}

/**
 * Type used to store records of pending commands.
 */
type ISignalMap = Map<number, ISignalRecord>;

/**
 * The last id assigned to an outgoing ICommandMessage.
 */
let lastConversationId = 0;

/**
 * The default delay in milliseconds to wait for an 'acknowledge' reply.
 */
const ACKNOWLEDGE_TIMEOUT_DELAY_MS = 1000;
/**
 * The default delay in milliseconds to wait for the result of a command.
 */
const RESULT_TIMEOUT_DELAY_MS = 10000;
/**
 * The default delay in milliseconds to wait for channel initialization.
 */
const INITIALIZE_TIMEOUT_DELAY_MS = 10000;

/**
 * Component that handles communication between two browser contexts via postMessage.
 */
export class Messenger implements IMessenger, IDisposable {
  public readonly ready: Promise<void>;

  private readonly _async: Async;
  private readonly _invokeCommandHandler: (command: ICommand) => Promise<IResult>;
  private readonly _invokeNotificationHandler: (notification: INotification) => void;
  private readonly _port: Promise<IMessagePort>;
  private readonly _scope: Scope;
  private readonly _signals: ISignalMap;
  private readonly _ackTimeoutMs: number;
  private readonly _resultTimeoutMs: number;

  public constructor(params: IMessengerParams, dependencies: IMessengerDependencies = {}) {
    const signals = new Map();

    // Avoid causing the entire `params` object to be captured by the closure
    const handlerHost = {
      onCommand: params.onCommand,
      onNotification: params.onNotification
    };

    const {
      initResources,
      initTimeoutMs = INITIALIZE_TIMEOUT_DELAY_MS,
      ackTimeoutMs = ACKNOWLEDGE_TIMEOUT_DELAY_MS,
      resultTimeoutMs = RESULT_TIMEOUT_DELAY_MS
    } = params;

    const { asyncType = Async, eventGroupType = EventGroup } = dependencies;

    const scope = (this._scope = new Scope());
    scope.attach(initResources);
    const async = (this._async = scope.attach(new asyncType(this)));
    const events = scope.attach(new eventGroupType(this));

    this._ackTimeoutMs = ackTimeoutMs;
    this._resultTimeoutMs = resultTimeoutMs;

    // Wrapper for the partner-provided command handler, to ensure that any and all exceptions are handled and reported.
    this._invokeCommandHandler = (command: ICommand): Promise<IResult> => {
      try {
        const result = handlerHost.onCommand && handlerHost.onCommand(command);
        if (!result) {
          return Promise.resolve<IErrorResult>({
            result: 'error',
            error: {
              code: ErrorCodes.unsupportedCommand,
              message: command.command
            },
            isExpected: true
          });
        }

        return Promise.resolve(result).catch((error: IQosError): IErrorResult => {
          error = error || {
            name: ErrorCodes.handlerRejection,
            isExpected: false,
            message: 'Unknown error'
          };
          return {
            result: 'error',
            error: {
              code: error.code || error.name || ErrorCodes.handlerRejection,
              message: error.message || `${error}`
            },
            isExpected: !!error.isExpected
          };
        });
      } catch (error) {
        return Promise.resolve<IErrorResult>({
          result: 'error',
          isExpected: false,
          error: {
            code: ErrorCodes.handlerError,
            message: error && `Name: ${error.name}; Message: ${error.message || error}`
          }
        });
      }
    };

    this._invokeNotificationHandler = (notification: INotification): void => {
      try {
        handlerHost.onNotification && handlerHost.onNotification(notification);
      } catch {
        // Do nothing.
      }
    };

    let initTimeout: number;
    const promise = (this._port = new Promise<IMessagePort>(
      (resolve: (port: IMessagePort) => void, reject: (error: IQosError) => void) => {
        scope.attach({
          dispose: () => {
            const error: IQosError = new Error('Initialization canceled.');
            error.code = ErrorCodes.canceled;
            reject(error);
          }
        });

        initTimeout = async.setTimeout(() => {
          const error: IQosError = new Error(`Initialization timed out after ${initTimeoutMs}ms`);
          error.code = ErrorCodes.setupTimeout;
          reject(error);
        }, initTimeoutMs);

        params.init((port: IMessagePort) => {
          // This logic needs to happen synchronously to handle the WebView scenario
          async.clearTimeout(initTimeout);

          scope.attach({
            dispose: () => {
              port.close();
            }
          });

          const handleMessage = (event: MessageEvent): void => {
            const data: IAcknowledgeMessage | ICommandMessage | INotificationMessage | IResultMessage =
              event.data;
            if (!data || typeof data !== 'object') {
              return;
            }

            if (data.type === 'acknowledge') {
              return this._onAcknowledge(data);
            }

            if (data.type === 'command') {
              return this._onCommand(port, data);
            }

            if (data.type === 'notification') {
              return this._onNotification(data);
            }

            if (data.type === 'result') {
              return this._onResult(data);
            }
          };

          events.on(port, 'message', handleMessage);
          // Instruct the port to start processing inbound messages
          port.start();

          resolve(port);
        }, reject);
      }
    ));
    this.ready = promise.then(
      () => {
        // Return void
      },
      (error: IQosError) => {
        this.dispose();
        return Promise.reject(error);
      }
    );
    this._signals = signals;
  }

  public dispose(): void {
    this._scope.dispose();

    this._signals.forEach((entry: ISignalRecord) => {
      entry.resolve({
        result: 'error',
        isExpected: true,
        error: {
          code: ErrorCodes.canceled,
          message: 'Messenger disposed'
        }
      });
    });

    this._signals.clear();
  }

  /**
   * Sends a command to the other context and waits for a result.
   *
   * @param command - The command to send.
   * @param options - Options to apply to the request, such as a custom timeout delay.
   * @returns A promise for the result.
   */
  public sendCommand<TCommand extends ICommand>(
    command: TCommand,
    options: ICommandOptions = {}
  ): Promise<IResult> {
    if (this._scope.isDisposed) {
      return Promise.resolve<IErrorResult>({
        result: 'error',
        isExpected: false,
        error: {
          code: ErrorCodes.disposed,
          message: 'disposed'
        }
      });
    }

    const conversationId: number = ++lastConversationId;

    const {
      timeoutDelayMs: resultTimeoutDelayMs = this._resultTimeoutMs,
      acknowledgeTimeoutMs = this._ackTimeoutMs,
      transfer
    } = options;

    return this._port.then((port: IMessagePort) => {
      // Set the timeouts after initialization
      const async = this._async;
      const promise = new Promise((resolve: (result: IResult) => void) => {
        this._signals.set(conversationId, {
          acknowledgeTimeout: async.setTimeout(() => {
            this._onResult(createTimeoutResult(conversationId, ErrorCodes.acknowledgeTimeout));
          }, acknowledgeTimeoutMs),
          resultTimeout: async.setTimeout(() => {
            this._onResult(createTimeoutResult(conversationId, ErrorCodes.resultTimeout));
          }, resultTimeoutDelayMs),
          resolve: resolve
        });
      });

      port.postMessage(
        {
          type: 'command',
          id: conversationId,
          data: command
        },
        transfer || []
      );

      return promise;
    });
  }

  /**
   * Sends a fire-and-forget notification to the other context.
   * @param notification - The notification to send.
   */
  public sendNotification<TNotification extends INotification>(notification: TNotification): void {
    if (this._scope.isDisposed) {
      return;
    }

    this._port.then((port: IMessagePort) => {
      port.postMessage({
        type: 'notification',
        data: notification
      });
    });
  }

  private _onAcknowledge(data: IAcknowledgeMessage): void {
    const entry = this._signals.get(data.id);
    if (entry) {
      this._async.clearTimeout(entry.acknowledgeTimeout);
    }
  }

  private _onCommand(port: IMessagePort, data: ICommandMessage): void {
    const id = data.id;
    port.postMessage({
      type: 'acknowledge',
      id: id
    });

    this._invokeCommandHandler(data.data)
      .then((result: IResult) => {
        port.postMessage({
          type: 'result',
          data: result,
          id: id
        });
      })
      .catch((error: Error) => {
        // TODO: log error communicating with host.
      });
  }

  private _onNotification(data: INotificationMessage): void {
    this._invokeNotificationHandler(data.data);
  }

  private _onResult(data: Pick<IResultMessage, 'id' | 'data'>): void {
    const id = data.id;
    const async = this._async;
    const signals = this._signals;
    const entry = signals.get(id);
    if (entry) {
      async.clearTimeout(entry.acknowledgeTimeout);
      async.clearTimeout(entry.resultTimeout);
      entry.resolve(data.data);
    }
    signals.delete(id);
  }
}

function createTimeoutResult(id: number, code: ErrorCodes): Pick<IResultMessage, 'id' | 'data'> {
  return {
    id: id,
    data: {
      result: 'error',
      error: {
        code: code,
        message: 'Timed out'
      },
      isExpected: false
    }
  };
}

import { orderBy } from "lodash";
import {
  forceDownloadFile,
  unleakString,
} from "../../utilities/common/fileUtils";
import { ILogger, LoggerLevels } from "../logger/interface";
import { IShortcutService } from "../shortcut-service/interface";
import { DiagnosticsEventQueue } from "./DiagnosticsEventQueue";
import { IDiagnosticsEvent, IDiagnosticsService } from "./interface";
import { Store } from "redux";
import { AuthenticationService } from "../../core/auth/authenticationService";

const MAX_MESSAGE_SIZE = 512;
const LONG_MESSAGE_ENDING_PATTERN = "...";

export class DiagnosticsService implements IDiagnosticsService {
  private logger: ILogger;
  private store?: Store;
  private diagnosticsEvents: DiagnosticsEventQueue<IDiagnosticsEvent>;
  private logToConsole: boolean = false;

  constructor(logger: ILogger, logToConsole: boolean = false) {
    this.logger = logger;
    this.logToConsole = logToConsole;
    this.diagnosticsEvents = new DiagnosticsEventQueue<IDiagnosticsEvent>();
  }

  public initShortcut(shortcutService: IShortcutService): void {
    shortcutService.assignCommandHandler("DownloadLogsFiles", () =>
      this.download()
    );
  }

  public setStore(store: Store): void {
    this.store = store;
  }

  private getLogName(): string {
    return (
      "Teams Virtual Events Portal Diagnostics Log " +
      new Date().toLocaleString().replace(/\/| |:|,/g, "_")
    );
  }

  private stringifyLogs(): string {
    const logs = this.getDiagnosticsEvents();
    return this.sortLogs(logs);
  }

  private stringifyEcsState(): string {
    return JSON.stringify(this.store?.getState().ecs, null, "  ");
  }

  private stringifyEventState(): string {
    const state = this.store?.getState();
    if (!state || !state.event || !state.event.eventID) {
      return "No current state or event";
    }

    return JSON.stringify(
      {
        eventID: state.event.eventID,
        eventType: state.event.eventObject?.type,
        access: state.event.eventObject?.access,
        logoImage: state.event.eventObject?.theme.logoImage,
        promoImage: state.event.eventObject?.theme.promoImage,
        speakers: state.event.speakers.length,
        sessions: state.sessions.sessionObjects?.length,
        sponsors: state.sponsor.sponsorObjects?.length,
      },
      null,
      "  "
    );
  }

  private stringifyUserState(): string {
    const state = this.store?.getState();
    if (!state) {
      return "No current state";
    }

    return JSON.stringify(
      {
        isAuthenticated: state.user.isAuthenticated,
        isRegistered: state.event.isRegistered,
        loginState: AuthenticationService.getInstance().getLoginState(),
      },
      null,
      "  "
    );
  }

  private stringifyRegistrationState(): string {
    const state = this.store?.getState();
    if (!state || !state.event) {
      return "No current state or event for registration";
    }

    return JSON.stringify(
      {
        isFull: state.event.registrationDetails?.registrationProperties.isFull,
        isWaitlistEnabled:
          state.event.registrationDetails?.registrationProperties
            .isWaitlistEnabled,
        isWaitlistFull:
          state.event.registrationDetails?.registrationProperties
            .isWaitlistFull,
        isManualApprovalEnabled:
          state.event.registrationDetails?.registrationProperties
            .isManualApprovalEnabled,
      },
      null,
      "  "
    );
  }

  private stringifyCommonLoggerProperties(): string {
    return JSON.stringify(
      {
        sessionId: this.logger.sessionId,
        deployment: this.logger.deployment,
        version: this.logger.version,
        userAgent: this.logger.userAgent,
        url: window.location.href,
        deviceInfo: this.logger.getDeviceInfo(),
      },
      null,
      "  "
    );
  }

  private getLogHeader(): string {
    let resultStr = `Common Properties\r\n`;
    resultStr += `${this.stringifyCommonLoggerProperties()}\r\n\r\n`;
    resultStr += `ECS State\r\n${this.stringifyEcsState()}\r\n\r\n`;
    resultStr += `Event\r\n${this.stringifyEventState()}\r\n\r\n`;
    resultStr += `User\r\n${this.stringifyUserState()}\r\n\r\n`;
    resultStr += `Registration\r\n${this.stringifyRegistrationState()}\r\n\r\n`;
    return resultStr;
  }

  public getLogs(): string {
    return `${this.getLogHeader()}Logs\r\n${this.stringifyLogs()}`;
  }

  /** Sort all logs ordered by time stamp */
  private sortLogs(logs: IDiagnosticsEvent[]): string {
    return this.formatEvents(orderBy(logs, ["timeStamp"], ["desc"]));
  }

  private formatEvent = (event: IDiagnosticsEvent): string => {
    // If the toISOString function is present, then the timeStamp is of type Date
    const date =
      typeof event.timeStamp?.toISOString === "function"
        ? event.timeStamp.toISOString()
        : event.timeStamp;
    const level = event.level;
    return `${date} ${level}\t${event.message}`;
  };

  /**
   * Format the diagnostics logs as a string.
   */
  public formatEvents = (events: IDiagnosticsEvent[]): string => {
    if (!events || events.length === 0) {
      return "";
    }

    let result = "";
    events.forEach((event: IDiagnosticsEvent) => {
      if (!event) {
        return;
      }
      result += `${this.formatEvent(event)}\r\n`;
    });

    return unleakString(result);
  };

  /**
   * Store a log diagnostics event to queue for reporting purposes
   * and truncate its message if necessary.
   * @param diagnosticsEvent - An diagnostics event.
   */
  public pushDiagnosticsEvent(
    level: LoggerLevels,
    message: string
  ): IDiagnosticsEvent {
    // truncate long messages, to not inflate the stored events size
    if (message.length > MAX_MESSAGE_SIZE) {
      message =
        message.substring(0, MAX_MESSAGE_SIZE) + LONG_MESSAGE_ENDING_PATTERN;
    }
    message = unleakString(message);
    const event: IDiagnosticsEvent = {
      message,
      level,
      timeStamp: new Date(),
    };
    if (this.logToConsole) {
      this.logDiagnosticsEventToConsole(event);
    }
    this.diagnosticsEvents.enqueueEvent(event);
    return event;
  }

  private logDiagnosticsEventToConsole = (event: IDiagnosticsEvent): void => {
    switch (event.level) {
      case LoggerLevels.info:
        console.info(event.message);
        break;
      case LoggerLevels.debug:
        console.debug(event.message);
        break;
      case LoggerLevels.log:
        console.log(event.message);
        break;
      case LoggerLevels.warn:
        console.warn(event.message);
        break;
      case LoggerLevels.error:
        console.error(event.message);
        break;
    }
  };

  /**
   * Get the diagnostics queue. The events are sorted in descending order (the most recent is on top, at index 0)
   */
  public getDiagnosticsEvents(): IDiagnosticsEvent[] {
    return this.diagnosticsEvents.getEvents();
  }

  public download(): void {
    const filename = this.getLogName();
    const body = this.getLogs();
    forceDownloadFile(filename, body, "text/plain;charset=utf-8");
  }
}

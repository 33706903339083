import React from "react";
import {
  SkeletonV9 as Skeleton,
  SkeletonItem,
  mergeClasses,
} from "../../shared";
import { registrationCardStyles } from "../../styles/EventRegistration";
import { flexAlignStyles, flexStyles } from "../../styles/FlexStyles";
import { skeletonItemStyles } from "../../styles/SkeletonStyles";

export const RegistrationCardLoading: React.FunctionComponent = () => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const skeletonItemClasses = skeletonItemStyles();
  const registrationCardClasses = registrationCardStyles();
  const registrationCardLoading = (
    <Skeleton
      data-testid="registration-section-loading-component"
      animation="pulse"
    >
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.column,
          flexClasses.columnGapSmall,
          registrationCardClasses.root
        )}
      >
        <div
          className={mergeClasses(
            flexClasses.root,
            flexClasses.row,
            flexClasses.rowGapSmall,
            flexAlignClasses.alignItemCenter,
            flexAlignClasses.justifyContentSpaceBetween
          )}
        >
          <SkeletonItem
            className={mergeClasses(
              skeletonItemClasses.root,
              skeletonItemClasses.height24,
              skeletonItemClasses.width50
            )}
          />
          <SkeletonItem
            className={mergeClasses(
              skeletonItemClasses.root,
              skeletonItemClasses.height24,
              skeletonItemClasses.width80
            )}
          />
        </div>
        <SkeletonItem
          className={mergeClasses(
            skeletonItemClasses.root,
            skeletonItemClasses.height100
          )}
        />
        <SkeletonItem
          className={mergeClasses(
            skeletonItemClasses.root,
            skeletonItemClasses.textSize600
          )}
        />
      </div>
    </Skeleton>
  );

  return registrationCardLoading;
};

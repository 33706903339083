import { makeStyles, shorthands, tokens } from "../shared";
import { Breakpoint, mediaQueryBreakpointDown } from "./Grid";

export const sessionCardStyles = makeStyles({
  root: {
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.border("1px", "solid", tokens.colorNeutralStroke2),
  },
  section: {
    ...shorthands.padding("16px"),
    "&:not(:last-child)": {
      ...shorthands.borderBottom("1px", "solid", tokens.colorNeutralStroke2),
    },
  },
  date: {
    width: "64px",
  },
  dateTime: {
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.border("1px", "solid", tokens.colorNeutralStroke2),
    borderBottomStyle: "none",
    ...shorthands.borderRadius(
      tokens.borderRadiusMedium,
      tokens.borderRadiusMedium,
      "0px",
      "0px"
    ),
    ...shorthands.padding("9px", "3px"),
    whiteSpace: "pre",
  },
  sessionDate: {
    lineHeight: tokens.lineHeightBase300,
  },
  sessionMonth: {
    lineHeight: tokens.lineHeightBase100,
  },
  sessionTitle: {
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightSemibold,
    [mediaQueryBreakpointDown(Breakpoint.Medium)]: {
      fontSize: "18px",
      lineHeight: tokens.lineHeightBase300,
    },
  },
  sessionDescriptionText: {
    ...shorthands.overflow("hidden"),
    textOverflow: "ellipsis",
    overflowWrap: "break-word",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    display: "-webkit-box",
  },
  sessionPresenters: {
    maxHeight: "24px",
  },
  dateDuration: {
    backgroundColor: tokens.colorBrandBackground2,
    ...shorthands.borderRadius(
      "0px",
      "0px",
      tokens.borderRadiusMedium,
      tokens.borderRadiusMedium
    ),
    ...shorthands.padding("2px", "3px"),
    whiteSpace: "pre",
  },
  avatarName: {
    color: tokens.colorNeutralForeground2,
    ...shorthands.padding("8px", "0px", "8px", "0px"),
    lineHeight: tokens.lineHeightBase200,
  },
  sessionDescriptionInSpan: {
    "& > *:first-child": {
      marginTop: "0px",
    },
    "& > *:last-child": {
      marginBottom: "0px",
    },
  },
});

import { makeStyles, shorthands, tokens } from "../shared";

export const logoImageStyles = makeStyles({
  root: {
    ...shorthands.borderRadius(tokens.borderRadiusMedium),
    backgroundColor: tokens.colorNeutralBackground1,
    objectFit: "contain",
    boxShadow: tokens.shadow8,
  },
});

import React from "react";
import {
  SkeletonV9 as Skeleton,
  mergeClasses,
  SkeletonItem,
} from "../../shared";
import { registrationFormStyles } from "../../styles/EventRegistration";
import { useBreakpoint } from "../../utilities/hooks/useBreakpoints";
import { Breakpoint, gridStyles } from "../../styles/Grid";
import { flexStyles } from "../../styles/FlexStyles";
import { skeletonItemStyles } from "../../styles/SkeletonStyles";
export const EventRegistrationPageLoading: React.FunctionComponent = () => {
  const flexClasses = flexStyles();
  const skeletonItemClasses = skeletonItemStyles();
  const gridClasses = gridStyles();
  const registrationFormClasses = registrationFormStyles();
  const { isBreakpointAndDown } = useBreakpoint();
  const isMobileView = isBreakpointAndDown(Breakpoint.Medium);

  const skeletonFormInput = (
    <>
      <SkeletonItem
        className={mergeClasses(
          skeletonItemClasses.root,
          skeletonItemClasses.textSize18,
          registrationFormClasses.label
        )}
      />
      <SkeletonItem
        className={mergeClasses(
          skeletonItemClasses.root,
          skeletonItemClasses.textSize600
        )}
      />
    </>
  );

  const registrationFormHeader = (
    <div
      className={mergeClasses(
        "registration-form-header",
        registrationFormClasses.header
      )}
    >
      <div className={gridClasses.row}>
        <div
          className={mergeClasses(
            gridClasses.col,
            gridClasses.xs12,
            gridClasses.md10,
            gridClasses.offsetMd1,
            gridClasses.xl8,
            gridClasses.offsetXl2
          )}
        >
          <div
            className={mergeClasses(
              flexClasses.root,
              flexClasses.column,
              flexClasses.columnGapSmall
            )}
          >
            {isMobileView && (
              <SkeletonItem
                className={mergeClasses(
                  registrationFormClasses.logo,
                  registrationFormClasses.headerImage,
                  skeletonItemClasses.root
                )}
              />
            )}
            <div
              className={mergeClasses(
                flexClasses.root,
                flexClasses.rowGapSmall
              )}
            >
              <SkeletonItem
                className={mergeClasses(
                  skeletonItemClasses.root,
                  skeletonItemClasses.height24,
                  skeletonItemClasses.width300
                )}
              />
            </div>
            <SkeletonItem
              className={mergeClasses(
                skeletonItemClasses.root,
                skeletonItemClasses.textSize300,
                skeletonItemClasses.width200
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const registrationFormBody = (
    <div
      className={mergeClasses(
        "registration-form-Body",
        registrationFormClasses.body
      )}
    >
      <div className={gridClasses.row}>
        <div
          className={mergeClasses(
            gridClasses.col,
            gridClasses.xs12,
            gridClasses.md10,
            gridClasses.offsetMd1,
            gridClasses.xl8,
            gridClasses.offsetXl2
          )}
        >
          <div
            className={mergeClasses(
              flexClasses.root,
              flexClasses.column,
              flexClasses.columnGapMedium
            )}
          >
            <SkeletonItem
              className={mergeClasses(
                skeletonItemClasses.root,
                skeletonItemClasses.textSize18,
                skeletonItemClasses.width150
              )}
            />
            <div
              className={mergeClasses(
                gridClasses.row,
                registrationFormClasses.fieldsGrid
              )}
            >
              <div
                className={mergeClasses(
                  gridClasses.col,
                  gridClasses.xs12,
                  gridClasses.lg6
                )}
              >
                {skeletonFormInput}
              </div>
              <div
                className={mergeClasses(
                  gridClasses.col,
                  gridClasses.xs12,
                  gridClasses.lg6
                )}
              >
                {skeletonFormInput}
              </div>
              <div className={mergeClasses(gridClasses.col, gridClasses.xs12)}>
                {skeletonFormInput}
              </div>
            </div>
            <div
              className={mergeClasses(
                flexClasses.root,
                flexClasses.rowGapSmall
              )}
            >
              <SkeletonItem
                className={mergeClasses(
                  skeletonItemClasses.root,
                  skeletonItemClasses.textSize600
                )}
              />
              <SkeletonItem
                className={mergeClasses(
                  skeletonItemClasses.root,
                  skeletonItemClasses.textSize600
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const registrationPageLoading = (
    <div
      data-testid="registration-page-loading-component"
      className={gridClasses.row}
    >
      <div
        className={mergeClasses(
          gridClasses.col,
          gridClasses.xs12,
          gridClasses.lg8,
          gridClasses.offsetLg2,
          registrationFormClasses.cardArea
        )}
      >
        <div className={registrationFormClasses.card}>
          <Skeleton animation="pulse">
            {registrationFormHeader}
            {registrationFormBody}
          </Skeleton>
        </div>
      </div>
    </div>
  );

  return registrationPageLoading;
};

import {
  createAsyncThunk,
  createSlice,
  createAction,
  PayloadAction,
} from "@reduxjs/toolkit";
import { EventId, Guid } from "./CMDTypes";
import { fetchSponsors, fetchSponsorsOrder } from "./sponsorAPI";
import {
  IError,
  getErrorResponse,
  isErrorResponseAuthError,
  isErrorResponseNotFoundError,
  getErrorResponseLoggerScenarioMessage,
  isErrorResponseSkypeTokenBadTenantId,
  isErrorHandledError,
  isErrorResponseAcquireTokenRedirectRequiredError,
} from "./error";
import { RootState } from "../store/store";
import {
  IEventSponsor,
  IEventSponsorOrder,
  IEventSponsorResponse,
} from "./sponsor.interface";
import { Logger, Scenario } from "../../common/logger/Logger";
import { LoggerLevels } from "../../common/logger/interface";
import { getEventTenantId } from "../../utilities/common/utils";
import { enableRedirectAcquireTokenSelector } from "./ecsSlice";
import {
  getEventOverviewAsyncAction,
  IEventOverviewResponse,
} from "./eventSlice";
export interface IEventSponsorsState {
  sponsorObjects?: IEventSponsor[];
  order?: IEventSponsorOrder;
  sponsorError: IError | undefined;
}

const getSponsorsAction = "sponsors/fetch";
export const getEventSponsorsAsyncAction = createAsyncThunk<
  IEventSponsorResponse,
  EventId,
  { rejectValue: IError }
>(getSponsorsAction, async (eventId: EventId, { rejectWithValue }) => {
  const logger = Logger.getInstance();
  const scenarioData = {
    eventId: eventId,
    eventTenantId: getEventTenantId(eventId) || "",
  };
  const scenario = logger.createScenario(Scenario.SponsorsRequest, {
    data: scenarioData,
  });

  try {
    const sponsors: IEventSponsorResponse = await fetchSponsors(
      eventId,
      scenario || undefined
    );
    scenario?.stop();
    return sponsors;
  } catch (err) {
    logger.logTrace(
      LoggerLevels.error,
      `Could not get the sponsors for event with id ${eventId}`
    );
    const response: IError = getErrorResponse(getSponsorsAction, err);

    const scenarioEventData = {
      message: getErrorResponseLoggerScenarioMessage(response),
    };

    if (
      isErrorResponseNotFoundError(response) ||
      isErrorResponseAuthError(response) ||
      isErrorResponseSkypeTokenBadTenantId(response) ||
      isErrorHandledError(response)
    ) {
      scenario?.stop(scenarioEventData);
    } else {
      scenario?.fail(scenarioEventData);
    }

    return rejectWithValue(response);
  }
});

const getSponsorsOrderAction = "sponsors/order/fetch";
export const getEventSponsorsOrderAsyncAction = createAsyncThunk<
  IEventSponsorOrder,
  EventId,
  { rejectValue: IError }
>(
  getSponsorsOrderAction,
  async (eventId: EventId, { rejectWithValue, getState }) => {
    const logger = Logger.getInstance();
    const scenarioData = {
      eventId: eventId,
      eventTenantId: getEventTenantId(eventId) || "",
    };
    const scenario = logger.createScenario(Scenario.SponsorsOrderRequest, {
      data: scenarioData,
    });

    try {
      const order: IEventSponsorOrder = await fetchSponsorsOrder(
        eventId,
        scenario || undefined
      );
      return order;
    } catch (err) {
      logger.logTrace(
        LoggerLevels.error,
        `Could not get the sponsors order for event with id ${eventId}`
      );
      const response: IError | null = getErrorResponse(
        getSponsorsOrderAction,
        err
      );

      const scenarioEventData = {
        message: getErrorResponseLoggerScenarioMessage(response),
      };

      if (!response) {
        scenario?.fail(scenarioEventData);
        // Not an error we recognize, throw
        throw err;
      } else if (
        isErrorResponseNotFoundError(response) ||
        isErrorResponseAuthError(response) ||
        isErrorResponseSkypeTokenBadTenantId(response) ||
        (enableRedirectAcquireTokenSelector(getState()) &&
          isErrorResponseAcquireTokenRedirectRequiredError(response)) ||
        isErrorHandledError(response)
      ) {
        scenario?.stop(scenarioEventData);
      } else {
        scenario?.fail(scenarioEventData);
      }

      return rejectWithValue(response);
    }
  }
);

const initialState: IEventSponsorsState = {
  sponsorObjects: undefined,
  order: undefined,
  sponsorError: undefined,
};

export const resetEventSponsorsAction = createAction<boolean>("sponsors/reset");

export const eventSponsorsSlice = createSlice({
  name: "sponsor",
  initialState: initialState,
  reducers: {
    clearSponsorError: (state) => {
      state.sponsorError = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getEventOverviewAsyncAction.fulfilled,
        (
          state: IEventSponsorsState,
          { payload }: PayloadAction<IEventOverviewResponse>
        ) => {
          if (process.env.noop_api) {
            return;
          }

          state.sponsorObjects = payload.sponsors;
          state.order = payload.sponsorOrder;
          state.sponsorError = undefined;
        }
      )
      .addCase(getEventOverviewAsyncAction.rejected, (state, action) => {
        state.sponsorError = action.payload;
      })
      .addCase(
        getEventSponsorsAsyncAction.fulfilled,
        (
          state: IEventSponsorsState,
          { payload }: PayloadAction<IEventSponsorResponse>
        ) => {
          /* istanbul ignore if */
          if (process.env.noop_api) {
            return;
          }
          state.sponsorObjects = payload.sponsors;
          state.order = payload.sponsorsOrder;
          state.sponsorError = undefined;
        }
      )
      .addCase(getEventSponsorsAsyncAction.rejected, (state, action) => {
        state.sponsorError = action.payload;
      })
      .addCase(
        getEventSponsorsOrderAsyncAction.fulfilled,
        (
          state: IEventSponsorsState,
          { payload }: PayloadAction<IEventSponsorOrder>
        ) => {
          if (!process.env.noop_api && payload) {
            state.order = payload;
          }
        }
      )
      .addCase(getEventSponsorsOrderAsyncAction.rejected, (state, action) => {
        state.sponsorError = action.payload;
      })
      .addCase(
        resetEventSponsorsAction,
        (state: IEventSponsorsState, { payload }: PayloadAction<boolean>) => {
          if (!process.env.noop_api && payload) {
            Object.assign(state, initialState);
          }
        }
      );
  },
});

// selectors
export const eventSponsorsSelector = (state: RootState): IEventSponsor[] =>
  state.sponsor?.sponsorObjects || [];

export const orderedSponsorsSelector = (state: RootState): IEventSponsor[] => {
  if (
    state.sponsor?.sponsorObjects &&
    state.sponsor.order &&
    state.sponsor.order.orderedTiers
  ) {
    const defaultOrder = state.sponsor.order.orderedTiers["default"];
    const unorderedSponsors = [];
    for (let i = 0; i < state.sponsor?.sponsorObjects.length; i++) {
      unorderedSponsors[i] = state.sponsor?.sponsorObjects[i];
    }
    const orderedSponsors = unorderedSponsors.sort(function (a, b) {
      const A = a["id"],
        B = b["id"];

      if (defaultOrder.indexOf(A) > defaultOrder.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });
    return orderedSponsors;
  }
  return [];
};

export const eventSponsorSelector = (
  state: RootState,
  id: Guid
): IEventSponsor | undefined =>
  state.sponsor?.sponsorObjects?.find((item: IEventSponsor) => item.id === id);
export const eventSponsorErrorSelector = (
  state: RootState
): IError | undefined => state.sponsor?.sponsorError;
export const sponsorOrderSelector = (
  state: RootState
): IEventSponsorOrder | undefined => state.sponsor?.order;

// reducer
export default eventSponsorsSlice.reducer;

import React from "react";
import { useSelector } from "react-redux";
import { promoImageSelector } from "../core/slices/eventSlice";
import { heroImageStyles } from "../styles/HeroImage";
import { ImageV9 as Image, mergeClasses } from "../shared";
import { HeroImageLoading } from "./loading/HeroImageLoading";
import { flexAlignStyles, flexStyles } from "../styles/FlexStyles";

export const HeroImage: React.FunctionComponent = () => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const heroImageClasses = heroImageStyles();
  // draw the placeholder if we don't have one yet
  const promoImage = useSelector(promoImageSelector);
  let heroImage = null;

  if (!promoImage) {
    heroImage = <HeroImageLoading />;
  } else {
    heroImage = (
      <div
        className={mergeClasses(
          "hero-image",
          flexClasses.root,
          flexClasses.column,
          flexAlignClasses.alignItemCenter,
          flexAlignClasses.justifyContentEnd,
          heroImageClasses.flex
        )}
      >
        <div
          className={mergeClasses("hero-image-bg", heroImageClasses.bg)}
          style={{ backgroundImage: `url(${promoImage.image})` }}
        />
        <div
          className={mergeClasses(
            "hero-image-bg-blur",
            heroImageClasses.bgBlur
          )}
        />
        <Image
          className={mergeClasses("hero-image-image", heroImageClasses.image)}
          src={promoImage.image}
          alt=""
        />
      </div>
    );
  }

  return heroImage;
};

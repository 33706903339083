import React from "react";
import { useSelector } from "react-redux";
import { imageSelector } from "../core/slices/imageSlice";
import { sponsorCardStyles } from "../styles/SponsorCard";
import { IEventSponsor } from "../core/slices/sponsor.interface";
import {
  ImageV9 as Image,
  PortalAnchorV9 as PortalAnchor,
  mergeClasses,
} from "../shared";
import { UserBIScenario } from "../common/logger/Logger";
import { useLogger } from "../common/logger/LoggerContext";
import { gridStyles } from "../styles/Grid";
import { flexAlignStyles, flexStyles } from "../styles/FlexStyles";

export const SponsorCard: React.FunctionComponent<{
  sponsor: IEventSponsor;
}> = ({ sponsor }) => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const sponsorCardClasses = sponsorCardStyles();
  const logger = useLogger()?.logger;
  const onClickSponsor = () => {
    logger?.logUiTelemetry(
      "triggerNavigation",
      UserBIScenario.ViewSponsors,
      "click",
      "none",
      "SponsorsCard"
    );
  };
  const logo = useSelector(imageSelector(sponsor.logo));
  const sponsorLogo = logo ? logo : undefined;
  return (
    <div
      className={mergeClasses(
        "sponsor-card",
        flexClasses.root,
        flexAlignClasses.alignItemCenter,
        flexAlignClasses.justifyContentCenter,
        sponsorCardClasses.wrapper
      )}
    >
      {sponsor.website && (
        <PortalAnchor
          data-testid="sponsorWebsite"
          href={sponsor.website}
          target="sponsorWebsiteTab"
          referrerPolicy="origin"
          onClick={onClickSponsor}
        >
          {renderLogo()}
        </PortalAnchor>
      )}
      {!sponsor.website && renderLogo()}
    </div>
  );

  function renderLogo() {
    return (
      <Image
        src={sponsorLogo}
        alt={sponsor.name}
        fit="contain"
        className={sponsorCardClasses.logo}
        data-testid="sponsorIcon"
      />
    );
  }
};

export const SponsorCardList: React.FunctionComponent<{
  sponsors: IEventSponsor[];
}> = ({ sponsors }) => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const sponsorCardClasses = sponsorCardStyles();
  const gridClasses = gridStyles();
  return (
    <div className={sponsorCardClasses.list}>
      <div
        className={mergeClasses(gridClasses.row, sponsorCardClasses.listRow)}
      >
        {sponsors.map((sponsor) => {
          return (
            <div
              key={sponsor.id}
              className={mergeClasses(
                gridClasses.col,
                gridClasses.xs6,
                gridClasses.md4,
                flexClasses.root,
                flexAlignClasses.alignItemCenter,
                flexAlignClasses.justifyContentCenter
              )}
            >
              <SponsorCard sponsor={sponsor} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

import { makeStyles } from "../shared";

export const HERO_IMAGE_ASPECT_RATIO = "4 / 1";

export const heroImageStyles = makeStyles({
  bg: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundSize: "100% 100%",
  },
  bgBlur: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backdropFilter: `blur(${"30px"})`,
  },
  flex: {
    position: "relative",
    width: "100%",
    minWidth: "100%",
    aspectRatio: HERO_IMAGE_ASPECT_RATIO,
    maxHeight: "340px",
  },
  image: {
    position: "absolute",
    width: "100%",
    maxWidth: "1366px",
    aspectRatio: HERO_IMAGE_ASPECT_RATIO,
  },
});

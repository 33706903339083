import React, { useContext } from "react";
import { IAuthenticationService } from "./authenticationService.interface";

interface IAuthContext {
  authenticationService: IAuthenticationService;
}

const defaultAuthContext: IAuthContext = {
  // Never type because this should never be undefined in our app,
  // the context value is set at the very top level of the React app.
  // Note: If we set the value here, an AuthenticationService instance would be
  // created on import of the file, which is not ideal for UTs.
  authenticationService: undefined as never,
};

export const AuthContext =
  React.createContext<IAuthContext>(defaultAuthContext);

export const useAuthenticationService = (): IAuthContext =>
  useContext(AuthContext);

import {
  createAsyncThunk,
  createSlice,
  createAction,
  PayloadAction,
} from "@reduxjs/toolkit";
import { EventId, Guid } from "./CMDTypes";
import {
  fetchEvent,
  fetchEventSpeakers,
  fetchAMSImage,
  eventRegister,
  cancelEventRegister,
  getEventRegistration,
  getRegistrationStatusFromRegistrationID,
  getRegistrationStatusFromUserID,
  pageViewCount,
  fetchEventOverview,
} from "./eventAPI";
import { EventUser, EventUserRole } from "./userTypes.interface";
import { RootState } from "../store/store";
import {
  IEvent,
  IEventRegister,
  IEventRegisterResponse,
  IEventImageRecord,
  UserRegistrationStatus,
  AccessType,
  EventType,
  IEventPageViewCountResponse,
  IPageType,
  PageViewCountStatus,
} from "./eventTypes.interface";
import {
  IError,
  getErrorResponse,
  CmdServicesResponseErrorCode,
  isErrorResponseUnpublishedError,
  isErrorResponseAuthError,
  isErrorResponseNotFoundError,
  getErrorResponseLoggerScenarioMessage,
  isErrorResponseSkypeTokenBadTenantId,
  isErrorResponseCanceledEvent,
  isErrorHandledError,
  isErrorPreconditionFailedError,
  isErrorResponseAcquireTokenRedirectRequiredError,
} from "./error";
import { CMDTimeZoneDetails } from "./CMDTypes";
import placeholderPromoImage from "../../assets/placeholder_promo_image.jpg";
import placeholderLogoImage from "../../assets/placeholder_logo_image.jpg";
import {
  Logger,
  Scenario,
  SubScenario,
  ScenarioStepStatus,
} from "../../common/logger/Logger";
import { LoggerLevels } from "../../common/logger/interface";
import { authenticatedSelector, userTenantIDSelector } from "./userSlice";
import {
  getRegistrationButtonState,
  RegistrationState,
} from "../../views/registration/RegistrationStateInternal";
import {
  addToCalendar,
  CalendarType,
  eventToCalendarEvent,
} from "../../utilities/addToCalendarUtils";
import { getEventTenantId } from "../../utilities/common/utils";
import { enableRedirectAcquireTokenSelector } from "./ecsSlice";
import { IEventOverview } from "./eventOverview.interface";
import { IEventSession } from "./session.interface";
import { IEventSponsor, IEventSponsorOrder } from "./sponsor.interface";

export const enum QuestionTypes {
  SingleChoice = "SingleChoice",
  MultiChoice = "MultiChoice",
  BooleanType = "Boolean",
  PromoEmailConsent = "PromoEmailConsent",
  Note = "Note",
  Text = "Text",
}

//TODO: Discuss regarding answer object needing to be capitalized
export type Answer = {
  Id: string;
  Answer?: string | string[] | boolean | undefined;
};

//The registration answers payload
export interface IEventRegisterAnswers {
  firstName: string;
  lastName: string;
  email: string;
  language: string;
  timeZoneDetails: CMDTimeZoneDetails;
  answers?: Answer[];
  hasAcceptedTermsOfUse: boolean | null;
}

export const enum EventEngagementRailChoices {
  None = "none",
  Chat = "chat",
  People = "people",
}

export interface IEventState {
  eventObject?: IEvent;
  eventID?: string;
  eventError?: IError;
  speakersError?: IError;
  isRegistered: boolean;
  registrationDetails?: IEventRegister;
  registrationStatus?: IEventRegisterResponse;
  registrationError?: IError;
  registrationStatusCheckError?: IError;
  userRegistrationStatus?: UserRegistrationStatus;
  promoImage: IEventImageRecord;
  logoImage: IEventImageRecord;
  engagementRail: EventEngagementRailChoices;
  speakers: EventUser[];
  attendeeId?: string;
  presenterKey?: string; // Signed presenter ID.
  vodHint?: boolean;
}

const initialState: IEventState = {
  eventObject: undefined,
  eventID: undefined,
  eventError: undefined,
  speakersError: undefined,
  isRegistered: false,
  registrationDetails: undefined,
  registrationStatus: undefined,
  registrationError: undefined,
  promoImage: { image: placeholderPromoImage, id: "NaN" },
  logoImage: { image: placeholderLogoImage, id: "NaN" },
  engagementRail: EventEngagementRailChoices.None,
  speakers: [],
  attendeeId: undefined,
  presenterKey: undefined,
  vodHint: undefined,
};

const initialStateRegistrationDetails: IEventRegister = {
  eventDetails: {
    title: "",
    access: {
      accessType: AccessType.PUBLIC,
      registrationPermission: "",
    },
    theme: {
      logoImage: "",
      promoImage: "",
      primaryColor: "",
    },
    type: EventType.UNKNOWN,
  },
  registrationProperties: {
    registrationOpenTime: {
      startTime: "",
      endTime: "",
      timeZoneOffset: 0,
    },
    registrationLimit: undefined,
    isFull: undefined,
    isWaitlistEnabled: undefined,
    waitlistLimit: undefined,
    isWaitlistFull: undefined,
    isManualApprovalEnabled: undefined,
    termsOfUseUrl: "",
  },
  questions: [],
};

export interface IEventRegisterPayload {
  eventId: EventId;
  regInfo: IEventRegisterAnswers;
}

export interface IEventCancelRegPayload {
  eventId: EventId;
  regId: Guid;
}

export interface IEventPageViewCountPayload {
  eventId: EventId;
  pageType: IPageType;
}

export interface IEventUserRegistrationStatus {
  eventId: EventId;
  userObjectId: Guid;
  userUPN: string;
  userTenantId?: Guid;
}

export interface IEventRegistrationStatusFromRegistrationId {
  eventId: EventId;
  registrationId: Guid;
}

export interface IEventOverviewResponse {
  event: IEvent;
  sessions: IEventSession[];
  registrationInfo?: IEventRegister;
  sponsors: IEventSponsor[];
  sponsorOrder?: IEventSponsorOrder;
  roles: EventUserRole[];
}

interface IEventResponse {
  event: IEvent;
  eventId: EventId;
}

const eventOverviewFetchAction: string = "event/fetch/overview";
export const getEventOverviewAsyncAction = createAsyncThunk<
  IEventOverviewResponse,
  EventId,
  { rejectValue: IError }
>(
  eventOverviewFetchAction,
  async (eventId: EventId, { rejectWithValue, getState }) => {
    const logger = Logger.getInstance();
    const scenarioData = {
      eventId: eventId,
      eventTenantId: getEventTenantId(eventId) || "",
      isEventOverviewApiEnabled: true,
    };
    const scenario = logger.createScenario(Scenario.EventRequest, {
      data: scenarioData,
    });

    try {
      const eventOverview: IEventOverview = await fetchEventOverview(
        eventId,
        scenario || undefined
      );
      scenario?.mark(
        SubScenario.EventDetailsRetrieved,
        ScenarioStepStatus.Success,
        {
          data: { EventType: eventOverview.event.type },
        }
      );
      const eventResponse: IEventOverviewResponse = {
        event: eventOverview.event,
        sessions: eventOverview.sessions,
        registrationInfo: eventOverview.registrationInfo,
        sponsors: eventOverview.sponsors,
        sponsorOrder: eventOverview.sponsorOrder,
        roles: eventOverview.roles,
      };
      scenario?.stop();
      return eventResponse;
    } catch (err) {
      logger.logTrace(
        LoggerLevels.error,
        `Could not get event overview with id ${eventId}.`
      );
      const response: IError = getErrorResponse(eventOverviewFetchAction, err);

      const scenarioEventData = {
        message: getErrorResponseLoggerScenarioMessage(response),
      };

      if (
        isErrorResponseUnpublishedError(response) ||
        isErrorResponseNotFoundError(response) ||
        isErrorResponseAuthError(response) ||
        isErrorResponseSkypeTokenBadTenantId(response) ||
        (enableRedirectAcquireTokenSelector(getState()) &&
          isErrorResponseAcquireTokenRedirectRequiredError(response)) ||
        isErrorResponseCanceledEvent(response) ||
        isErrorHandledError(response)
      ) {
        scenario?.stop(scenarioEventData);
      } else {
        scenario?.fail(scenarioEventData);
      }

      return rejectWithValue(response);
    }
  },
  {
    // check to see if we already have the event
    condition: (eventId: EventId, { getState, extra }) => {
      const { event } = getState() as RootState;
      if (event.eventObject && event.eventID === eventId) {
        return false;
      }
    },
  }
);

const eventFetchAction: string = "event/fetch";
export const getEventAsyncAction = createAsyncThunk<
  IEventResponse,
  EventId,
  { rejectValue: IError }
>(
  eventFetchAction,
  async (eventId: EventId, { rejectWithValue, getState }) => {
    const logger = Logger.getInstance();
    const scenarioData = {
      eventId: eventId,
      eventTenantId: getEventTenantId(eventId) || "",
      isEventOverviewApiEnabled: false,
    };
    const scenario = logger.createScenario(Scenario.EventRequest, {
      data: scenarioData,
    });

    try {
      const event: IEvent = await fetchEvent(eventId, scenario || undefined);
      scenario?.mark(
        SubScenario.EventDetailsRetrieved,
        ScenarioStepStatus.Success,
        {
          data: { EventType: event.type },
        }
      );
      const eventResponse: IEventResponse = { event, eventId };
      scenario?.stop();
      return eventResponse;
    } catch (err) {
      logger.logTrace(
        LoggerLevels.error,
        `Could not get event with id ${eventId}`
      );
      const response: IError = getErrorResponse(eventFetchAction, err);

      const scenarioEventData = {
        message: getErrorResponseLoggerScenarioMessage(response),
      };

      if (
        isErrorResponseUnpublishedError(response) ||
        isErrorResponseNotFoundError(response) ||
        isErrorResponseAuthError(response) ||
        isErrorResponseSkypeTokenBadTenantId(response) ||
        (enableRedirectAcquireTokenSelector(getState()) &&
          isErrorResponseAcquireTokenRedirectRequiredError(response)) ||
        isErrorResponseCanceledEvent(response) ||
        isErrorHandledError(response)
      ) {
        scenario?.stop(scenarioEventData);
      } else {
        scenario?.fail(scenarioEventData);
      }

      return rejectWithValue(response);
    }
  },
  {
    // check to see if we already have the event
    condition: (eventId: EventId, { getState, extra }) => {
      const { event } = getState() as RootState;
      if (event.eventObject && event.eventID === eventId) {
        return false;
      }
    },
  }
);

const eventFetchSpeakersAction: string = "event/fetch/speakers";
export const getEventSpeakersAsyncAction = createAsyncThunk<
  EventUser[],
  EventId,
  { rejectValue: IError }
>(eventFetchSpeakersAction, async (eventId: EventId, { rejectWithValue }) => {
  try {
    const speakers: EventUser[] = await fetchEventSpeakers(eventId);
    return speakers.filter((x) => !x.isHidden);
  } catch (error) {
    const err: IError = getErrorResponse(eventFetchSpeakersAction, error);
    return rejectWithValue(err);
  }
});

const getPromoImageAction: string = "event/promoImage";
export const getPromoImageAsyncAction = createAsyncThunk(
  getPromoImageAction,
  async (imageID: string) => {
    if (imageID) {
      const image: string = await fetchAMSImage(imageID);
      const imageRecord: IEventImageRecord = {
        image: image,
        id: imageID,
      };
      return imageRecord;
    } else {
      const imageRecord: IEventImageRecord = {
        image: placeholderPromoImage,
        id: "NaN",
      };
      return imageRecord;
    }
  }
);

const getLogoImageAction: string = "event/logoImage";
export const getLogoImageAsyncAction = createAsyncThunk(
  getLogoImageAction,
  async (imageID: string) => {
    if (imageID) {
      const image: string = await fetchAMSImage(imageID);
      const imageRecord: IEventImageRecord = {
        image: image,
        id: imageID,
      };
      return imageRecord;
    } else {
      const imageRecord: IEventImageRecord = {
        image: placeholderLogoImage,
        id: "NaN",
      };
      return imageRecord;
    }
  }
);

const eventRegisterAction: string = "event/register/post";
export const eventRegisterAsyncAction = createAsyncThunk<
  IEventRegisterResponse,
  IEventRegisterPayload,
  { rejectValue: IError }
>(
  eventRegisterAction,
  async (payload: IEventRegisterPayload, { rejectWithValue, getState }) => {
    const { eventId, regInfo } = payload;
    const logger = Logger.getInstance();
    const userScenarioData: IEventRegisterAnswers = {
      firstName: "anonymous",
      lastName: "anonymous",
      email: "anonymous",
      language: regInfo.language,
      timeZoneDetails: regInfo.timeZoneDetails,
      hasAcceptedTermsOfUse: regInfo.hasAcceptedTermsOfUse,
      answers: regInfo.answers,
    };
    const scenarioData = {
      eventId: eventId,
      eventTenantId: getEventTenantId(eventId) || "",
      registrationPayLoad: JSON.stringify(userScenarioData),
    };
    const scenario = logger.createScenario(Scenario.EventRegistrationRequest, {
      data: scenarioData,
    });

    try {
      const eventRegisterResponse = await eventRegister(
        eventId,
        regInfo,
        scenario || undefined
      );
      scenario?.stop();
      return eventRegisterResponse;
    } catch (err) {
      const response: IError = getErrorResponse(eventRegisterAction, err);

      const scenarioEventData = {
        message: getErrorResponseLoggerScenarioMessage(response),
      };

      if (
        response.responseErrorCode ===
          CmdServicesResponseErrorCode.REGISTRATION_FULL ||
        response.responseErrorCode ===
          CmdServicesResponseErrorCode.WAITLIST_FULL ||
        response.responseErrorCode ===
          CmdServicesResponseErrorCode.PRESENTER_CANNOT_REGISTER ||
        isErrorResponseAuthError(response) ||
        isErrorResponseSkypeTokenBadTenantId(response) ||
        (enableRedirectAcquireTokenSelector(getState()) &&
          isErrorResponseAcquireTokenRedirectRequiredError(response)) ||
        isErrorHandledError(response)
      ) {
        scenario?.stop(scenarioEventData);
      } else {
        scenario?.fail(scenarioEventData);
      }

      logger.logTrace(
        LoggerLevels.error,
        response && response.responseErrorMessage
          ? /* istanbul ignore next */ response.responseErrorMessage
          : "Error in registering for the event"
      );

      return rejectWithValue(response);
    }
  }
);

const eventPageViewCount: string = "event/metrics/pageviewcount";
export const eventPageViewCountAsyncAction = createAsyncThunk<
  IEventPageViewCountResponse,
  IEventPageViewCountPayload,
  { rejectValue: IError }
>(
  eventPageViewCount,
  async (
    payload: IEventPageViewCountPayload,
    { rejectWithValue, getState }
  ) => {
    const { eventId, pageType } = payload;
    const logger = Logger.getInstance();
    const scenarioData = {
      eventId: eventId,
      eventTenantId: getEventTenantId(eventId) || "",
      type: JSON.stringify(pageType),
    };
    const scenario = logger.createScenario(Scenario.PageViewCountRequest, {
      data: scenarioData,
    });

    try {
      const pageViewCountResponse = await pageViewCount(
        eventId,
        pageType,
        scenario || undefined
      );
      scenario?.stop();
      return pageViewCountResponse;
    } catch (err) {
      const response: IError = getErrorResponse(eventRegisterAction, err);
      const scenarioEventData = {
        message: getErrorResponseLoggerScenarioMessage(response),
      };

      if (
        isErrorResponseAuthError(response) ||
        isErrorResponseSkypeTokenBadTenantId(response) ||
        (enableRedirectAcquireTokenSelector(getState()) &&
          isErrorResponseAcquireTokenRedirectRequiredError(response)) ||
        isErrorHandledError(response) ||
        isErrorPreconditionFailedError(response)
      ) {
        scenario?.stop(scenarioEventData);
      } else {
        scenario?.fail(scenarioEventData);
      }

      logger.logTrace(
        LoggerLevels.error,
        response && response.responseErrorMessage
          ? /* istanbul ignore next */ response.responseErrorMessage
          : "Error when increasing the page count for an event"
      );

      const unknownPageViewCountResponse: IEventPageViewCountResponse = {
        status: PageViewCountStatus.UNKNOWN,
      };

      if (isErrorPreconditionFailedError(response)) {
        return unknownPageViewCountResponse;
      }

      return rejectWithValue(response);
    }
  }
);

const eventCancelAction: string = "event/register/delete";
export const cancelEventRegisterAsyncAction = createAsyncThunk<
  IEventRegisterResponse,
  IEventCancelRegPayload,
  { rejectValue: IError }
>(
  eventCancelAction,
  async (payload: IEventCancelRegPayload, { rejectWithValue, getState }) => {
    const { eventId, regId } = payload;
    const logger = Logger.getInstance();
    const scenarioData = {
      eventId: eventId,
      eventTenantId: getEventTenantId(eventId) || "",
      registrationId: regId,
    };
    const scenario = logger.createScenario(Scenario.CancelRegistrationRequest, {
      data: scenarioData,
    });

    try {
      const cancelRegistrationResponse = await cancelEventRegister(
        eventId,
        regId,
        scenario || undefined
      );
      scenario?.stop();
      return cancelRegistrationResponse;
    } catch (err) {
      const response: IError = getErrorResponse(eventRegisterAction, err);

      const scenarioEventData = {
        message: getErrorResponseLoggerScenarioMessage(response),
      };

      if (
        isErrorResponseAuthError(response) ||
        isErrorResponseSkypeTokenBadTenantId(response) ||
        (enableRedirectAcquireTokenSelector(getState()) &&
          isErrorResponseAcquireTokenRedirectRequiredError(response)) ||
        isErrorHandledError(response)
      ) {
        scenario?.stop(scenarioEventData);
      } else {
        scenario?.fail(scenarioEventData);
      }

      logger.logTrace(
        LoggerLevels.error,
        response && response.responseErrorMessage
          ? /* istanbul ignore next */ response.responseErrorMessage
          : "Error when cancelling the registration for an event"
      );

      return rejectWithValue(response);
    }
  }
);

const getEventRegistrationDetailsAction: string = "event/register/get";
export const getRegistrationDetailsAsyncAction = createAsyncThunk<
  IEventRegister,
  EventId,
  { rejectValue: IError }
>(
  getEventRegistrationDetailsAction,
  async (eventId: EventId, { rejectWithValue, getState }) => {
    const logger = Logger.getInstance();
    const scenarioData = {
      eventId: eventId,
      eventTenantId: getEventTenantId(eventId) || "",
    };
    const scenario = logger.createScenario(
      Scenario.RegistrationDetailsRequest,
      { data: scenarioData }
    );
    try {
      const registration: IEventRegister = await getEventRegistration(
        eventId,
        scenario || undefined
      );
      scenario?.stop();
      return registration;
    } catch (err) {
      logger?.logTrace(LoggerLevels.error, err);
      const response: IError = getErrorResponse(
        getEventRegistrationDetailsAction,
        err
      );

      const scenarioEventData = {
        message: getErrorResponseLoggerScenarioMessage(response),
      };

      if (
        response.responseErrorCode ===
          CmdServicesResponseErrorCode.EXPIRED_EVENT ||
        isErrorResponseNotFoundError(response) ||
        isErrorResponseAuthError(response) ||
        isErrorResponseSkypeTokenBadTenantId(response) ||
        (enableRedirectAcquireTokenSelector(getState()) &&
          isErrorResponseAcquireTokenRedirectRequiredError(response)) ||
        isErrorResponseCanceledEvent(response) ||
        isErrorHandledError(response)
      ) {
        scenario?.stop(scenarioEventData);
      } else {
        scenario?.fail(scenarioEventData);
      }

      return rejectWithValue(response);
    }
  }
);

const getRegistrationStatusFromSignedInUserIDAction: string =
  "event/user/register/status";
export const getRegistrationStatusFromUserIdAsyncAction = createAsyncThunk<
  IEventRegisterResponse,
  IEventUserRegistrationStatus,
  { rejectValue: IError }
>(
  getRegistrationStatusFromSignedInUserIDAction,
  async (
    payload: IEventUserRegistrationStatus,
    { rejectWithValue, getState }
  ) => {
    const { eventId, userObjectId, userUPN, userTenantId } = payload;
    const logger = Logger.getInstance();
    const scenarioData = {
      eventId: eventId,
      eventTenantId: getEventTenantId(eventId) || "",
      userObjectId: userObjectId,
      userUPN: userUPN,
      userTenantId: userTenantId || "",
    };
    const scenario = logger.createScenario(
      Scenario.SignedInUserRegistrationStatus,
      { data: scenarioData }
    );
    const eventRegisterResponse: IEventRegisterResponse = {
      registrationId: "",
      status: UserRegistrationStatus.UNREGISTERED,
    };
    try {
      const eventRegisterResponse = await getRegistrationStatusFromUserID(
        eventId,
        userObjectId,
        userUPN,
        userTenantId,
        scenario || undefined
      );
      scenario?.stop();
      return eventRegisterResponse;
    } catch (err) {
      const response: IError = getErrorResponse(
        getRegistrationStatusFromSignedInUserIDAction,
        err
      );
      const scenarioEventData = {
        message: getErrorResponseLoggerScenarioMessage(response),
      };

      if (
        isErrorResponseSkypeTokenBadTenantId(response) ||
        (enableRedirectAcquireTokenSelector(getState()) &&
          isErrorResponseAcquireTokenRedirectRequiredError(response)) ||
        isErrorHandledError(response)
      ) {
        scenario?.stop(scenarioEventData);
      } else if (isErrorResponseNotFoundError(response)) {
        scenario?.stop(scenarioEventData);
        return eventRegisterResponse;
      } else if (isErrorResponseAuthError(response)) {
        scenario?.stop(scenarioEventData);
      } else {
        scenario?.fail(scenarioEventData);
      }

      logger.logTrace(
        LoggerLevels.error,
        "Could not get registration status from userID"
      );

      return rejectWithValue(response);
    }
  }
);

const getRegistrationStatusFromRegistrationIdAction: string =
  "event/registration/status";
export const getRegistrationStatusFromRegistrationIdAsyncAction =
  createAsyncThunk<
    IEventRegisterResponse,
    IEventRegistrationStatusFromRegistrationId,
    { rejectValue: IError }
  >(
    getRegistrationStatusFromRegistrationIdAction,
    async (
      payload: IEventRegistrationStatusFromRegistrationId,
      { rejectWithValue, getState }
    ) => {
      const { eventId, registrationId } = payload;
      const logger = Logger.getInstance();
      const scenarioData = {
        eventId: eventId,
        eventTenantId: getEventTenantId(eventId) || "",
        registrationId: registrationId,
      };
      const scenario = logger.createScenario(
        Scenario.RegistrationStatusFromRegistrationID,
        { data: scenarioData }
      );
      try {
        const eventRegisterResponse =
          await getRegistrationStatusFromRegistrationID(
            eventId,
            registrationId,
            scenario || undefined
          );
        scenario?.stop();
        return eventRegisterResponse;
      } catch (err) {
        const response: IError = getErrorResponse(
          getRegistrationStatusFromRegistrationIdAction,
          err
        );

        const scenarioEventData = {
          message: getErrorResponseLoggerScenarioMessage(response),
        };
        if (
          isErrorResponseNotFoundError(response) ||
          isErrorResponseAuthError(response) ||
          isErrorResponseSkypeTokenBadTenantId(response) ||
          (enableRedirectAcquireTokenSelector(getState()) &&
            isErrorResponseAcquireTokenRedirectRequiredError(response)) ||
          isErrorResponseCanceledEvent(response) ||
          isErrorHandledError(response)
        ) {
          scenario?.stop(scenarioEventData);
        } else {
          scenario?.fail(scenarioEventData);
        }

        logger.logTrace(
          LoggerLevels.error,
          "Could not get registration status from registration ID"
        );

        return rejectWithValue(response);
      }
    }
  );

export const setEventEngagementRailAction =
  createAction<EventEngagementRailChoices>("event/engagementRail");

const eventAddToCalendarAction: string = "event/addToCalendar";
export const eventAddToCalendarAsyncAction = createAsyncThunk(
  eventAddToCalendarAction,
  async (payload: CalendarType, { getState }) => {
    const event = eventSelector(getState() as RootState);
    /* istanbul ignore if */
    if (!event) {
      return;
    }
    const calendarEvent = eventToCalendarEvent(event);
    addToCalendar(calendarEvent, payload);
  }
);

export const setAttendeeIdAction = createAction<string>("event/attendeeId");

export const setPresenterKeyAction = createAction<string>("event/presenterKey");

export const setVodHintAction = createAction<boolean>("event/vodHint");

const statusCheck: (status: UserRegistrationStatus) => boolean = (status) =>
  status &&
  (status === UserRegistrationStatus.REGISTERED ||
    status === UserRegistrationStatus.WAITLIST ||
    status === UserRegistrationStatus.AWAITING_APPROVAL ||
    status === UserRegistrationStatus.PROCESSING_APPROVAL);

const isUserRegistered: (
  registrationResponse?: IEventRegisterResponse,
  userRegistrationStatus?: UserRegistrationStatus
) => boolean = (registrationResponse, userRegistrationStatus) => {
  let isRegistered = !!(
    userRegistrationStatus && statusCheck(userRegistrationStatus)
  );

  if (!isRegistered) {
    //If it is public registration or user previously registered with same email address in unauth state then Unknown is the response
    isRegistered =
      registrationResponse?.status === UserRegistrationStatus.UNKNOWN;
  }

  if (!isRegistered && registrationResponse && registrationResponse.status) {
    isRegistered = !!statusCheck(registrationResponse.status);
  }
  return isRegistered;
};

export const eventSlice = createSlice({
  name: "event",
  initialState: initialState,
  reducers: {
    resetEvent: (state) => {
      if (!process.env.noop_api) {
        const refreshEventState: IEventState = {
          eventObject: undefined,
          eventID: undefined,
          eventError: undefined,
          speakersError: undefined,
          isRegistered: false,
          registrationDetails: state.registrationDetails,
          registrationError: undefined,
          registrationStatus: state.registrationStatus,
          promoImage: { image: placeholderPromoImage, id: "NaN" },
          logoImage: { image: placeholderLogoImage, id: "NaN" },
          engagementRail: state.engagementRail,
          speakers: [],
        };
        Object.assign(state, refreshEventState);
      }
    },
    clearEventError: (state) => {
      state.eventError = undefined;
    },
    clearSpeakersError: (state) => {
      state.speakersError = undefined;
    },
    clearRegistrationError: (state) => {
      state.registrationError = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getEventOverviewAsyncAction.fulfilled,
        (
          state: IEventState,
          { payload }: PayloadAction<IEventOverviewResponse>
        ) => {
          if (process.env.noop_api) {
            return;
          }

          state.eventID = payload.event.id;
          state.eventObject = payload.event;
          state.eventObject.roles = payload.roles;
          state.eventError = undefined;
          state.speakers = [
            ...new Map(
              payload.sessions.flatMap((s) => s.speakers).map((s) => [s.id, s])
            ).values(),
          ].filter((s) => !s.isHidden);
          state.speakersError = undefined;
          if (!payload.registrationInfo) {
            state.registrationDetails = initialStateRegistrationDetails;
          } else {
            state.registrationDetails = payload.registrationInfo;
            state.registrationDetails.eventDetails = state.eventObject;
          }
          state.registrationError = undefined;
        }
      )
      .addCase(getEventOverviewAsyncAction.rejected, (state, action) => {
        state.eventError = action.payload;
      })
      .addCase(
        getEventAsyncAction.fulfilled,
        (state: IEventState, { payload }: PayloadAction<IEventResponse>) => {
          if (!process.env.noop_api) {
            state.eventObject = payload.event;
            state.eventID = payload.eventId;
            state.eventError = undefined;
          }
        }
      )
      .addCase(getEventAsyncAction.rejected, (state, action) => {
        state.eventError = action.payload;
      })
      .addCase(
        getEventSpeakersAsyncAction.fulfilled,
        (state: IEventState, { payload }: PayloadAction<EventUser[]>) => {
          /* istanbul ignore else */
          if (!process.env.noop_api) {
            state.speakers = payload;
            state.speakersError = undefined;
          }
        }
      )
      .addCase(getEventSpeakersAsyncAction.rejected, (state, action) => {
        state.speakersError = action.payload;
        state.speakers = [];
      })
      .addCase(
        getPromoImageAsyncAction.fulfilled,
        (state: IEventState, { payload }: PayloadAction<IEventImageRecord>) => {
          if (!process.env.noop_api && payload) {
            state.promoImage = payload;
          }
        }
      )
      .addCase(
        getLogoImageAsyncAction.fulfilled,
        (state: IEventState, { payload }: PayloadAction<IEventImageRecord>) => {
          if (!process.env.noop_api && payload) {
            state.logoImage = payload;
          }
        }
      )
      .addCase(
        getRegistrationDetailsAsyncAction.fulfilled,
        (state: IEventState, { payload }: PayloadAction<IEventRegister>) => {
          if (!process.env.noop_api) {
            state.registrationDetails = payload;
            state.registrationError = undefined;
          }
        }
      )
      .addCase(getRegistrationDetailsAsyncAction.rejected, (state, action) => {
        state.registrationError = action.payload;
        state.registrationDetails = initialStateRegistrationDetails;
      })
      .addCase(
        cancelEventRegisterAsyncAction.fulfilled,
        (
          state: IEventState,
          { payload }: PayloadAction<IEventRegisterResponse>
        ) => {
          state.registrationStatus = payload;
        }
      )
      .addCase(cancelEventRegisterAsyncAction.rejected, (state, action) => {
        state.registrationError = action.payload;
      })
      .addCase(
        eventRegisterAsyncAction.fulfilled,
        (
          state: IEventState,
          { payload }: PayloadAction<IEventRegisterResponse>
        ) => {
          state.registrationError = undefined;
          state.registrationStatus = payload;
        }
      )
      .addCase(eventRegisterAsyncAction.rejected, (state, action) => {
        state.registrationError = action.payload;
      })
      .addCase(
        getRegistrationStatusFromRegistrationIdAsyncAction.fulfilled,
        (
          state: IEventState,
          { payload }: PayloadAction<IEventRegisterResponse>
        ) => {
          state.registrationStatus = payload;
        }
      )
      .addCase(
        getRegistrationStatusFromRegistrationIdAsyncAction.rejected,
        (state, action) => {
          state.registrationStatusCheckError = action.payload;
        }
      )
      .addCase(
        getRegistrationStatusFromUserIdAsyncAction.fulfilled,
        (
          state: IEventState,
          { payload }: PayloadAction<IEventRegisterResponse>
        ) => {
          state.registrationStatus = payload;
        }
      )
      .addCase(
        getRegistrationStatusFromUserIdAsyncAction.rejected,
        (state, action) => {
          state.registrationStatusCheckError = action.payload;
        }
      )
      .addCase(
        setEventEngagementRailAction,
        (
          state: IEventState,
          { payload }: PayloadAction<EventEngagementRailChoices>
        ) => {
          /* istanbul ignore else */
          if (!!payload) {
            state.engagementRail = payload
              ? payload
              : EventEngagementRailChoices.None;
          }
        }
      )
      .addCase(
        setAttendeeIdAction,
        (state: IEventState, { payload }: PayloadAction<string>) => {
          state.attendeeId = payload;
        }
      )
      .addCase(
        setPresenterKeyAction,
        (state: IEventState, { payload }: PayloadAction<string>) => {
          state.presenterKey = payload;
        }
      )
      .addCase(
        setVodHintAction,
        (state: IEventState, { payload }: PayloadAction<boolean>) => {
          state.vodHint = payload;
        }
      );
  },
});

export const { resetEvent } = eventSlice.actions;

// selectors
export const eventSelector = (state: RootState): IEvent | undefined =>
  state.event?.eventObject;
export const eventSpeakersSelector = (state: RootState): EventUser[] => {
  if (state.event?.speakers) {
    return state.event?.speakers;
  } else {
    return [];
  }
};
export const eventIDSelector = (state: RootState): EventId | undefined =>
  state.event?.eventID;
export const eventErrorSelector = (state: RootState): IError | undefined =>
  state.event?.eventError;
export const eventSpeakersErrorSelector = (
  state: RootState
): IError | /* istanbul ignore next */ undefined => state.event?.speakersError;
export const registeredSelector = (
  state: RootState
): IEventRegisterResponse | undefined => state.event?.registrationStatus;
export const registrationErrorSelector = (
  state: RootState
): IError | undefined => state.event?.registrationError;
export const registrationStatusCheckError = (
  state: RootState
): IError | /* istanbul ignore next */ undefined =>
  state.event.registrationStatusCheckError;
export const userIsRegistered = (state: RootState): boolean => {
  const registrationResponse = state.event?.registrationStatus;
  const userRegistrationStatusFromID = state.event?.userRegistrationStatus;
  const isRegistered: boolean = isUserRegistered(
    registrationResponse,
    userRegistrationStatusFromID
  );
  return isRegistered;
};
export const isUserRegistrationStatusKnown = (state: RootState): boolean => {
  const registrationResponse = state.event?.registrationStatus;
  return registrationResponse?.status !== UserRegistrationStatus.UNKNOWN;
};
export const isUserAwaitingApprovalSelector = (state: RootState): boolean => {
  const registrationResponse = state.event?.registrationStatus;
  return (
    registrationResponse?.status === UserRegistrationStatus.AWAITING_APPROVAL ||
    registrationResponse?.status === UserRegistrationStatus.PROCESSING_APPROVAL
  );
};
export const isUserRejectedSelector = (state: RootState): boolean => {
  const registrationResponse = state.event?.registrationStatus;
  return registrationResponse?.status === UserRegistrationStatus.REJECTED;
};
export const hasUserCancelledRegistrationSelector = (
  state: RootState
): boolean => {
  const registrationResponse = state.event?.registrationStatus;
  return registrationResponse?.status === UserRegistrationStatus.CANCELED;
};
export const registrationDetailSelector = (
  state: RootState
): IEventRegister | undefined => state.event?.registrationDetails;
export const promoImageSelector = (state: RootState): IEventImageRecord =>
  state.event?.promoImage;
export const logoImageSelector = (state: RootState): IEventImageRecord =>
  state.event?.logoImage;
export const engagementRailSelector = (
  state: RootState
): EventEngagementRailChoices =>
  state.event?.engagementRail || EventEngagementRailChoices.None;
export const displayEngagementRailSelector = (state: RootState): boolean => {
  if (
    !userIsRegistered(state) ||
    !authenticatedSelector(state) ||
    state.event?.eventObject?.type === EventType.WEBINAR
  ) {
    return false;
  }
  const engagementRail =
    state.event?.engagementRail || EventEngagementRailChoices.None;
  return engagementRail !== EventEngagementRailChoices.None;
};
export const eventHasEndedSelector = (state: RootState): boolean => {
  return (
    getRegistrationButtonState(
      state.event?.eventObject,
      state.event?.registrationDetails
    ) === RegistrationState.EVENT_ENDED
  );
};
export const registrationHasEndedSelector = (state: RootState): boolean => {
  return (
    getRegistrationButtonState(
      state.event?.eventObject,
      state.event?.registrationDetails
    ) === RegistrationState.REGISTRATION_ENDED
  );
};
export const registrationHasNotStartedSelector = (
  state: RootState
): boolean => {
  return (
    getRegistrationButtonState(
      state.event?.eventObject,
      state.event?.registrationDetails
    ) === RegistrationState.REGISTRATION_NOT_STARTED
  );
};
export const isOrganizerSelector = (state: RootState): boolean => {
  const currentEvent = eventSelector(state);
  if (currentEvent) {
    return (
      currentEvent.roles.includes(EventUserRole.ORGANIZER) ||
      currentEvent.roles.includes(EventUserRole.COORGANIZER)
    );
  }
  return false;
};
export const isInternalPresenterSelector = (state: RootState): boolean => {
  const currentEvent = eventSelector(state);
  const userTenantID = userTenantIDSelector(state);
  if (currentEvent && userTenantID) {
    return (
      currentEvent.roles.includes(EventUserRole.PRESENTER) &&
      currentEvent.organization?.tenantId === userTenantID
    );
  }
  return false;
};
export const attendeeIdSelector = (state: RootState): string | undefined =>
  state.event?.attendeeId;

export const presenterKeySelector = (state: RootState): string | undefined =>
  state.event?.presenterKey;

export const vodHintSelector = (state: RootState): boolean =>
  state.event?.vodHint;

// reducer
export default eventSlice.reducer;

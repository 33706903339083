import { AccountInfo } from "@azure/msal-common";
import { IScenarioLogger } from "../../common/logger/interface";

export interface IAuthenticationService extends IWithAuthCallbacks {
  // Gets access token for specified scope on behalf of current signed in user.
  getAccessTokenForUser: (
    scope: Scope,
    scenarioLogger?: IScenarioLogger
  ) => Promise<IAccessToken>;
  // Fetches skypeToken. If user is not logged in or is logged into their social account, fetches a visitor skype token. Otherwise, fetches
  // an authenticated skypetoken.
  getSkypeToken: (eventTenantId: string) => Promise<ISkypeToken>;
  getSkypeTokenForAms: (eventTenantId: string) => Promise<ISkypeToken>;
  getSkypeTokenForBrb: () => Promise<ISkypeToken>;
  acquireToken: (account: AccountInfo, scope: string) => Promise<IAccessToken>;
  getBadgerToken: () => Promise<IBadgerFetchResponse>;
  // Gets account information for current signed in user. Null if user is not signed in.
  getAccount: () => AccountInfo | null;
  // Logs user in based on the LoginState which would be work or social
  login: (
    loginPref: LoginState,
    redirect?: boolean,
    redirectStartPage?: string
  ) => Promise<void>;
  // Logs user out. User can only be logged into one account at a time.
  logout: (postLogoutRedirectUri?: string) => Promise<void>;
  // Whether current user is signed in or not.
  userIsAuthenticated: () => boolean;
  // Gets the oid and tid from the token of a signed in user
  getUserInfoFromToken: () => IUserInfoFromToken | undefined;
  // Delete user in based on the LoginState which would be work or social
  delete: (loginPref: LoginState) => Promise<void>;
}

interface IWithAuthCallbacks {
  // Registers a callback that will be called once the user's authentication state (i.e. whether they are logged in or not)
  // changes. E.g. if the callback is called with newState = true, user just succesfully logged in.
  // Note that it is possible for AuthenticationService to have found a logged-in account on construction, before callbacks are registered.
  // AuthenticationService will call the callback at the time it is registered if this is the case.
  registerAuthStateChangedCallback: (
    callback: AuthStateChangedCallback
  ) => void;

  registerAuthServiceIntializedCallback: (
    callback: AuthServiceInitializedCallback
  ) => void;

  registerRedirectLoginErrorCallback: (
    callback: RedirectLoginErrorCallback
  ) => void;
}

export interface IUserInfoFromToken {
  oid: string;
  tid: string;
}

export interface ISkypeTokenFetchResponse {
  tokens: {
    skypeToken: string;
    expiresIn: number;
  };
  tenantId: string;
  region: string;
  regionGtms: {
    amsV2: string | undefined;
    virtualEventsService: string | undefined;
  };
}

export interface IBadgerFetchResponse {
  authScheme: string;
  token: string;
  expiryTimeUtc: string;
}

export type AuthStateChangedCallback = (loginState: LoginState) => void;

export type AuthServiceInitializedCallback = (newState: boolean) => void;

export type RedirectLoginErrorCallback = (error: unknown) => void;

export interface IAccessToken {
  accessToken: string;
}

export interface ISkypeToken extends IAccessToken {
  region: string;
  regionGtms: {
    amsV2: string | undefined;
    virtualEventsService: string | undefined;
  };
}

export enum LoginState {
  NotLoggedIn = "UnAuth",
  Social = "Social",
  Work = "Work",
}

export enum Scope {
  CMD_SERVICES = "cmd_services",
  SKYPE_SPACES = "skype_spaces",
}

export enum AuthzEndpointEnv {
  Int = "Int",
  Prod = "Prod",
  GCC = "GCC",
  GCCH = "GCCH",
  DOD = "DOD",
}

export interface AuthzEndpoints {
  VISITOR_SKYPE_TOKEN: string;
  AUTH_SKYPE_TOKEN: string;
}

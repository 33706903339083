import React from "react";
import {
  mergeClasses,
  SkeletonV9 as Skeleton,
  SkeletonItem,
} from "../../shared";
import { flexAlignStyles, flexStyles } from "../../styles/FlexStyles";
import { heroImageStyles } from "../../styles/HeroImage";
import { skeletonItemStyles } from "../../styles/SkeletonStyles";

export const HeroImageLoading: React.FunctionComponent = () => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const skeletonItemClasses = skeletonItemStyles();
  const heroImageClasses = heroImageStyles();
  return (
    <Skeleton data-testid="hero-image-loading-component" animation="pulse">
      <div
        className={mergeClasses(
          "heroImageContainerLoading",
          flexClasses.root,
          flexClasses.column,
          flexAlignClasses.alignItemCenter,
          flexAlignClasses.justifyContentEnd,
          heroImageClasses.flex
        )}
      >
        <SkeletonItem
          className={mergeClasses(
            "heroImageBgLoading",
            skeletonItemClasses.root,
            heroImageClasses.bg
          )}
        />
        <div
          className={mergeClasses(
            "heroImageBgBlurLoading",
            heroImageClasses.bgBlur
          )}
        />
      </div>
    </Skeleton>
  );
};

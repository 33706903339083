import { LoggerLevels } from "../../common/logger/interface";
import { Logger } from "../../common/logger/Logger";
import { getWindow } from "@microsoft/applicationinsights-core-js";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { EventId, Guid } from "../../core/slices/CMDTypes";
import { validate as isValidUUID } from "uuid";
import { PortalLocationState } from "../../core/history/history.interface";

export const isDevelopment = (): boolean =>
  process.env.NODE_ENV === "development";

//Validates correct format of email address
export const isValidEmail = (email?: string): boolean => {
  const regexp = new RegExp(
    /^(mailto:)?(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  let isValidEmail = false;
  if (email && email?.trim().length > 0) {
    isValidEmail = regexp.test(email);
  }
  return isValidEmail;
};

export const isValidUrl = (url?: string): boolean => {
  const urlRegExp = new RegExp(
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
  );
  let isValidUrl = false;
  if (url && url.trim().length > 0) {
    isValidUrl = urlRegExp.test(url);
  }
  return isValidUrl;
};

export const isValidUserURL = (url?: string): boolean => {
  if (!url) {
    return false;
  }
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.protocol === "http:" || parsedUrl.protocol === "https:";
  } catch (e) {
    return false;
  }
};

export const getValidUserURL = (url?: string): string | undefined => {
  return isValidUserURL(url) ? url : undefined;
};

// converting duration ms to Minutes & seconds
export const millisToMinutesAndSeconds = (millis: number): string => {
  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000).toFixed(0);
  const minutesText = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const secondsText =
    (millis % 60000) / 1000 <= 9 ? `0${seconds}` : `${seconds}`;
  return `${minutesText}:${secondsText}`;
};

export const setLastKnownEvent = (id: string): void => {
  if (window) {
    window.localStorage.setItem("LKE", id);
  }
};

export const getLastKnownEvent = (): string | null => {
  if (window && window.localStorage) {
    return window.localStorage.getItem("LKE");
  }
  return "";
};

export const removeHTML = (txt: string): string => {
  return txt.replace(/(<([^>]+)>)/gi, "");
};

export const removeEmojis = (txt: string): string => {
  const emojiRegex: RegExp =
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;
  return txt.replace(emojiRegex, "");
};

export const isUrlEncoded = (url: string): boolean => {
  return decodeURI(url) !== decodeURIComponent(url);
};

export const isValidEventIdFormat = (eventId: EventId): boolean => {
  const eventIdSplit = eventId.split("@");
  if (eventIdSplit.length !== 2) {
    return false;
  }

  const eventGuid = eventIdSplit[0];
  const tenantId = eventIdSplit[1];
  return isValidUUID(eventGuid) && isValidUUID(tenantId);
};

export const getEventTenantId = (eventId: EventId): Guid | undefined => {
  const eventIdTenantIdSplit = eventId.split("@");
  if (eventIdTenantIdSplit.length === 2) {
    return eventIdTenantIdSplit[1];
  }
  const logger = Logger.getInstance();

  logger.logTrace(
    LoggerLevels.warn,
    `Expected array length to be 2 containing eventId and tenantId but instead received length ${eventIdTenantIdSplit.length} when splitting ${eventId} by delimiter @`
  );

  return undefined;
};

export const runningInJest = (): boolean => {
  return process.env.JEST_WORKER_ID !== undefined;
};

export default function ScrollToTop(): null {
  const { pathname } = useLocation<PortalLocationState>();
  useEffect(() => {
    const window = getWindow();
    window?.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

import React from "react";
import {
  breakpointMaxWidths,
  breakpointMinWidths,
  Breakpoint,
} from "../../styles/Grid";
import { useWindowSize } from "./useWindowSize";

export const useBreakpoint = (): {
  isBreakpoint: (breakPoint: Breakpoint) => boolean;
  isBreakpointAndDown: (breakPoint: Breakpoint) => boolean;
  isBreakpointAndUp: (breakPoint: Breakpoint) => boolean;
} => {
  const { width: windowWidth } = useWindowSize();

  const isBreakpoint = React.useCallback(
    (breakpoint: Breakpoint): boolean => {
      return (
        breakpointMinWidths[breakpoint] <= windowWidth &&
        windowWidth <= breakpointMaxWidths[breakpoint]
      );
    },
    [windowWidth]
  );

  const isBreakpointAndDown = React.useCallback(
    (breakpoint: Breakpoint): boolean => {
      return windowWidth <= breakpointMaxWidths[breakpoint];
    },
    [windowWidth]
  );

  const isBreakpointAndUp = React.useCallback(
    (breakpoint: Breakpoint): boolean => {
      return breakpointMinWidths[breakpoint] <= windowWidth;
    },
    [windowWidth]
  );

  return {
    isBreakpoint,
    isBreakpointAndDown,
    isBreakpointAndUp,
  };
};

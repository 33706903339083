import React from "react";
import { noop } from "lodash";
import {
  PortalFormTextInput,
  IPortalFormTextInputField,
  IPortalFormTextInputValue,
} from "./PortalFormTextInput";
import {
  PortalFormTextArea,
  IPortalFormTextAreaField,
  IPortalFormTextAreaValue,
} from "./PortalFormTextArea";
import {
  PortalFormDropdownInput,
  IPortalFormDropdownInputField,
  IPortalFormDropdownInputValue,
} from "./PortalFormDropDownInput";
import {
  PortalFormCheckboxInput,
  IPortalFormCheckboxInputField,
  IPortalFormCheckboxInputValue,
} from "./PortalFormCheckboxInput";
import { formFieldsStyles } from "../../styles/PortalFormFields";
import { mergeClasses, TextV9 as Text } from "..";

export enum PortalFormFieldType {
  Text,
  TextArea,
  DropDown,
  TextList,
  Checkbox,
}

export type PortalFormField = {
  name: string;
  type: PortalFormFieldType;
  className?: string;
} & (
  | IPortalFormTextInputField
  | IPortalFormTextAreaField
  | IPortalFormDropdownInputField
  | IPortalFormCheckboxInputField
);

export type PortalFormFieldValue =
  | IPortalFormTextInputValue
  | IPortalFormTextAreaValue
  | IPortalFormDropdownInputValue
  | IPortalFormCheckboxInputValue;

export const ValidationMessage: React.FunctionComponent<{
  message: string | undefined;
}> = ({ message }) => {
  const formFieldsClasses = formFieldsStyles();
  return (
    <Text className={mergeClasses(formFieldsClasses.validationMessage)}>
      {message}
    </Text>
  );
};

export const PortalFormFields: React.FunctionComponent<{
  form: PortalFormField[];
  onChange?: (item: PortalFormField, newValue?: PortalFormFieldValue) => void;
  forceShowErrorMessage?: boolean;
  field?: (item: PortalFormField, field: JSX.Element) => JSX.Element;
}> = (props) => {
  const { form, onChange = noop, forceShowErrorMessage, field } = props;

  return (
    <>
      {form.map((item) => {
        let input: JSX.Element;
        switch (item.type) {
          case PortalFormFieldType.Text:
            input = (
              <PortalFormTextInput
                key={item.name}
                labelTranslationKey={item.labelTranslationKey}
                name={item.name}
                value={(item as IPortalFormTextInputField).value}
                onChange={(event, newValue) => onChange(item, newValue)}
                validatorFns={(item as IPortalFormTextInputField).validatorFns}
                forceShowErrorMessage={forceShowErrorMessage}
                placeholderTranslationKey={
                  (item as IPortalFormTextInputField).placeholderTranslationKey
                }
                disabled={(item as IPortalFormTextInputField).disabled}
                borderless={(item as IPortalFormTextInputField).borderless}
              />
            );
            break;
          case PortalFormFieldType.TextArea:
            input = (
              <PortalFormTextArea
                key={item.name}
                labelTranslationKey={item.labelTranslationKey}
                name={item.name}
                value={(item as IPortalFormTextAreaField).value}
                onChange={(event, newValue) => onChange(item, newValue)}
                validatorFns={(item as IPortalFormTextAreaField).validatorFns}
                forceShowErrorMessage={forceShowErrorMessage}
                placeholderTranslationKey={
                  (item as IPortalFormTextAreaField).placeholderTranslationKey
                }
                disabled={(item as IPortalFormTextInputField).disabled}
                borderless={(item as IPortalFormTextInputField).borderless}
              />
            );
            break;
          case PortalFormFieldType.DropDown:
            input = (
              <PortalFormDropdownInput
                key={item.name}
                labelTranslationKey={item.labelTranslationKey}
                value={(item as IPortalFormDropdownInputField).value}
                options={(item as IPortalFormDropdownInputField).options}
                onChange={(_, newValue) => onChange(item, newValue)}
                validatorFns={
                  (item as IPortalFormDropdownInputField).validatorFns
                }
                forceShowErrorMessage={forceShowErrorMessage}
                placeholderTranslationKey={
                  (item as IPortalFormDropdownInputField)
                    .placeholderTranslationKey
                }
                disabled={(item as IPortalFormDropdownInputField).disabled}
                borderless={(item as IPortalFormTextInputField).borderless}
              />
            );
            break;
          case PortalFormFieldType.Checkbox:
            input = (
              <PortalFormCheckboxInput
                key={item.name}
                labelTranslationKey={item.labelTranslationKey}
                value={(item as IPortalFormCheckboxInputField).value}
                onChange={(event, newValue) => onChange(item, newValue)}
                validatorFns={
                  (item as IPortalFormCheckboxInputField).validatorFns
                }
                forceShowErrorMessage={forceShowErrorMessage}
              />
            );
            break;
          default:
            return null;
        }

        return field ? field(item, input) : input;
      })}
    </>
  );
};

import { makeStyles } from "../shared";
import { Breakpoint, mediaQueryBreakpointUp } from "./Grid";

export const regAndCancelSuccessStyles = makeStyles({
  successBodyText: {
    display: "block",
  },
  successImageOnModal: {
    maxWidth: "181px",
    maxHeight: "104px",
    [mediaQueryBreakpointUp(Breakpoint.Medium)]: {
      maxWidth: "280px",
      maxHeight: "160px",
    },
  },
  successBody: {
    [mediaQueryBreakpointUp(Breakpoint.Large)]: {
      paddingLeft: "28px",
      paddingRight: "28px",
    },
  },
  successContent: {
    marginLeft: "auto",
    marginRight: "auto",
  },
});

import { makeStyles, tokens } from "../shared";
import { Breakpoint, mediaQueryBreakpointUp } from "./Grid";

export const eventLoginPageStyles = makeStyles({
  container: {
    backgroundColor: tokens.colorNeutralBackground3,
  },
  button: {
    paddingLeft: "33px",
    paddingRight: "33px",
    height: "64px",
    [mediaQueryBreakpointUp(Breakpoint.Medium)]: {
      paddingLeft: "78px",
      paddingRight: "78px",
    },
  },
  buttonsWrapper: {
    marginBottom: "16px",
  },
  image: {
    maxWidth: "168px",
  },
  text: {
    textAlign: "center",
    display: "block",
  },
  headerTitleText: {
    marginBottom: "10px",
    fontWeight: tokens.fontWeightBold,
    lineHeight: tokens.lineHeightBase600,
  },
  headerEventText: {
    fontWeight: tokens.fontWeightSemibold,
    lineHeight: tokens.lineHeightBase400,
  },
  privacyText: {
    fontWeight: tokens.fontWeightSemibold,
    color: tokens.colorNeutralForeground3,
  },
  main: {
    paddingTop: "60px",
  },
  footer: {
    height: "48px",
    backgroundColor: tokens.colorNeutralBackground1,
  },
});

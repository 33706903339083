import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  displayEngagementRailSelector,
  EventEngagementRailChoices,
  setEventEngagementRailAction,
} from "../core/slices/eventSlice";
import {
  ButtonV9 as Button,
  DismissRegular,
  mergeClasses,
  MoreHorizontalRegular,
  MenuV9 as Menu,
  MenuTriggerV9 as MenuTrigger,
  MenuPopoverV9 as MenuPopover,
  MenuListV9 as MenuList,
  MenuItemV9 as MenuItem,
} from "../shared";
import { flexStyles } from "../styles/FlexStyles";
import { iconStyles } from "../styles/IconStyles";

export const EventEngagementRail: React.FunctionComponent = () => {
  const flexClasses = flexStyles();
  const iconClasses = iconStyles();
  const dispatch = useDispatch();

  const displayEngagementRail = useSelector(displayEngagementRailSelector);
  /* istanbul ignore next */
  const onClickCloseRail = () => {
    dispatch(setEventEngagementRailAction(EventEngagementRailChoices.None));
  };

  return displayEngagementRail ? (
    <div
      className={mergeClasses(flexClasses.root, flexClasses.wrap)}
      id="EventEngagementRail"
    >
      <Menu>
        <MenuTrigger>
          <Button
            icon={<MoreHorizontalRegular className={iconClasses.medium} />}
            appearance="transparent"
          />
        </MenuTrigger>
        <MenuPopover>
          <MenuList>
            <MenuItem>Chat</MenuItem>
            <MenuItem>People</MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
      <Button
        onClick={onClickCloseRail}
        icon={<DismissRegular className={iconClasses.medium} />}
        appearance="transparent"
      />
    </div>
  ) : /* istanbul ignore next */ null;
};

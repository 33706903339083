import {
  createAsyncThunk,
  createSlice,
  createAction,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AccountInfo } from "@azure/msal-browser";
import { EventId, Guid } from "./CMDTypes";
import {
  fetchRecordingPlaybackInfo,
  redeemFile,
  fetchSession,
  fetchSessions,
} from "./sessionAPI";
import {
  IError,
  getErrorResponse,
  getErrorResponseLoggerScenarioMessage,
  isErrorHandledError,
  isErrorResponseAuthError,
  isErrorResponseNotFoundError,
  isErrorResponseSkypeTokenBadTenantId,
} from "./error";
import { RootState } from "../store/store";
import { IEvent, SessionType } from "./eventTypes.interface";
import {
  IEventSession,
  IEventSessionResponse,
  IRecordingPlaybackInfo,
} from "./session.interface";
import { EventUser } from "./userTypes.interface";
import {
  addToCalendar,
  CalendarType,
  sessionToCalendarEvent,
} from "../../utilities/addToCalendarUtils";
import { Logger, Scenario } from "../../common/logger/Logger";
import { getEventTenantId } from "../../utilities/common/utils";
import { ITelemetryData, LoggerLevels } from "../../common/logger/interface";
import {
  isAttendeeNotFoundError,
  isExpiredRecordingError,
  isExpiredASLError,
  isConditionalAccessPolicyError,
} from "./recordingError";
import {
  getEventOverviewAsyncAction,
  IEventOverviewResponse,
} from "./eventSlice";

export interface IEventSessionsState {
  sessionObjects?: IEventSession[];
  currentSession?: IEventSession;
  speakers?: EventUser[];
  speakerSessions?: { [speakerId: string]: IEventSession[] };
  displayedSpeaker: Guid | null;
  sessionError: IError | undefined;
  recordingPlayback?: IRecordingPlaybackInfo;
  recordingPlaybackError?: IError;
}

const getSessionsAction = "sessions/fetch";
export const getEventSessionsAsyncAction = createAsyncThunk<
  IEventSessionResponse,
  EventId,
  { rejectValue: IError }
>(getSessionsAction, async (eventId: EventId, { rejectWithValue }) => {
  try {
    return await fetchSessions(eventId);
  } catch (error) {
    const err: IError = getErrorResponse(getSessionsAction, error);
    return rejectWithValue(err);
  }
});

const getSessionAction = "session/fetch";
export interface IGetSessionArgs {
  eventId: EventId;
  sessionId: string;
}
export const getEventSessionAsyncAction = createAsyncThunk<
  IEventSession,
  IGetSessionArgs,
  { rejectValue: IError }
>(getSessionAction, async (args: IGetSessionArgs, { rejectWithValue }) => {
  try {
    return await fetchSession(args.eventId, args.sessionId);
  } catch (error) {
    const err: IError = getErrorResponse(getSessionAction, error);
    return rejectWithValue(err);
  }
});

const getRecordingPlaybackInfoAction = "recording/playback";
export interface IGetRecordingPlaybackInfoArgs {
  eventId: EventId;
  sessionId: string;
  recordingId: string;
  registrationId: string | undefined;
  presenterKey: string | undefined;
  badgerToken: string | undefined;
  account: AccountInfo | null;
}
export const getRecordingPlaybackInfoAsyncAction = createAsyncThunk<
  IRecordingPlaybackInfo,
  IGetRecordingPlaybackInfoArgs,
  { rejectValue: IError }
>(
  getRecordingPlaybackInfoAction,
  async (args: IGetRecordingPlaybackInfoArgs, { rejectWithValue }) => {
    let playbackInfo: IRecordingPlaybackInfo;
    const result = await getPlaybackInfo(args);
    if ("streamingUrl" in result || "redeemUrl" in result) {
      playbackInfo = result as IRecordingPlaybackInfo;
    } else {
      return rejectWithValue(result as IError);
    }

    if (playbackInfo.streamingUrl || !playbackInfo.redeemUrl) {
      return playbackInfo;
    }

    const streamingUrlOrError = await getStreamingUrl(
      args,
      playbackInfo.redeemUrl
    );
    if (typeof streamingUrlOrError === "string") {
      playbackInfo.streamingUrl = streamingUrlOrError;
    } else {
      return rejectWithValue(streamingUrlOrError);
    }

    return playbackInfo;
  }
);

async function getPlaybackInfo(
  args: IGetRecordingPlaybackInfoArgs
): Promise<IRecordingPlaybackInfo | IError> {
  const logger = Logger.getInstance();
  const scenarioData: ITelemetryData = {
    eventId: args.eventId,
    eventTenantId: getEventTenantId(args.eventId) ?? "",
  };
  const scenario = logger.createScenario(
    Scenario.RecordingPlaybackInfoRequest,
    {
      data: scenarioData,
    }
  );
  try {
    const playbackInfo = await fetchRecordingPlaybackInfo(
      args.eventId,
      args.sessionId,
      args.recordingId,
      args.registrationId,
      args.presenterKey,
      args.badgerToken,
      scenario
    );
    scenario?.stop();
    return playbackInfo;
  } catch (ex) {
    logger.logTrace(
      LoggerLevels.error,
      `Could not get recording playback info. Event Id: ${args.eventId}, Session Id: ${args.sessionId}, Recording id: ${args.recordingId}, Registration id: ${args.registrationId}.`
    );
    const error: IError = getErrorResponse(getRecordingPlaybackInfoAction, ex);
    const scenarioEventData = {
      message: getErrorResponseLoggerScenarioMessage(error),
    };

    if (
      isErrorResponseNotFoundError(error) ||
      isErrorResponseAuthError(error) ||
      isErrorResponseSkypeTokenBadTenantId(error) ||
      isErrorHandledError(error) ||
      isAttendeeNotFoundError(error) ||
      isExpiredRecordingError(error)
    ) {
      scenario?.stop(scenarioEventData);
    } else {
      scenario?.fail(scenarioEventData);
    }

    return error;
  }
}

async function getStreamingUrl(
  args: IGetRecordingPlaybackInfoArgs,
  redeemUrl: string
): Promise<string | IError> {
  const logger = Logger.getInstance();
  const scenarioData: ITelemetryData = {
    eventId: args.eventId,
    eventTenantId: getEventTenantId(args.eventId) ?? "",
    anonymous: !!args.badgerToken,
  };
  const scenario = logger.createScenario(Scenario.RedeemFileRequest, {
    data: scenarioData,
  });
  try {
    const response = await redeemFile(
      redeemUrl,
      args.badgerToken,
      args.account,
      scenario
    );
    scenario?.stop();
    const url = new URL(response.getUrl);
    url.searchParams.delete("access_token");
    return url.toString();
  } catch (ex) {
    logger.logTrace(
      LoggerLevels.error,
      `Could not redeem file. Event Id: ${args.eventId}, Session Id: ${
        args.sessionId
      }, Recording id: ${args.recordingId}, Registration id: ${
        args.registrationId
      }. Anonymous: ${!!args.badgerToken}.`
    );
    const error: IError = getErrorResponse(getRecordingPlaybackInfoAction, ex);
    const scenarioEventData = {
      message: getErrorResponseLoggerScenarioMessage(error),
    };

    if (
      isErrorHandledError(error) ||
      isExpiredASLError(error) ||
      isConditionalAccessPolicyError(error)
    ) {
      scenario?.stop(scenarioEventData);
    } else {
      scenario?.fail(scenarioEventData);
    }

    return error;
  }
}

type DatedSession = {
  start: Date;
  session: IEventSession;
};

export type DatedSessionsMap = {
  [key: string]: IEventSession[];
};

const initialState: IEventSessionsState = {
  sessionObjects: undefined,
  speakers: undefined,
  speakerSessions: undefined,
  sessionError: undefined,
  displayedSpeaker: null,
};

export const resetEventSessionsAction = createAction<boolean>("sessions/reset");
export const resetCurrentSessionAction = createAction<void>("session/reset");

export const setEventSessionsDisplayedSpeakerAction = createAction<Guid | null>(
  "sessions/displayedSpeaker"
);

const eventSessionsAddToCalendarAction: string = "sessions/addToCalendar";
export const eventSessionsAddToCalendarAsyncAction = createAsyncThunk(
  eventSessionsAddToCalendarAction,
  async (payload: { session: IEventSession; type: CalendarType }) => {
    const calendarEvent = sessionToCalendarEvent(payload.session);
    addToCalendar(calendarEvent, payload.type);
  }
);

export const eventSessionsSlice = createSlice({
  name: "sessions",
  initialState: initialState,
  reducers: {
    clearSessionError: (state) => {
      state.sessionError = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getEventOverviewAsyncAction.fulfilled,
        (
          state: IEventSessionsState,
          { payload }: PayloadAction<IEventOverviewResponse>
        ) => {
          if (process.env.noop_api) {
            return;
          }

          state.sessionObjects = payload.sessions;
          state.sessionError = undefined;

          const speakerSessions: {
            [speakerId: string]: IEventSession[];
          } = {};
          const speakers: EventUser[] = [];

          payload.sessions.forEach((session: IEventSession) => {
            if (session.speakers) {
              session.speakers.forEach((speaker) => {
                if (
                  !Object.prototype.hasOwnProperty.call(
                    speakerSessions,
                    speaker.id
                  )
                ) {
                  speakers.push(speaker);
                  speakerSessions[speaker.id] = [session];
                } else {
                  speakerSessions[speaker.id].push(session);
                }
              });
            }
          });
          state.speakers = speakers;
          state.speakerSessions = speakerSessions;
        }
      )
      .addCase(getEventOverviewAsyncAction.rejected, (state, action) => {
        state.sessionError = action.payload;
      })
      .addCase(
        getEventSessionsAsyncAction.fulfilled,
        (
          state: IEventSessionsState,
          { payload }: PayloadAction<IEventSessionResponse>
        ) => {
          /* istanbul ignore if */
          if (process.env.noop_api) {
            return;
          }
          state.sessionObjects = payload;
          state.sessionError = undefined;

          const speakerSessions: {
            [speakerId: string]: IEventSession[];
          } = {};
          const speakers: EventUser[] = [];
          payload.forEach((session: IEventSession) => {
            if (session.speakers) {
              session.speakers.forEach((speaker) => {
                if (
                  !Object.prototype.hasOwnProperty.call(
                    speakerSessions,
                    speaker.id
                  )
                ) {
                  speakers.push(speaker);
                  speakerSessions[speaker.id] = [session];
                } else {
                  speakerSessions[speaker.id].push(session);
                }
              });
            }
          });
          state.speakers = speakers;
          state.speakerSessions = speakerSessions;
        }
      )
      .addCase(getEventSessionsAsyncAction.rejected, (state, action) => {
        state.sessionError = action.payload;
      })
      .addCase(
        getRecordingPlaybackInfoAsyncAction.fulfilled,
        (state, action) => {
          state.recordingPlayback = action.payload;
          state.recordingPlaybackError = undefined;
        }
      )
      .addCase(
        getRecordingPlaybackInfoAsyncAction.rejected,
        (state, action) => {
          state.recordingPlayback = undefined;
          state.recordingPlaybackError = action.payload;
        }
      )
      .addCase(
        resetEventSessionsAction,
        (state: IEventSessionsState, { payload }: PayloadAction<boolean>) => {
          if (!process.env.noop_api && payload) {
            Object.assign(state, initialState);
          }
        }
      )
      .addCase(resetCurrentSessionAction, (state: IEventSessionsState) => {
        if (!process.env.noop_api) {
          state.currentSession = undefined;
        }
      })
      .addCase(
        setEventSessionsDisplayedSpeakerAction,
        (
          state: IEventSessionsState,
          { payload }: PayloadAction<Guid | null>
        ) => {
          state.displayedSpeaker = payload;
        }
      )
      .addCase(
        getEventSessionAsyncAction.fulfilled,
        (
          state: IEventSessionsState,
          { payload }: PayloadAction<IEventSession>
        ) => {
          state.currentSession = payload;
        }
      )
      .addCase(getEventSessionAsyncAction.rejected, (state, action) => {
        state.sessionError = action.payload;
      });
  },
});

// selectors
export const eventSessionsSelector = (state: RootState): IEventSession[] =>
  state.sessions?.sessionObjects || [];
export const currentSessionSelector = (
  state: RootState
): IEventSession | undefined => state.sessions?.currentSession;
export const isMultiSessionEventSelector = (state: RootState): boolean => {
  const sessions = state.sessions?.sessionObjects;
  const currentEvent: IEvent = state.event.eventObject;
  return (
    (currentEvent && currentEvent.sessionType === SessionType.MULTI) ||
    (sessions && sessions.length > 1)
  );
};
export const eventSessionSelector = (
  state: RootState,
  id: Guid
): IEventSession | undefined =>
  state.sessions?.sessionObjects?.find((item: IEventSession) => item.id === id);
export const eventSessionError = (state: RootState): IError | undefined =>
  state.sessions?.sessionError;
export const sessionsSpeakersSelector = (state: RootState): EventUser[] =>
  state.sessions?.speakers || [];
export const eventSpeakerSessions =
  (speakerId: Guid) =>
  (state: RootState): IEventSession[] => {
    const speakerSessions = state.sessions?.speakerSessions;
    if (speakerSessions) {
      if (speakerId in speakerSessions) {
        return speakerSessions[speakerId];
      }
    }
    return [];
  };

export const sessionErrorSelector = (state: RootState): IError | undefined =>
  state.sessions?.sessionError;

export const recordingPlaybackSelector = (
  state: RootState
): IRecordingPlaybackInfo | undefined => state.sessions?.recordingPlayback;
export const recordingPlaybackErrorSelector = (
  state: RootState
): IError | undefined => state.sessions?.recordingPlaybackError;

export const displayedSpeakerSelector = (
  state: RootState
): EventUser | null => {
  const speakerId = state.sessions.displayedSpeaker;
  if (speakerId && state.sessions?.sessionObjects) {
    return (
      state.sessions?.sessionObjects
        .flatMap((session: IEventSession) => session.speakers)
        .find((speaker: IEventSession) => speaker.id === speakerId) || null
    );
  }
  return null;
};

export const sessionsByDaySelector = (
  state: RootState
): DatedSessionsMap | null => {
  if (state.sessions.sessionObjects && state.sessions.sessionObjects.length) {
    // make a Date object so we can sort and bin
    let datedSessions: DatedSession[] = [];
    state.sessions.sessionObjects.forEach((session: IEventSession) => {
      const dtSession: DatedSession = {
        start: new Date(session.sessionTime.startTime),
        session: session,
      };
      datedSessions.push(dtSession);
    });
    datedSessions = datedSessions.sort(
      (a, b) => a.start.valueOf() - b.start.valueOf()
    );
    // now we bin them
    const formatOptions: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "numeric",
      year: "2-digit",
    };
    let day = datedSessions[0].start.getDay();
    let dateStr = Intl.DateTimeFormat("en-US", formatOptions).format(
      datedSessions[0].start
    );
    const datedMap: DatedSessionsMap = {};

    datedMap[dateStr] = [];
    datedSessions.forEach((dsession) => {
      if (dsession.start.getDay() !== day) {
        dateStr = Intl.DateTimeFormat("en-US", formatOptions).format(
          dsession.start
        );
        datedMap[dateStr] = [];
        day = dsession.start.getDay();
      }
      datedMap[dateStr].push(dsession.session);
    });
    return datedMap;
  }
  return null;
};

// reducer
export default eventSessionsSlice.reducer;

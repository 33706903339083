import React from "react";
import {
  mergeClasses,
  SkeletonV9 as Skeleton,
  SkeletonItem,
} from "../../shared";
import { HeroImageLoading } from "./HeroImageLoading";
import { EventInfoLoading } from "./EventInfoLoading";
import { RegistrationCardLoading } from "./RegistrationCardLoading";
import {
  titleEventRegBarStyles,
  eventPageStyles,
} from "../../styles/EventPage";
import { gridStyles } from "../../styles/Grid";
import {
  flexAlignStyles,
  flexItemStyles,
  flexStyles,
} from "../../styles/FlexStyles";
import { skeletonItemStyles } from "../../styles/SkeletonStyles";

export const EventPageLoading: React.FunctionComponent = () => {
  const flexAlignClasses = flexAlignStyles();
  const flexItemClasses = flexItemStyles();
  const flexClasses = flexStyles();
  const skeletonItemClasses = skeletonItemStyles();
  const titleEventRegBarClasses = titleEventRegBarStyles();
  const gridClasses = gridStyles();
  const eventPageClasses = eventPageStyles();
  const eventPageLoading = (
    <Skeleton data-testid="event-page-loading-component" animation="pulse">
      <div className="event-page-loading-wrapper">
        <div className="event-page-header">
          <HeroImageLoading />
          <div
            className={mergeClasses(
              "title-event-reg-bar",
              titleEventRegBarClasses.root
            )}
          >
            <div
              className={mergeClasses(
                gridClasses.container,
                flexClasses.root,
                flexClasses.rowGap24px
              )}
            >
              <div
                className={mergeClasses(
                  flexClasses.root,
                  flexClasses.fill,
                  flexAlignClasses.alignItemCenter,
                  flexClasses.rowGap24px
                )}
              >
                <SkeletonItem
                  className={mergeClasses(
                    skeletonItemClasses.root,
                    eventPageClasses.titleEventRegBarImage
                  )}
                />
                <div
                  className={mergeClasses(
                    flexClasses.root,
                    flexClasses.column,
                    flexClasses.columnGapSmall,
                    flexAlignClasses.justifyContentCenter,
                    flexItemClasses.grow
                  )}
                >
                  <SkeletonItem
                    className={mergeClasses(
                      skeletonItemClasses.root,
                      skeletonItemClasses.textSize700,
                      skeletonItemClasses.width300
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={mergeClasses(
            "event-page-container",
            gridClasses.container,
            eventPageClasses.container
          )}
        >
          <div className={gridClasses.row}>
            <div
              className={mergeClasses(
                gridClasses.col,
                gridClasses.xs12,
                gridClasses.lg8
              )}
            >
              <EventInfoLoading />
            </div>
            <div
              className={mergeClasses(
                gridClasses.col,
                gridClasses.hiddenMdDown,
                gridClasses.xs4
              )}
            >
              <RegistrationCardLoading />
            </div>
          </div>
        </div>
      </div>
    </Skeleton>
  );

  return eventPageLoading;
};

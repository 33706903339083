import React, { useEffect, useState } from "react";
import { mergeClasses } from "../shared";
import { IEvent, EventType } from "../core/slices/eventTypes.interface";
import { useDispatch, useSelector } from "react-redux";
import {
  eventSessionsSelector,
  getEventSessionAsyncAction,
  getEventSessionsAsyncAction,
  isMultiSessionEventSelector,
  resetCurrentSessionAction,
  sessionErrorSelector,
} from "../core/slices/sessionSlice";
import { flexAlignStyles, flexStyles } from "../styles/FlexStyles";
import { About } from "./eventInfo/About";
import { Speakers } from "./eventInfo/Speakers";
import { Breakpoint, gridStyles } from "../styles/Grid";
import { useBreakpoint } from "../utilities/hooks/useBreakpoints";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { currentSessionSelector } from "../core/slices/sessionSlice";
import { IEventSession } from "../core/slices/session.interface";
import {
  ButtonV9 as Button,
  TextV9 as Text,
  ArrowLeftRegular,
} from "../shared";
import { portalTextStyles } from "../styles/PortalText";
import { eventPageStyles } from "../styles/EventPage";
import {
  displayEngagementRailSelector,
  eventSelector,
  getEventAsyncAction,
  eventErrorSelector,
} from "../core/slices/eventSlice";
import { authenticatedSelector } from "../core/slices/userSlice";
import { IError } from "../core/slices/error";
import { eventErrorHandler } from "../utilities/eventErrorHandler";
import { EventLayout } from "./EventLayout";
import { EventRegistrationButton } from "./EventRegistrationButton";
import { useTranslation } from "react-i18next";
import { sessionPageStyles } from "../styles/SessionPage";
import { buttonStyles } from "../styles/ButtonStyles";
import { TopInfo } from "./eventInfo/TopInfo";
import { SessionPageLoading } from "./loading/SessionPageLoading";
import Recording from "./eventInfo/Recording";
import {
  enableRedirectAcquireTokenSelector,
  enableVODSelector,
} from "../core/slices/ecsSlice";
import { routes } from "../common/constants";
import { generateSearchParams } from "../utilities/common/generateSearchParams";
import { useAttendeeIdSearchParamHandlerHook } from "../utilities/hooks/useAttendeeIdSearchParamHandlerHook";
import { usePresenterKeySearchParamHandlerHook } from "../utilities/hooks/usePresenterKeySearchParamHandlerHook";
import { PortalLocationState } from "../core/history/history.interface";
const RECORDING_HOST_VIEW = "SessionPage";

export const SessionPage: React.FunctionComponent = () => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const portalTextClasses = portalTextStyles();
  const gridClasses = gridStyles();
  const buttonClasses = buttonStyles();
  const sessionPageClasses = sessionPageStyles();
  const eventPageClasses = eventPageStyles();
  const { id, sessionId } = useParams<{ id: string; sessionId: string }>();
  useAttendeeIdSearchParamHandlerHook();
  usePresenterKeySearchParamHandlerHook();
  const { t: i18n } = useTranslation();
  const history = useHistory<PortalLocationState>();
  const location = useLocation<PortalLocationState>();
  const isAuthenticated = useSelector(authenticatedSelector);
  const sessionError: IError | undefined = useSelector(sessionErrorSelector);
  const eventError: IError | undefined = useSelector(eventErrorSelector);

  const currentEvent: IEvent | undefined = useSelector(eventSelector);
  const displayEngagementRail = useSelector(displayEngagementRailSelector);
  const currentSession: IEventSession | undefined = useSelector(
    currentSessionSelector
  );
  const allSessions: IEventSession[] = useSelector(eventSessionsSelector);
  const enableVOD = useSelector(enableVODSelector);
  const enableRedirectAcquireToken = useSelector(
    enableRedirectAcquireTokenSelector
  );
  const isMultiSessionEvent = useSelector(isMultiSessionEventSelector);

  const [loadedSessionDetails, setloadedSessionDetails] = useState(false);
  const dispatch = useDispatch();
  const { isBreakpointAndDown } = useBreakpoint();
  const isMobileView = isBreakpointAndDown(Breakpoint.Medium);
  const showRegistrationCard = currentEvent
    ? currentEvent.type === EventType.WEBINAR
    : false;

  useEffect(() => {
    if (!loadedSessionDetails) {
      dispatch(
        getEventSessionAsyncAction({
          eventId: id,
          sessionId: sessionId,
        })
      );
      setloadedSessionDetails(true);
    }

    if (isAuthenticated && (sessionError || eventError)) {
      eventErrorHandler(
        sessionError || eventError,
        isAuthenticated,
        enableRedirectAcquireToken,
        history,
        location
      );
    }
  }, [
    id,
    sessionId,
    dispatch,
    loadedSessionDetails,
    isAuthenticated,
    enableRedirectAcquireToken,
    sessionError,
    history,
    location,
    eventError,
  ]);

  const OnClickReturnToEventPage = () => {
    dispatch(resetCurrentSessionAction());
    history.push({
      pathname: `${routes.event}/${id}`,
      search: generateSearchParams(location),
    });
  };

  useEffect(() => {
    if (!currentEvent) {
      dispatch(getEventAsyncAction(id));
    }
    if (!allSessions) {
      dispatch(getEventSessionsAsyncAction(id));
    }
  }, [dispatch, allSessions, currentEvent, id]);

  const sessionPageLoading = <SessionPageLoading />;

  return currentSession ? (
    <EventLayout>
      <div
        className={mergeClasses(sessionPageClasses.sessionHeader)}
        data-testid="sessionPageHeader"
      >
        <div
          className={mergeClasses(
            gridClasses.container,
            flexClasses.root,
            flexClasses.rowGap24px
          )}
        >
          <div
            className={mergeClasses(
              flexClasses.root,
              flexClasses.column,
              flexClasses.columnGapMedium
            )}
          >
            <Button
              data-testid="backToEventButton"
              aria-label={i18n("back_to_event")}
              appearance="transparent"
              icon={<ArrowLeftRegular />}
              className={mergeClasses(
                sessionPageClasses.backToEventButton,
                flexAlignClasses.justifyContentStart,
                buttonClasses.primaryWithText
              )}
              onClick={OnClickReturnToEventPage}
            >
              {i18n("back_to_event")}
            </Button>
            <Text
              data-testid="sessionTitle"
              as="h1"
              className={mergeClasses(
                portalTextClasses.xLarge,
                portalTextClasses.eventTitle,
                eventPageClasses.eventTitle
              )}
            >
              {currentSession.title}
            </Text>
          </div>
        </div>
      </div>
      <div
        className={mergeClasses(
          "event-page-container",
          gridClasses.container,
          sessionPageClasses.container
        )}
        data-testid="sessionPageContainer"
      >
        <div className={gridClasses.row}>
          <div
            className={mergeClasses(
              gridClasses.col,
              gridClasses.xs12,
              displayEngagementRail ? gridClasses.lg10 : gridClasses.lg8
            )}
          >
            <div
              className={mergeClasses(
                flexClasses.root,
                flexClasses.column,
                flexClasses.columnGap32px
              )}
              data-testid="sessionInfo"
            >
              {enableVOD && isMultiSessionEvent && currentEvent && (
                <Recording
                  event={currentEvent}
                  session={currentSession}
                  hostView={RECORDING_HOST_VIEW}
                />
              )}
              {isMobileView && <TopInfo session={currentSession} />}
              {currentSession.description && (
                <About descriptionText={currentSession.description} />
              )}
              <Speakers speakers={currentSession.speakers} />
            </div>
          </div>
          <div
            className={mergeClasses(
              gridClasses.col,
              gridClasses.hiddenMdDown,
              gridClasses.xs4
            )}
            data-testid="sessionRegistrationCard"
          >
            {showRegistrationCard ? (
              <EventRegistrationButton />
            ) : /* istanbul ignore next */ null}
          </div>
        </div>
        {showRegistrationCard && isMobileView ? (
          <EventRegistrationButton />
        ) : null}
      </div>
    </EventLayout>
  ) : (
    sessionPageLoading
  );
};

import { makeStyles, shorthands, tokens } from "../shared";
import { Breakpoint, mediaQueryBreakpointDown } from "./Grid";
export const speakerProfileStyles = makeStyles({
  session: {
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.border("1px", "solid", tokens.colorNeutralStroke2),
    ...shorthands.borderRadius(tokens.borderRadiusMedium),
    ...shorthands.padding("12px", "16px", "12px", "16px"),
  },
  infoText: {
    lineHeight: tokens.lineHeightBase200,
  },
  sessionDate: {
    width: "70px",
  },
  sessionDateTime: {
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.border("1px", "solid", tokens.colorNeutralStroke2),
    borderBottomStyle: "none",
    ...shorthands.borderRadius(
      tokens.borderRadiusMedium,
      tokens.borderRadiusMedium,
      "0",
      "0"
    ),
    ...shorthands.padding("2px", "3px"),
    whiteSpace: "pre",
  },
  sessionDateDuration: {
    backgroundColor: tokens.colorBrandBackground2,
    ...shorthands.borderRadius(
      "0",
      "0",
      tokens.borderRadiusMedium,
      tokens.borderRadiusMedium
    ),
    ...shorthands.padding("2px", "3px"),
    whiteSpace: "pre",
  },
  sessionDurationInfo: {
    whiteSpace: "nowrap",
    lineHeight: "10px",
  },
  dialogHeader: {
    ...shorthands.margin("0px", "0px", "25px"),
  },
  speakerDialogTitle: {
    fontWeight: tokens.fontWeightSemibold,
    [mediaQueryBreakpointDown(Breakpoint.Medium)]: {
      fontSize: "15px",
      fontWeight: tokens.fontWeightBold,
      lineHeight: tokens.lineHeightBase300,
    },
  },
  speakerSessionTitle: {
    fontSize: tokens.fontSizeBase200,
    fontWeight: tokens.fontWeightSemibold,
    lineHeight: tokens.lineHeightBase200,
    [mediaQueryBreakpointDown(Breakpoint.Medium)]: {
      fontSize: "15px",
      lineHeight: tokens.lineHeightBase300,
    },
  },
  speakerDialogDescriptionBox: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.overflow("hidden"),
    textOverflow: "ellipsis",
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
  },
  speakerDialogDescriptionText: {
    fontSize: tokens.fontSizeBase400,
    fontWeight: tokens.fontWeightRegular,
    lineHeight: tokens.lineHeightBase300,
    [mediaQueryBreakpointDown(Breakpoint.Medium)]: {
      fontSize: "15px",
    },
  },
  dialogTitle: {
    [mediaQueryBreakpointDown(Breakpoint.Medium)]: {
      ...shorthands.borderBottom("1px", "solid", tokens.colorNeutralStroke2),
      ...shorthands.padding("16px", "16px", "0px", "16px"),
    },
  },
  dialogContent: {
    [mediaQueryBreakpointDown(Breakpoint.Medium)]: {
      ...shorthands.padding("0px", "16px", "38px", "16px"),
    },
  },
  dialogSurface: {
    ...shorthands.padding("28px", "32px", "28px", "32px"),
    [mediaQueryBreakpointDown(Breakpoint.Medium)]: {
      ...shorthands.padding("0px"),
    },
  },
});

/**
 * Wrapper for {@link window.sessionStorage}.
 */
export class SessionStorage {
  public static put<T>(key: string, value: T): boolean {
    /* istanbul ignore if */
    if (!window.sessionStorage) {
      return false;
    }

    try {
      const serializedValue = JSON.stringify(value);
      window.sessionStorage.setItem(key, serializedValue);
      return true;
    } catch (err) {
      return false;
    }
  }

  public static get<T>(key: string): T | null {
    /* istanbul ignore if */
    if (!window.sessionStorage) {
      return null;
    }

    const valueFromBrowserStorage = window.sessionStorage.getItem(key);
    if (!valueFromBrowserStorage) {
      return null;
    }

    try {
      return JSON.parse(valueFromBrowserStorage) as T;
    } catch (err) {
      return null;
    }
  }

  public static delete(key: string): void {
    /* istanbul ignore if */
    if (!window.sessionStorage) {
      return;
    }

    window.sessionStorage.removeItem(key);
  }
}

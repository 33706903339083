import { Guid } from "./CMDTypes";

export enum EventUserRole {
  ORGANIZER = "Organizer",
  COORGANIZER = "CoOrganizer",
  ATTENDEE = "Attendee",
  PRESENTER = "Presenter",
  UNKNOWN = "Unknown", // for unauthenticated user
}

export type EventUserBio = {
  company: string | null;
  jobTitle: string | null;
  description: string | null;
  websites: string[] | null;
  socialMediaLinks: { [index: string]: string | undefined } | null;
  // TODO : Update once industry and interests are needed in the portal
  // industry: string | null;
  // interests: string | null;
};

export type EventUser = {
  id: Guid;
  firstName: string | null;
  lastName: string | null;
  displayName: string | null;
  email: string;
  role: EventUserRole;
  userBio?: EventUserBio;
  profileImage: string | null;
  identity: {
    upn: string; // User Principal Name
    tenantId?: string;
    objectId?: string;
    idp?: string;
  };
  isHidden?: boolean; // indicates if the user should be hidden from the Event Page
};

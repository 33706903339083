import { makeStyles, mergeClasses } from '@griffel/react';
export const radioGroupClassNames = {
    root: 'fui-RadioGroup'
};
const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'flex-start'
    },
    vertical: {
        flexDirection: 'column'
    }
});
/**
 * Apply styling to the RadioGroup slots based on the state
 */ export const useRadioGroupStyles_unstable = (state)=>{
    'use no memo';
    const styles = useStyles();
    state.root.className = mergeClasses(radioGroupClassNames.root, styles.root, state.layout === 'vertical' && styles.vertical, state.root.className);
    return state;
};

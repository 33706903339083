import React from "react";
import { forceDownloadFile } from "../utilities/common/fileUtils";
import { IEvent } from "../core/slices/eventTypes.interface";
import { IEventSession } from "../core/slices/session.interface";
import {
  PortalGoogleCalendarIcon,
  PortalMicrosoft365Icon,
  PortalOutlookIcon,
  PortalAppleCalendarIcon,
  getWindow,
  MenuItemV9 as MenuItem,
} from "../shared";
import { removeHTML } from "./common/utils";
import i18n from "../core/localization/i18n";
import { Logger, UserBIScenario } from "../common/logger/Logger";
import { TFunction } from "i18next";

export enum CalendarType {
  Microsoft365 = "Microsoft365",
  ICalendar = "ICalendar", // Apple, Outlook
  OutlookLive = "OutlookLive", // Outlook.com
  Google = "Google",
}
interface CalendarEvent {
  name: string;
  description: string;
  startTime: Date;
  endTime: Date;
  location: string;
}

export const eventToCalendarEvent = (event: IEvent): CalendarEvent => {
  return {
    name: event.title,
    description: addUrlToDescription(event.siteUrl, event.description),
    startTime: new Date(event.eventTime.startTime),
    endTime: new Date(event.eventTime.endTime),
    location: i18n.t("calendar_event_location_text"),
  };
};

export const sessionToCalendarEvent = (
  session: IEventSession
): CalendarEvent => {
  return {
    name: session.title,
    description: addUrlToDescription(
      session.meetingContext.joinLink,
      session.description
    ),
    startTime: new Date(session.sessionTime.startTime),
    endTime: new Date(session.sessionTime.endTime),
    location: i18n.t("calendar_event_location_text"),
  };
};

export const addToCalendar = (
  calendarEvent: CalendarEvent,
  type: CalendarType
): void => {
  switch (type) {
    case CalendarType.Microsoft365:
      addToCalendarOutlookWeb(calendarEvent, "microsoft365");
      break;
    case CalendarType.OutlookLive:
      addToCalendarOutlookWeb(calendarEvent, "outlookLive");
      break;
    case CalendarType.Google:
      addToCalendarGoogle(calendarEvent);
      break;
    case CalendarType.ICalendar:
      addToCalendarICal(calendarEvent);
      break;
  }
};
interface AddToCalenderMenuItemsProps {
  onClick: (type: CalendarType) => void;
  i18n: TFunction;
}

export const AddToCalenderMenuItems: React.FunctionComponent<
  AddToCalenderMenuItemsProps
> = (props) => {
  const loggerInstance = Logger.getInstance();
  return (
    <>
      <MenuItem
        aria-label={`Apple Calendar - ${props.i18n("link_new_tab")}`}
        onClick={() => {
          props.onClick(CalendarType.ICalendar);
          loggerInstance?.logUiTelemetry(
            "addToCalendar",
            UserBIScenario.AddToAppleCalendar,
            "click",
            "none",
            "EventAddToCalenderButton"
          );
        }}
        icon={<PortalAppleCalendarIcon />}
      >
        {"Apple Calendar"}
      </MenuItem>
      <MenuItem
        aria-label={`Google Calendar - ${props.i18n("link_new_tab")}`}
        onClick={() => {
          props.onClick(CalendarType.Google);
          loggerInstance?.logUiTelemetry(
            "addToCalendar",
            UserBIScenario.AddToGoogleCalendar,
            "click",
            "none",
            "EventAddToCalenderButton"
          );
        }}
        icon={<PortalGoogleCalendarIcon />}
      >
        {"Google Calendar"}
      </MenuItem>
      <MenuItem
        aria-label={`Microsoft 365 - ${props.i18n("link_new_tab")}`}
        onClick={() => {
          props.onClick(CalendarType.Microsoft365);
          loggerInstance?.logUiTelemetry(
            "addToCalendar",
            UserBIScenario.AddToMicrosoft365Calendar,
            "click",
            "none",
            "EventAddToCalenderButton"
          );
        }}
        icon={<PortalMicrosoft365Icon />}
      >
        {"Microsoft 365"}
      </MenuItem>
      <MenuItem
        aria-label={`Outlook - ${props.i18n("link_new_tab")}`}
        onClick={() => {
          props.onClick(CalendarType.ICalendar);
          loggerInstance?.logUiTelemetry(
            "addToCalendar",
            UserBIScenario.AddToOutlookCalendar,
            "click",
            "none",
            "EventAddToCalenderButton"
          );
        }}
        icon={<PortalOutlookIcon />}
      >
        {"Outlook"}
      </MenuItem>
      <MenuItem
        aria-label={`Outlook.com - ${props.i18n("link_new_tab")}`}
        onClick={() => {
          props.onClick(CalendarType.OutlookLive);
          loggerInstance?.logUiTelemetry(
            "addToCalendar",
            UserBIScenario.AddToOutlookLiveCalendar,
            "click",
            "none",
            "EventAddToCalenderButton"
          );
        }}
        icon={<PortalOutlookIcon />}
      >
        {"Outlook.com"}
      </MenuItem>
    </>
  );
};

const addUrlToDescription = (
  url: string,
  description: string | undefined
): string => {
  if (description) return `<a href="${url}">${url}</a><br><br>${description}`;
  return `<a href="${url}">${url}</a>`;
};

const getDateTimeString = (dateTime: Date, type: CalendarType): string => {
  const year = dateTime.getUTCFullYear().toString().padStart(4, "0");
  const month = (dateTime.getUTCMonth() + 1).toString().padStart(2, "0");
  const date = dateTime.getUTCDate().toString().padStart(2, "0");
  const hours = dateTime.getUTCHours().toString().padStart(2, "0");
  const minutes = dateTime.getUTCMinutes().toString().padStart(2, "0");
  const seconds = dateTime.getUTCSeconds().toString().padStart(2, "0");

  switch (type) {
    case CalendarType.Microsoft365:
    case CalendarType.OutlookLive:
      return `${year}-${month}-${date}T${hours}:${minutes}:${seconds}Z`;
    case CalendarType.Google:
    case CalendarType.ICalendar:
      return `${year}${month}${date}T${hours}${minutes}${seconds}Z`;
  }
};

const addToCalendarOutlookWeb = (
  calendarEvent: CalendarEvent,
  outlookType: "microsoft365" | "outlookLive"
): void => {
  const window = getWindow();

  const urlPath =
    outlookType === "microsoft365"
      ? "https://outlook.office365.com/calendar/deeplink/compose"
      : "https://outlook.live.com/calendar/0/deeplink/compose";

  const params = new URLSearchParams({
    path: "/calendar/action/compose",
    rru: "addevent",
    subject: calendarEvent.name,
    body: calendarEvent.description,
    startdt: getDateTimeString(
      calendarEvent.startTime,
      CalendarType.Microsoft365
    ),
    enddt: getDateTimeString(calendarEvent.endTime, CalendarType.Microsoft365),
    online: "true",
    location: calendarEvent.location,
  });

  const url = `${urlPath}?${params.toString()}`;
  window?.open(url, "_blank")?.focus();
};

const addToCalendarGoogle = (calendarEvent: CalendarEvent): void => {
  const window = getWindow();
  const urlPath = "https://calendar.google.com/calendar/render";

  const dates = `${getDateTimeString(
    calendarEvent.startTime,
    CalendarType.Google
  )}/${getDateTimeString(calendarEvent.endTime, CalendarType.Google)}`;

  const params = new URLSearchParams({
    action: "TEMPLATE",
    text: calendarEvent.name,
    details: calendarEvent.description,
    dates,
    location: calendarEvent.location,
  });

  const url = `${urlPath}?${params.toString()}`;
  window?.open(url, "_blank")?.focus();
};

const addToCalendarICal = (calendarEvent: CalendarEvent): void => {
  const fileBody = generateICalFile(calendarEvent);
  forceDownloadFile(
    `${calendarEvent.name}.ics`,
    fileBody,
    "text/calendar;charset=utf-8"
  );
};

const generateICalFile = (calendarEvent: CalendarEvent): string => {
  const description = calendarEvent.description;
  const descriptionText = removeHTML(description.replaceAll("<br>", "\n"));

  const fileLines: string[] = [
    "BEGIN:VCALENDAR",
    "VERSION:2.0",
    "BEGIN:VEVENT",
    `DTSTAMP:${getDateTimeString(
      calendarEvent.startTime,
      CalendarType.ICalendar
    )}`,
    `DTSTART:${getDateTimeString(
      calendarEvent.startTime,
      CalendarType.ICalendar
    )}`,
    `DTEND:${getDateTimeString(calendarEvent.endTime, CalendarType.ICalendar)}`,
    `LAST-MODIFIED:${getDateTimeString(new Date(), CalendarType.ICalendar)}`,
    "STATUS:CONFIRMED",
    "SEQUENCE:0",
    "TRANSP:OPAQUE",
    `SUMMARY:${calendarEvent.name}`,
    `LOCATION:${calendarEvent.location}`,
    `DESCRIPTION:${descriptionText.replace(/\n/g, "\\n")}`,
    `X-ALT-DESC;FMTTYPE=text/html:${description.replace(/\n/g, "\\n")}`,
    "END:VEVENT",
    "END:VCALENDAR",
  ];
  return fileLines.join("\r\n");
};

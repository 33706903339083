import { makeStyles, shorthands, tokens, zIndexes } from "../shared";
import { Breakpoint, mediaQueryBreakpointDown } from "./Grid";

export const webinarInstancesStyles = makeStyles({
  root: {
    marginLeft: "5px",
    marginRight: "5px",
  },
  cardArea: {
    zIndex: zIndexes.foreground,
  },
  headerWrapper: {
    display: "flex",
    "> :not(:last-child)": {
      marginRight: "15px",
    },
    [mediaQueryBreakpointDown(Breakpoint.Medium)]: {
      display: "flex",
      flexDirection: "column",
      "> :not(:last-child)": {
        marginBottom: "15px",
      },
    },
  },
  dropInBackground: {
    height: "30px",
    width: "70px",
    backgroundColor: tokens.colorNeutralBackground6,
    ...shorthands.borderRadius("2px"),
  },
  continueToRegistrationButton: {
    maxWidth: "300px",
  },
  numberCircle: {
    ...shorthands.borderRadius("50%"),
    width: "25px",
    height: "25px",
    backgroundColor: "#fff",
    ...shorthands.border("2px", "solid", "rgb(50, 49, 48)"),
    color: "rgb(50, 49, 48)",
    textAlign: "center",
  },
  registrationForm: {
    paddingTop: "20px",
    paddingBottom: "50px",
  },
});

import { makeStyles, tokens } from "../shared";
import { Breakpoint, mediaQueryBreakpointDown } from "./Grid";

export const timeDisplayStyles = makeStyles({
  root: {
    fontSize: tokens.fontSizeBase400, //16px
    lineHeight: tokens.lineHeightBase400,
    color: tokens.colorNeutralForeground2, //#242424
    marginTop: "0px",
    [mediaQueryBreakpointDown(Breakpoint.Medium)]: {
      fontSize: "17px",
    },
  },
  speakerSessionTime: {
    fontSize: tokens.fontSizeBase200,
    lineHeight: tokens.lineHeightBase200,
  },
  mobileDisplay: {
    fontSize: "17px",
    lineHeight: tokens.lineHeightBase400,
    color: tokens.colorNeutralForeground1, //#242424
  },
  weightRegular: {
    fontWeight: tokens.fontWeightRegular,
  },
  weightMedium: {
    fontWeight: tokens.fontWeightMedium,
  },
  weightSemiBold: {
    fontWeight: tokens.fontWeightSemibold,
  },
  weightBold: {
    fontWeight: tokens.fontWeightBold,
  },
});

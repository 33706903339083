import { v4 as uuidv4 } from "uuid";

import { merge } from "lodash";
import {
  ILogger,
  IScenarioEventData,
  IScenarioLogger,
  IScenarioLoggerJsonObject,
  LoggerLevels,
  ScenarioName,
} from "./interface";

export enum ScenarioStep {
  Begin = "Begin",
  End = "End",
}

export class ScenarioLogger implements IScenarioLogger {
  public readonly id: string;
  public readonly name: ScenarioName;
  private eventData: IScenarioEventData;
  private logger: ILogger;
  private sequence: number;
  private isScenarioComplete: boolean;
  private intervalTimestamp: number;
  private relativeTimestamp: number;

  constructor(
    logger: ILogger,
    name: ScenarioName,
    eventData?: Partial<IScenarioEventData>
  ) {
    this.id = uuidv4();
    this.name = name;
    this.logger = logger;
    this.sequence = 0;
    this.isScenarioComplete = false;
    this.intervalTimestamp = this.relativeTimestamp = performance.now();
    this.eventData = {
      id: this.id,
      delta: 0,
      elapsed: 0,
      message: "",
      sequence: this.sequence,
      status: "",
      step: "",
      stepDelta: 0,
    };

    try {
      performance.mark(`${this.name}-${ScenarioStep.Begin}`);
    } catch (err) {
      // Do nothing
    }

    this.createMark(ScenarioStep.Begin, "init", eventData, false);
  }

  public static fromJson(
    logger: ILogger,
    scenarioLoggerJsonObject: IScenarioLoggerJsonObject
  ): IScenarioLogger | null {
    try {
      const scenario = Object.setPrototypeOf(
        {
          ...scenarioLoggerJsonObject,
          logger,
        },
        ScenarioLogger.prototype
      );
      return scenario;
    } catch (error) {
      return null;
    }
  }

  public isScenarioStopped(): boolean {
    return this.isScenarioComplete;
  }

  public mark(
    step: string,
    status: string,
    eventData?: Partial<IScenarioEventData>
  ): void {
    this.createMark(step, status, eventData, false);
  }

  public stop(eventData?: Partial<IScenarioEventData>): void {
    try {
      performance.mark(`${this.name}-${ScenarioStep.End}`);
      performance.measure(
        this.name,
        `${this.name}-${ScenarioStep.Begin}`,
        `${this.name}-${ScenarioStep.End}`
      );
    } catch (err) {
      // Do nothing
    }

    this.createMark(
      ScenarioStep.End,
      (eventData && eventData.status) || "success",
      eventData,
      true
    );

    this.logger.logAction(this.name, this.name, "success", {
      ...this.eventData,
    });
  }

  public fail(eventData?: Partial<IScenarioEventData>): void {
    this.createMark(ScenarioStep.End, "failed", eventData, true);
    this.logger.logAction(this.name, this.name, "failure", {
      ...this.eventData,
    });
  }

  private createMark(
    step: string,
    status: string,
    eventData: Partial<IScenarioEventData> | undefined,
    completeScenario: boolean
  ): void {
    if (!this.isScenarioComplete) {
      this.isScenarioComplete = completeScenario;

      const timestamp = performance.now();

      if (eventData) {
        this.eventData = merge(this.eventData, eventData);
      }

      this.eventData.id = this.id;
      this.eventData.step = step;
      this.eventData.status = status;
      this.eventData.sequence = this.sequence;
      this.eventData.delta = timestamp - this.relativeTimestamp;
      this.eventData.stepDelta = timestamp - this.intervalTimestamp;
      this.eventData.elapsed = timestamp;

      this.logger.logScenario(this.name, this.eventData, completeScenario);
      this.intervalTimestamp = timestamp;
      this.sequence += 1;
    } else {
      this.logger.logTrace(
        LoggerLevels.warn,
        `Scenario: ${this.id}, Step: ${step} of ${this.id} ${this.name} is being called after scenario has completed.`
      );
    }
  }

  public toJSON(): IScenarioLoggerJsonObject {
    return {
      id: this.id,
      name: this.name,
      eventData: this.eventData,
      sequence: this.sequence,
      isScenarioComplete: this.isScenarioComplete,
      intervalTimestamp: this.intervalTimestamp,
      relativeTimestamp: this.relativeTimestamp,
    };
  }
}

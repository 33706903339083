import React from "react";
import { mergeClasses, SkeletonV9 as Skeleton } from "../../shared";
import { sessionPageStyles } from "../../styles/SessionPage";
import { gridStyles } from "../../styles/Grid";
import { flexAlignStyles, flexStyles } from "../../styles/FlexStyles";
import { ButtonV9 as Button, TextV9 as Text } from "../../shared";
import { buttonStyles } from "../../styles/ButtonStyles";
import { portalTextStyles } from "../../styles/PortalText";
import { eventPageStyles } from "../../styles/EventPage";
import { EventInfoLoading } from "./EventInfoLoading";
import { RegistrationCardLoading } from "./RegistrationCardLoading";
export const SessionPageLoading: React.FunctionComponent = () => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const gridClasses = gridStyles();
  const eventPageClasses = eventPageStyles();
  const sessionPageClasses = sessionPageStyles();
  const buttonClasses = buttonStyles();
  const portalTextClasses = portalTextStyles();
  const sessionPageLoading = (
    <Skeleton data-testid="session-page-loading-component" animation="pulse">
      <div className="session-page-loading-wrapper">
        <div className={mergeClasses(sessionPageClasses.sessionHeader)}>
          <div
            className={mergeClasses(
              gridClasses.container,
              flexClasses.root,
              flexClasses.rowGap24px
            )}
          >
            <div
              className={mergeClasses(
                flexClasses.root,
                flexClasses.column,
                flexClasses.columnGapMedium
              )}
            >
              <Button
                appearance="transparent"
                className={mergeClasses(
                  sessionPageClasses.backToEventButton,
                  flexAlignClasses.justifyContentStart,
                  buttonClasses.primaryWithText
                )}
              ></Button>
              <Text
                as="h1"
                className={mergeClasses(
                  portalTextClasses.xLarge,
                  portalTextClasses.eventTitle,
                  eventPageClasses.eventTitle
                )}
              ></Text>
            </div>
          </div>
        </div>
        <div
          className={mergeClasses(
            gridClasses.container,
            sessionPageClasses.container
          )}
        >
          <div className={gridClasses.row}>
            <div
              className={mergeClasses(
                gridClasses.col,
                gridClasses.xs12,
                gridClasses.lg8
              )}
            >
              <EventInfoLoading />
            </div>
            <div
              className={mergeClasses(
                gridClasses.col,
                gridClasses.hiddenMdDown,
                gridClasses.xs4
              )}
            >
              <RegistrationCardLoading />
            </div>
          </div>
        </div>
      </div>
    </Skeleton>
  );
  return sessionPageLoading;
};

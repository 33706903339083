import { AXHelper } from "../api/axHelper";
// import {Guid} from './CMDTypes'; /* will need soon */
import { EventUser } from "./userTypes.interface";
import { meRoute } from "./CMDRoutes";
import { PortalAxiosError } from "../api/PortalAxiosError";
import { IScenarioLogger } from "../../common/logger/interface";

export async function fetchMe(
  createUser: boolean = false,
  scenario?: IScenarioLogger
): Promise<EventUser> {
  try {
    const response: EventUser = await AXHelper.putDataToServer(
      meRoute,
      {},
      undefined,
      scenario
    );
    return response;
  } catch (error) {
    if (
      createUser &&
      error instanceof PortalAxiosError &&
      (error.response?.status === 404 || error.response?.status === 500)
    ) {
      return AXHelper.postDataToServer(meRoute, {}, undefined, scenario);
    } else {
      throw error;
    }
  }
}

export async function postMe(scenario?: IScenarioLogger): Promise<EventUser> {
  return AXHelper.postDataToServer(meRoute, {}, undefined, scenario);
}

export async function deleteMe(scenario?: IScenarioLogger): Promise<EventUser> {
  return AXHelper.deleteDataFromServer(meRoute, undefined, scenario);
}

export async function patchMe(
  data: EventUser,
  scenario?: IScenarioLogger
): Promise<EventUser> {
  return AXHelper.patchDataToServer(meRoute, data, undefined, scenario);
}

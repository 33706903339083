import { makeStyles } from "../shared";

export const flexStyles = makeStyles({
  root: {
    display: "flex",
  },
  block: {
    display: "block",
  },
  column: {
    flexDirection: "column",
  },
  wrap: {
    flexWrap: "wrap",
  },
  columnGap2px: {
    "> :not(:last-child)": {
      marginBottom: "2px",
    },
  },
  columnGap4px: {
    "> :not(:last-child)": {
      marginBottom: "4px",
    },
  },
  columnGapSmaller: {
    "> :not(:last-child)": {
      marginBottom: "8px",
    },
  },
  columnGapSmall: {
    "> :not(:last-child)": {
      marginBottom: "10px",
    },
  },
  columnGapMedium: {
    "> :not(:last-child)": {
      marginBottom: "15px",
    },
  },
  columnGap12px: {
    "> :not(:last-child)": {
      marginBottom: "12px",
    },
  },
  columnGap16px: {
    "> :not(:last-child)": {
      marginBottom: "16px",
    },
  },
  columnGap18px: {
    "> :not(:last-child)": {
      marginBottom: "18px",
    },
  },
  columnGap20px: {
    "> :not(:last-child)": {
      marginBottom: "20px",
    },
  },
  columnGap23px: {
    "> :not(:last-child)": {
      marginBottom: "23px",
    },
  },
  columnGapLarge: {
    "> :not(:last-child)": {
      marginBottom: "30px",
    },
  },
  columnGap32px: {
    "> :not(:last-child)": {
      marginBottom: "32px",
    },
  },
  columnGap40px: {
    "> :not(:last-child)": {
      marginBottom: "40px",
    },
  },
  row: {
    flexDirection: "row",
  },
  rowGap2px: {
    "> :not(:last-child)": {
      marginRight: "2px",
    },
  },
  rowGap4px: {
    "> :not(:last-child)": {
      marginRight: "4px",
    },
  },
  rowGapSmaller: {
    "> :not(:last-child)": {
      marginRight: "8px",
    },
  },
  rowGapSmall: {
    "> :not(:last-child)": {
      marginRight: "10px",
    },
  },
  rowGap12px: {
    "> :not(:last-child)": {
      marginRight: "12px",
    },
  },
  rowGapMedium: {
    "> :not(:last-child)": {
      marginRight: "15px",
    },
  },
  rowGap16px: {
    "> :not(:last-child)": {
      marginRight: "16px",
    },
  },
  rowGap20px: {
    "> :not(:last-child)": {
      marginRight: "20px",
    },
  },
  rowGap24px: {
    "> :not(:last-child)": {
      marginRight: "24px",
    },
  },
  fill: {
    width: "100%",
    height: "100%",
  },
  fluid: {
    width: "100%",
    height: "100%",
  },
});

export const flexItemStyles = makeStyles({
  grow: {
    flexGrow: 1,
  },
  shrink: {
    flexShrink: 0,
  },
  rowPush: {
    marginLeft: "auto",
  },
  columnPush: {
    marginTop: "auto",
  },
});

export const flexAlignStyles = makeStyles({
  alignItemStart: {
    alignItems: "start",
  },
  alignItemFlexStart: {
    alignItems: "flex-start",
  },
  alignItemCenter: {
    alignItems: "center",
  },
  alignItemEnd: {
    alignItems: "end",
  },
  alignItemStretch: {
    alignItems: "stretch",
  },
  alignItemBaseLine: {
    alignItems: "baseline",
  },
  justifyContentStart: {
    justifyContent: "start",
  },
  justifyContentCenter: {
    justifyContent: "center",
  },
  justifyContentEnd: {
    justifyContent: "end",
  },
  justifyContentSpaceEvenly: {
    justifyContent: "space-evenly",
  },
  justifyContentSpaceBetween: {
    justifyContent: "space-between",
  },
  justifyContentStretch: {
    justifyContent: "stretch",
  },
  justifyContentLeft: {
    justifyContent: "left",
  },
});

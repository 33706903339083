import { makeStyles, shorthands, tokens } from "../shared";

export const buttonStyles = makeStyles({
  fluid: {
    width: "100%",
    maxWidth: "100%",
  },
  primaryWithText: {
    color: tokens.colorBrandForeground1,
  },
  text: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  largeButton: {
    height: "44px",
  },
  showMoreButton: {
    color: tokens.colorBrandForeground1,
    ...shorthands.margin(0),
    minWidth: "0px",
    fontWeight: tokens.fontWeightSemibold,
    fontSize: tokens.fontSizeBase400, //16px
    lineHeight: tokens.lineHeightBase400, //22px
    ...shorthands.padding(0),
    ":hover": {
      "text-decoration": "underline",
    },
    alignSelf: "flex-start",
  },
  learnMoreButton: {
    color: tokens.colorBrandForeground2,
    ...shorthands.margin(0, 0, "3.5px", "auto"),
    minWidth: "0px",
    fontWeight: tokens.fontWeightRegular,
    fontSize: tokens.fontSizeBase300,
    ...shorthands.padding(0),
    ":hover": {
      "text-decoration": "underline",
    },
  },
});

import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CmdServicesResponseErrorCode } from "../../core/slices/error";
import {
  TextV9 as Text,
  Spinner,
  StartOrEndTimeDisplayOnly,
  mergeClasses,
} from "../../shared";
import { isUserRejectedSelector } from "../../core/slices/eventSlice";
import { flexAlignStyles, flexStyles } from "../../styles/FlexStyles";
import { registrationUnavailableScenarioStyles } from "../../styles/EventRegistration";
import { timeDisplayStyles } from "../../styles/TimeDisplay";

export const RegistrationUnavailableScenarios: React.FunctionComponent<{
  errorCode?: string;
  regStartTime?: string;
}> = ({ errorCode, regStartTime }) => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const timeDisplayClasses = timeDisplayStyles();
  const registrationUnavailableScenarioClasses =
    registrationUnavailableScenarioStyles();
  const { t: i18n } = useTranslation();
  const isUserRejected = useSelector(isUserRejectedSelector);

  let display = null;
  if (errorCode) {
    switch (errorCode) {
      case CmdServicesResponseErrorCode.EXPIRED_EVENT:
        display = (
          <Text id="event-ended-text">{i18n("this_event_ended") + "."}</Text>
        );
        break;
      case CmdServicesResponseErrorCode.REGISTRATION_CLOSED:
      default:
        const currentTime = new Date();
        if (regStartTime) {
          if (currentTime.getTime() < new Date(regStartTime).getTime()) {
            display = (
              <Text id="regitration-not-started-text">
                {i18n("registration_not_started")}&nbsp;
                <StartOrEndTimeDisplayOnly
                  time={regStartTime}
                  className={timeDisplayClasses.root}
                />
              </Text>
            );
          } else {
            display = (
              <Text id="regitration-closed-text">
                {i18n("registration_is_closed") + "."}
              </Text>
            );
          }
        } else {
          display = (
            <div
              className={mergeClasses(
                flexClasses.root,
                flexClasses.row,
                flexAlignClasses.justifyContentCenter,
                registrationUnavailableScenarioClasses.root
              )}
            >
              <Spinner />
            </div>
          );
        }
        break;
    }
  } else if (isUserRejected) {
    display = (
      <Text id="registrationNotApproved">
        {i18n("registration_not_approved")}
      </Text>
    );
  }

  return display;
};

import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { isValidEventIdFormat } from "../utilities/common/utils";
import { PageNotFound } from "./errorPages/ErrorPages";

type ValidateEventIdRouteProps = Omit<RouteProps, "children" | "render">;

export const ValidateEventIdRoute: React.FunctionComponent<
  ValidateEventIdRouteProps
> = (props) => {
  const { component } = props;
  return (
    <Route
      location={props.location}
      path={props.path}
      exact={props.exact}
      sensitive={props.sensitive}
      strict={props.strict}
      render={(routeProps) => {
        if (isValidEventIdFormat(routeProps.match.params.id || "")) {
          return component ? React.createElement(component, routeProps) : null;
        } else {
          return <PageNotFound />;
        }
      }}
    />
  );
};

import { makeStyles } from "../shared";

export const aboutStyles = makeStyles({
  aboutDescriptionInSpan: {
    "& > *:first-child": {
      marginTop: "0px",
    },
    "& > *:last-child": {
      marginBottom: "0px",
    },
  },
});

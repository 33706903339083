import React from "react";
import { mergeClasses, SkeletonItem } from "../../shared";
import { speakerProfileCardStyles } from "../../styles/EventSpeakers";
import { speakerCardStyles } from "../../styles/SpeakerCard";
import {
  flexAlignStyles,
  flexItemStyles,
  flexStyles,
} from "../../styles/FlexStyles";
import { skeletonItemStyles } from "../../styles/SkeletonStyles";

export const SpeakerCardLoading: React.FunctionComponent = () => {
  const flexAlignClasses = flexAlignStyles();
  const flexItemClasses = flexItemStyles();
  const flexClasses = flexStyles();
  const skeletonItemClasses = skeletonItemStyles();
  const speakerCardClasses = speakerCardStyles();
  const speakerProfileCardClasses = speakerProfileCardStyles();
  const speakerCardInfo = (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexClasses.columnGap4px
      )}
    >
      <SkeletonItem
        className={mergeClasses(
          skeletonItemClasses.root,
          skeletonItemClasses.height24,
          skeletonItemClasses.width150,
          speakerCardClasses.infoHeader
        )}
      />

      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.column,
          flexClasses.fill
        )}
      >
        <SkeletonItem
          className={mergeClasses(
            skeletonItemClasses.root,
            skeletonItemClasses.height24,
            skeletonItemClasses.width200
          )}
        />
        <SkeletonItem
          className={mergeClasses(
            skeletonItemClasses.root,
            skeletonItemClasses.height24,
            skeletonItemClasses.width200
          )}
        />
      </div>
    </div>
  );

  const speakerCardLoadingDisplay = (
    <div
      data-testid="speaker-card-loading-component"
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexClasses.columnGapMedium
      )}
    >
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.rowGapMedium,
          flexAlignClasses.alignItemEnd
        )}
      >
        <SkeletonItem
          className={mergeClasses(
            skeletonItemClasses.root,
            skeletonItemClasses.textSize600,
            skeletonItemClasses.width150
          )}
        />
      </div>
      <div
        className={mergeClasses(
          "speaker-profile-card",
          speakerProfileCardClasses.root
        )}
      >
        <div
          className={mergeClasses(
            "speaker-profile",
            flexClasses.root,
            flexClasses.rowGapMedium
          )}
        >
          <div
            className={mergeClasses(
              flexClasses.root,
              flexClasses.fill,
              flexClasses.rowGap20px,
              flexAlignClasses.alignItemCenter
            )}
          >
            <SkeletonItem
              shape="circle"
              className={mergeClasses(
                skeletonItemClasses.root,
                speakerCardClasses.avatar,
                flexItemClasses.shrink
              )}
            />
            <div
              className={mergeClasses(
                flexClasses.root,
                flexClasses.column,
                flexClasses.columnGapSmaller,
                flexClasses.fill
              )}
            >
              {speakerCardInfo}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return speakerCardLoadingDisplay;
};

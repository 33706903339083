import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  userSelector,
  downloadUserDataAsyncAction,
  socialLoginSelector,
  deleteMeAsyncAction,
  getMeAsyncAction,
} from "../../core/slices/userSlice";
import { EventUser } from "../../core/slices/userTypes.interface";
import {
  ButtonV9 as Button,
  AvatarV9 as Avatar,
  mergeClasses,
  TextV9 as Text,
  PersonRegular,
  DialogContentV9 as DialogContent,
  DialogTitleV9 as DialogTitle,
  DialogV9 as Dialog,
  DialogSurfaceV9 as DialogSurface,
  DialogBodyV9 as DialogBody,
  DialogActionsV9 as DialogActions,
} from "../../shared";
import { profileAccountSettingsStyles } from "../../styles/ProfileAccountSettings";
import { profilePageStyles } from "../../styles/ProfilePage";
import { ProfilePageHeaderMobile } from "./ProfilePageHeaderMobile";
import { useBreakpoint } from "../../utilities/hooks/useBreakpoints";
import { imageSelector } from "../../core/slices/imageSlice";
import { UserBIScenario } from "../../common/logger/Logger";
import { useLogger } from "../../common/logger/LoggerContext";
import { useAuthenticationService } from "../../core/auth/auth-context";
import { LoginState } from "../../core/auth/authenticationService.interface";
import { useHistory, useLocation } from "react-router-dom";
import { LoggerLevels } from "../../common/logger/interface";
import { Breakpoint, gridStyles } from "../../styles/Grid";
import {
  getErrorResponse,
  isErrorResponsePopupWindowError,
} from "../../core/slices/error";
import { flexStyles } from "../../styles/FlexStyles";
import { portalTextStyles } from "../../styles/PortalText";
import { portalResponsiveDialogStyles } from "../../styles/PortalResponsiveDialog";
import { buttonStyles } from "../../styles/ButtonStyles";
import { generateSearchParams } from "../../utilities/common/generateSearchParams";
import { PortalLocationState } from "../../core/history/history.interface";

export const ProfileAccountSettings: React.FunctionComponent = () => {
  const user = useSelector(userSelector);

  if (!user) {
    // TODO: have loading spinner until user is fetched?
    return null;
  }

  return <ProfileAccountSettingsInternal user={user} />;
};

const ProfileAccountSettingsInternal: React.FunctionComponent<{
  user: EventUser;
}> = ({ user }) => {
  const flexClasses = flexStyles();
  const gridClasses = gridStyles();
  const portalTextClasses = portalTextStyles();
  const profileAccountSettingsClasses = profileAccountSettingsStyles();
  const profilePageClasses = profilePageStyles();
  const { t: i18n } = useTranslation();
  const dispatch = useDispatch();
  const { authenticationService } = useAuthenticationService();
  const logger = useLogger()?.logger;
  const { isBreakpointAndDown } = useBreakpoint();
  const mobileView = isBreakpointAndDown(Breakpoint.Medium);
  const profileImage = useSelector(imageSelector(user && user.profileImage));
  const isUserLoggedInViaSocial = useSelector(socialLoginSelector);
  const history = useHistory<PortalLocationState>();
  const location = useLocation<PortalLocationState>();
  /* eslint-disable no-console, @typescript-eslint/no-unused-vars*/
  const [popupErrorDialogIsVisible, setPopupErrorDialogIsVisible] =
    useState(false);

  const onClickExportAccount = () => {
    logger?.logUiTelemetry(
      "exportAccount",
      UserBIScenario.ExportAccount,
      "click",
      "none",
      "ProfilePage"
    );
    dispatch(downloadUserDataAsyncAction(false));
  };
  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);

  const onClickDeleteAccount = () => {
    logger?.logUiTelemetry(
      "deleteAccount",
      UserBIScenario.DeleteAccount,
      "click",
      "none",
      "ProfilePage"
    );
    setShowConfirmationDialog(true);
  };

  const onDismissConfirmationDialog = () => {
    setShowConfirmationDialog(false);
  };

  const onDeleteCallback = () => {
    dispatch(getMeAsyncAction(true));
    history.replace({
      pathname: "/user-deleted",
      search: generateSearchParams(location),
    });
  };

  /* eslint-disable no-console, @typescript-eslint/no-explicit-any*/
  const onDeleteFailureCallback = (err: any) => {
    logger?.logTrace(
      LoggerLevels.error,
      `Deleting social user failed with error ${err}`
    );
    const errorResponse = getErrorResponse("deleteAccount", err);
    const isPopupError = isErrorResponsePopupWindowError(errorResponse);

    if (isPopupError) {
      setPopupErrorDialogIsVisible(true);
    }
  };
  const onClickConfirmDeleteAccount = async () => {
    // TODO: Move this to user slice as action.
    // Call service to delete user from cosmosDB
    dispatch(deleteMeAsyncAction());
    await authenticationService.delete(LoginState.Social).catch((err: any) => {
      dispatch(getMeAsyncAction(true));
      authenticationService
        .logout()
        .then(() => onDeleteCallback())
        .catch((err: any) => onDeleteFailureCallback(err));
    });
  };
  /* eslint-enable no-console, @typescript-eslint/no-explicit-any */

  const profile = (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexClasses.columnGapMedium
      )}
    >
      <Avatar
        image={
          /* istanbul ignore next */
          profileImage ? { src: profileImage } : undefined
        }
        name={user?.displayName || /* istanbul ignore next */ undefined}
        size={96}
        icon={<PersonRegular />}
        className={profileAccountSettingsClasses.avatar}
      />
      <div className={mergeClasses(flexClasses.root, flexClasses.column)}>
        <Text weight="bold"> {user.displayName} </Text>
        <Text
          className={mergeClasses(
            portalTextClasses.small,
            profileAccountSettingsClasses.emailText
          )}
        >
          {user.email}
        </Text>
      </div>
    </div>
  );

  const exportAccount = (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexClasses.columnGapMedium
      )}
    >
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.column,
          flexClasses.columnGapSmall
        )}
      >
        <Text className={portalTextClasses.medium2} weight="semibold">
          {i18n("account_settings_export_account_header")}
        </Text>
        <Text>{i18n("account_settings_export_account_description")}</Text>
      </div>
      <div>
        <Button onClick={onClickExportAccount}>
          {i18n("account_settings_export_account_button")}
        </Button>
      </div>
    </div>
  );

  const deleteAccount = (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexClasses.columnGapMedium
      )}
    >
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.column,
          flexClasses.columnGapSmall
        )}
      >
        <Text className={portalTextClasses.medium2} weight="semibold">
          {i18n("account_settings_delete_account_header")}
        </Text>
        <Text>{i18n("account_settings_delete_account_description")}</Text>
      </div>
      <div>
        <Button
          onClick={onClickDeleteAccount}
          className={profileAccountSettingsClasses.deleteButton}
        >
          {i18n("account_settings_delete_account_button")}
        </Button>
        <ConfirmationDialog
          hidden={!showConfirmationDialog}
          onDismiss={onDismissConfirmationDialog}
          onClickDeleteAccount={onClickConfirmDeleteAccount}
        />
      </div>
    </div>
  );

  return (
    <>
      <div
        className={mergeClasses(
          "profile-account-settings",
          flexClasses.root,
          flexClasses.column,
          flexClasses.fill
        )}
      >
        {mobileView && (
          <ProfilePageHeaderMobile
            header={i18n("account_settings_header")}
            backPath="/profile"
          />
        )}
        <div
          className={mergeClasses(
            "profile-page-container",
            gridClasses.container,
            profilePageClasses.container
          )}
        >
          <div className={gridClasses.row}>
            <div
              className={mergeClasses(
                gridClasses.col,
                gridClasses.xs12,
                gridClasses.lg11,
                gridClasses.xl10
              )}
            >
              <div
                className={mergeClasses(
                  flexClasses.root,
                  flexClasses.column,
                  flexClasses.columnGapLarge
                )}
              >
                <Text
                  className={mergeClasses(
                    gridClasses.hiddenMdDown,
                    portalTextClasses.large,
                    profileAccountSettingsClasses.header
                  )}
                  as="h1"
                  weight="bold"
                >
                  {i18n("account_settings_header")}
                </Text>
                {profile}
                {isUserLoggedInViaSocial && exportAccount}
                {isUserLoggedInViaSocial && deleteAccount}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ConfirmationDialog: React.FunctionComponent<{
  hidden: boolean;
  onDismiss: () => void;
  onClickDeleteAccount: () => void;
}> = ({ hidden, onDismiss, onClickDeleteAccount }) => {
  const flexClasses = flexStyles();
  const portalResponsiveDialogClasses = portalResponsiveDialogStyles();
  const buttonClasses = buttonStyles();
  const { t: i18n } = useTranslation();
  return (
    <Dialog open={!hidden} onOpenChange={onDismiss}>
      <DialogSurface
        className={mergeClasses(
          flexClasses.root,
          flexClasses.column,
          portalResponsiveDialogClasses.dialogSurface
        )}
      >
        <DialogBody
          className={mergeClasses(
            flexClasses.root,
            flexClasses.column,
            portalResponsiveDialogClasses.dialogBody
          )}
        >
          <DialogTitle>
            {i18n("account_settings_delete_account_dialog_header")}
          </DialogTitle>
          <DialogContent>
            {i18n("account_settings_delete_account_dialog_description")}
          </DialogContent>
          <DialogActions
            className={portalResponsiveDialogClasses.dialogActions}
          >
            <Button
              appearance="secondary"
              className={buttonClasses.fluid}
              onClick={onClickDeleteAccount}
            >
              {i18n("account_settings_delete_account_dialog_delete_button")}
            </Button>
            <Button
              appearance="primary"
              className={buttonClasses.fluid}
              onClick={onDismiss}
              aria-label={i18n("no_keep_registration")}
            >
              {i18n("account_settings_delete_account_dialog_cancel_button")}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

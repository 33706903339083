import { AXHelper } from "../api/axHelper";
import { EventId } from "./CMDTypes";
import { IEventSponsorResponse, IEventSponsorOrder } from "./sponsor.interface";
import { eventsRoute } from "./CMDRoutes";
import { IScenarioLogger } from "../../common/logger/interface";
import {
  getEventTenantId,
  isValidEventIdFormat,
} from "../../utilities/common/utils";
import { InvalidEventIdFormatError } from "./InvalidEventIdFormatError";

export async function fetchSponsors(
  eventId: EventId,
  scenario?: IScenarioLogger
): Promise<IEventSponsorResponse> {
  if (!isValidEventIdFormat(eventId)) {
    throw new InvalidEventIdFormatError(eventId);
  }
  const eventTenantId = getEventTenantId(eventId);
  const route: string = `${eventsRoute}/${eventId}/sponsors`;
  return AXHelper.getDataFromServer(route, eventTenantId, scenario);
}

export async function fetchSponsorsOrder(
  eventId: EventId,
  scenario?: IScenarioLogger
): Promise<IEventSponsorOrder> {
  if (!isValidEventIdFormat(eventId)) {
    throw new InvalidEventIdFormatError(eventId);
  }
  const eventTenantId = getEventTenantId(eventId);
  const route: string = `${eventsRoute}/${eventId}/sponsors/order`;
  return AXHelper.getDataFromServer(route, eventTenantId, scenario);
}

/**
 * Result used to indicate that the command succeeded.
 */
export declare interface ISuccessResult {
  /**
   * The type of the result. Should be 'success'.
   */
  result: 'success';
}

/**
 * Result used to indicate that command failed.
 */
export declare interface IErrorResult {
  /**
   * The type of the result. Should be 'error'.
   */
  result: 'error';

  /**
   * Whether the error should be considered expected or unexpected. Used as part of measuring the reliability of the command.
   */
  isExpected: boolean;

  /**
   * Detailed error information.
   */
  error: {
    /**
     * Short error code. Used to group similar errors for telemetry purposes. Will be logged and should not contain PII.

     */
    code: string;
    /**
     * Detailed error message. Will be logged and should not contain PII.

     */
    message: string;
  };
}

/**
 * Extended error type with data for Quality of Service tracking.
 */
export type IQosError = Error & {
  /**
   * Error code for this error. Defaults to the `.name` property.
   */
  code?: string;
  /**
   * Whether or not this error was expected. If not provided, defaults to `false`.
   */
  isExpected?: boolean;
};

/**
 * Error codes that the hosts/clients are expected to recognize. Consumers may provide additional codes,
 * but should use codes in this set when they apply.
 */
export const ErrorCodes = {
  /**
   * Indicates that the received command is unknown.
   */
  unsupportedCommand: 'unsupportedCommand',

  /**
   * Indicates that the command handler threw an error.
   */
  handlerError: 'handlerError',

  /**
   * Indicates that the command handler rejected the promise, but did not specify an error.
   */
  handlerRejection: 'handlerRejection',

  /**
   * Indicates that the communication layer timed out while waiting for an acknowledgement.
   */
  acknowledgeTimeout: 'acknowledgeTimeout',

  /**
   * Indicates that the communication layer timed out while waiting for a result.
   */
  resultTimeout: 'resultTimeout',

  /**
   * Indicates that the communication layer timed out during initialization.
   */
  setupTimeout: 'setupTimeout',

  /**
   * Indicates that the promise was canceled.
   */
  canceled: 'canceled',

  /**
   * Indicates that a method was invoked on a disposed object.
   */
  disposed: 'disposed'
  // ... TBD
} as const;

export type ErrorCodes = typeof ErrorCodes[keyof typeof ErrorCodes];

/**
 * A result from a command.
 */
export type IResult = ISuccessResult | IErrorResult;

import {
  IEventRegister,
  IEvent,
  PredefinedQuestionsInternal,
  UserRegistrationStatus,
} from "../../core/slices/eventTypes.interface";

export enum RegistrationState {
  OPEN = "Open",
  FULL = "Full",
  FULL_WAITLIST_OPEN = "Full - Waitlist Open",
  FULL_WAITLIST_CLOSED = "Full - Waitlist Closed",
  OPEN_WITH_VOD = "Open With VOD",
  EVENT_ENDED = "Event Ended",
  REGISTRATION_NOT_STARTED = "Registration Not Started",
  REGISTRATION_ENDED = "Registration Ended",
  DRAFT = "Draft",
}

export enum WebinarLifecycle {
  USER_COMPLETED_REGISTRATION = "User Completed Registration",
  FIFTEEN_MINUTES = "Fifteen Minutes",
  WEBINAR_ENDED = "Webinar Ended",
  DEFAULT = "Default",
  USER_REJECTED = "Rejected",
}

export const getRegistrationButtonState: (
  eventDetails?: IEvent,
  registrationDetails?: IEventRegister
) => RegistrationState = (eventDetails, registrationDetails) => {
  if (
    (eventDetails && eventDetails.state === "Draft") ||
    !registrationDetails ||
    !eventDetails
  ) {
    return RegistrationState.DRAFT;
  }

  const currentTime = new Date();
  const eventEndTime = new Date(eventDetails?.eventTime?.endTime);
  const hasEventEnded: boolean = currentTime.getTime() > eventEndTime.getTime();

  if (registrationDetails?.registrationProperties.registrationOpenTime) {
    const registrationStartTime = new Date(
      registrationDetails.registrationProperties.registrationOpenTime.startTime
    );
    const registrationEndTime = new Date(
      registrationDetails.registrationProperties.registrationOpenTime.endTime
    );
    if (currentTime.getTime() >= registrationEndTime.getTime()) {
      return RegistrationState.REGISTRATION_ENDED;
    } else if (currentTime.getTime() < registrationStartTime.getTime()) {
      return RegistrationState.REGISTRATION_NOT_STARTED;
    }
  }

  if (
    registrationDetails &&
    !hasEventEnded &&
    registrationDetails.registrationProperties.isFull &&
    registrationDetails.registrationProperties.isWaitlistEnabled &&
    !registrationDetails.registrationProperties.isWaitlistFull
  ) {
    return RegistrationState.FULL_WAITLIST_OPEN;
  } else if (
    registrationDetails &&
    !hasEventEnded &&
    registrationDetails.registrationProperties.isFull &&
    registrationDetails.registrationProperties.isWaitlistEnabled &&
    registrationDetails.registrationProperties.isWaitlistFull
  ) {
    return RegistrationState.FULL_WAITLIST_CLOSED;
  } else if (
    registrationDetails &&
    !hasEventEnded &&
    registrationDetails.registrationProperties.isFull &&
    !registrationDetails.registrationProperties.isWaitlistEnabled
  ) {
    return RegistrationState.FULL;
  } else if (hasEventEnded) {
    return RegistrationState.EVENT_ENDED;
  } else {
    return RegistrationState.OPEN;
  }
};

export const getWebinarLifecyleState: (
  eventDetails: IEvent,
  registrationStatus: string
) => WebinarLifecycle = (eventDetails, registrationStatus) => {
  const currentTime = new Date();
  const eventStartTime = new Date(eventDetails?.eventTime?.startTime);

  const eventEndTime = new Date(eventDetails?.eventTime?.endTime);
  const hasEventEnded: boolean = currentTime.getTime() > eventEndTime.getTime();
  const fifteenMinutes = 15 * 60 * 1000;
  if (
    !hasEventEnded &&
    currentTime.getTime() < eventStartTime.getTime() &&
    eventStartTime.getTime() - currentTime.getTime() <= fifteenMinutes &&
    registrationStatus === UserRegistrationStatus.REGISTERED
  ) {
    return WebinarLifecycle.FIFTEEN_MINUTES;
  } else if (hasEventEnded) {
    return WebinarLifecycle.WEBINAR_ENDED;
  } else if (
    registrationStatus === UserRegistrationStatus.REGISTERED &&
    currentTime.getTime() >= eventStartTime.getTime()
  ) {
    return WebinarLifecycle.DEFAULT;
  } else if (registrationStatus === UserRegistrationStatus.REJECTED) {
    return WebinarLifecycle.USER_REJECTED;
  }
  return WebinarLifecycle.USER_COMPLETED_REGISTRATION;
};

/* istanbul ignore next */
export const isPredefinedQuestion: (internalName: string) => boolean = (
  internalName
) => {
  return !!(
    internalName === PredefinedQuestionsInternal.Address ||
    internalName === PredefinedQuestionsInternal.City ||
    internalName === PredefinedQuestionsInternal.Country ||
    internalName === PredefinedQuestionsInternal.Industry ||
    internalName === PredefinedQuestionsInternal.JobTitle ||
    internalName === PredefinedQuestionsInternal.Organization ||
    internalName === PredefinedQuestionsInternal.State ||
    internalName === PredefinedQuestionsInternal.ZipCode
  );
};

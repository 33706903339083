import React from "react";
import {
  ButtonV9 as Button,
  QuestionCircleRegular,
  QuestionCircleFilled,
  bundleIcon,
  MenuV9,
  MenuTriggerV9,
  MenuPopoverV9,
  MenuListV9,
  MenuItemV9,
} from "../shared";

import { useDispatch, useSelector } from "react-redux";
import {
  setDownloadLogsDialogAction,
  setReportProblemDialogAction,
} from "../core/slices/userSlice";
import { useTranslation } from "react-i18next";

import { enableDownloadLogsButtonSelector } from "../core/slices/ecsSlice";
import { isBrbEnabled } from "../configs/brbConfigs";
import { helpMenuButtonStyles } from "../styles/HelpMenuButton";

export const HelpMenuButton: React.FunctionComponent = () => {
  const helpMenuButtonClasses = helpMenuButtonStyles();
  const dispatch = useDispatch();
  const { t: i18n } = useTranslation();

  const enableBrb = isBrbEnabled();
  const enableDownloadLogsButton = useSelector(
    enableDownloadLogsButtonSelector
  );
  const brbMenuItem = enableBrb ? (
    <MenuItemV9 onClick={() => dispatch(setReportProblemDialogAction(true))}>
      {i18n("report_problem_button")}
    </MenuItemV9>
  ) : null;

  const downloadLogsMenuItem = enableDownloadLogsButton ? (
    <MenuItemV9 onClick={() => dispatch(setDownloadLogsDialogAction(true))}>
      {i18n("download_logs_button")}
    </MenuItemV9>
  ) : null;

  const QuestionCircle = bundleIcon(
    QuestionCircleFilled,
    QuestionCircleRegular
  );

  const trigger = (
    <Button
      data-testid="helpButton"
      icon={<QuestionCircle />}
      appearance="transparent"
      className={helpMenuButtonClasses.root}
      aria-label={i18n("help")}
    />
  );

  const showHelp = enableBrb || downloadLogsMenuItem;

  return (
    <>
      {showHelp && (
        <MenuV9
          positioning={{
            position: "below",
            align: "end",
            offset: { mainAxis: 2 },
          }}
        >
          <MenuTriggerV9 disableButtonEnhancement>{trigger}</MenuTriggerV9>
          <MenuPopoverV9>
            <MenuListV9>
              {brbMenuItem}
              {downloadLogsMenuItem}
            </MenuListV9>
          </MenuPopoverV9>
        </MenuV9>
      )}
    </>
  );
};

import { RootState } from "../../core/store/store";
import { Logger } from "../logger/Logger";

export function getSystemInfoText(state: RootState): string {
  const logger = Logger.getInstance();

  let result = "<br>";
  result += `<b>Deployment</b>: ${process.env.REACT_APP_DEPLOYMENT}`;
  result += "<br>";
  result += `<b>Version</b>: ${process.env.__VERSION__}`;
  result += "<br>";
  result += `<b>UserAgent</b>: ${navigator.userAgent}`;
  result += "<br>";
  result += `<b>URL</b>: ${window.location.href}`;
  result += "<br>";
  result += `<b>Event ID</b>: ${state?.event.eventID}`;
  result += "<br>";
  result += `<b>Device OS Name</b>: ${logger.getDeviceInfo().osName}`;
  result += "<br>";
  result += `<b>Device OS Version</b>: ${logger.getDeviceInfo().osVer}`;
  result += "<br>";
  result += `<b>Browser Name</b>: ${logger.getDeviceInfo().browserName}`;
  result += "<br>";
  result += `<b>Browser Version</b>: ${logger.getDeviceInfo().browserVer}`;
  return result;
}

import { makeStyles, shorthands, tokens } from "../shared";

export const profileAccountSettingsStyles = makeStyles({
  header: {
    ...shorthands.margin("0px"),
  },
  avatar: {
    fontWeight: tokens.fontWeightRegular,
    fontSize: "41.1488px",
  },
  emailText: {
    color: tokens.colorNeutralForeground3,
  },
  deleteButton: {
    color: tokens.colorPaletteRedForeground1,
    ...shorthands.borderColor(tokens.colorPaletteRedForeground1),
    ":hover": {
      color: tokens.colorPaletteRedForeground1,
      backgroundColor: tokens.colorPaletteRedBackground1,
      ...shorthands.borderColor(tokens.colorPaletteRedForeground1),
    },
    ":active": {
      backgroundColor: tokens.colorPaletteRedBackground1,
    },
  },
});

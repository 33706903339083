import React from "react";
import { mergeClasses, TextV9 as Text, ButtonV9 as Button } from "../../shared";
import { SessionCardList } from "../SessionCard";
import { IEvent } from "../../core/slices/eventTypes.interface";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { eventSessionsSelector } from "../../core/slices/sessionSlice";
import { IEventSession } from "../../core/slices/session.interface";
import {
  flexAlignStyles,
  flexItemStyles,
  flexStyles,
} from "../../styles/FlexStyles";
import { buttonStyles } from "../../styles/ButtonStyles";
import { useLogger } from "../../common/logger/LoggerContext";
import { UserBIScenario } from "../../common/logger/Logger";

export const Sessions: React.FunctionComponent<{ currentEvent: IEvent }> = ({
  currentEvent,
}) => {
  const flexClasses = flexStyles();
  const sessions: IEventSession[] = useSelector(eventSessionsSelector);
  const logger = useLogger()?.logger;
  const minSessionDisplayCount = 3;
  const [displayCount, setDisplayCount] = React.useState(
    minSessionDisplayCount
  );

  const onClickShowMore = () => {
    setDisplayCount((prevDisplayCount) =>
      prevDisplayCount === minSessionDisplayCount
        ? sessions.length
        : minSessionDisplayCount
    );
    logger?.logUiTelemetry(
      "seeMoreLink",
      UserBIScenario.SeeMoreLink,
      "click",
      "none",
      "SpeakersHeader"
    );
  };

  return sessions.length > 0 ? (
    <div
      id="sessionsSection"
      data-testid="sessionsSection"
      className={mergeClasses(
        "sessionHeader",
        flexClasses.root,
        flexClasses.column,
        flexClasses.columnGap16px
      )}
    >
      {sessions.length > 0 && (
        <SessionsHeader
          sessions={sessions}
          displayCount={displayCount}
          onClickShowMore={onClickShowMore}
        />
      )}
      <SessionCardList sessions={sessions.slice(0, displayCount)} />
    </div>
  ) : null;
};

const SessionsHeader: React.FunctionComponent<{
  sessions: IEventSession[];
  displayCount: number;
  onClickShowMore: () => void;
}> = ({ sessions, displayCount, onClickShowMore }) => {
  const flexAlignClasses = flexAlignStyles();
  const flexItemClasses = flexItemStyles();
  const flexClasses = flexStyles();
  const buttonClasses = buttonStyles();
  const { t: i18n } = useTranslation();
  const numOfSessions = sessions?.length;
  const sessionsText =
    numOfSessions > 0
      ? `${i18n("sessions")} (${numOfSessions})`
      : `${i18n("sessions")} (${i18n("tba")})`;
  return (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.rowGapMedium,
        flexAlignClasses.alignItemEnd
      )}
    >
      <Text
        data-testid="sessionsCount"
        as="h2"
        size={600}
        weight="bold"
        aria-label={sessionsText}
      >
        {sessionsText}
      </Text>
      {numOfSessions > 3 && displayCount >= 3 && (
        <div className={mergeClasses(flexClasses.row, flexItemClasses.rowPush)}>
          <Button
            appearance="transparent"
            data-testid={
              displayCount === sessions.length
                ? "sessionsShowLessButton"
                : "sessionsShowMoreButton"
            }
            onClick={onClickShowMore}
            className={buttonClasses.showMoreButton}
          >
            {displayCount === sessions.length
              ? i18n("see_less")
              : i18n("see_more")}
          </Button>
        </div>
      )}
    </div>
  );
};

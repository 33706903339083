import { makeStyles, tokens } from "../shared";

export const skeletonItemStyles = makeStyles({
  root: {
    margintop: "1px",
    marginBottom: "1px",
  },
  textSize100: {
    height: tokens.lineHeightBase100, //14px
  },
  textSize200: {
    height: tokens.lineHeightBase200, //16px
  },
  textSize18: {
    height: "18px",
  },
  textSize300: {
    height: tokens.lineHeightBase300, //20px
  },
  textSize400: {
    height: tokens.lineHeightBase400, //22px
  },
  height24: {
    height: "24px",
  },
  textSize500: {
    height: tokens.lineHeightBase500, //28px
  },
  textSize600: {
    height: tokens.lineHeightBase600, //32px
  },
  textSize700: {
    height: tokens.lineHeightHero700, //36px
  },
  textSize800: {
    height: tokens.lineHeightHero800, //40px
  },
  textSize900: {
    height: tokens.lineHeightHero900, //52px
  },
  textSize1000: {
    height: tokens.lineHeightHero1000, //92px
  },
  height100: {
    height: "100px",
  },
  width50: {
    maxWidth: "50px",
  },
  width80: {
    maxWidth: "80px",
  },
  width100: {
    maxWidth: "100px",
  },
  width150: {
    maxWidth: "150px",
  },
  width200: {
    maxWidth: "200px",
  },
  width300: {
    maxWidth: "300px",
  },
});

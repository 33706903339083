import React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setVodHintAction } from "../../core/slices/eventSlice";
import { PortalLocationState } from "../../core/history/history.interface";

export const useVodHintSearchParamHandlerHook = (): void => {
  const { search } = useLocation<PortalLocationState>();
  const dispatch = useDispatch();

  const vodHint = new URLSearchParams(search).has("vod");

  React.useEffect(() => {
    dispatch(setVodHintAction(vodHint));
  }, [vodHint, dispatch]);
};

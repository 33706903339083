import { AccountInfo } from "@azure/msal-browser";
import { AXHelper } from "../api/axHelper";
import { EventId } from "./CMDTypes";
import {
  IEventSession,
  IEventSessionResponse,
  ISharingLinkPreviewResponse,
  IRecordingPlaybackInfo,
} from "./session.interface";
import { eventsRoute, recordingsRoute, sessionsRoute } from "./CMDRoutes";
import {
  getEventTenantId,
  isValidEventIdFormat,
} from "../../utilities/common/utils";
import { InvalidEventIdFormatError } from "./InvalidEventIdFormatError";
import { IScenarioLogger } from "../../common/logger/interface";
import { AxiosError } from "axios";
import {
  PortalAxiosError,
  PortalAxiosErrorType,
} from "../api/PortalAxiosError";

export async function fetchSessions(
  eventId: EventId
): Promise<IEventSessionResponse> {
  if (!isValidEventIdFormat(eventId)) {
    throw new InvalidEventIdFormatError(eventId);
  }
  const eventTenantId = getEventTenantId(eventId);
  const route: string = `${eventsRoute}/${eventId}/${sessionsRoute}`;
  return AXHelper.getDataFromServer(route, eventTenantId);
}

export async function fetchSession(
  eventId: EventId,
  sessionId: string
): Promise<IEventSession> {
  if (!isValidEventIdFormat(eventId)) {
    throw new InvalidEventIdFormatError(eventId);
  }
  const eventTenantId = getEventTenantId(eventId);
  const route: string = `${eventsRoute}/${eventId}/${sessionsRoute}/${sessionId}`;
  return AXHelper.getDataFromServer(route, eventTenantId);
}

export async function fetchRecordingPlaybackInfo(
  eventId: EventId,
  sessionId: string,
  recordingId: string,
  registrationId: string | undefined,
  presenterKey: string | undefined,
  // to be removed, if new svc version is not fully deployed yet
  badgerToken: string | undefined,
  scenario: IScenarioLogger | null
): Promise<IRecordingPlaybackInfo> {
  if (!isValidEventIdFormat(eventId)) {
    throw new InvalidEventIdFormatError(eventId);
  }
  const eventTenantId = getEventTenantId(eventId);
  let route: string = `${eventsRoute}/${eventId}/${sessionsRoute}/${sessionId}/${recordingsRoute}/${recordingId}/playback`;
  const params = new URLSearchParams();
  if (registrationId && !presenterKey) {
    params.append("registrationId", registrationId);
  }
  if (presenterKey) {
    params.append("presenterKey", presenterKey);
  }
  // to be removed, if new svc version is not fully deployed yet
  if (badgerToken) {
    params.append("badgerToken", badgerToken);
  }
  params.append("redeem", "false");
  if (Array.from(params).length > 0) {
    route += "?" + params.toString();
  }
  return AXHelper.getDataFromServer<IRecordingPlaybackInfo>(
    route,
    eventTenantId,
    scenario ?? undefined
  );
}

export async function redeemFile(
  redeemUrl: string,
  badgerToken: string | undefined,
  account: AccountInfo | null,
  scenario: IScenarioLogger | null
): Promise<ISharingLinkPreviewResponse> {
  const axHelper: AXHelper = AXHelper.getAXHelper();
  let authHeader = {};
  if (account) {
    authHeader = await axHelper.makeTokenAuthHeaderForOrigin(
      account,
      new URL(redeemUrl).origin
    );
  } else if (badgerToken) {
    authHeader = axHelper.makeBadgerAuthHeader(badgerToken);
  }
  const config = {
    headers: {
      Prefer: "redeemSharingLink",
      ...authHeader,
    },
  };

  try {
    const response = await AXHelper.post<ISharingLinkPreviewResponse>(
      "redeemFile",
      redeemUrl,
      undefined,
      config,
      undefined,
      scenario || undefined
    );
    return response.data;
  } catch (err) {
    if (err instanceof AxiosError) {
      throw PortalAxiosError.fromAxiosError(PortalAxiosErrorType.ODSP, err);
    }
    throw err;
  }
}

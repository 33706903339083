import { makeStyles } from "../shared";
import {
  Breakpoint,
  mediaQueryBreakpointDown,
  mediaQueryBreakpointUp,
} from "./Grid";

export const errorPageStyles = makeStyles({
  authErrorImage: {
    maxWidth: "148px",
    maxHeight: "148px",
    marginBottom: "32px",
    [mediaQueryBreakpointUp(Breakpoint.Medium)]: {
      maxWidth: "176px",
      maxHeight: "176px",
    },
  },
  eventCancelledErrorImage: {
    maxWidth: "148px",
    maxHeight: "118px",
    marginBottom: "32px",
    [mediaQueryBreakpointUp(Breakpoint.Medium)]: {
      maxWidth: "176px",
      maxHeight: "141px",
    },
  },
  pageNotFoundImage: {
    maxWidth: "148px",
    maxHeight: "148px",
    marginBottom: "32px",
    [mediaQueryBreakpointUp(Breakpoint.Medium)]: {
      maxWidth: "176px",
      maxHeight: "176px",
    },
  },
  pageTooManyRequestsImage: {
    maxWidth: "148px",
    maxHeight: "148px",
    marginBottom: "32px",
    [mediaQueryBreakpointUp(Breakpoint.Medium)]: {
      maxWidth: "176px",
      maxHeight: "176px",
    },
  },
  eventFetchUnsuccessfulImage: {
    maxWidth: "148px",
    maxHeight: "148px",
    marginBottom: "32px",
    [mediaQueryBreakpointUp(Breakpoint.Medium)]: {
      maxWidth: "196px",
      maxHeight: "209px",
    },
  },
  signInbutton: {
    marginTop: "24px",
    maxWidth: "343px",
    [mediaQueryBreakpointDown(Breakpoint.Medium)]: {
      height: "44px",
      paddingLeft: "60px",
      paddingRight: "60px",
    },
  },
  authErrorContainer: {
    marginTop: "92px",
    [mediaQueryBreakpointUp(Breakpoint.Medium)]: {
      marginTop: "192px",
    },
  },
  mobileTextPadding: {
    [mediaQueryBreakpointDown(Breakpoint.Medium)]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
  authUnsuccessfulWrapper: {
    marginBottom: "8px",
  },
  authUnsuccessfulWrapperMobile: {
    marginBottom: "12px",
  },
  eventFetchUnsuccessfulContainer: {
    marginTop: "92px",
    [mediaQueryBreakpointUp(Breakpoint.Medium)]: {
      marginTop: "192px",
    },
  },
  pageTooManyRequestsContainer: {
    marginTop: "92px",
    [mediaQueryBreakpointUp(Breakpoint.Medium)]: {
      marginTop: "192px",
    },
  },
  pageNotFoundContainer: {
    marginTop: "92px",
    [mediaQueryBreakpointUp(Breakpoint.Medium)]: {
      marginTop: "192px",
    },
  },
  eventCancelledContainer: {
    marginTop: "122px",
    [mediaQueryBreakpointUp(Breakpoint.Medium)]: {
      marginTop: "227px",
    },
  },
});
export const successPageStyles = makeStyles({
  userDeletedContainer: {
    marginTop: "92px",
    [mediaQueryBreakpointUp(Breakpoint.Medium)]: {
      marginTop: "192px",
    },
  },
  userDeletedImage: {
    maxWidth: "148px",
    maxHeight: "148px",
    marginBottom: "32px",
    [mediaQueryBreakpointUp(Breakpoint.Medium)]: {
      maxWidth: "176px",
      maxHeight: "176px",
    },
  },
});

import React from "react";
import {
  SkeletonV9 as Skeleton,
  mergeClasses,
  SkeletonItem,
} from "../../shared";
import { AboutSectionLoading } from "./AboutSectionLoading";
import { SpeakerCardLoading } from "./SpeakerCardLoading";
import { flexAlignStyles, flexStyles } from "../../styles/FlexStyles";
import { skeletonItemStyles } from "../../styles/SkeletonStyles";

export const EventInfoLoading: React.FunctionComponent = () => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const skeletonItemClasses = skeletonItemStyles();
  const eventInfoLoadingDisplay = (
    <Skeleton data-testid="event-info-loading-component" animation="pulse">
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.column,
          flexClasses.columnGapLarge
        )}
      >
        <div
          className={mergeClasses(
            flexClasses.root,
            flexClasses.column,
            flexClasses.columnGapMedium
          )}
        >
          <div className={mergeClasses(flexClasses.root, flexClasses.column)}>
            <div
              className={mergeClasses(
                flexClasses.root,
                flexClasses.rowGapMedium,
                flexAlignClasses.alignItemCenter
              )}
            >
              <SkeletonItem
                shape="circle"
                className={mergeClasses(
                  skeletonItemClasses.root,
                  skeletonItemClasses.textSize300
                )}
              />
              <SkeletonItem
                className={mergeClasses(
                  skeletonItemClasses.root,
                  skeletonItemClasses.textSize300,
                  skeletonItemClasses.width200
                )}
              />
            </div>
          </div>
          <div className={mergeClasses(flexClasses.root, flexClasses.column)}>
            <div
              className={mergeClasses(
                flexClasses.root,
                flexClasses.rowGapMedium,
                flexAlignClasses.alignItemCenter
              )}
            >
              <SkeletonItem
                shape="circle"
                className={mergeClasses(
                  skeletonItemClasses.root,
                  skeletonItemClasses.textSize300
                )}
              />
              <SkeletonItem
                className={mergeClasses(
                  skeletonItemClasses.root,
                  skeletonItemClasses.textSize300,
                  skeletonItemClasses.width200
                )}
              />
            </div>
          </div>
        </div>
        <AboutSectionLoading />
        <SpeakerCardLoading />
      </div>
    </Skeleton>
  );

  return eventInfoLoadingDisplay;
};

import React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Field,
  InputV9 as Input,
  InputProps,
  LabelV9 as Label,
  mergeClasses,
} from "../../shared";
import { IPortalFormValidatorFn } from "./PortalFormValidators";
import { useTranslation } from "react-i18next";
import { useInputIsInvalid } from "./PortalFormValidatorContext";
import { ValidationMessage } from "./PortalFormFields";
import { formFieldsStyles } from "../../styles/PortalFormFields";

export interface IPortalFormTextInputField {
  name: string;
  labelTranslationKey: string;
  value?: IPortalFormTextInputValue;
  validatorFns?: IPortalFormValidatorFn<string>[];
  placeholderTranslationKey?: string;
  disabled?: boolean;
  borderless?: boolean;
}
export interface IPortalFormTextInputProps extends IPortalFormTextInputField {
  onChange?: (
    event: React.SyntheticEvent<HTMLElement>,
    newValue?: IPortalFormTextInputValue
  ) => void;
  forceShowErrorMessage?: boolean;
}

export type IPortalFormTextInputValue = string;

export const PortalFormTextInput: React.FunctionComponent<
  IPortalFormTextInputProps
> = (props) => {
  const {
    name,
    labelTranslationKey,
    value,
    onChange,
    validatorFns = [],
    forceShowErrorMessage,
    placeholderTranslationKey,
    disabled,
    borderless,
  } = props;
  const formFieldsClasses = formFieldsStyles();
  const { t: i18n } = useTranslation();

  const validators = validatorFns.map((validatorFn) =>
    validatorFn(value || "")
  );
  const failedValidator = validators.findIndex(
    (validator) => validator.isValid === false
  );
  const isValid = failedValidator === -1;
  const errorMessage = !isValid
    ? i18n(validators[failedValidator].message)
    : "";

  const [, setIsAnswerInvalid] = useInputIsInvalid(false);
  useEffect(() => {
    setIsAnswerInvalid(!isValid);
  }, [setIsAnswerInvalid, isValid]);

  const [hasBeenChanged, setHasBeenChanged] = useState(false);

  const shouldShowErrorMessage = useMemo(() => {
    return (forceShowErrorMessage || hasBeenChanged) && !!errorMessage;
  }, [forceShowErrorMessage, hasBeenChanged, errorMessage]);

  const onTextFieldChange = useCallback(
    (
      event: React.SyntheticEvent<HTMLElement>,
      data?: InputProps & {
        value: string;
      }
    ) => {
      setHasBeenChanged(true);
      if (onChange) {
        onChange(event, data?.value);
      }
    },
    [setHasBeenChanged, onChange]
  );

  return (
    <Field
      label={
        <Label htmlFor={name} className={formFieldsClasses.itemLabel}>
          {i18n(labelTranslationKey)}
        </Label>
      }
      validationState={shouldShowErrorMessage ? "error" : undefined}
      validationMessage={
        shouldShowErrorMessage ? (
          <ValidationMessage message={errorMessage} />
        ) : undefined
      }
      validationMessageIcon={null}
    >
      <Input
        name={name}
        className={mergeClasses(
          borderless
            ? formFieldsClasses.borderlessInputText
            : formFieldsClasses.itemInput,
          disabled && borderless
            ? formFieldsClasses.borderlessDisabled
            : undefined
        )}
        defaultValue={value}
        placeholder={
          placeholderTranslationKey
            ? i18n(placeholderTranslationKey)
            : undefined
        }
        onChange={onTextFieldChange}
        aria-label={`${labelTranslationKey}`}
        disabled={disabled}
      />
    </Field>
  );
};

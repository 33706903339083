import { makeStyles, tokens } from "../shared";

export const eventAddToCalenderStyles = makeStyles({
  webAddToCalButton: {
    height: "32px",
  },
  addToCalButtonText: {
    fontWeight: tokens.fontWeightSemibold,
  },
});

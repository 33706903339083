import React, { useState } from "react";
import { useSelector } from "react-redux";
import { userSelector } from "../../core/slices/userSlice";
import { EventUser } from "../../core/slices/userTypes.interface";
import { LoggerLevels } from "../../common/logger/interface";
import { useTranslation } from "react-i18next";
import { useLogger } from "../../common/logger/LoggerContext";
import { publicProfileStyles } from "../../styles/ProfilePublicProfile";
import {
  useBoolean,
  mergeClasses,
  ButtonV9 as Button,
  TextV9 as Text,
  DialogContentV9 as DialogContent,
  DialogV9 as Dialog,
  DialogSurfaceV9 as DialogSurface,
  DialogBodyV9 as DialogBody,
  DialogActionsV9 as DialogActions,
  PortalFormValidatorProvider,
  PortalFormField,
  PortalFormFieldType,
  usePortalFormValidatorContext,
} from "../../shared";
import { ProfileCard } from "./ProfileCard";
import { ProfilePageHeaderMobile } from "./ProfilePageHeaderMobile";
import { Breakpoint } from "../../styles/Grid";
import { useBreakpoint } from "../../utilities/hooks/useBreakpoints";
import { profilePageStyles } from "../../styles/ProfilePage";
import { buttonStyles } from "../../styles/ButtonStyles";
import { gridStyles } from "../../styles/Grid";
import { flexStyles } from "../../styles/FlexStyles";
import { portalTextStyles } from "../../styles/PortalText";
import { portalResponsiveDialogStyles } from "../../styles/PortalResponsiveDialog";
import {
  PortalFormFieldValue,
  PortalFormFields,
} from "../../shared/form/PortalFormFields";
import { getValidUserURL, isValidUserURL } from "../../utilities/common/utils";
export const ProfilePublicProfile: React.FunctionComponent = () => {
  // TODO: Fetch and pass in initial form data instead of user.
  const user = useSelector(userSelector);

  if (!user) {
    // TODO: have loading spinner until user is fetched?
    return null;
  }

  return (
    <PortalFormValidatorProvider>
      <ProfilePublicProfileInternal user={user} />
    </PortalFormValidatorProvider>
  );
};

const ProfilePublicProfileInternal: React.FunctionComponent<{
  user: EventUser;
}> = ({ user }) => {
  const flexClasses = flexStyles();
  const portalResponsiveDialogClasses = portalResponsiveDialogStyles();
  const portalTextClasses = portalTextStyles();
  const gridClasses = gridStyles();
  const buttonClasses = buttonStyles();
  const profilePageClasses = profilePageStyles();
  const publicProfileClasses = publicProfileStyles();
  const { t: i18n } = useTranslation();
  const logger = useLogger()?.logger;
  const { isBreakpointAndDown } = useBreakpoint();
  const mobileView = isBreakpointAndDown(Breakpoint.Medium);

  const [
    isEditDialogOpen,
    { setTrue: openEditDialog, setFalse: dismissEditDialog },
  ] = useBoolean(false);

  const [hasFormChanged, setHasFormChanged] = useState(false);
  const [hasSaveBeenClicked, setHasSaveBeenClicked] = useState(false);

  const [publicProfileFormFields, setPublicProfileFormFields] = useState<
    PortalFormField[]
  >([
    {
      type: PortalFormFieldType.Checkbox,
      name: "displayName",
      labelTranslationKey: "display_name_label",
      value: true, // TODO: intialize from fetched data.
    },
    {
      type: PortalFormFieldType.Checkbox,
      name: "profilePicture",
      labelTranslationKey: "profile_picture_label",
      value: true, // TODO: intialize from fetched data.
    },
    {
      type: PortalFormFieldType.Checkbox,
      name: "firstName",
      labelTranslationKey: "first_name_label",
      value: true, // TODO: intialize from fetched data.
    },
    {
      type: PortalFormFieldType.Checkbox,
      name: "lastName",
      labelTranslationKey: "last_name_label",
      value: true, // TODO: intialize from fetched data.
    },
    {
      type: PortalFormFieldType.Checkbox,
      name: "pronoun",
      labelTranslationKey: "pronoun_label",
      value: true, // TODO: intialize from fetched data.
    },
    {
      type: PortalFormFieldType.Checkbox,
      name: "email",
      labelTranslationKey: "email_label",
      value: true, // TODO: intialize from fetched data.
    },
    {
      type: PortalFormFieldType.Checkbox,
      name: "phone",
      labelTranslationKey: "phone_label",
      value: true, // TODO: intialize from fetched data.
    },
    {
      type: PortalFormFieldType.Checkbox,
      name: "organization",
      labelTranslationKey: "organization_label",
      value: true, // TODO: intialize from fetched data.
    },
    {
      type: PortalFormFieldType.Checkbox,
      name: "industry",
      labelTranslationKey: "industry_label",
      value: true, // TODO: intialize from fetched data.
    },
    {
      type: PortalFormFieldType.Checkbox,
      name: "location",
      labelTranslationKey: "location_label",
      value: true, // TODO: intialize from fetched data.
    },
    {
      type: PortalFormFieldType.Checkbox,
      name: "interests",
      labelTranslationKey: "interests_label",
      value: true, // TODO: intialize from fetched data.
    },
    {
      type: PortalFormFieldType.Checkbox,
      name: "linkedin",
      labelTranslationKey: "linkedin_label",
      value: true, // TODO: intialize from fetched data.
    },
    {
      type: PortalFormFieldType.Checkbox,
      name: "twitter",
      labelTranslationKey: "twitter_label",
      value: true, // TODO: intialize from fetched data.
    },
    {
      type: PortalFormFieldType.Checkbox,
      name: "github",
      labelTranslationKey: "github_label",
      value: true, // TODO: intialize from fetched data.
    },
  ]);

  const { errorCount } = usePortalFormValidatorContext();

  const onClickSave = () => {
    setHasSaveBeenClicked(true);
    if (!errorCount) {
      logger?.logTrace(
        LoggerLevels.debug,
        `ProfilePublicProfile: submited form fields = ${JSON.stringify(
          publicProfileFormFields
        )}`
      );
      // TODO: Dispatch action to submit data.
    }
  };

  const onFieldChange = (
    fieldName: string,
    newValue?: PortalFormFieldValue
  ): void => {
    setHasFormChanged(true);
    const newFormValues = publicProfileFormFields.map((item) => {
      if (item.name === fieldName) {
        item.value = newValue;
      }
      return item;
    });
    setPublicProfileFormFields(newFormValues);
    setPreviewUser(generatePreviewUser());
  };

  const generatePreviewUser: () => EventUser = () => {
    let showDisplayName = false;
    let showProfilePicture = false;
    let showFirstName = false;
    let showLastName = false;
    // let showPronoun = false;
    let showEmail = false;
    // let showPhone = false;
    let showOrganization = false;
    // let showIndustry = false;
    // let showLocation = false;
    // let showInterests = false;
    let showLinkedin = false;
    let showTwitter = false;
    let showGithub = false;

    publicProfileFormFields.forEach((field) => {
      switch (field.name) {
        case "displayName":
          showDisplayName = field.value as boolean;
          break;
        case "profilePicture":
          showProfilePicture = field.value as boolean;
          break;
        case "firstName":
          showFirstName = field.value as boolean;
          break;
        case "lastName":
          showLastName = field.value as boolean;
          break;
        case "pronoun":
          // TODO: currently not in EventUser
          // showPronoun = field.value as boolean;
          break;
        case "email":
          showEmail = field.value as boolean;
          break;
        case "phone":
          // TODO: currently not in EventUser
          // showPhone = field.value as boolean;
          break;
        case "organization":
          showOrganization = field.value as boolean;
          break;
        case "industry":
          // TODO: currently not in EventUser
          // showIndustry = field.value as boolean;
          break;
        case "location":
          // TODO: currently not in EventUser
          // showLocation = field.value as boolean;
          break;
        case "interests":
          // TODO: currently not in EventUser
          // showInterests = field.value as boolean;
          break;
        case "linkedin":
          showLinkedin = field.value as boolean;
          break;
        case "twitter":
          showTwitter = field.value as boolean;
          break;
        case "github":
          showGithub = field.value as boolean;
          break;
      }
    });

    return {
      ...user,
      firstName: showFirstName ? user.firstName : null,
      lastName: showLastName ? user.lastName : null,
      displayName: showDisplayName ? user.displayName : null,
      email: showEmail ? user.email : "",
      userBio: user.userBio
        ? {
            company: showOrganization ? user.userBio.company : null,
            jobTitle: user.userBio.jobTitle,
            description: user.userBio.description,
            websites:
              user.userBio.websites?.filter((website) =>
                isValidUserURL(website)
              ) ?? null,
            socialMediaLinks: user.userBio.socialMediaLinks
              ? {
                  linkedIn: showLinkedin
                    ? getValidUserURL(user.userBio.socialMediaLinks["linkedIn"])
                    : undefined,
                  twitter: showTwitter
                    ? getValidUserURL(user.userBio.socialMediaLinks["twitter"])
                    : undefined,
                  github: showGithub
                    ? getValidUserURL(user.userBio.socialMediaLinks["github"])
                    : undefined,
                }
              : null,
          }
        : undefined,
      profileImage: showProfilePicture ? user.profileImage : null,
    };
  };

  const [previewUser, setPreviewUser] = useState(generatePreviewUser());

  const profileCard = <ProfileCard user={previewUser} fluid />;

  const form = (
    <>
      <Text>{i18n("form_fields")}</Text>
      <PortalFormFields
        form={publicProfileFormFields}
        onChange={(item, newValue) => onFieldChange(item.name, newValue)}
        forceShowErrorMessage={hasSaveBeenClicked}
        field={(item, field) => (
          <div className={item.className} key={item.name}>
            {field}
          </div>
        )}
      />
    </>
  );

  const webContent = (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexClasses.columnGapLarge
      )}
    >
      <Text
        className={mergeClasses(
          gridClasses.hiddenMdDown,
          portalTextClasses.large,
          publicProfileClasses.header
        )}
        as="h1"
        weight="bold"
      >
        {i18n("public_profile_header")}
      </Text>
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.column,
          flexClasses.columnGapMedium
        )}
      >
        <Text>{i18n("public_profile_form_instructions")}</Text>
        <div
          className={mergeClasses(
            "public-profile-form-card",
            flexClasses.root,
            publicProfileClasses.formCard
          )}
        >
          <div
            className={mergeClasses(
              flexClasses.root,
              flexClasses.column,
              flexClasses.columnGapSmall,
              publicProfileClasses.contentWrapper
            )}
          >
            {form}
          </div>
          <div
            className={mergeClasses("divider", publicProfileClasses.divider)}
          />
          <div
            className={mergeClasses(
              flexClasses.root,
              flexClasses.column,
              flexClasses.fill,
              publicProfileClasses.contentWrapper
            )}
          >
            <Text>{i18n("preview")}</Text>
            {profileCard}
          </div>
        </div>
        <div>
          <Button
            appearance="primary"
            disabled={hasFormChanged ? !!errorCount : false}
            onClick={onClickSave}
          >
            {i18n("public_profile_form_button_save")}
          </Button>
        </div>
      </div>
    </div>
  );

  const mobileContent = (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexClasses.columnGapMedium
      )}
    >
      <Text>{i18n("public_profile_preview_instructions")}</Text>
      {profileCard}
      <Button className={buttonClasses.fluid} onClick={openEditDialog}>
        {i18n("public_profile__button_edit")}
      </Button>
      <Dialog open={isEditDialogOpen} onOpenChange={dismissEditDialog}>
        <DialogSurface
          className={mergeClasses(
            flexClasses.root,
            flexClasses.column,
            portalResponsiveDialogClasses.dialogSurface
          )}
        >
          <DialogBody
            className={mergeClasses(
              flexClasses.root,
              flexClasses.column,
              portalResponsiveDialogClasses.dialogBody
            )}
          >
            <DialogContent>
              <div
                className={mergeClasses(flexClasses.root, flexClasses.column)}
              >
                {form}
              </div>
            </DialogContent>
            <DialogActions
              className={portalResponsiveDialogClasses.dialogActions}
            >
              <Button
                appearance="primary"
                className={buttonClasses.fluid}
                disabled={hasFormChanged ? !!errorCount : false}
                onClick={onClickSave}
              >
                {i18n("public_profile_form_button_save")}
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );

  return (
    <div
      className={mergeClasses(
        "public-profile",
        flexClasses.root,
        flexClasses.column,
        flexClasses.fill
      )}
    >
      {mobileView && (
        <ProfilePageHeaderMobile
          header={i18n("public_profile_header")}
          backPath="/profile"
        />
      )}
      <div
        className={mergeClasses(
          "profile-page-container",
          gridClasses.container,
          profilePageClasses.container
        )}
      >
        <div className={gridClasses.row}>
          <div
            className={mergeClasses(
              gridClasses.col,
              gridClasses.xs12,
              gridClasses.lg11,
              gridClasses.xl10
            )}
          >
            {mobileView ? mobileContent : webContent}
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadLogsAction,
  downloadLogsDialogSelector,
  setDownloadLogsDialogAction,
} from "../core/slices/userSlice";
import {
  ButtonV9 as Button,
  DialogActionsV9 as DialogActions,
  DialogBodyV9 as DialogBody,
  DialogContentV9 as DialogContent,
  DialogSurfaceV9 as DialogSurface,
  DialogTitleV9 as DialogTitle,
  DialogTriggerV9 as DialogTrigger,
  DialogV9 as Dialog,
} from "../shared";

export const DownloadLogsDialog: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { t: i18n } = useTranslation();
  const open = useSelector(downloadLogsDialogSelector);

  const onDismiss: () => void = () => {
    dispatch(setDownloadLogsDialogAction(false));
  };

  const onClickSubmit: () => void = () => {
    dispatch(downloadLogsAction());
    dispatch(setDownloadLogsDialogAction(false));
  };

  return (
    <Dialog open={open} onOpenChange={onDismiss}>
      <DialogSurface>
        <DialogBody data-testid="downloadLogsDialog">
          <DialogTitle>{i18n("download_logs_dialog_title")}</DialogTitle>
          <DialogContent>
            {i18n("download_logs_dialog_legal_statement")}
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button
                data-testid="downloadLogsDialogCancelButton"
                appearance="secondary"
              >
                {i18n("button_cancel")}
              </Button>
            </DialogTrigger>
            <Button
              data-testid="downloadLogsDialogDownloadButton"
              appearance="primary"
              onClick={onClickSubmit}
            >
              {i18n("download_logs_button")}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

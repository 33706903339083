import { makeStyles, shorthands, tokens } from "../shared";
import {
  Breakpoint,
  mediaQueryBreakpointDown,
  mediaQueryBreakpointUp,
} from "./Grid";

export const sessionPageStyles = makeStyles({
  backToEventButton: {
    paddingLeft: 0,
    paddingRight: 0,
    fontSize: tokens.fontSizeBase300,
    fontWeight: tokens.fontWeightSemibold,
    lineHeight: tokens.lineHeightBase300,
    [mediaQueryBreakpointDown(Breakpoint.Medium)]: {
      fontSize: "17px",
      lineHeight: tokens.lineHeightBase400,
    },
  },
  container: {
    ...shorthands.margin("32px", "16px", "32px", "16px"),
    [mediaQueryBreakpointDown(Breakpoint.Medium)]: {
      ...shorthands.margin("0px", "16px", "16px", "16px"),
    },
  },
  sessionHeader: {
    ...shorthands.padding("32px", "0", "20px", "0"),
    backgroundColor: tokens.colorNeutralBackground1,
    [mediaQueryBreakpointDown(Breakpoint.Medium)]: {
      ...shorthands.padding("16px", "0", "32px", "0"),
    },
    [mediaQueryBreakpointUp(Breakpoint.Large)]: {
      ...shorthands.borderBottom("1px", "solid", tokens.colorNeutralStroke2),
    },
  },
});

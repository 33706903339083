import React from "react";
import {
  LocationFilled,
  CalendarLtrFilled,
  ClockFilled,
  mergeClasses,
  TextV9 as Text,
  TicketDiagonalFilled,
  StartOrEndTimeDisplayOnly,
} from "../../shared";
import { eventInfoStyles } from "../../styles/EventInfo";
import { IEvent } from "../../core/slices/eventTypes.interface";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  userIsRegistered,
  isUserRegistrationStatusKnown,
  registrationDetailSelector,
  eventHasEndedSelector,
  registrationHasNotStartedSelector,
  registrationHasEndedSelector,
} from "../../core/slices/eventSlice";
import { authenticatedSelector } from "../../core/slices/userSlice";
import { Breakpoint } from "../../styles/Grid";
import { EventAttendingAndCancel } from "../registration/RegistrationCard";
import { useBreakpoint } from "../../utilities/hooks/useBreakpoints";
import { EventShareEventButton } from "../EventShareEventButton";
import { EventAddToCalenderButton } from "../EventAddToCalendarButton";
import { flexStyles, flexAlignStyles } from "../../styles/FlexStyles";
import { iconStyles } from "../../styles/IconStyles";
import { IEventInfo } from "./IEventInfo";
import { TimeDisplay, DateTimeDisplay } from "../../shared/TimeDisplay";
import { timeDisplayStyles } from "../../styles/TimeDisplay";
import { IEventSession } from "../../core/slices/session.interface";

export interface TopInfoProps {
  eventInfo?: IEventInfo;
  event?: IEvent;
  session?: IEventSession;
}

export const TopInfo: React.FunctionComponent<TopInfoProps> = ({
  eventInfo,
  event,
  session,
}) => {
  const flexClasses = flexStyles();
  const eventInfoClasses = eventInfoStyles();
  const { t: i18n } = useTranslation();
  const { isBreakpointAndDown } = useBreakpoint();
  const isMobileView = isBreakpointAndDown(Breakpoint.Medium);

  const renderEvent = () => {
    const EventComponent = isMobileView ? TopInfoMobile : TopInfoSectionWeb;
    return <EventComponent currentEvent={event} eventInfo={eventInfo} />;
  };

  const renderSession = () => {
    const SessionComponent = isMobileView ? TopInfoMobile : TopInfoSectionWeb;
    return <SessionComponent currentSession={session} />;
  };

  return (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexClasses.columnGap12px
      )}
    >
      {!isMobileView && (
        <div
          className={mergeClasses(
            eventInfoClasses.eventDetailsTextStyles,
            flexClasses.root,
            flexClasses.rowGap12px
          )}
        >
          <Text size={300} weight="semibold">
            {i18n("details_section")}
          </Text>
        </div>
      )}
      {event && renderEvent()}
      {session && renderSession()}
    </div>
  );
};

export const TimeDateSection: React.FunctionComponent<{
  startTime: string;
  endTime: string;
}> = ({ startTime, endTime }) => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const eventInfoClasses = eventInfoStyles();
  const iconClasses = iconStyles();
  return (
    <>
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.rowGap12px,
          flexAlignClasses.alignItemCenter
        )}
        data-testid="dateSection"
      >
        <CalendarLtrFilled
          filled
          className={mergeClasses(
            eventInfoClasses.topSectionIcons,
            iconClasses.large
          )}
        />
        <div
          className={mergeClasses(
            flexClasses.root,
            flexClasses.column,
            flexClasses.columnGap4px
          )}
        >
          <DateTimeDisplay
            startTime={startTime}
            endTime={endTime}
            format="date"
          />
        </div>
      </div>
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.rowGap12px,
          flexAlignClasses.alignItemCenter
        )}
      >
        <ClockFilled
          filled
          className={mergeClasses(
            eventInfoClasses.topSectionIcons,
            iconClasses.large
          )}
        />
        <div
          className={mergeClasses(
            flexClasses.root,
            flexClasses.column,
            flexClasses.columnGap4px
          )}
        >
          <DateTimeDisplay
            startTime={startTime}
            endTime={endTime}
            format="time"
          />
        </div>
      </div>
    </>
  );
};

// Location Section
export const LocationSection: React.FunctionComponent<{
  locationText?: string;
  isMobileView: boolean;
}> = ({ locationText, isMobileView }) => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const eventInfoClasses = eventInfoStyles();
  const iconClasses = iconStyles();
  const { t: i18n } = useTranslation();
  const onlineEventText = i18n("online_event");
  const locationTextClass = isMobileView
    ? eventInfoClasses.locationTextMobile
    : eventInfoClasses.locationTextWeb;

  return (
    <div className={mergeClasses(flexClasses.root, flexClasses.column)}>
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.rowGap12px,
          flexAlignClasses.alignItemCenter
        )}
      >
        <LocationFilled
          filled
          className={mergeClasses(
            eventInfoClasses.topSectionIcons,
            iconClasses.large
          )}
        />
        <div
          className={mergeClasses(
            flexClasses.root,
            flexClasses.column,
            flexClasses.columnGap4px
          )}
        >
          <Text className={locationTextClass}>{onlineEventText}</Text>
          <Text className={locationTextClass}>{locationText}</Text>
        </div>
      </div>
    </div>
  );
};

export const TopInfoMobile: React.FunctionComponent<{
  currentEvent?: IEvent;
  eventInfo?: IEventInfo;
  currentSession?: IEventSession;
}> = ({ currentEvent, eventInfo, currentSession }) => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const eventInfoClasses = eventInfoStyles();
  const iconClasses = iconStyles();
  const isUserRegistered = useSelector(userIsRegistered);
  const isAuthenticated = useSelector(authenticatedSelector);
  const isRegistrationStatusKnown = useSelector(isUserRegistrationStatusKnown);
  const showRegistrationStatusAuth = isUserRegistered && isAuthenticated;
  const showEventRegistrationStateForUnauth =
    !isUserRegistered || !isAuthenticated || !isRegistrationStatusKnown;

  const { startTime, endTime, location } = React.useMemo(() => {
    const timeInfo = currentSession?.sessionTime || currentEvent?.eventTime;
    return {
      startTime: timeInfo?.startTime,
      endTime: timeInfo?.endTime,
      location: currentSession?.location || currentEvent?.location,
    };
  }, [currentSession, currentEvent]);

  const renderTimeSection = () => (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.rowGap12px,
        flexAlignClasses.alignItemStart
      )}
    >
      <ClockFilled
        filled
        className={mergeClasses(
          eventInfoClasses.topSectionIcons,
          iconClasses.large
        )}
      />
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.column,
          flexClasses.columnGap4px
        )}
      >
        {startTime && endTime && (
          <TimeDisplay
            startTime={startTime}
            endTime={endTime}
            weight="semiBold"
            mobile
          />
        )}
      </div>
    </div>
  );

  const renderEventControls = () => (
    <>
      {showRegistrationStatusAuth && <EventAttendingAndCancel />}
      {showEventRegistrationStateForUnauth && eventInfo && (
        <RegistrationMobileContent info={eventInfo} />
      )}
      {currentEvent && (
        <EventShareEventButton
          event={currentEvent}
          eventUrl={window.location.href}
        />
      )}
      {isUserRegistered && <EventAddToCalenderButton />}
    </>
  );

  return (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexClasses.columnGap16px
      )}
    >
      {renderTimeSection()}
      <LocationSection locationText={location} isMobileView={true} />
      {!currentSession && currentEvent && eventInfo && renderEventControls()}
    </div>
  );
};

export const TopInfoSectionWeb: React.FunctionComponent<{
  currentEvent?: IEvent;
  currentSession?: IEventSession;
}> = ({ currentEvent, currentSession }) => {
  const flexClasses = flexStyles();
  const { startTime, endTime, location } = React.useMemo(() => {
    const timeInfo = currentSession?.sessionTime || currentEvent?.eventTime;
    return {
      startTime: timeInfo?.startTime,
      endTime: timeInfo?.endTime,
      location: currentSession?.location || currentEvent?.location,
    };
  }, [currentSession, currentEvent]);

  return (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexClasses.columnGap4px
      )}
    >
      {startTime && endTime && (
        <>
          <TimeDateSection startTime={startTime} endTime={endTime} />
        </>
      )}
      <LocationSection locationText={location} isMobileView={false} />
    </div>
  );
};

export const RegistrationMobileContent: React.FunctionComponent<{
  info: IEventInfo;
}> = ({ info }) => {
  const { isFull, waitlistOpen } = info;
  const { t: i18n } = useTranslation();
  const eventInfoClasses = eventInfoStyles();
  const flexClasses = flexStyles();
  const timeDisplayClasses = timeDisplayStyles();
  const iconClasses = iconStyles();
  const registrationDetails = useSelector(registrationDetailSelector);
  const hasEventEnded = useSelector(eventHasEndedSelector);
  const hasRegistrationNotStarted = useSelector(
    registrationHasNotStartedSelector
  );
  const hasRegistrationEnded = useSelector(registrationHasEndedSelector);

  const portalTicketIcon = (
    <TicketDiagonalFilled
      className={mergeClasses(
        eventInfoClasses.portalTicketIcon,
        iconClasses.large
      )}
    />
  );

  const registrationMobileDisplay = (
    <>
      {isFull && !waitlistOpen && !hasEventEnded && (
        <div className={mergeClasses(flexClasses.root, flexClasses.rowGap12px)}>
          {portalTicketIcon}
          <Text
            className={eventInfoClasses.registrationStateMobile}
            id="sold-out-info"
          >
            {i18n("registration_at_capacity")}
          </Text>
        </div>
      )}
      {isFull && waitlistOpen && !hasEventEnded && (
        <div className={mergeClasses(flexClasses.root, flexClasses.rowGap12px)}>
          {portalTicketIcon}
          <Text
            className={eventInfoClasses.registrationStateMobile}
            id="waitlist-state-info"
          >
            {i18n("event_waitlisted_state")}
          </Text>
        </div>
      )}
      {hasRegistrationNotStarted && (
        <div className={mergeClasses(flexClasses.root, flexClasses.rowGap12px)}>
          {portalTicketIcon}
          <Text
            className={eventInfoClasses.registrationStateMobile}
            id="registration-not-started"
          >
            {i18n("registration_not_started")}&nbsp;
            <StartOrEndTimeDisplayOnly
              time={
                registrationDetails?.registrationProperties.registrationLimit &&
                registrationDetails?.registrationProperties.registrationOpenTime
                  ?.startTime
                  ? registrationDetails.registrationProperties
                      .registrationOpenTime.startTime
                  : ""
              }
              className={timeDisplayClasses.root}
            />
          </Text>
        </div>
      )}
      {hasRegistrationEnded && (
        <div className={mergeClasses(flexClasses.root, flexClasses.rowGap12px)}>
          {portalTicketIcon}
          <Text
            className={eventInfoClasses.registrationStateMobile}
            id="registration-has-ended"
          >
            {i18n("registration_is_closed")}
          </Text>
        </div>
      )}
      {hasEventEnded && (
        <div className={mergeClasses(flexClasses.root, flexClasses.rowGap12px)}>
          {portalTicketIcon}
          <Text
            className={eventInfoClasses.registrationStateMobile}
            id="event-ended"
          >
            {i18n("event_ended_mobile")}
          </Text>
        </div>
      )}
    </>
  );
  return registrationMobileDisplay;
};

import React from "react";
import { useTranslation } from "react-i18next";
import { TextV9 as Text, ImageV9 as Image, mergeClasses } from "../../shared";
import socialUserDeleted from "../../assets/user_deleted.svg";
import { successPageStyles, errorPageStyles } from "../../styles/ErrorPages";
import { Logger, Scenario } from "../../common/logger/Logger";
import { flexAlignStyles, flexStyles } from "../../styles/FlexStyles";
import { portalTextStyles } from "../../styles/PortalText";

export const UserDeleted: React.FunctionComponent = () => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const successPageClasses = successPageStyles();
  const errorPageClasses = errorPageStyles();
  const portalTextClasses = portalTextStyles();
  const { t: i18n } = useTranslation();
  const userDeletedMessage = i18n("user_account_deleted");
  const logger = Logger.getInstance();
  const route = window.location.href;
  const scenario = logger.createScenario(Scenario.ErrorPages, {
    data: { detailText: userDeletedMessage + route },
  });
  scenario?.stop();

  const userDeleted = (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexAlignClasses.alignItemCenter,
        successPageClasses.userDeletedContainer
      )}
    >
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.column,
          errorPageClasses.mobileTextPadding
        )}
      >
        <Text className={portalTextClasses.large} weight="bold" align="center">
          {userDeletedMessage}
        </Text>
      </div>
      <Image
        src={socialUserDeleted}
        fit="contain"
        className={successPageClasses.userDeletedImage}
      />
    </div>
  );

  return userDeleted;
};

import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  IRegistrationWarningPopUp,
  RegistrationWarningPopUp,
} from "./RegistrationWarningPopUp";
import {
  RegistrationSuccess,
  IRegistrationSuccess,
  CancellationSuccess,
} from "./RegistrationAndCancellationSuccess";
import {
  IEvent,
  UserRegistrationStatus,
  IEventRegisterResponse,
} from "../../core/slices/eventTypes.interface";
import { useLogger } from "../../common/logger/LoggerContext";
import { Scenario } from "../../common/logger/Logger";
import { UserAction } from "./UserAction";
import { CmdServicesResponseErrorCode } from "../../core/slices/error";
import { PortalLocationState } from "../../core/history/history.interface";

export interface IEventPageRegistrationModals {
  isRegistered: boolean;
  registration?: IEventRegisterResponse;
  currentEvent?: IEvent;
}

/**
 * These are success and failure modals that show up for registration based on
 * user actions which includes user registering for an event or user cancelling their registration
 */

export const EventPageRegistrationModals: React.FunctionComponent<{
  props: IEventPageRegistrationModals;
}> = ({ props }) => {
  const { isRegistered, registration, currentEvent } = props;
  const eventLocationProps = useLocation<PortalLocationState>();
  const { id } = useParams<{ id: string; regId: string }>();
  const { t: i18n } = useTranslation();
  const logger = useLogger()?.logger;

  let modal = null;

  if (eventLocationProps) {
    if (
      isRegistered &&
      eventLocationProps.state &&
      eventLocationProps.state.emailAddress
    ) {
      const eventTitle =
        currentEvent && currentEvent.title
          ? currentEvent.title
          : /* istanbul ignore next */ "";
      const promoImageID =
        currentEvent && currentEvent.theme
          ? currentEvent.theme.promoImage
          : /* istanbul ignore next */ "";
      const eventID: string = currentEvent
        ? currentEvent.id
        : /* istanbul ignore next */ "";
      const eventDescription =
        currentEvent && currentEvent.description
          ? currentEvent.description
          : /* istanbul ignore next */ "";
      const truncatedUrl =
        window.location.href.length > 255
          ? window.location.href.substring(0, 255)
          : window.location.href;
      const regSuccessDetails: IRegistrationSuccess = {
        titleId: `title-${id}`,
        eventUrl: truncatedUrl,
        eventName: eventTitle,
        eventDescription: eventDescription,
        emailAddress: eventLocationProps.state.emailAddress,
        promoImageID: promoImageID,
        eventID: eventID,
      };
      modal = <RegistrationSuccess props={regSuccessDetails} />;
    } else if (
      registration?.status === UserRegistrationStatus.CANCELED &&
      eventLocationProps.state &&
      eventLocationProps.state.userAction === UserAction.CancelledRegistration
    ) {
      const scenario = logger?.findScenario(Scenario.CancelRegistration);
      /* istanbul ignore if */
      if (scenario) {
        scenario.stop();
      }
      modal = <CancellationSuccess />;
    } else if (
      eventLocationProps.state &&
      eventLocationProps.state.error ===
        CmdServicesResponseErrorCode.REGISTRATION_FULL
    ) {
      const warningFields: IRegistrationWarningPopUp = {
        title: i18n(
          "errors.service_errors.registration_event_full_warning_titile"
        ),
        errorMessage: i18n(
          "errors.service_errors.registration_event_full_warning_text"
        ),
        showError: true,
      };
      modal = <RegistrationWarningPopUp {...warningFields} />;
    }

    if (
      eventLocationProps.state &&
      eventLocationProps.state.error ===
        CmdServicesResponseErrorCode.UNKNOWN_ERROR
    ) {
      const warningFields: IRegistrationWarningPopUp = {
        title: i18n(
          "errors.service_errors.trouble_processing_registration_title"
        ),
        errorMessage: i18n(
          "errors.service_errors.trouble_processing_registration_body"
        ),
        showError: true,
      };
      modal = <RegistrationWarningPopUp {...warningFields} />;
    }
  }

  return modal;
};

import { StringMap, TFunction, TOptions } from "i18next";

export const isSameDay = (date1: Date, date2: Date): boolean =>
  date1.getFullYear() === date2.getFullYear() &&
  date1.getMonth() === date2.getMonth() &&
  date1.getDate() === date2.getDate();

export const formatDateTime = (
  startTime: Date,
  value: TOptions<StringMap>,
  i18n: TFunction
): string => {
  return i18n("{{value, datetime}}", {
    value: startTime,
    formatParams: {
      value: value,
    },
  });
};

import { CMDTimeZoneDetails } from "../../core/slices/CMDTypes";
import { IEvent } from "../../core/slices/eventTypes.interface";

const getTzMap = async () => {
  try {
    return (await import(/* webpackChunkName: "tz-map" */ "./tzMap")).tzMap;
  } catch (err) {
    throw Error("Failed to load time zone map.");
  }
};

export const ianaToWindowsTZ = async (ianaTZ: string): Promise<string> => {
  const tzMap = await getTzMap();
  const res = tzMap.find((tz) => {
    return tz.ianaTZ.includes(ianaTZ);
  });
  if (!res) {
    throw Error(`Failed to map IANA TZ ${ianaTZ}`);
  }
  return res.windowsTZ;
};

export const browserTZToWindowsTZ = (
  currentEvent?: IEvent
): Promise<string> => {
  const tz: string = getTimezone(currentEvent);
  return ianaToWindowsTZ(tz);
};

export const localizedWindowsTZ = async (ianaTZ: string): Promise<string> => {
  const tzMap = await getTzMap();
  const res = tzMap.find((tz) => {
    return tz.ianaTZ.includes(ianaTZ);
  });
  if (!res) {
    throw Error(
      `Failed to localize windowzTZ. The corressponding ianaTZ is ${ianaTZ}`
    );
  }
  return res.intlStr;
};

export const browserTZToLocalizedWindowsTZ = (
  currentEvent?: IEvent
): Promise<string> => {
  const tz: string = getTimezone(currentEvent);
  return localizedWindowsTZ(tz);
};

export const getTimezone = (currentEvent?: IEvent): string => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (!timezone && currentEvent) {
    const tzd: CMDTimeZoneDetails = currentEvent.eventTime.timeZoneDetails;
    return tzd.ianaTimeZone;
  }
  return timezone;
};

const oneDsSdkTenantKey = process.env.REACT_APP_ONE_DS_SDK_TENANT_KEY as string;
const oneDsEndpointOverride = process.env.REACT_APP_ONE_DS_ENDPOINT_OVERRIDE as
  | string
  | undefined;
const disableTelemetry = process.env.REACT_APP_DISABLE_TELEMETRY === "true";
const logDiagnosticsToConsole =
  process.env.REACT_APP_LOG_DIAGNOSTICS_TO_CONSOLE === "true";
export const loggerConfig = {
  oneDsSdkTenantKey,
  oneDsEndpointOverride,
  disableTelemetry,
  logDiagnosticsToConsole,
};

import React from "react";
import { useTranslation } from "react-i18next";
import { isSameDay } from "../utilities/common/dateUtils";
import { mergeClasses, TextV9 as Text } from ".";
import { timeDisplayStyles } from "../styles/TimeDisplay";

export const TimeDisplay: React.FunctionComponent<{
  startTime: string;
  endTime: string;
  weight: "regular" | "medium" | "semiBold" | "bold";
  mobile?: boolean;
}> = ({ startTime, endTime, weight, mobile = false }) => {
  const timeDisplayClasses = timeDisplayStyles();
  const {
    t: i18n,
    i18n: { resolvedLanguage },
  } = useTranslation();
  const localZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const startTimeInternal = new Date(startTime);
  const endTimeInternal = new Date(endTime);
  const isStartEndTimeOnSameDay = isSameDay(startTimeInternal, endTimeInternal);
  const weightClasses = {
    regular: timeDisplayClasses.weightRegular,
    medium: timeDisplayClasses.weightMedium,
    semiBold: timeDisplayClasses.weightSemiBold,
    bold: timeDisplayClasses.weightBold,
  };

  let startTimeString, endTimeString;

  if (mobile) {
    startTimeString = `${startTimeInternal.toLocaleDateString(
      resolvedLanguage,
      {
        month: "long",
        day: "2-digit",
        year: "numeric",
      }
    )} • ${startTimeInternal.toLocaleTimeString(resolvedLanguage, {
      hour: "numeric",
      minute: "2-digit",
      timeZone: localZone,
    })}`;

    endTimeString = isStartEndTimeOnSameDay
      ? `${endTimeInternal.toLocaleTimeString(resolvedLanguage, {
          hour: "numeric",
          minute: "2-digit",
          timeZone: localZone,
        })}`
      : `${endTimeInternal.toLocaleDateString(resolvedLanguage, {
          month: "long",
          day: "2-digit",
          year: "numeric",
        })} • ${endTimeInternal.toLocaleTimeString(resolvedLanguage, {
          hour: "numeric",
          minute: "2-digit",
          timeZone: localZone,
        })}`;
  } else {
    startTimeString = i18n("{{value, datetime}}", {
      value: startTimeInternal,
      formatParams: {
        value: {
          weekday: "short",
          month: "short",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
        },
      },
    });

    endTimeString = i18n("{{value, datetime}}", {
      value: endTimeInternal,
      formatParams: {
        value: {
          weekday: !isStartEndTimeOnSameDay ? "short" : undefined,
          month: !isStartEndTimeOnSameDay ? "short" : undefined,
          day: !isStartEndTimeOnSameDay ? "2-digit" : undefined,
          hour: "numeric",
          minute: "numeric",
          timeZone: localZone,
          timeZoneName: "short",
        },
      },
    });
  }

  const eventTimeText = `${startTimeString} - ${endTimeString}`;

  return (
    <Text
      className={mergeClasses(timeDisplayClasses.root, weightClasses[weight])}
      data-testid="timeDisplay"
    >
      {eventTimeText}
    </Text>
  );
};

export const DateTimeDisplay: React.FunctionComponent<{
  startTime: string;
  endTime: string;
  format: "time" | "date";
  className?: string;
}> = ({ startTime, endTime, format, className }) => {
  const timeDisplayClasses = timeDisplayStyles();
  const { t: i18n } = useTranslation();
  const localZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const startTimeInternal = new Date(startTime);
  const endTimeInternal = new Date(endTime);
  const isStartEndTimeOnSameDay = isSameDay(startTimeInternal, endTimeInternal);

  const formatParamsStart =
    format === "date"
      ? {
          value: {
            weekday: "short",
            month: "short",
            day: "2-digit",
          },
        }
      : {
          value: {
            hour: "numeric",
            minute: "numeric",
          },
        };

  const formatParamsEnd =
    format === "date"
      ? {
          value: {
            weekday: !isStartEndTimeOnSameDay ? "short" : undefined,
            month: !isStartEndTimeOnSameDay ? "short" : undefined,
            day: !isStartEndTimeOnSameDay ? "2-digit" : undefined,
          },
        }
      : {
          value: {
            hour: "numeric",
            minute: "numeric",
            timeZone: localZone,
            timeZoneName: "short",
          },
        };

  const startTimeString = i18n("{{value, datetime}}", {
    value: startTimeInternal,
    formatParams: formatParamsStart,
  });

  const endTimeString = i18n("{{value, datetime}}", {
    value: endTimeInternal,
    formatParams: formatParamsEnd,
  });

  const eventTimeText =
    format === "date" && isStartEndTimeOnSameDay
      ? `${startTimeString}`
      : `${startTimeString} - ${endTimeString}`;

  return (
    <Text
      className={mergeClasses(
        timeDisplayClasses.root,
        timeDisplayClasses.weightRegular,
        className
      )}
      data-testid="dateTimeDisplay"
    >
      {eventTimeText}
    </Text>
  );
};

export interface StartOrEndTimeDisplayOnlyProps {
  time: string;
  className: string;
}

export const StartOrEndTimeDisplayOnly: React.FunctionComponent<
  StartOrEndTimeDisplayOnlyProps
> = ({ time, className }) => {
  const { t: i18n } = useTranslation();
  if (!time) {
    return null;
  }
  const timeInternal = new Date(time);
  const localZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeDate = i18n("{{value, datetime}}", {
    value: timeInternal,
    formatParams: {
      value: {
        weekday: "long",
        month: "long",
        day: "2-digit",
      },
    },
  });

  const timeHour = i18n("{{value, datetime}}", {
    value: timeInternal,
    formatParams: {
      value: {
        hour: "numeric",
        minute: "numeric",
        timeZone: localZone,
        timeZoneName: "short",
      },
    },
  });
  const dateAndTime = timeDate + ", " + timeHour;

  return <Text className={className}>{dateAndTime}</Text>;
};

import { makeStyles, shorthands, tokens, zIndexes } from "../shared";
import { Breakpoint, mediaQueryBreakpointUp } from "./Grid";
import { HERO_IMAGE_ASPECT_RATIO } from "./HeroImage";

export const registrationCardStyles = makeStyles({
  root: {
    [mediaQueryBreakpointUp(Breakpoint.Large)]: {
      backgroundColor: tokens.colorNeutralBackground1,
      ...shorthands.padding("20px"),
      ...shorthands.borderRadius(tokens.borderRadiusXLarge),
      ...shorthands.border("1px", "solid", tokens.colorNeutralStroke2),
      boxSizing: "border-box",
      [mediaQueryBreakpointUp(Breakpoint.XXLarge)]: {
        marginTop: 30,
      },
      width: "100%",
      marginRight: 0,
    },
  },
  topSectionWithImage: {
    [mediaQueryBreakpointUp(Breakpoint.Large)]: {
      backgroundColor: tokens.colorNeutralBackground2,
      ...shorthands.border("1px", "solid", tokens.colorNeutralStroke2),
      boxSizing: "border-box",
      ...shorthands.padding("0px", "0px"),
      ...shorthands.margin("0px", "0px"),
      [mediaQueryBreakpointUp(Breakpoint.XXLarge)]: {
        marginTop: 0,
      },
      width: "100%",
      marginRight: 0,
    },
  },
  detailsSection: {
    paddingTop: "20px",
    backgroundColor: tokens.colorNeutralBackground1,
    [mediaQueryBreakpointUp(Breakpoint.Large)]: {
      ...shorthands.borderTop("1px", "solid", tokens.colorNeutralStroke2),
    },
  },
  TopInfoShowImage: {
    ...shorthands.padding("8px", "20px", "8px", "20px"),
  },
  detailsSectionShowImage: {
    ...shorthands.padding("15px", "20px", "20px", "20px"),
    backgroundColor: tokens.colorNeutralBackground1,
    [mediaQueryBreakpointUp(Breakpoint.Large)]: {
      ...shorthands.borderTop("0px", "solid", tokens.colorNeutralStroke2),
    },
  },
  registrationButtonShowImage: {
    ...shorthands.padding("0px", "20px", "20px", "20px"),
    backgroundColor: tokens.colorNeutralBackground1,
  },
  eventEndedPill: {
    display: "inline-flex",
    backgroundColor: tokens.colorNeutralBackground4,
    backgroundHover: undefined,
    ...shorthands.margin("4px"),
    ...shorthands.borderRadius(tokens.borderRadiusCircular),
  },
  eventEndedPillText: {
    ...shorthands.padding("4px", "8px"),
  },
  eventSoldOutText: {
    color: "rgb(196, 49, 75)",
  },
  image: {
    maxWidth: "68px",
    maxHeight: "68px",
  },
  container: {
    flexGrow: 1,
  },
  imageContainer: {
    marginLeft: "12px",
  },
});

export const registrationUnavailableScenarioStyles = makeStyles({
  root: {
    paddingTop: "30px",
    paddingBottom: "30px",
  },
});

export const registrationPageStyles = makeStyles({
  container: {
    minHeight: "45vh",
    [mediaQueryBreakpointUp(Breakpoint.Large)]: {
      marginTop: `calc((-1 / ${HERO_IMAGE_ASPECT_RATIO}) * 40%)`,
    },
    [mediaQueryBreakpointUp(Breakpoint.XLarge)]: {
      marginTop: `calc((-1 / ${HERO_IMAGE_ASPECT_RATIO}) * 30%)`,
    },
  },
  registerSectionMobileButton: {
    height: "44px",
    maxWidth: "110px",
  },
  eventAttendAndCancelTriggerButton: {
    height: "32px",
    contentFontWeight: tokens.fontWeightBold,
    lineHeight: tokens.lineHeightBase100,
    fontSize: "13px",
  },
  eventAttendAndCancelMenuIcon: {
    marginRight: "10px",
    verticalAlign: "text-bottom",
  },
  titleDisplay: {
    ...shorthands.margin(0),
    overflowWrap: "anywhere",
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightBold,
    lineHeight: tokens.lineHeightBase300,
  },
  registrationInformationStyles: {
    fontSize: tokens.fontSizeBase300,
    fontWeight: tokens.fontWeightBold,
    lineHeight: tokens.lineHeightBase300,
  },
});

const registrationFormFieldsGridGutterWidth = 12;
export const registrationFormStyles = makeStyles({
  root: {
    justifyItems: "start",
    lastChildMarginTop: 0,
  },
  logo: {
    marginTop: "-16px",
  },
  header: {
    [mediaQueryBreakpointUp(Breakpoint.Large)]: {
      paddingTop: "24px",
      paddingBottom: "24px",
      ...shorthands.borderBottom("1px", "solid", tokens.colorNeutralStroke2),
    },
  },
  headerImage: {
    zIndex: zIndexes.foreground,
    width: "32px",
    height: "32px",
  },
  body: {
    paddingTop: "13px",
    paddingBottom: "37px",
  },
  fieldsGrid: {
    // Using !important because the gutter style of the grid system which includes a media query is more specific, and has more priority.
    // Griffel from Fluent UI v9 also does not allow specifying class name for makeStyles classes to give it more priority.
    marginLeft: `${-registrationFormFieldsGridGutterWidth / 2}px !important`,
    marginRight: `${-registrationFormFieldsGridGutterWidth / 2}px !important`,
    "> *": {
      paddingLeft: `${registrationFormFieldsGridGutterWidth / 2}px !important`,
      paddingRight: `${registrationFormFieldsGridGutterWidth / 2}px !important`,
      marginBottom: "16px",
    },
  },
  card: {
    [mediaQueryBreakpointUp(Breakpoint.Large)]: {
      backgroundColor: tokens.colorNeutralBackground1,
      ...shorthands.borderRadius(tokens.borderRadiusMedium),
      boxShadow: tokens.shadow4,
    },
  },
  cardArea: {
    zIndex: zIndexes.foreground,
    [mediaQueryBreakpointUp(Breakpoint.Large)]: {
      marginBottom: "60px",
    },
  },
  label: {
    marginBottom: "4px",
    lineHeight: tokens.lineHeightBase200,
    display: "block",
  },
  itemLabel: {
    lineHeight: tokens.lineHeightBase200,
    display: "block",
  },
  itemInput: {
    ...shorthands.borderBottom("1px", "solid", tokens.colorNeutralStroke1),
    ":hover": {
      ...shorthands.borderBottom("1px", "solid", tokens.colorNeutralStroke1),
    },
  },
  inputPresenceAvailableIcon: {
    color: "rgb(199, 199, 199)",
  },
  validationErrorIcon: {
    color: tokens.colorPaletteRedForeground1,
  },
  validationSuccessIcon: {
    color: tokens.colorPaletteGreenForeground1,
  },
});

export const registrationMobileStyles = makeStyles({
  root: {
    position: "fixed",
    height: "88px",
    backgroundColor: tokens.colorNeutralBackground2,
    left: "0",
    right: "0",
    bottom: "0",
    ...shorthands.borderTop("1px", "solid", tokens.colorNeutralStroke2),
    zIndex: zIndexes.fixedMenu,
  },
  wrapper: {
    ...shorthands.padding("16px", "12px"),
  },
  eventEndedText: {
    color: tokens.colorNeutralForeground3,
    ...shorthands.padding("4px", "12px"),
  },
});

export const noOfAttendeesStyles = makeStyles({
  personRegisteredIcon: {
    color: tokens.colorNeutralForeground3,
  },
  mobileAttendingEventText: {
    color: tokens.colorNeutralForeground3,
    fontSize: "15px",
    paddingTop: "1px",
  },
  attendingEventText: {
    color: tokens.colorNeutralForeground3,
  },
});

export const registrationCancelMobileStyles = makeStyles({
  eventAttendingAndCancelButton: {
    color: tokens.colorBrandForeground1,
    colorHover: tokens.colorBrandForeground1,
  },
});

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CategoryName } from "../common/brb/interface";
import {
  brbSubmitAsyncAction,
  reportProblemDialogSelector,
  setReportProblemDialogAction,
} from "../core/slices/userSlice";
import {
  PortalFormField,
  PortalFormFields,
  PortalFormFieldType,
  PortalFormValidatorProvider,
  requiredStringValidator,
  usePortalFormValidatorContext,
  DialogContentV9 as DialogContent,
  DialogTitleV9 as DialogTitle,
  DialogV9 as Dialog,
  DialogSurfaceV9 as DialogSurface,
  DialogBodyV9 as DialogBody,
  DialogActionsV9 as DialogActions,
  DialogTriggerV9 as DialogTrigger,
  ButtonV9 as Button,
  mergeClasses,
} from "../shared";
import { isBrbEnabled } from "../configs/brbConfigs";
import { PortalFormFieldValue } from "../shared/form/PortalFormFields";
import { flexAlignStyles, flexStyles } from "../styles/FlexStyles";
import { reportProblemDialogStyles } from "../styles/ReportProblemDialog";

export const ReportProblemDialog: React.FunctionComponent = () => {
  const enableBrb = isBrbEnabled();
  const open = useSelector(reportProblemDialogSelector);

  return (
    <>
      {enableBrb && open && (
        <PortalFormValidatorProvider>
          <ReportProblemDialogInternal />
        </PortalFormValidatorProvider>
      )}
    </>
  );
};

export const ReportProblemDialogInternal: React.FunctionComponent = () => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const reportProblemDialogClasses = reportProblemDialogStyles();
  const dispatch = useDispatch();
  const { t: i18n } = useTranslation();
  const open = useSelector(reportProblemDialogSelector);

  const { errorCount } = usePortalFormValidatorContext();
  const [hasFormChanged, setHasFormChanged] = useState(false);
  const [hasSaveBeenClicked, setHasSaveBeenClicked] = useState(false);

  const onDismiss: () => void = () => {
    dispatch(setReportProblemDialogAction(false));
  };

  const onClickSubmit: () => void = () => {
    setHasSaveBeenClicked(true);
    if (!errorCount) {
      const data = {
        categoryName: reportProblemFields[0].value as CategoryName,
        reportDescription: reportProblemFields[1].value as string,
      };
      dispatch(brbSubmitAsyncAction(data));
    }
  };

  const categoryNameOptions = [
    {
      key: CategoryName.Registration,
      id: "report_problem_category_registration",
      content: i18n("report_problem_category_registration"),
    },
    {
      key: CategoryName.Profile,
      id: "report_problem_category_profile",
      content: i18n("report_problem_category_profile"),
    },
    {
      key: CategoryName.Other,
      id: "report_problem_category_other",
      content: i18n("report_problem_category_other"),
    },
  ];

  const [reportProblemFields, setReportProblemFields] = useState<
    PortalFormField[]
  >([
    {
      type: PortalFormFieldType.DropDown,
      name: "categoryName",
      labelTranslationKey: "report_problem_category_field_label",
      placeholderTranslationKey: "report_problem_category_field_placeholder",
      options: categoryNameOptions,
      validatorFns: [requiredStringValidator],
      disabled: false,
      borderless: false,
    },
    {
      type: PortalFormFieldType.TextArea,
      name: "reportDescription",
      labelTranslationKey: "report_problem_description_field_label",
      placeholderTranslationKey: "report_problem_description_field_placeholder",
      validatorFns: [requiredStringValidator],
      multiline: true,
      rows: 6,
      resizable: false,
      disabled: false,
      borderless: false,
    },
  ]);

  const onFieldChange = (
    fieldName: string,
    newValue?: PortalFormFieldValue
  ): void => {
    setHasFormChanged(true);
    const newFormValues = reportProblemFields.map((item) => {
      if (item.name === fieldName) {
        item.value = newValue;
      }
      return item;
    });
    setReportProblemFields(newFormValues);
  };

  const content = (
    <form
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexClasses.columnGapMedium,
        flexClasses.fill,
        flexAlignClasses.justifyContentSpaceEvenly,
        reportProblemDialogClasses.formElement
      )}
    >
      <PortalFormFields
        form={reportProblemFields}
        onChange={(item, newValue) => onFieldChange(item.name, newValue)}
        forceShowErrorMessage={hasSaveBeenClicked}
      />
    </form>
  );

  return (
    <Dialog open={open} onOpenChange={onDismiss}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle className={reportProblemDialogClasses.title}>
            {i18n("report_problem_dialog_header")}
          </DialogTitle>
          <DialogContent className={reportProblemDialogClasses.content}>
            {content}
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary" onClick={onDismiss}>
                {i18n("button_cancel")}
              </Button>
            </DialogTrigger>
            <Button
              appearance="primary"
              onClick={onClickSubmit}
              disabled={
                hasFormChanged || hasSaveBeenClicked ? !!errorCount : false
              }
            >
              {i18n("report_problem_button_submit")}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

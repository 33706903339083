import { makeStyles, shorthands } from "../shared";
import { Breakpoint, mediaQueryBreakpointDown } from "./Grid";

export const portalResponsiveDialogStyles = makeStyles({
  dialogSurface: {
    ...shorthands.padding("27px", "32px", "32px", "32px"),
    [mediaQueryBreakpointDown(Breakpoint.Medium)]: {
      ...shorthands.borderRadius("14px", "14px", "0", "0"),
      ...shorthands.margin("auto", "auto", "0", "auto"),
      ...shorthands.padding("35px", "16px", "48px", "16px"),
      justifyContent: "flex-end",
      maxWidth: "none",
    },
  },
  dialogActions: {
    [mediaQueryBreakpointDown(Breakpoint.Medium)]: {
      flexDirection: "column",
      maxWidth: "none",
    },
  },
  dialogBody: {
    [mediaQueryBreakpointDown(Breakpoint.Medium)]: {
      maxWidth: "none",
    },
  },
});

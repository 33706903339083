import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  ImageV9 as Image,
  mergeClasses,
  ButtonV9 as Button,
  DialogActionsV9 as DialogActions,
  DialogBodyV9 as DialogBody,
  DialogContentV9 as DialogContent,
  DialogSurfaceV9 as DialogSurface,
  DialogTitleV9 as DialogTitle,
  DialogTriggerV9 as DialogTrigger,
  DialogV9 as Dialog,
  TextV9 as Text,
  PortalAnchorV9 as PortalAnchor,
} from "../shared";
import { useLogger } from "../common/logger/LoggerContext";
import { LoggerLevels } from "../common/logger/interface";
import { useAuthenticationService } from "../core/auth/auth-context";
import { LoginState } from "../core/auth/authenticationService.interface";
import { IEvent } from "../core/slices/eventTypes.interface";
import LoginImage from "../assets/login.svg";
import { useSelector, useDispatch } from "react-redux";
import { eventSelector, resetEvent } from "../core/slices/eventSlice";
import { getMeAsyncAction } from "../core/slices/userSlice";
import { useTranslation } from "react-i18next";
import { eventLoginPageStyles } from "../styles/EventLoginPage";
import {
  enableRedirectLoginSelector,
  enableSocialLoginSelector,
} from "../core/slices/ecsSlice";
import { microsoftLinks, routes } from "../common/constants";
import { UserBIScenario } from "../common/logger/Logger";
import { buttonStyles } from "../styles/ButtonStyles";
import { gridStyles } from "../styles/Grid";
import {
  flexAlignStyles,
  flexItemStyles,
  flexStyles,
} from "../styles/FlexStyles";
import { portalTextStyles } from "../styles/PortalText";
import {
  getErrorResponse,
  isErrorResponseLicenseError,
  isErrorResponsePopupWindowError,
} from "../core/slices/error";
import { PortalLocationState } from "../core/history/history.interface";
import { generateSearchParams } from "../utilities/common/generateSearchParams";

export const EventLoginPage: React.FunctionComponent = () => {
  const flexItemClasses = flexItemStyles();
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const portalTextClasses = portalTextStyles();
  const gridClasses = gridStyles();
  const buttonClasses = buttonStyles();
  const eventLoginPageClasses = eventLoginPageStyles();
  const { authenticationService } = useAuthenticationService();
  const history = useHistory<PortalLocationState>();
  const location = useLocation<PortalLocationState>();
  const logger = useLogger()?.logger;
  const { t: i18n } = useTranslation();
  const currentEvent: IEvent | undefined = useSelector(eventSelector);
  const enableSocialLogin = useSelector(enableSocialLoginSelector);
  const enableRedirectLogin = useSelector(enableRedirectLoginSelector);
  const dispatch = useDispatch();

  const eventTitle = currentEvent?.title;
  const locationState: PortalLocationState = history.location.state;
  const redirectPath = locationState?.redirectPath;
  const showWorkOnly = locationState?.showWorkLoginOnly;
  const initiateWorkLogin = locationState?.initiateWorkLogin;
  const [errorDialogError, setErrorDialogError] = useState(null);

  const onLoginSuccessCallback = React.useCallback(() => {
    logger?.logTrace(LoggerLevels.info, "Login success, redirecting user");
    dispatch(getMeAsyncAction(true));
    /* istanbul ignore if */
    if (!!redirectPath) {
      dispatch(resetEvent());
      history.replace(redirectPath);
    } else {
      history.goBack();
    }
  }, [dispatch, history, logger, redirectPath]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onLoginFailureCallback = (err: any) => {
    setErrorDialogError(err);
  };

  const workLogin = React.useCallback(() => {
    const currentEventPath = currentEvent
      ? {
          pathname: `${routes.event}/${currentEvent.id}`,
          search: generateSearchParams(location),
        }
      : null;
    authenticationService
      .login(
        LoginState.Work,
        enableRedirectLogin,
        enableRedirectLogin
          ? history.createHref(redirectPath ?? currentEventPath ?? location)
          : undefined
      )
      .then(() => onLoginSuccessCallback())
      .catch((err) => onLoginFailureCallback(err));
  }, [
    authenticationService,
    currentEvent,
    enableRedirectLogin,
    history,
    location,
    onLoginSuccessCallback,
    redirectPath,
  ]);

  const onClickWorkLogin = React.useCallback(() => {
    logger?.logUiTelemetry(
      "auth",
      UserBIScenario.WorkLogin,
      "click",
      "none",
      "EventLoginPage"
    );
    workLogin();
  }, [logger, workLogin]);

  const onClickSocialLogin = React.useCallback(() => {
    logger?.logUiTelemetry(
      "auth",
      UserBIScenario.SocialLogin,
      "click",
      "none",
      "EventLoginPage"
    );
    const currentEventPath = currentEvent
      ? {
          pathname: `${routes.event}/${currentEvent.id}`,
          search: generateSearchParams(location),
        }
      : null;
    // TODO: Move this to user slice as action.
    authenticationService
      .login(
        LoginState.Social,
        enableRedirectLogin,
        enableRedirectLogin
          ? history.createHref(redirectPath ?? currentEventPath ?? location)
          : undefined
      )
      .then(() => onLoginSuccessCallback())
      .catch((err) => onLoginFailureCallback(err));
  }, [
    authenticationService,
    currentEvent,
    enableRedirectLogin,
    history,
    location,
    logger,
    onLoginSuccessCallback,
    redirectPath,
  ]);

  React.useEffect(() => {
    if (initiateWorkLogin) {
      workLogin();
    }
  }, [initiateWorkLogin, workLogin]);

  const getHeaderText = () => {
    if (!!currentEvent) {
      return i18n("login_page_header_with_event");
    } else {
      return i18n("login_page_header_no_event");
    }
  };

  const errorResponse = errorDialogError
    ? getErrorResponse("login", errorDialogError)
    : null;
  const isPopupError = errorResponse
    ? isErrorResponsePopupWindowError(errorResponse)
    : false;
  const isLicenseError = errorResponse
    ? isErrorResponseLicenseError(errorResponse)
    : false;

  const showErrorDialog = isPopupError || isLicenseError;

  const blockedDialog = (
    <Dialog open={showErrorDialog}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>
            {i18n(
              isPopupError
                ? "errors.auth_errors.popup_error_title"
                : "errors.service_errors.user_license_not_present_title"
            )}
          </DialogTitle>
          <DialogContent>
            {i18n(
              isPopupError
                ? "errors.auth_errors.popup_error_body_login"
                : "errors.service_errors.user_license_not_present_body"
            )}
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button
                appearance="secondary"
                onClick={() => setErrorDialogError(null)}
              >
                {i18n("button_close")}
              </Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );

  return (
    <div
      className={mergeClasses(
        "login_page",
        flexClasses.root,
        flexClasses.column,
        flexItemClasses.grow,
        eventLoginPageClasses.container
      )}
    >
      {blockedDialog}
      <div
        role="main"
        className={mergeClasses(
          gridClasses.container,
          flexClasses.root,
          flexClasses.column,
          flexClasses.columnGapLarge,
          flexAlignClasses.alignItemCenter,
          flexItemClasses.grow,
          eventLoginPageClasses.main
        )}
      >
        <div
          className={mergeClasses(
            flexClasses.root,
            flexClasses.column,
            flexClasses.columnGapSmall,
            flexAlignClasses.alignItemCenter
          )}
        >
          <Text
            id="login-page-header"
            className={mergeClasses(
              portalTextClasses.larger,
              eventLoginPageClasses.text,
              eventLoginPageClasses.headerTitleText
            )}
          >
            {getHeaderText()}
          </Text>
          {!!currentEvent && (
            <Text
              className={mergeClasses(
                portalTextClasses.large,
                eventLoginPageClasses.text,
                eventLoginPageClasses.headerEventText,
                portalTextClasses.eventTitle
              )}
            >
              {eventTitle}
            </Text>
          )}
        </div>

        <Image src={LoginImage} className={eventLoginPageClasses.image} />

        <div
          className={mergeClasses(
            flexClasses.root,
            flexClasses.column,
            flexClasses.columnGapSmall,
            flexAlignClasses.alignItemCenter,
            eventLoginPageClasses.buttonsWrapper
          )}
        >
          {enableSocialLogin && !showWorkOnly && (
            <Button
              id="sign-in-social"
              className={mergeClasses(
                eventLoginPageClasses.button,
                buttonClasses.fluid
              )}
              onClick={onClickSocialLogin}
            >
              {i18n("sign_in_social")}
            </Button>
          )}
          <Button
            id="sign-in-work"
            className={mergeClasses(
              eventLoginPageClasses.button,
              buttonClasses.fluid
            )}
            onClick={onClickWorkLogin}
          >
            {i18n("sign_in_work")}
          </Button>
        </div>
      </div>

      {!!!currentEvent && (
        <div
          role="contentinfo"
          className={mergeClasses(
            flexClasses.root,
            flexAlignClasses.justifyContentCenter,
            flexAlignClasses.alignItemCenter,
            eventLoginPageClasses.footer
          )}
        >
          <Text
            className={mergeClasses(
              eventLoginPageClasses.text,
              eventLoginPageClasses.privacyText
            )}
          >
            {
              <>
                {i18n("microsoft")}{" "}
                <PortalAnchor
                  aria-label={i18n("privacy_policy")}
                  href={microsoftLinks.microsoftPrivacyPolicy}
                >
                  {i18n("privacy_policy")}
                </PortalAnchor>
              </>
            }
          </Text>
        </div>
      )}
    </div>
  );
};

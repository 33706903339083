import { AnyAction, CombinedState, PreloadedState, Store } from "redux";
import {
  configureStore,
  ThunkAction,
  Action,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import ecsReducer from "../slices/ecsSlice";
import eventReducer, { resetEvent } from "../slices/eventSlice";
import userReducer, {
  authenticatedAction,
  setIsAuthenicationServiceInitializedAction,
  setRedirectloginErrorAsyncAction,
} from "../slices/userSlice";
import sessionReducer from "../slices/sessionSlice";
import sponsorReducer from "../slices/sponsorSlice";
import imageReducer from "../slices/imageSlice";
import { AXHelper } from "../api/axHelper";
import { AuthenticationService } from "../auth/authenticationService";
import {
  IAuthenticationService,
  LoginState,
} from "../auth/authenticationService.interface";
import { inTest } from "../slices/testFuncs";
import { NoInfer } from "@reduxjs/toolkit/dist/tsHelpers";

export class AuthNotifier {
  private static _singleton: AuthNotifier;
  private store: AppStore;

  constructor(authService: IAuthenticationService, store: Store<RootState>) {
    this.store = store;

    authService.registerAuthStateChangedCallback((userLoginState) => {
      this.setUserLoginState(userLoginState);
    });
    authService.registerAuthServiceIntializedCallback((value) => {
      this.setIsAuthServiceInitialized(value);
    });
    authService.registerRedirectLoginErrorCallback((error) => {
      this.setRedirectLoginError(error);
    });
  }

  public static create(
    authService: IAuthenticationService,
    store: Store<RootState>
  ): AuthNotifier {
    if (!AuthNotifier._singleton) {
      AuthNotifier._singleton = new AuthNotifier(authService, store);
    }
    return AuthNotifier._singleton;
  }

  public setUserLoginState(loginState: LoginState): void {
    this.store.dispatch(authenticatedAction(loginState));
    if (loginState === LoginState.NotLoggedIn) {
      this.store.dispatch(resetEvent());
    }
  }

  public setIsAuthServiceInitialized(isAuthServiceInitialized: boolean): void {
    this.store.dispatch(
      setIsAuthenicationServiceInitializedAction(isAuthServiceInitialized)
    );
  }

  public setRedirectLoginError(error: unknown): void {
    this.store.dispatch(setRedirectloginErrorAsyncAction(error));
  }
}

export function makeStore(
  preloadedState?: PreloadedState<CombinedState<NoInfer<RootState>>>
): Store {
  const useDevTools =
    process.env.NODE_ENV !== "production"
      ? true
      : /* istanbul ignore next */ false;

  const _store = configureStore({
    reducer: {
      ecs: ecsReducer,
      event: eventReducer,
      user: userReducer,
      sessions: sessionReducer,
      images: imageReducer,
      sponsor: sponsorReducer,
    },
    devTools: useDevTools,
    preloadedState: preloadedState ?? {},
  });

  /* istanbul ignore next */
  if (!inTest()) {
    const authService = AuthenticationService.getInstance();
    authService.setStore(_store);

    // initialize Axios transport
    AXHelper.createAXHelper(authService, _store);
    AuthNotifier.create(authService, _store);
  }
  return _store;
}

export const store = makeStore();
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type AppThunkDispatch = ThunkDispatch<RootState, unknown, AnyAction>;
export type AppStore = Omit<Store<RootState, AnyAction>, "dispatch"> & {
  dispatch: AppThunkDispatch;
};

import { Guid, CMDDateTime, EventId } from "./CMDTypes";
import { EventUserRole } from "./userTypes.interface";

//TODO: This will take the form od ID and ANSWERS as specified in the POST section of registration doc
/* eslint-disable @typescript-eslint/no-empty-interface */
export interface IEventRegisterAnswers {}
/* eslint-enable @typescript-eslint/no-empty-interface */

export enum PageViewType {
  EVENTPAGE = "EventPageViewCount",
  REGISTRATIONPAGE = "RegistrationPageViewCount",
}

export interface IPageType {
  type: PageViewType;
}

export enum PageViewCountStatus {
  UNKNOWN = "Unknown",
  SUCCESS = "Success",
}
export interface IEventPageViewCountResponse {
  status: PageViewCountStatus;
}

//TODO: Work item to address Registration Full and all other
//registration states and lifecycles - https://domoreexp.visualstudio.com/MSTeams/_workitems/edit/2147818/
export enum UserRegistrationStatus {
  DRAFT = "Draft",
  REGISTERED = "Registered",
  AWAITING_APPROVAL = "PendingApproval",
  PROCESSING_APPROVAL = "ProcessingApproval",
  REJECTED = "Rejected",
  WAITLIST = "Waitlisted",
  WAITLIST_FULL = "WaitlistFull",
  REGISTRATION_FULL = "RegistrationFull",
  CANCELED = "Canceled",
  UNKNOWN = "Unknown",
  UNREGISTERED = "Unregistered",
}

export interface IEventRegisterResponse {
  meetingJoinUrl?: string;
  registrationId: Guid;
  status: UserRegistrationStatus;
}

export enum EventType {
  CONFERENCE = "Conference",
  WEBINAR = "Webinar",
  TOWNHALL = "Townhall",
  CUSTOM = "Custom",
  UNKNOWN = "Unknown",
}

export enum EventState {
  PUBLISHED = "Published",
  DRAFT = "Draft",
  CANCELED = "Canceled",
}

export enum AccessType {
  PUBLIC = "Public",
  PRIVATE = "Private",
}

export enum RecurrencePatternType {
  DAILY = "Daily",
  WEEKLY = "Weekly",
  ABSOLUTE_MONTHLY = "AbsoluteMonthly",
  ABSOLUTE_YEARLY = "AbsoluteYearly",
  RELATIVE_YEARLY = "RelativeYearly",
}

export interface RecurrencePattern {
  patternType?: RecurrencePatternType;
  daysOfWeek?: number[];
  dayOfMonth?: number;
  month?: number;
  interval: number;
  range: CMDDateTime;
}

export interface IEventImageRecord {
  image: string;
  id: string;
}

export interface IEventRegister {
  eventDetails: {
    title: string;
    access: IEventAccessPermissions;
    theme: {
      logoImage: string;
      promoImage: string;
      primaryColor: string;
    };
    type: EventType;
  };
  registrationProperties: {
    registrationOpenTime?: {
      startTime: string;
      endTime: string;
      timeZoneOffset: number;
    };
    registrationLimit?: number;
    registeredAttendeeCount?: number;
    isFull?: boolean;
    isWaitlistEnabled?: boolean;
    waitlistLimit?: number;
    isWaitlistFull?: boolean;
    isManualApprovalEnabled?: boolean;
    termsOfUseUrl: string;
  };
  questions: Questions[];
}

export interface IEventTheme {
  logoImage: string;
  promoImage: string;
  primaryColor: string;
}

export interface IEventOwner {
  id: Guid;
  firstName: string | null;
  lastName: string | null;
  displayName: string | null;
  email: string;
  identity: {
    upn: string; // User Principal Name
    tenantId?: string;
  };
}

export interface ITenantAddress {
  street: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}
export interface IEventOrganization {
  tenantId: string;
  tenantName: string;
  privacyLink: string;
  address: ITenantAddress;
}

export enum SessionType {
  SINGLE = "Single",
  MULTI = "Multi",
}

export interface IEventCommunicationProperties {
  isSocialShareEnabled?: boolean;
}

export interface IEvent {
  id: EventId;
  title: string;
  description?: string;
  eventTime: CMDDateTime;
  recurrencePattern?: RecurrencePattern;
  state?: string;
  location: string;
  type: EventType;
  theme: IEventTheme;
  owner: IEventOwner;
  roles: EventUserRole[];
  team: IEventOwner[];
  access: IEventAccessPermissions;
  organization?: IEventOrganization;
  siteUrl: string;
  sessionType: SessionType;
  communicationProperties?: IEventCommunicationProperties;
}

export type Theme = {
  logoImage?: string;
  promoImage?: string;
  primaryColor?: string;
};

export enum QuestionCategories {
  USER_CREATED = "UserCreated",
  PREDEFINED = "Predefined",
}

export interface Questions {
  id: Guid;
  internalName: string;
  category: string;
  type: string;
  displayText: string;
  choices?: string[];
  isRequired?: boolean;
  isCheckedByDefault?: boolean;
  order: number;
  answer?: string;
}

export enum PredefinedQuestionsInternal {
  Address = "Address",
  City = "City",
  State = "State",
  ZipCode = "ZipCode",
  Country = "Country",
  Industry = "Industry",
  JobTitle = "JobTitle",
  Organization = "Organization",
}

export interface IEventAccessPermissions {
  accessType: AccessType;
  registrationPermission: string;
}

import React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setPresenterKeyAction } from "../../core/slices/eventSlice";
import { PortalLocationState } from "../../core/history/history.interface";

export const usePresenterKeySearchParamHandlerHook = (): void => {
  const { search } = useLocation<PortalLocationState>();
  const dispatch = useDispatch();

  const presenterKey = new URLSearchParams(search).get("presenterKey");

  React.useEffect(() => {
    if (presenterKey != null) {
      dispatch(setPresenterKeyAction(presenterKey));
    }
  }, [presenterKey, dispatch]);
};

import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { PortalLocationState } from "../../core/history/history.interface";
import { redirectLoginErrorSelector } from "../../core/slices/userSlice";
import { generateSearchParams } from "../common/generateSearchParams";
import { routes } from "../../common/constants";

export const useRedirectLoginErrorHandlerHook = (): void => {
  const history = useHistory<PortalLocationState>();
  const redirectLoginError = useSelector(redirectLoginErrorSelector);

  React.useEffect(() => {
    if (redirectLoginError) {
      history.replace({
        pathname: routes.authError,
        search: generateSearchParams(history.location),
        state: {
          from: {
            pathname: history.location.pathname,
            search: generateSearchParams(history.location),
            hash: history.location.hash,
          },
        },
      });
    }
  }, [history, redirectLoginError]);
};

import { EventGroup } from '../utilities/EventGroup';
import type { IDisposable } from '@ms/utilities-disposables/lib/Disposable';

import type { ICommand } from '../interfaces/Commands';
import type {
  IInitializeMessage,
  IMessagePort,
  IMessenger,
  INotification,
  IIdentifyParentMessage
} from '../interfaces/Messenger';
import type { IResult, IQosError } from '../interfaces/Results';
import { Messenger } from './Messenger';
import { Scope } from '@ms/utilities-disposables/lib/Scope';

/**
 * Parameters to construct a host messenger, for communication with a client window.
 */
export interface IHostParams {
  /**
   * Unique identifier provided by the host to verify the source of messages.
   */
  channelId: string;

  /**
   * Origin of the client window.
   */
  origin: string;

  /**
   * The delay in milliseconds to wait for channel initialization.
   */
  initTimeoutMs?: number;

  /**
   * A default timeout for acknowledging commands, in milliseconds.
   */
  ackTimeoutMs?: number;

  /**
   * A default timeout for waiting for command results, in milliseconds.
   */
  resultTimeoutMs?: number;

  /**
   * Window from which the initialize message will be sent, e.g. iframe.contentWindow. If not specified, will not be validated.
   */
  source?: Window;

  /**
   * Object that will receive the 'initialize' message. Defaults to `window`.
   */
  receiver?: EventTarget;

  /**
   * Whether or not the host needs to explicitly identify itself to the client using proactive post-message calls.
   */
  identifyParent?: boolean;

  /**
   * Handler for commands sent by the client. This function should check the `command` field to determine the appropriate action.
   * For unknown commands, the handler shall return `undefined`.
   */
  onCommand?(command: ICommand): Promise<IResult> | IResult | undefined;

  /**
   * Handler for notifications sent by the client.
   */
  onNotification?(notification: INotification): void;

  /**
   * Handler for if a frame is initialized a second time with the same channel id.
   */
  onReinitialize?(): void;
}

export interface IHostDependencies {
  /**
   * Test hook to override the `EventGroup` constructor.
   * @internal
   */
  eventGroupType?: typeof EventGroup;

  /**
   * Test hook to override the `Messenger` constructor.
   * @internal
   */
  messengerType?: typeof Messenger;
}

/**
 * Creates a messenger for communication with a client.
 *
 * @param params - Configuration for the messenger
 * @param dependencies - Optional overrides for external components, for testing
 */
export function createHost(
  params: IHostParams,
  dependencies: IHostDependencies = {}
): IMessenger & IDisposable {
  const { channelId, onReinitialize, origin, receiver = window, source, identifyParent } = params;

  const { eventGroupType = EventGroup, messengerType = Messenger } = dependencies;

  const scope = new Scope();

  const events = scope.attach(new eventGroupType(null));

  const init = (resolve: (port: IMessagePort) => void, reject: (error: IQosError) => void) => {
    let interval: IDisposable | undefined;

    if (identifyParent && source) {
      const intervalId = setInterval(() => {
        const message: IIdentifyParentMessage = {
          type: 'identify-parent',
          channelId
        };

        source.postMessage(message, origin);
      }, 100);

      interval = scope.attach({
        dispose: () => {
          clearInterval(intervalId);
        }
      });
    }

    let boundToListener = false;
    const initListener = (event: MessageEvent): void => {
      const data: IInitializeMessage = event.data;
      if (
        event.origin === origin &&
        (!source || event.source === source) &&
        data &&
        typeof data === 'object' &&
        data.type === 'initialize' &&
        data.channelId === channelId
      ) {
        if (interval) {
          interval.dispose();
        }
        if (onReinitialize) {
          if (boundToListener) {
            onReinitialize();
            events.dispose();
            return;
          }
          boundToListener = true;
        } else {
          events.dispose();
        }

        const port = data.replyTo;
        port.postMessage({
          type: 'activate'
        });
        resolve(port);
      }
    };

    events.on(receiver, 'message', initListener);
  };

  return new messengerType({
    onCommand: params.onCommand,
    onNotification: params.onNotification,
    init: init,
    initResources: scope,
    initTimeoutMs: params.initTimeoutMs,
    ackTimeoutMs: params.ackTimeoutMs,
    resultTimeoutMs: params.resultTimeoutMs
  });
}

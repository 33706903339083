import { IShortcutService } from "./interface";
import * as React from "react";

export interface IShortcutServiceContext {
  shortcutService: IShortcutService;
}

export const ShortcutServiceContext: React.Context<IShortcutServiceContext | null> =
  React.createContext<IShortcutServiceContext | null>(null);

type ShortcutServiceProviderPropsType = IShortcutServiceContext & {
  children?: JSX.Element;
};

export const ShortcutServiceContextProvider: React.FunctionComponent<
  ShortcutServiceProviderPropsType
> = (props: ShortcutServiceProviderPropsType): JSX.Element => {
  const { children } = props;
  return (
    <ShortcutServiceContext.Provider value={props}>
      {children}
    </ShortcutServiceContext.Provider>
  );
};
ShortcutServiceContextProvider.displayName = "ShortcutServiceContextProvider";

import React from "react";
import { Link, LinkProps, mergeClasses } from ".";
import { portalAnchorStyles } from "../styles/PortalAnchor";

/**
 * This is the euqivalent of v0 portal anchor for external navigation.
 */
export const PortalAnchorV9: React.FunctionComponent<LinkProps> = (props) => {
  const portalAnchorClasses = portalAnchorStyles();
  const className = mergeClasses(portalAnchorClasses.root, props.className);
  return <Link {...props} className={className} />;
};

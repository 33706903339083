import { CmdServicesResponseErrorCode, ErrorType, IError } from "./error";
import { RecordingPublishStatus } from "./session.interface";

export const isAttendeeNotFoundError = (response: IError): boolean => {
  return (
    !!response &&
    response.type === ErrorType.CMD_SERVICES &&
    response.responseErrorCode === CmdServicesResponseErrorCode.FORBIDDEN &&
    response.responseErrorMessage === "Attendee not found by id." &&
    response.status === 403
  );
};

export const isExpiredRecordingError = (response: IError): boolean => {
  return (
    !!response &&
    response.type === ErrorType.CMD_SERVICES &&
    isExpiredRecording(response)
  );
};

export const isExpiredASLError = (response: IError): boolean => {
  return (
    !!response && response.type === ErrorType.ODSP && response.status === 403
  );
};

export const isConditionalAccessPolicyError = (response: IError): boolean => {
  return (
    !!response &&
    response.type === ErrorType.ODSP &&
    response.status === 401 &&
    response.responseError?.innerError?.code ===
      "conditionalAccessPolicyEnforced"
  );
};

function isExpiredRecording(response: IError): boolean {
  return (
    response.responseErrorCode === CmdServicesResponseErrorCode.BAD_REQUEST &&
    response.status === 400 &&
    !!response.responseErrorMessage &&
    response.responseErrorMessage.startsWith(
      "Recording is not in valid status."
    ) &&
    response.responseErrorMessage.includes(
      ` Status: ${RecordingPublishStatus.Expired}`
    )
  );
}

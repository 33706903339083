import { makeStyles, shorthands, tokens } from "../shared";

export const footerStyles = makeStyles({
  root: {
    backgroundColor: tokens.colorNeutralBackground2,
    ...shorthands.borderTop("1px", "solid", tokens.colorNeutralStroke2),
  },
  contentStyles: {
    maxWidth: "600px",
    paddingTop: "16px",
    paddingBottom: "24px",
  },
  footerMobileStyles: {
    marginBottom: "88px",
  },
  organizerText: {
    ...shorthands.borderRadius(tokens.borderRadiusSmall),
    color: tokens.colorNeutralForeground3,
    marginBottom: "4px",
  },
  tenantPrivacyStatement: {
    color: tokens.colorNeutralForeground3,
    lineHeight: tokens.lineHeightBase100,
  },
  privacyStatement: {
    marginTop: "16px",
    color: tokens.colorNeutralForeground3,
    lineHeight: tokens.lineHeightBase100,
  },
});

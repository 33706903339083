import React from "react";
import { useTranslation } from "react-i18next";
import {
  mergeClasses,
  ButtonV9 as Button,
  TextV9 as Text,
  getWindow,
  PortalAnchorV9 as PortalAnchor,
} from "../shared";
import { CreateWebinarCardStyles } from "../styles/CreateWebinarCard";
import { buttonStyles } from "../styles/ButtonStyles";
import { flexAlignStyles, flexStyles } from "../styles/FlexStyles";
import { eventInfoStyles } from "../styles/EventInfo";
import { UserBIScenario } from "../common/logger/Logger";
import { useLogger } from "../common/logger/LoggerContext";
import { microsoftLinks } from "../common/constants";
import { isDODCloud, isGCCHCloud } from "../configs/authConfigs";

export const CreateWebinarCard: React.FunctionComponent = () => {
  const logger = useLogger()?.logger;
  const flexClasses = flexStyles();
  const flexAlignClasses = flexAlignStyles();
  const buttonClasses = buttonStyles();
  const CreateWebinarCardStylesClasses = CreateWebinarCardStyles();
  const eventInfoClasses = eventInfoStyles();

  const { t: i18n } = useTranslation();
  const window = getWindow();
  const onClickCreateWebinarButton = () => {
    const createWebinarUrl = isGCCHCloud()
      ? microsoftLinks.createWebinarGCCH
      : isDODCloud()
      ? microsoftLinks.createWebinarDOD
      : microsoftLinks.createWebinar;

    window?.open(createWebinarUrl, "_blank")?.focus();
    logger?.logUiTelemetry(
      "clickButton",
      UserBIScenario.CreateWebinarButton,
      "click",
      "none",
      "CreateWebinarCard"
    );
  };

  const createWebinarButtonSection = () => {
    return (
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.column,
          flexClasses.columnGap12px,
          buttonClasses.fluid,
          CreateWebinarCardStylesClasses.createWebinarButtonSection
        )}
      >
        <Button
          id="create_webinar_button"
          className={mergeClasses(buttonClasses.fluid)}
          appearance="secondary"
          onClick={onClickCreateWebinarButton}
          aria-label={i18n("create_webinar_button")}
        >
          {i18n("create_webinar_button")}
        </Button>
      </div>
    );
  };

  const createWebinarSection = (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexAlignClasses.alignItemFlexStart,
        CreateWebinarCardStylesClasses.topSectionOfCreateWebinarCard
      )}
    >
      <div
        className={mergeClasses(
          CreateWebinarCardStylesClasses.detailsSection,
          flexClasses.fluid
        )}
      >
        <div
          className={mergeClasses(
            flexClasses.root,
            flexClasses.column,
            flexClasses.columnGap12px,
            flexClasses.root,
            flexClasses.rowGap12px,
            eventInfoClasses.eventDetailsTextStyles
          )}
        >
          <Text size={300} weight="semibold">
            {i18n("create_webinar_header")}
          </Text>
        </div>
        <div className={CreateWebinarCardStylesClasses.descriptionSection}>
          <Text
            className={mergeClasses(
              CreateWebinarCardStylesClasses.descriptionText
            )}
          >
            {i18n("create_webinar_description")}
          </Text>

          <PortalAnchor
            aria-label={`${i18n("learn_more_webinar_link")}}`}
            href={microsoftLinks.learnMoreWebinar}
            onClick={() =>
              logger?.logUiTelemetry(
                "clickButton",
                UserBIScenario.LearnMoreWebinarButton,
                "click",
                "none",
                "CreateWebinarCard"
              )
            }
          >
            {<>{i18n("learn_more_webinar_link")}</>}
          </PortalAnchor>
        </div>
      </div>
      {createWebinarButtonSection()}
    </div>
  );

  return createWebinarSection;
};

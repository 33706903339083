import { makeStyles, shorthands, tokens } from "../shared";

export const eventSessionsStyles = makeStyles({
  dateButtonSelected: {
    backgroundColor: tokens.colorBrandBackground,
    ...shorthands.borderColor("transparent"),
    ":hover": {
      backgroundColor: tokens.colorBrandBackground,
      ...shorthands.borderColor("transparent"),
    },
  },
});

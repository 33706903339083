import { AccountInfo } from "@azure/msal-browser-1p";
import {
  IBadgerFetchResponse,
  ISkypeTokenFetchResponse,
} from "./authenticationService.interface";

export function isValidSkypeTokenResponse(
  obj: ISkypeTokenFetchResponse | null
): boolean {
  return obj ? !!obj.tokens && !!obj.tokens.skypeToken && !!obj.region : false;
}

export function isValidBadgerTokenResponse(obj: IBadgerFetchResponse): boolean {
  return new Date() < new Date(obj.expiryTimeUtc);
}

export function areAccountsEqual(
  account1: AccountInfo,
  account2: AccountInfo
): boolean {
  return (
    account1.localAccountId === account2.localAccountId &&
    account1.username === account2.username &&
    account1.tenantId === account2.tenantId &&
    account1.environment === account2.environment
  );
}

import React from "react";
import { useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { EventType, IEvent } from "../core/slices/eventTypes.interface";
import {
  MenuListV9 as MenuList,
  MenuItemV9 as MenuItem,
  mergeClasses,
} from "../shared";
import { eventNavStyles } from "../styles/EventNav";
import { ILogger } from "../common/logger/interface";
import { UserBIScenario } from "../common/logger/Logger";
import { navItemIds } from "../common/constants";
import { useLogger } from "../common/logger/LoggerContext";
import { enableSponsorsSelector } from "../core/slices/ecsSlice";
import { eventSponsorsSelector } from "../core/slices/sponsorSlice";
import { eventSelector } from "../core/slices/eventSlice";
import { flexStyles } from "../styles/FlexStyles";
import { generateSearchParams } from "../utilities/common/generateSearchParams";
import { PortalLocationState } from "../core/history/history.interface";

const getUserBIScenarioByMenuItemId: (id: string) => UserBIScenario = (
  id: string
) => {
  switch (id) {
    case navItemIds.speakersTab:
      return UserBIScenario.ViewSpeakers;
    case navItemIds.sessionsTab:
      return UserBIScenario.ViewSessions;
    case navItemIds.myAgendaTab:
      return UserBIScenario.ViewAgenda;
    case navItemIds.sponsorsTab:
      return UserBIScenario.ViewSponsors;
    case navItemIds.informationTab:
      return UserBIScenario.ViewInformation;
    /* istanbul ignore next */
    default:
      return UserBIScenario.Unknown;
  }
};

export enum EventNavDirection {
  Horizontal,
  Vertical,
}

interface IEventNavProps {
  /**Nav path for each nav */
  urlPath: string;
  direction?: EventNavDirection;
  onNav?: () => void;
}

export const EventNav: React.FunctionComponent<IEventNavProps> = (
  props: IEventNavProps
) => {
  const eventNavClasses = eventNavStyles();
  const flexClasses = flexStyles();
  const { direction = EventNavDirection.Horizontal, onNav } = props;
  const isHorizontal = direction === EventNavDirection.Horizontal;
  const history = useHistory<PortalLocationState>();
  const location = useLocation<PortalLocationState>();
  const logger = useLogger()?.logger;
  const basePath = props.urlPath;
  const currentPath = location.pathname;
  const enableSponsors = useSelector(enableSponsorsSelector);
  const sponsors = useSelector(eventSponsorsSelector);
  const currentEvent: IEvent | undefined = useSelector(eventSelector);

  const onClickMenuItem = React.useCallback(
    (navItem: INavItem) => {
      if (onNav) {
        onNav();
      }
      const scenario = getUserBIScenarioByMenuItemId(navItem.id);
      logger?.logUiTelemetry(
        "triggerNavigation",
        scenario,
        "click",
        "none",
        navItem.id
      );
      history.push({
        pathname: navItem.url,
        search: generateSearchParams(location),
      });
    },
    [history, location, logger, onNav]
  );

  if (
    !currentEvent ||
    currentEvent.type === EventType.WEBINAR ||
    currentEvent.type === EventType.TOWNHALL
  ) {
    return null;
  }

  interface INavItem {
    name: string;
    id: string;
    url: string;
    currentPath: string;
    logger?: ILogger;
  }

  const navItemsConference: INavItem[] = [
    {
      name: "Information",
      id: navItemIds.informationTab,
      url: basePath,
      currentPath: currentPath,
      logger: logger,
    },
    {
      name: "Speakers",
      id: navItemIds.speakersTab,
      url: `${basePath}/speakers`,
      currentPath: currentPath,
      logger: logger,
    },
    {
      name: "Sessions",
      id: navItemIds.sessionsTab,
      url: `${basePath}/sessions`,
      currentPath: currentPath,
      logger: logger,
    },
    {
      name: "My Agenda",
      id: navItemIds.myAgendaTab,
      url: `${basePath}/myagenda`,
      currentPath: currentPath,
      logger: logger,
    },
  ];

  if (enableSponsors && sponsors) {
    navItemsConference.push({
      name: "Sponsors",
      id: navItemIds.sponsorsTab,
      url: `${basePath}/sponsors`,
      currentPath: currentPath,
      logger: logger,
    });
  }

  return (
    <MenuList
      className={
        isHorizontal
          ? mergeClasses(flexClasses.row, flexClasses.rowGap24px)
          : mergeClasses(flexClasses.column)
      }
      data-testid="eventNavMenuList"
    >
      {navItemsConference.map((navItem) => (
        <MenuItem key={navItem.url} className={eventNavClasses.item}>
          <span
            id={navItem.id}
            className={mergeClasses(
              eventNavClasses.itemContent,
              navItem.url === navItem.currentPath &&
                eventNavClasses.itemContentActive
            )}
            onClick={() => onClickMenuItem(navItem)}
          >
            {navItem.name}
          </span>
        </MenuItem>
      ))}
    </MenuList>
  );
};

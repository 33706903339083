import React, { ReactNode, useRef, useLayoutEffect } from "react";
import { Questions } from "../../core/slices/eventTypes.interface";
import { QuestionTypes } from "../../core/slices/eventSlice";
import "../../styles/EventPage.scss";
import { useTranslation } from "react-i18next";
import {
  PortalAnchorV9 as PortalAnchor,
  Field,
  LabelV9 as Label,
  RadioGroup,
  Radio,
  CheckboxV9 as Checkbox,
  mergeClasses,
  TextV9 as Text,
} from "..";
import { flexStyles } from "../../styles/FlexStyles";
import { formOptionStyles } from "../../styles/FormOption.";
import { ValidationMessage } from "./PortalFormFields";

export interface IFormOptions {
  question: Questions;
  termsOfUseUrl?: string;
  localizedDisplayLabel: string;
  onChange(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    quesId: string,
    quesType: string,
    choices?: string[],
    choice?: string
  ): void;
  validationError?: string;
  promoConsent?: boolean;
  updateQuestionRefs(questionId: string, referenceElem: HTMLDivElement): void;
  validationStates?: Map<
    string,
    "none" | "success" | "error" | "warning" | undefined
  >;
}

export const FormOptions: React.FunctionComponent<{
  formOptions: IFormOptions;
}> = ({ formOptions }) => {
  const {
    question,
    termsOfUseUrl,
    localizedDisplayLabel,
    onChange,
    validationError,
    promoConsent,
    updateQuestionRefs,
    validationStates,
  } = formOptions;
  const flexClasses = flexStyles();
  const formOptionClasses = formOptionStyles();
  const { t: i18n } = useTranslation();

  const referenceElemTrial = useRef<HTMLDivElement>(null);

  let field = null;

  useLayoutEffect(() => {
    const { current } = referenceElemTrial;
    if (current !== null) {
      updateQuestionRefs(question.id, current as HTMLDivElement);
    }
  }, [question.id, updateQuestionRefs]);

  const requiredAriaLabel = question.isRequired ? i18n("required") : "";

  switch (question.type) {
    case QuestionTypes.SingleChoice:
      field = (
        <Field
          validationState={validationStates?.get(question.id)}
          validationMessage={<ValidationMessage message={validationError} />}
          validationMessageIcon={null}
        >
          <Label
            htmlFor={question.id}
            required={question.isRequired}
            aria-label={`${localizedDisplayLabel} ${requiredAriaLabel}`}
          >
            {localizedDisplayLabel + " "}
          </Label>
          <RadioGroup
            id={question.id}
            ref={referenceElemTrial}
            onChange={(_, data) => {
              onChange(data?.value, question.id, question.type);
            }}
            aria-label={`${localizedDisplayLabel} ${requiredAriaLabel}`}
          >
            {question.choices?.map((choice, i) => (
              <Radio
                key={question.id + "-" + i}
                id={question.id + "-" + i}
                label={
                  <label className={formOptionClasses.itemLabel}>
                    {choice}
                  </label>
                }
                value={choice}
              />
            ))}
          </RadioGroup>
        </Field>
      );
      break;
    case QuestionTypes.MultiChoice:
      const chkOptionsV9: ReactNode[] = [];
      if (question.choices) {
        question.choices.forEach((choice, i) => {
          chkOptionsV9.push(
            <Checkbox
              key={question.id + "-" + i}
              id={question.id + "_" + i}
              label={
                <label className={formOptionClasses.itemLabel}>{choice}</label>
              }
              onChange={(e, data) => {
                onChange(
                  data?.checked,
                  question.id,
                  question.type,
                  question.choices,
                  choice
                );
              }}
              aria-label={choice}
              aria-describedby={choice}
            />
          );
        });
        field = (
          <div id={question.id + "-MultiChoice"}>
            <Field
              validationState={validationStates?.get(question.id)}
              validationMessage={
                <ValidationMessage message={validationError} />
              }
              validationMessageIcon={null}
              ref={referenceElemTrial}
            >
              <Label
                htmlFor={question.id}
                required={question.isRequired}
                className={formOptionClasses.multiChoiceLabel}
                aria-label={`${localizedDisplayLabel} ${requiredAriaLabel}`}
              >
                {localizedDisplayLabel}
              </Label>
              <div
                className={mergeClasses(flexClasses.root, flexClasses.column)}
              >
                {chkOptionsV9}
              </div>
            </Field>
          </div>
        );
      }
      break;
    case QuestionTypes.PromoEmailConsent:
    case QuestionTypes.BooleanType:
      let displayLabel: ReactNode = localizedDisplayLabel;
      if (termsOfUseUrl && termsOfUseUrl?.trim().length > 0) {
        displayLabel = (
          <Text
            weight="semibold"
            aria-label={`${i18n(
              "terms_of_use_statement"
            )} ${localizedDisplayLabel} ${requiredAriaLabel} ${i18n(
              "link_new_tab"
            )}`}
          >
            <>
              {i18n("terms_of_use_statement")}&nbsp;
              <PortalAnchor
                id="terms_and_conditions"
                href={termsOfUseUrl}
                target="_blank"
              >
                {localizedDisplayLabel}
              </PortalAnchor>
            </>
          </Text>
        );
      } else {
        displayLabel = (
          <Text
            weight={
              question.type === QuestionTypes.PromoEmailConsent
                ? "semibold"
                : "regular"
            }
            className={formOptionClasses.itemLabel}
            aria-label={`${localizedDisplayLabel} ${requiredAriaLabel}`}
          >
            {localizedDisplayLabel}
          </Text>
        );
      }
      field = (
        <div id={question.id + `-${question.type}`}>
          <Field
            required={question.isRequired}
            ref={referenceElemTrial}
            validationState={validationStates?.get(question.id)}
            validationMessage={<ValidationMessage message={validationError} />}
            validationMessageIcon={null}
          >
            <Checkbox
              label={displayLabel}
              key={question.id}
              id={question.id}
              data-testid={question.id}
              checked={promoConsent}
              onChange={(e, data) => {
                onChange(data?.checked, question.id, question.type);
              }}
              aria-required={!!question?.isRequired}
            />
          </Field>
        </div>
      );
      break;
    default:
      break;
  }

  return field;
};

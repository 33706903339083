import { makeStyles, shorthands, tokens } from "../shared";

export const portalTextStyles = makeStyles({
  smaller: {
    fontSize: tokens.fontSizeBase100, //10px
  },
  small: {
    fontSize: tokens.fontSizeBase200, //12px
  },
  medium: {
    fontSize: tokens.fontSizeBase300, //14px
  },
  medium2: {
    fontSize: tokens.fontSizeBase400, //16px
  },
  large: {
    fontSize: tokens.fontSizeBase500, //20px
  },
  larger: {
    fontSize: tokens.fontSizeBase600, //24px
  },
  xLarge: {
    fontSize: tokens.fontSizeHero700, //28px
  },
  eventTitle: {
    ...shorthands.margin(0),
    overflowWrap: "anywhere",
  },
  colorBrand: {
    color: tokens.colorBrandForeground1,
  },
  smallLineHeight: {
    lineHeight: tokens.lineHeightBase200,
  },
  mediumLineHeight: {
    lineHeight: tokens.lineHeightBase300,
  },
  lineHeight12px: {
    lineHeight: "12px",
  },
  lineHeight24px: {
    lineHeight: "24px",
  },
  lineHeight26px: {
    lineHeight: "26px",
  },
  size18px: {
    fontSize: "18px",
  },
  size17px: {
    fontSize: "17px",
  },
  size22px: {
    fontSize: "22px",
  },
});

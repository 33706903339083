import { routes } from "../common/constants";
import { loggerConfig } from "../configs/loggerConfigs";

// Link to list of CI-Fx tenants for reference if needed:
// https://microsoft.sharepoint.com/:o:/t/skypespacesprague0/Egb8xcZAGQVDkeWQ9w1ZNgsBqrEmIq7wu_BJv4U5Qj0HwA?e=ZkxWll
const automatedTestingTenants = [
  "6a990bf4-98f0-4f32-bdd2-3ac3f95fe146", // cifxtmppr.onmicrosoft.com
  "fe8d0eaa-329b-4f48-8b71-8917f976f5e8", // cifxtmpgate.onmicrosoft.com
];

/**
 * @returns Is telemetry disabled for automated testing tenants.
 */
const handleAutomatedTestingTenants: () => boolean = () => {
  const pathSegments = window.location.pathname.split("/");

  if (pathSegments.length < 3) {
    return false;
  }

  if (`/${pathSegments[1]}` !== routes.event) {
    return false;
  }

  const eventGuid = pathSegments[2];
  const eventIdTenantIdSplit = eventGuid.split("@");
  if (eventIdTenantIdSplit.length === 2) {
    const eventTenantId = eventIdTenantIdSplit[1];
    if (automatedTestingTenants.includes(eventTenantId)) {
      loggerConfig.disableTelemetry = true;
      return true;
    }
  }

  return false;
};

export const disableTelemetryForAutomatedTestingTenants =
  handleAutomatedTestingTenants();

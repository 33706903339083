import { Search, Location } from "history";

const PERSISTANT_SEARCH_PARAMS = ["locale"];

export const generateSearchParams = (
  location: Location,
  newSearchParams?: URLSearchParams
): Search => {
  if (!newSearchParams) {
    newSearchParams = new URLSearchParams();
  }

  const searchParams = new URLSearchParams(location.search);

  PERSISTANT_SEARCH_PARAMS.forEach((name) => {
    const value = searchParams.get(name);
    if (value != null) {
      newSearchParams?.append(name, value);
    }
  });

  return newSearchParams.toString();
};

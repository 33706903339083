import {
  createAction,
  createSlice,
  createAsyncThunk,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  IError,
  getErrorResponse,
  getErrorResponseLoggerScenarioMessage,
  isErrorHandledError,
} from "./error";
import { fetchMe, postMe, patchMe, deleteMe } from "./userAPI";
import { Guid } from "./CMDTypes";
import { RootState } from "../store/store";
import { EventUser } from "./userTypes.interface";
import { IShortcutService } from "../../common/shortcut-service/interface";
import { Logger, Scenario } from "../../common/logger/Logger";
import { LoggerLevels } from "../../common/logger/interface";
import { AXHelper } from "../api/axHelper";
import { CategoryName } from "../../common/brb/interface";
import { forceDownloadFile } from "../../utilities/common/fileUtils";
import { LoginState } from "../auth/authenticationService.interface";
import { isBrbEnabled } from "../../configs/brbConfigs";

export interface IUserState {
  user: EventUser | null;
  isUserLoading: boolean;
  loginState: LoginState;
  isAuthServiceInitialized: boolean;
  reportProblemDialog: boolean;
  downloadLogsDialog: boolean;
  meError?: IError;
  redirectLoginError?: IError;
}

const initialState: IUserState = {
  user: null,
  isUserLoading: false,
  loginState: LoginState.NotLoggedIn,
  isAuthServiceInitialized: false,
  reportProblemDialog: false,
  downloadLogsDialog: false,
  meError: undefined,
  redirectLoginError: undefined,
};

export type UserRegArgs = {
  eventID: Guid;
  userID: Guid;
};

const getMeAction: string = "user";
export const getMeAsyncAction = createAsyncThunk<
  EventUser,
  boolean,
  { rejectValue: IError }
>(
  getMeAction,
  async (
    /* istanbul ignore next */ createUser: boolean = false,
    { rejectWithValue }
  ) => {
    const scenarioData = {
      createUser: createUser,
    };
    const logger = Logger.getInstance();
    const scenario = logger.createScenario(Scenario.MeRequest, {
      data: scenarioData,
    });
    try {
      const user: EventUser = await fetchMe(createUser, scenario || undefined);
      scenario?.stop();
      return user;
    } catch (err) {
      if (createUser) {
        logger.logTrace(
          LoggerLevels.error,
          `No user retrieved as the user could not be created`
        );
      } else {
        logger.logTrace(LoggerLevels.error, `Could not find user`);
      }

      const response: IError = getErrorResponse(getMeAction, err);

      const scenarioErrorData = {
        message: getErrorResponseLoggerScenarioMessage(response),
      };

      if (isErrorHandledError(response)) {
        scenario?.stop(scenarioErrorData);
      } else {
        scenario?.fail(scenarioErrorData);
      }

      return rejectWithValue(response);
    }
  }
);

const postMeAction: string = "user/post";
export const postMeAsyncAction = createAsyncThunk<
  EventUser,
  void,
  { rejectValue: IError }
>(postMeAction, async (_payload: void, { rejectWithValue }) => {
  const logger = Logger.getInstance();
  const scenario = logger.createScenario(Scenario.MePostRequest);
  try {
    const user: EventUser = await postMe(scenario || undefined);
    scenario?.stop();
    return user;
  } catch (err) {
    logger.logTrace(LoggerLevels.error, `Could not create new user`);
    const response: IError = getErrorResponse(postMeAction, err);

    const scenarioErrorData = {
      message: getErrorResponseLoggerScenarioMessage(response),
    };

    if (isErrorHandledError(response)) {
      scenario?.stop(scenarioErrorData);
    } else {
      scenario?.fail(scenarioErrorData);
    }

    return rejectWithValue(response);
  }
});

const patchMeAction: string = "user/patch";
export const patchMeAsyncAction = createAsyncThunk<
  EventUser,
  EventUser,
  { rejectValue: IError }
>(patchMeAction, async (userData: EventUser, { rejectWithValue }) => {
  const logger = Logger.getInstance();
  const scenario = logger.createScenario(Scenario.MeUpdateRequest, {});
  try {
    const user: EventUser = await patchMe(userData, scenario || undefined);
    scenario?.stop();
    return user;
  } catch (err) {
    logger.logTrace(LoggerLevels.error, `Could not update user details`);
    const response: IError = getErrorResponse(patchMeAction, err);

    const scenarioErrorData = {
      message: getErrorResponseLoggerScenarioMessage(response),
    };

    if (isErrorHandledError(response)) {
      scenario?.stop(scenarioErrorData);
    } else {
      scenario?.fail(scenarioErrorData);
    }

    return rejectWithValue(response);
  }
});

const deleteMeAction: string = "user/delete";
export const deleteMeAsyncAction = createAsyncThunk<
  EventUser,
  void,
  { rejectValue: IError }
>(deleteMeAction, async (_payload: void, { rejectWithValue }) => {
  const logger = Logger.getInstance();
  const scenario = logger.createScenario(Scenario.MeDeleteRequest);
  try {
    const user: EventUser = await deleteMe(scenario || undefined);
    scenario?.stop();
    return user;
  } catch (err) {
    logger.logTrace(LoggerLevels.error, `Could not delete user`);
    const response: IError = getErrorResponse(deleteMeAction, err);

    const scenarioErrorData = {
      message: getErrorResponseLoggerScenarioMessage(response),
    };

    if (isErrorHandledError(response)) {
      scenario?.stop(scenarioErrorData);
    } else {
      scenario?.fail(scenarioErrorData);
    }

    return rejectWithValue(response);
  }
});

const downloadUserDataAction = "user/download";
export const downloadUserDataAsyncAction = createAsyncThunk(
  downloadUserDataAction,
  async (/* istanbul ignore next */ createUser: boolean = false) => {
    const eventUser: EventUser = await fetchMe(createUser);
    return await new Promise<void>((resolve) => {
      const eventUserJson = JSON.stringify(eventUser, null, "\t");
      const filename = `MyProfile-${eventUser.id}.log`;
      const body = eventUserJson;
      forceDownloadFile(filename, body, "text/plain;charset=utf-8");
      resolve();
    });
  }
);

export const authenticatedAction =
  createAction<LoginState>("user/authenticated");
export const setIsAuthenicationServiceInitializedAction = createAction<boolean>(
  "user/isAuthenicationServiceInitialized"
);

const initOpenReportProblemDialogShortcutAction: string =
  "user/reportProblemDialog/shortcut/init";
export const initOpenReportProblemDialogShortcutAsyncAction = createAsyncThunk(
  initOpenReportProblemDialogShortcutAction,
  async (shortcutService: IShortcutService, { getState, dispatch }) => {
    return await new Promise<void>((resolve) => {
      shortcutService.assignCommandHandler("OpenReportProblemDialog", () => {
        const enableBrb = isBrbEnabled();
        if (enableBrb) {
          dispatch(setReportProblemDialogAction(true));
        }
      });
      resolve();
    });
  }
);

export const setReportProblemDialogAction = createAction<boolean>(
  "user/reportProblemDialog"
);

export const setDownloadLogsDialogAction = createAction<boolean>(
  "user/downloadLogsDialog"
);

export const downloadLogsAction = createAction("user/downloadLogs", () => {
  const logger = Logger.getInstance();
  logger.download();
  return { payload: undefined };
});

const brbSubmitAction: string = "user/brb/post";
export const brbSubmitAsyncAction = createAsyncThunk(
  brbSubmitAction,
  async (
    payload: { categoryName: CategoryName; reportDescription: string },
    { getState, dispatch }
  ) => {
    dispatch(setReportProblemDialogAction(false));
    const logger = Logger.getInstance();
    const state = getState() as RootState;
    const user = userSelector(state);

    const authorizationResponseData = await AXHelper.brbClientAuthorization(
      payload.categoryName,
      payload.reportDescription,
      state,
      user?.email
    );

    const logs = logger.getLogs();

    await AXHelper.brbUploadLog(
      authorizationResponseData.ContainerUrl,
      authorizationResponseData.SASToken,
      "weblog.txt",
      logs
    );

    return await AXHelper.brbRelease(authorizationResponseData.ContainerID);
  }
);

const setRedirectloginErrorAction: string = "user/redirect-login-error/set";
export const setRedirectloginErrorAsyncAction = createAsyncThunk<
  IError | undefined,
  unknown
>(setRedirectloginErrorAction, async (error) => {
  return error
    ? getErrorResponse(setRedirectloginErrorAction, error)
    : undefined;
});

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        authenticatedAction,
        (state: IUserState, { payload }: PayloadAction<LoginState>) => {
          if (payload === LoginState.NotLoggedIn) {
            Object.assign(state, {
              user: initialState.user,
              loginState: initialState.loginState,
              meError: undefined,
            });
          } else {
            state.loginState = payload;
          }
        }
      )
      .addCase(getMeAsyncAction.pending, (state, action) => {
        state.isUserLoading = true;
        state.meError = undefined;
      })
      .addCase(
        getMeAsyncAction.fulfilled,
        (state: IUserState, { payload }: PayloadAction<EventUser>) => {
          /* istanbul ignore else */
          if (!process.env.noop_api) {
            state.user = payload;
            state.isUserLoading = false;
            state.meError = undefined;
          }
        }
      )
      .addCase(getMeAsyncAction.rejected, (state, action) => {
        state.meError = action.payload;
        state.isUserLoading = false;
      })
      .addCase(postMeAsyncAction.pending, (state, action) => {
        state.isUserLoading = true;
        state.meError = undefined;
      })
      .addCase(
        postMeAsyncAction.fulfilled,
        (state: IUserState, { payload }: PayloadAction<EventUser>) => {
          /* istanbul ignore else */
          if (!process.env.noop_api) {
            state.user = payload;
            state.isUserLoading = false;
            state.meError = undefined;
          }
        }
      )
      .addCase(postMeAsyncAction.rejected, (state, action) => {
        state.meError = action.payload;
        state.isUserLoading = false;
      })
      .addCase(patchMeAsyncAction.pending, (state, action) => {
        state.isUserLoading = true;
        state.meError = undefined;
      })
      .addCase(
        patchMeAsyncAction.fulfilled,
        (state: IUserState, { payload }: PayloadAction<EventUser>) => {
          /* istanbul ignore else */
          if (!process.env.noop_api) {
            state.user = payload;
            state.isUserLoading = false;
            state.meError = undefined;
          }
        }
      )
      .addCase(patchMeAsyncAction.rejected, (state, action) => {
        state.meError = action.payload;
        state.isUserLoading = false;
      })
      .addCase(deleteMeAsyncAction.pending, (state, action) => {
        state.isUserLoading = true;
        state.meError = undefined;
      })
      .addCase(
        deleteMeAsyncAction.fulfilled,
        (state: IUserState, { payload }: PayloadAction<EventUser>) => {
          if (!process.env.noop_api) {
            state.user = payload;
            state.isUserLoading = false;
            state.meError = undefined;
          }
        }
      )
      .addCase(deleteMeAsyncAction.rejected, (state, action) => {
        state.meError = action.payload;
        state.isUserLoading = false;
      })
      .addCase(
        initOpenReportProblemDialogShortcutAsyncAction.fulfilled,
        () => {}
      )
      .addCase(
        setReportProblemDialogAction,
        (state: IUserState, { payload }: PayloadAction<boolean>) => {
          state.reportProblemDialog = payload;
        }
      )
      .addCase(
        setDownloadLogsDialogAction,
        (state: IUserState, { payload }: PayloadAction<boolean>) => {
          state.downloadLogsDialog = payload;
        }
      )
      .addCase(downloadLogsAction, () => {})
      .addCase(
        setIsAuthenicationServiceInitializedAction,
        (state: IUserState, { payload }: PayloadAction<boolean>) => {
          state.isAuthServiceInitialized = payload;
        }
      )
      .addCase(
        setRedirectloginErrorAsyncAction.fulfilled,
        (state: IUserState, { payload }: PayloadAction<IError | undefined>) => {
          state.redirectLoginError = payload;
        }
      );
  },
});

// selectors
export const userSelector = (state: RootState): EventUser | null =>
  state.user?.user;
export const isUserLoadingSelector = (state: RootState): boolean =>
  state.user?.isUserLoading ?? false;
export const userOIDSelector = (state: RootState): string | undefined =>
  state.user?.user?.identity?.objectId;
export const userTenantIDSelector = (state: RootState): string | undefined =>
  state.user?.user?.identity?.tenantId;
export const authenticatedSelector = (state: RootState): boolean =>
  state.user.loginState !== LoginState.NotLoggedIn;
export const loginStateSelector = (state: RootState): LoginState =>
  state.user.loginState;
export const socialLoginSelector = (state: RootState): boolean =>
  state.user.loginState === LoginState.Social;
export const isAuthServiceInitializedSelector = (state: RootState): boolean =>
  state.user.isAuthServiceInitialized;
export const reportProblemDialogSelector = (state: RootState): boolean =>
  state.user.reportProblemDialog;
export const downloadLogsDialogSelector = (state: RootState): boolean =>
  state.user.downloadLogsDialog;
export const userErrorSelector = (state: RootState): IError | undefined =>
  state.user?.meError;
export const redirectLoginErrorSelector = (
  state: RootState
): IError | undefined => state.user?.redirectLoginError;

// reducer
export default userSlice.reducer;

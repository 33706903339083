import { makeStyles, tokens } from "../shared";

const eventInfoSpeakerListGap = 8;

export const eventInfoStyles = makeStyles({
  topSectionIcons: {
    color: tokens.colorBrandForeground1,
  },
  speakerListRow: {
    // Using !important because the gutter style of the grid system which includes a media query is more specific, and has more priority.
    // Griffel from Fluent UI v9 also does not allow specifying class name for makeStyles classes to give it more priority.
    marginLeft: `${-eventInfoSpeakerListGap / 2}px !important`,
    marginRight: `${-eventInfoSpeakerListGap / 2}px !important`,
    marginBottom: `${-eventInfoSpeakerListGap}px`,
    "> *": {
      paddingLeft: `${eventInfoSpeakerListGap / 2}px !important`,
      paddingRight: `${eventInfoSpeakerListGap / 2}px !important`,
      marginBottom: `${eventInfoSpeakerListGap}px`,
    },
  },
  sponsorListFlex: {
    width: "100%",
  },
  portalTicketIcon: {
    color: tokens.colorBrandForeground1,
  },
  eventDetailsTextStyles: {
    fontSize: tokens.fontSizeBase300,
    lineHeight: tokens.lineHeightBase300,
    fontWeight: tokens.fontWeightRegular,
  },
  locationTextMobile: {
    fontSize: "17px",
    fontWeight: tokens.fontWeightSemibold,
    lineHeight: tokens.lineHeightBase400,
  },
  locationTextWeb: {
    fontSize: tokens.fontSizeBase400,
    fontWeight: tokens.fontWeightRegular,
    lineHeight: tokens.lineHeightBase400,
  },
  registrationStateMobile: {
    fontSize: "15px",
    fontWeight: tokens.fontWeightRegular,
    lineHeight: tokens.lineHeightBase300,
  },
});

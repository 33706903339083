import { makeStyles, GriffelStyle, shorthands } from "../shared";

/**
 * Breakpoints are viewport widths that determine grid class behavior, as the viewport width enters a certain
 * breakpoint the grid classes will behave differently.
 *
 * For example "container" will have a different margin defined for each breakpoint,
 * and there are col span classes which will make elements take up differnt amounts of grid columns at curtain
 * breakpoints.
 */
export enum Breakpoint {
  XSmall = "xs",
  Small = "sm",
  Medium = "md",
  Large = "lg",
  XLarge = "xl",
  XXLarge = "xxl",
}

/**
 * In px.
 */
export const breakpointMinWidths: { [key in Breakpoint]: number } = {
  [Breakpoint.XSmall]: 0,
  [Breakpoint.Small]: 480,
  [Breakpoint.Medium]: 640,
  [Breakpoint.Large]: 904,
  [Breakpoint.XLarge]: 1366,
  [Breakpoint.XXLarge]: 1920,
};

/**
 * In px.
 */
export const breakpointMaxWidths: { [key in Breakpoint]: number } =
  Object.fromEntries(
    Object.entries(breakpointMinWidths).map(([key], index, array) => {
      if (array[index + 1]) {
        const nextValue = array[index + 1][1];
        return [key, nextValue - 1];
      }
      return [key, Infinity];
    })
  ) as { [key in Breakpoint]: number };

/**
 * In px.
 */
export const containerMargins: { [key in Breakpoint]: number } = {
  [Breakpoint.XSmall]: 16,
  [Breakpoint.Small]: 16,
  [Breakpoint.Medium]: 32,
  [Breakpoint.Large]: 80,
  [Breakpoint.XLarge]: 180,
  [Breakpoint.XXLarge]: 320,
};

/**
 * In px.
 */
const gutterWidths: { [key in Breakpoint]: number } = {
  [Breakpoint.XSmall]: 16,
  [Breakpoint.Small]: 16,
  [Breakpoint.Medium]: 16,
  [Breakpoint.Large]: 24,
  [Breakpoint.XLarge]: 24,
  [Breakpoint.XXLarge]: 24,
};

export const mediaQueryBreakpointOnly: (breakpoint: Breakpoint) => string = (
  breakpoint: Breakpoint
) => {
  /**
   * Breakpoint values are padded out because griffel inserts all media query styles in alpha numerical order, and sometimes order matter.
   * Eg "@media(min-width: 1200px)" would go before "@media(min-width: 900px)" but after "@media(min-width: 0900px)"
   */
  return `@media(min-width: ${String(breakpointMinWidths[breakpoint]).padStart(
    4,
    "0"
  )}px) and (max-width: ${String(breakpointMaxWidths[breakpoint]).padStart(
    4,
    "0"
  )}px)`;
};

export const mediaQueryBreakpointUp: (breakpoint: Breakpoint) => string = (
  breakpoint: Breakpoint
) => {
  /**
   * Breakpoint values are padded out because griffel inserts all media query styles in alpha numerical order, and sometimes order matter.
   * Eg "@media(min-width: 1200px)" would go before "@media(min-width: 900px)" but after "@media(min-width: 0900px)"
   */
  return `@media(min-width: ${String(breakpointMinWidths[breakpoint]).padStart(
    4,
    "0"
  )}px)`;
};

export const mediaQueryBreakpointDown: (breakpoint: Breakpoint) => string = (
  breakpoint: Breakpoint
) => {
  /**
   * Breakpoint values are padded out because griffel inserts all media query styles in alpha numerical order, and sometimes order matter.
   * Eg "@media(min-width: 1200px)" would go before "@media(min-width: 900px)" but after "@media(min-width: 0900px)"
   */
  return `@media(max-width: ${String(breakpointMaxWidths[breakpoint]).padStart(
    4,
    "0"
  )}px)`;
};

const columns = 12;

const containerMaxWidth = 1280; // In px.
const containerStyle: GriffelStyle = {
  maxWidth: `${containerMaxWidth}px`,
};
for (const breakpoint of Object.values(Breakpoint)) {
  const containerBreakpointStyle: GriffelStyle = {
    [mediaQueryBreakpointUp(breakpoint)]: {
      marginLeft: `max(${containerMargins[breakpoint]}px, calc((100% - ${containerMaxWidth}px) / 2))`,
      marginRight: `max(${containerMargins[breakpoint]}px, calc((100% - ${containerMaxWidth}px) / 2))`,
    },
  };
  Object.assign(containerStyle, containerBreakpointStyle);
}

const rowStyle: GriffelStyle = {
  display: "flex",
  flexWrap: "wrap",
};
for (const breakpoint of Object.values(Breakpoint)) {
  const rowBreakspointStyle: GriffelStyle = {
    [mediaQueryBreakpointUp(breakpoint)]: {
      marginLeft: `${-gutterWidths[breakpoint] / 2}px`,
      marginRight: `${-gutterWidths[breakpoint] / 2}px`,
    },
  };
  Object.assign(rowStyle, rowBreakspointStyle);
}

const colStyle: GriffelStyle = {
  ...shorthands.flex(1, 0, "0px"),
};
for (const breakpoint of Object.values(Breakpoint)) {
  const colBreakspointStyle: GriffelStyle = {
    [mediaQueryBreakpointUp(breakpoint)]: {
      paddingLeft: `${gutterWidths[breakpoint] / 2}px`,
      paddingRight: `${gutterWidths[breakpoint] / 2}px`,
    },
  };
  Object.assign(colStyle, colBreakspointStyle);
}

/**
 * Adjust column span for a specific breakpoint and up.
 * Should be used in the same element as "col"
 * class names = [BreakPoint][column span]
 * Eg: "lg3", "md6", "sm9", "xs12"
 */
const colSpanClassNameArray = [
  "xs1",
  "xs2",
  "xs3",
  "xs4",
  "xs5",
  "xs6",
  "xs7",
  "xs8",
  "xs9",
  "xs10",
  "xs11",
  "xs12",
  "sm1",
  "sm2",
  "sm3",
  "sm4",
  "sm5",
  "sm6",
  "sm7",
  "sm8",
  "sm9",
  "sm10",
  "sm11",
  "sm12",
  "md1",
  "md2",
  "md3",
  "md4",
  "md5",
  "md6",
  "md7",
  "md8",
  "md9",
  "md10",
  "md11",
  "md12",
  "lg1",
  "lg2",
  "lg3",
  "lg4",
  "lg5",
  "lg6",
  "lg7",
  "lg8",
  "lg9",
  "lg10",
  "lg11",
  "lg12",
  "xl1",
  "xl2",
  "xl3",
  "xl4",
  "xl5",
  "xl6",
  "xl7",
  "xl8",
  "xl9",
  "xl10",
  "xl11",
  "xl12",
  "xxl1",
  "xxl2",
  "xxl3",
  "xxl4",
  "xxl5",
  "xxl6",
  "xxl7",
  "xxl8",
  "xxl9",
  "xxl10",
  "xxl11",
  "xxl12",
] as const;
type colSpanClassName = typeof colSpanClassNameArray[number];
const colSpanStyles: Record<string, GriffelStyle> = {};
for (const breakpoint of Object.values(Breakpoint)) {
  for (let colSpan = 1; colSpan <= columns; colSpan++) {
    const className = `${breakpoint}${colSpan}`;
    colSpanStyles[`${className}`] = {
      [mediaQueryBreakpointUp(breakpoint)]: {
        ...shorthands.flex(0, 0, "auto"),
        width: `${(colSpan / 12) * 100}%`,
      },
    };
  }
}

/**
 * column offset for a specific breakpoint and up.
 * Should be used in the same element as "col"
 * class names = offset-[BreakPoint][column span]
 * Eg: "offsetLg3", "offsetMd6", "offsetSm9"
 */
const colOffsetClassNameArray = [
  "offsetXs1",
  "offsetXs2",
  "offsetXs3",
  "offsetXs4",
  "offsetXs5",
  "offsetXs6",
  "offsetXs7",
  "offsetXs8",
  "offsetXs9",
  "offsetXs10",
  "offsetXs11",
  "offsetSm1",
  "offsetSm2",
  "offsetSm3",
  "offsetSm4",
  "offsetSm5",
  "offsetSm6",
  "offsetSm7",
  "offsetSm8",
  "offsetSm9",
  "offsetSm10",
  "offsetSm11",
  "offsetMd1",
  "offsetMd2",
  "offsetMd3",
  "offsetMd4",
  "offsetMd5",
  "offsetMd6",
  "offsetMd7",
  "offsetMd8",
  "offsetMd9",
  "offsetMd10",
  "offsetMd11",
  "offsetLg1",
  "offsetLg2",
  "offsetLg3",
  "offsetLg4",
  "offsetLg5",
  "offsetLg6",
  "offsetLg7",
  "offsetLg8",
  "offsetLg9",
  "offsetLg10",
  "offsetLg11",
  "offsetXl1",
  "offsetXl2",
  "offsetXl3",
  "offsetXl4",
  "offsetXl5",
  "offsetXl6",
  "offsetXl7",
  "offsetXl8",
  "offsetXl9",
  "offsetXl10",
  "offsetXl11",
  "offsetXxl1",
  "offsetXxl2",
  "offsetXxl3",
  "offsetXxl4",
  "offsetXxl5",
  "offsetXxl6",
  "offsetXxl7",
  "offsetXxl8",
  "offsetXxl9",
  "offsetXxl10",
  "offsetXxl11",
] as const;
type colOffsetClassName = typeof colOffsetClassNameArray[number];
const colOffsetStyles: Record<string, GriffelStyle> = {};
for (const breakpoint of Object.values(Breakpoint)) {
  const breakPointCapFirstLetter =
    breakpoint.charAt(0).toUpperCase() + breakpoint.slice(1);
  for (let colSpan = 1; colSpan < columns; colSpan++) {
    const className = `offset${breakPointCapFirstLetter}${colSpan}`;
    colOffsetStyles[`${className}`] = {
      [mediaQueryBreakpointUp(breakpoint)]: {
        marginLeft: `${(colSpan / 12) * 100}%`,
      },
    };
  }
}

/**
 * Hides on a specific breakpoint.
 * class names = hidden[BreakPoint]
 * Eg: "hiddenSm", "hiddenXs"
 */
const hiddenClassNameArray = [
  "hiddenXs",
  "hiddenSm",
  "hiddenMd",
  "hiddenLg",
  "hiddenXl",
  "hiddenXxl",
] as const;
type hiddenClassName = typeof hiddenClassNameArray[number];
const hiddenStyles: Record<string, GriffelStyle> = {};
for (const breakpoint of Object.values(Breakpoint)) {
  const maxWidth = breakpointMaxWidths[breakpoint];
  const breakPointCapFirstLetter =
    breakpoint.charAt(0).toUpperCase() + breakpoint.slice(1);
  const className = `hidden${breakPointCapFirstLetter}`;
  const selector =
    maxWidth !== Infinity
      ? mediaQueryBreakpointOnly(breakpoint)
      : mediaQueryBreakpointUp(breakpoint);
  hiddenStyles[className] = {
    [selector]: {
      display: "none",
    },
  };
}

/**
 * Hides on a specific breakpoint and down.
 * class names = hidden[BreakPoint]Down
 * Eg: "hiddenMdDown", "hiddenSmDown"
 * Note:
 *   "hiddenXsDown" does not exist, use "hiddenXs"
 *   "hiddenXxlDown" does not exist, it would hide everything
 */
const hiddenDownClassNameArray = [
  "hiddenSmDown",
  "hiddenMdDown",
  "hiddenLgDown",
  "hiddenXlDown",
] as const;
type hiddenDownClassName = typeof hiddenDownClassNameArray[number];
const hiddenDownStyles: Record<string, GriffelStyle> = {};
for (let i = 1; i < Object.values(Breakpoint).length - 1; i++) {
  const breakpoint = Object.values(Breakpoint)[i];
  const breakPointCapFirstLetter =
    breakpoint.charAt(0).toUpperCase() + breakpoint.slice(1);
  const className = `hidden${breakPointCapFirstLetter}Down`;
  hiddenDownStyles[className] = {
    [mediaQueryBreakpointDown(breakpoint)]: {
      display: "none",
    },
  };
}

/**
 * Hides on a specific breakpoint and up
 * class names = hidden[BreakPoint]Up
 * Eg: "hiddenMdUp", "hiddenSmUp"
 * Note:
 *   "hiddenXsUp" does not exist, it would hide everything
 *   "hiddenXxlUp" does not exist, use "hiddenXxl"
 */
const hiddenUpClassNameArray = [
  "hiddenSmUp",
  "hiddenMdUp",
  "hiddenLgUp",
  "hiddenXlUp",
] as const;
type hiddenUpClassName = typeof hiddenUpClassNameArray[number];
const hiddenUpStyles: Record<string, GriffelStyle> = {};
for (let i = 1; i < Object.values(Breakpoint).length - 1; i++) {
  const breakpoint = Object.values(Breakpoint)[i];
  const breakPointCapFirstLetter =
    breakpoint.charAt(0).toUpperCase() + breakpoint.slice(1);
  const className = `hidden${breakPointCapFirstLetter}Up`;
  hiddenUpStyles[className] = {
    [mediaQueryBreakpointUp(breakpoint)]: {
      display: "none",
    },
  };
}

/**
 * container - This class wraps the content with the appropriate margins for each breakspoint. This class does not need
 * to used with "row" and "col", but if used with them should be used as a parent to one or more "row"s.
 *
 * row - Should be used as a parent to one or more "col"s, and usually used as a children to "container".
 *
 * col - Should be used as a children to "row", and along side the col span styles to adjust for how many grid columns
 * the element should take at given breakpoints. There is 12 grid column per "row" if that is extend the "col" will
 * overflow under.
 * Eg.
 *  <div class="row">
 *    <elementA class="col xs5">
 *    <elementB class="col xs5">
 *    <elementC class="col xs5">
 *  <div>
 *  ElementA will be next to ElementB and take up 10 grid columns, ElementC will be under ElementA as all 3 does not
 *  fit in 12 grid columns
 *
 * Note: "row" and "col" make up the actually grid portion of the grid styles and doesn't necessarily need to be used
 * with "container"
 */
export const gridStyles = makeStyles({
  container: containerStyle,
  row: rowStyle,
  col: colStyle,
  ...(colSpanStyles as { [K in colSpanClassName]: GriffelStyle }),
  ...(colOffsetStyles as { [K in colOffsetClassName]: GriffelStyle }),
  ...(hiddenStyles as { [K in hiddenClassName]: GriffelStyle }),
  ...(hiddenDownStyles as { [K in hiddenDownClassName]: GriffelStyle }),
  ...(hiddenUpStyles as { [K in hiddenUpClassName]: GriffelStyle }),
});

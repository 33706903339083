import React, { ReactNode, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IEcsClient } from "../core/ecs/interface";
import {
  initEcsConfigAction,
  isConfigSetSelector,
  requestUpdateEcsConfigAction,
} from "../core/slices/ecsSlice";
import {
  userOIDSelector,
  userTenantIDSelector,
} from "../core/slices/userSlice";
export const Ecs: React.FunctionComponent<{
  ecsClient: IEcsClient;
  children: ReactNode;
}> = ({ ecsClient, children }) => {
  const dispatch = useDispatch();
  const isConfigSet = useSelector(isConfigSetSelector);
  useEcsUserTenantChangeHandlerHook(ecsClient);

  useEffect(() => {
    dispatch(initEcsConfigAction(ecsClient));
  }, [dispatch, ecsClient]);

  return <>{isConfigSet ? children : null}</>;
};

const useEcsUserTenantChangeHandlerHook = (ecsClient: IEcsClient) => {
  const dispatch = useDispatch();
  const userOID = useSelector(userOIDSelector);
  const userTenantID = useSelector(userTenantIDSelector);

  const firstUpdate = useRef(true);
  useEffect(() => {
    /* istanbul ignore else */
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    /**
     * This action will get the new user OID or tenant ID from the state and update the ECS client before re-fetching ECS config.
     */
    dispatch(requestUpdateEcsConfigAction(ecsClient));
  }, [dispatch, ecsClient, userOID, userTenantID]);

  return null;
};

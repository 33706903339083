import * as React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { withLoggerContext, WithLoggerProps } from "../logger/LoggerContext";

type IErrorBoundaryProps = WithLoggerProps & WithTranslation;

interface IErrorBoundaryState {
  error?: Error;
}

/**
 * This react component catches JavaScript errors in its child component tree, logs the errors, and displays a fallback
 * UI.
 *
 * https://reactjs.org/docs/error-boundaries.html
 */
class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  public componentDidCatch(error: Error, info: React.ErrorInfo): void {
    const { logger } = this.props;

    console.log(`Error: ${error} -- ${info.componentStack}`);
    logger?.logException("Error Boundary", {
      error,
      details: info.componentStack,
    });
  }

  public render(): JSX.Element {
    const { error } = this.state;
    const { t: i18n } = this.props;

    if (error) {
      return (
        <span>{i18n("error_boundary_error_occurred_error", { error })}</span>
      );
    } else {
      return <>{this.props.children}</>;
    }
  }
}

const ErrorBoundaryWithLogger = withTranslation()(
  withLoggerContext(ErrorBoundary)
);

export { ErrorBoundaryWithLogger as ErrorBoundary };

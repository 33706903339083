import { debounce } from "lodash";
import * as React from "react";
import { getWindow } from "../../shared";

export const useWindowSize = (): {
  width: number;
  height: number;
} => {
  const window = getWindow();
  const [windowSize, setWindowSize] = React.useState<{
    width: number;
    height: number;
  }>({
    width: window?.innerWidth || 0,
    height: window?.innerHeight || 0,
  });

  const handleResize = React.useCallback(() => {
    setWindowSize({
      width: window?.innerWidth || 0,
      height: window?.innerHeight || 0,
    });
  }, [window, setWindowSize]);

  React.useEffect(() => {
    const debouncedCheck = debounce(handleResize, 50);
    window?.addEventListener("resize", debouncedCheck);

    handleResize();

    return () => window?.removeEventListener("resize", debouncedCheck);
  }, [window, handleResize]);

  return windowSize;
};

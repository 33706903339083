import React from "react";
import { Link, mergeClasses, TextV9 as Text } from "../../shared";
import { SponsorCard } from "../SponsorCard";
import { eventInfoStyles } from "../../styles/EventInfo";
import { IEvent, EventType } from "../../core/slices/eventTypes.interface";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { orderedSponsorsSelector } from "../../core/slices/sponsorSlice";
import { IEventSponsor } from "../../core/slices/sponsor.interface";
import { gridStyles } from "../../styles/Grid";
import { UserBIScenario } from "../../common/logger/Logger";
import { useLogger } from "../../common/logger/LoggerContext";
import { sponsorCardStyles } from "../../styles/SponsorCard";
import {
  flexAlignStyles,
  flexItemStyles,
  flexStyles,
} from "../../styles/FlexStyles";
import { useHistory, useLocation } from "react-router";
import { generateSearchParams } from "../../utilities/common/generateSearchParams";
import { routes } from "../../common/constants";
import { PortalLocationState } from "../../core/history/history.interface";

export const Sponsors: React.FunctionComponent<{ currentEvent: IEvent }> = ({
  currentEvent,
}) => {
  const flexClasses = flexStyles();
  const sponsors: IEventSponsor[] | undefined = useSelector(
    orderedSponsorsSelector
  );
  if (sponsors === undefined || sponsors?.length === 0) {
    return null;
  }
  return (
    <div
      id="eventInfoSponsorStack"
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexClasses.columnGapMedium
      )}
    >
      {sponsors?.length > 0 && (
        <SponsorsHeader currentEvent={currentEvent} sponsors={sponsors} />
      )}
      {sponsors && (
        <SponsorsList currentEvent={currentEvent} sponsors={sponsors} />
      )}
    </div>
  );
};

const SponsorsHeader: React.FunctionComponent<{
  currentEvent: IEvent;
  sponsors: IEventSponsor[];
}> = ({ currentEvent, sponsors }) => {
  const flexItemClasses = flexItemStyles();
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const { t: i18n } = useTranslation();
  const logger = useLogger()?.logger;
  const history = useHistory<PortalLocationState>();
  const location = useLocation<PortalLocationState>();
  const numOfSponsors = sponsors?.length;
  const onClickSeeMoreSponsors = React.useCallback(() => {
    logger?.logUiTelemetry(
      "seeMoreLink",
      UserBIScenario.SeeMoreLink,
      "click",
      "none",
      "SponsorsHeader"
    );
    history.push({
      pathname: `${currentEvent.id}/${routes.sponsors}`,
      search: generateSearchParams(location),
    });
  }, [currentEvent.id, history, location, logger]);
  const sponsorsText =
    sponsors && sponsors.length > 1
      ? `${i18n("sponsors")} (${sponsors?.length})`
      : i18n("sponsors") + "(" + i18n("tba") + ")";

  return (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.rowGapMedium,
        flexAlignClasses.alignItemEnd
      )}
    >
      <Text
        id="sponsorsCount"
        as="h2"
        size={600}
        weight="bold"
        aria-label={sponsors.length === 1 ? i18n("sponsor") : sponsorsText}
      >
        {sponsors.length === 1 ? i18n("sponsor") : sponsorsText}
      </Text>
      {numOfSponsors > 1 && currentEvent.type !== EventType.WEBINAR && (
        <div className={mergeClasses(flexClasses.row, flexItemClasses.rowPush)}>
          <Link
            aria-label={i18n("see_all_sponsors")}
            onClick={onClickSeeMoreSponsors}
          >
            {i18n("see_more")}
          </Link>
        </div>
      )}
    </div>
  );
};

const SponsorsList: React.FunctionComponent<{
  currentEvent: IEvent;
  sponsors: IEventSponsor[];
}> = ({ currentEvent, sponsors }) => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const gridClasses = gridStyles();
  const sponsorCardClasses = sponsorCardStyles();
  const eventInfoStylesClasses = eventInfoStyles();
  const shownSponsors =
    currentEvent.type !== EventType.WEBINAR ? sponsors.slice(0, 3) : sponsors;

  return (
    <div className={sponsorCardClasses.list}>
      <div
        className={mergeClasses(gridClasses.row, sponsorCardClasses.listRow)}
      >
        {shownSponsors.map((sponsor) => {
          return (
            <div
              key={sponsor.id}
              className={mergeClasses(
                gridClasses.col,
                gridClasses.xs6,
                gridClasses.md4,
                flexClasses.root,
                flexAlignClasses.alignItemCenter,
                flexAlignClasses.justifyContentCenter,
                eventInfoStylesClasses.sponsorListFlex
              )}
            >
              <SponsorCard sponsor={sponsor} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

import React from "react";
import { sanitize } from "dompurify";
import { mergeClasses, TextV9 as Text } from "../../shared";
import { useTranslation } from "react-i18next";
import { flexStyles } from "../../styles/FlexStyles";
import { aboutStyles } from "../../styles/About";

export const About: React.FunctionComponent<{
  descriptionText: string;
}> = ({ descriptionText }) => {
  const flexClasses = flexStyles();
  const aboutClasses = aboutStyles();
  const { t: i18n } = useTranslation();
  // We suppress the react/no-danger rule because we have sanitized it using DOMPurify
  /* eslint-disable react/no-danger */
  return (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexClasses.columnGapMedium
      )}
    >
      <Text as="h2" size={600} weight="bold">
        {i18n("details_section")}
      </Text>
      <Text size={400}>
        <span
          className={aboutClasses.aboutDescriptionInSpan}
          dangerouslySetInnerHTML={{
            __html: sanitize(descriptionText, {
              RETURN_TRUSTED_TYPE: true,
            }).toString(),
          }}
        />
      </Text>
    </div>
  );
  /* eslint-enable react/no-danger */
};

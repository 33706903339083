import { makeStyles, shorthands, tokens } from "../shared";

export const portalTagStyles = makeStyles({
  root: {
    fontWeight: tokens.fontWeightSemibold,
    ...shorthands.padding("0px", "4px", "0px", "4px"),
    ...shorthands.borderRadius("2px"),
    minHeight: "24px",
  },
  grey: {
    backgroundColor: tokens.colorNeutralBackground6,
    color: tokens.colorNeutralForeground3,
  },
  green: {
    backgroundColor: tokens.colorPaletteGreenBackground1,
    color: tokens.colorPaletteGreenForeground3,
  },
});

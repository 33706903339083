import React from "react";
import { IEventSponsor } from "../core/slices/sponsor.interface";
import { useSelector } from "react-redux";
import { orderedSponsorsSelector } from "../core/slices/sponsorSlice";
import { TextV9 as Text, mergeClasses } from "../shared";
import { SponsorCardList } from "./SponsorCard";

import { useTranslation } from "react-i18next";
import { flexAlignStyles, flexStyles } from "../styles/FlexStyles";
import { portalTextStyles } from "../styles/PortalText";
const SponsorsPageHeader: React.FunctionComponent<{
  sponsors: IEventSponsor[];
}> = ({ sponsors }) => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const portalTextClasses = portalTextStyles();
  const { t: i18n } = useTranslation();
  return (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.rowGapMedium,
        flexAlignClasses.alignItemEnd
      )}
    >
      <Text
        data-testid="eventSponsorCount"
        as="h2"
        className={portalTextClasses.larger}
        weight="semibold"
        aria-label={
          sponsors.length === 1
            ? i18n("sponsor")
            : i18n("sponsors", { count: sponsors?.length })
        }
      >
        {sponsors.length === 1
          ? `Sponsor`
          : `Sponsors (${sponsors ? sponsors.length : i18n("tba")})`}
      </Text>
    </div>
  );
};

export const EventSponsors: React.FunctionComponent = () => {
  const flexClasses = flexStyles();
  const sponsors: IEventSponsor[] | undefined = useSelector(
    orderedSponsorsSelector
  );
  return (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexClasses.columnGapMedium
      )}
    >
      {sponsors?.length > 0 && <SponsorsPageHeader sponsors={sponsors} />}
      {sponsors?.length > 0 && <SponsorCardList sponsors={sponsors} />}
    </div>
  );
};

import { makeStyles, shorthands } from "../shared";

export const portalListStyles = makeStyles({
  root: {
    ...shorthands.margin("0px"),
    ...shorthands.padding("0px"),
    listStylePosition: "initial",
    listStyleImage: "initial",
    listStyleType: "none",
    "> *:not(:last-child)": {
      marginBottom: "16px",
    },
  },
});

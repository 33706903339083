import React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setAttendeeIdAction } from "../../core/slices/eventSlice";
import { PortalLocationState } from "../../core/history/history.interface";

export const useAttendeeIdSearchParamHandlerHook = (): void => {
  const { search } = useLocation<PortalLocationState>();
  const dispatch = useDispatch();

  const attendeeId = new URLSearchParams(search).get("attendeeId");

  React.useEffect(() => {
    if (attendeeId != null) {
      dispatch(setAttendeeIdAction(attendeeId));
    }
  }, [attendeeId, dispatch]);
};

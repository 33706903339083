import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { PortalLocationState } from "../../core/history/history.interface";
import { authenticatedSelector } from "../../core/slices/userSlice";
import { generateSearchParams } from "../common/generateSearchParams";
import { SearchParamKey, routes } from "../../common/constants";

export const useInitiateWorkLoginParamHandlerHook = (): void => {
  const history = useHistory<PortalLocationState>();
  const location = useLocation<PortalLocationState>();

  const isAuthenticated = useSelector(authenticatedSelector);

  const initiateWorkLogin =
    new URLSearchParams(location.search).get(
      SearchParamKey.initiateWorkLogin
    ) !== null;

  React.useEffect(() => {
    if (initiateWorkLogin) {
      if (isAuthenticated) {
        history.replace({
          pathname: location.pathname,
          search: generateSearchParams(location),
          hash: location.hash,
        });
      } else {
        history.push({
          pathname: routes.login,
          search: generateSearchParams(location),
          state: {
            initiateWorkLogin: true,
            redirectPath: {
              pathname: location.pathname,
              search: generateSearchParams(location),
              hash: location.hash,
            },
          },
        });
      }
    }
  }, [history, isAuthenticated, location, initiateWorkLogin]);
};

import React from "react";
import { mergeClasses, SkeletonItem } from "../../shared";
import { flexStyles } from "../../styles/FlexStyles";
import { skeletonItemStyles } from "../../styles/SkeletonStyles";

export const AboutSectionLoading: React.FunctionComponent = () => {
  const flexClasses = flexStyles();
  const skeletonItemClasses = skeletonItemStyles();
  const aboutLoadingDisplay = (
    <div
      data-testid="about-section-loading-component"
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexClasses.columnGapMedium
      )}
    >
      <SkeletonItem
        className={mergeClasses(
          skeletonItemClasses.root,
          skeletonItemClasses.textSize600,
          skeletonItemClasses.width150
        )}
      />
      <span>
        <SkeletonItem
          className={mergeClasses(
            skeletonItemClasses.root,
            skeletonItemClasses.textSize300
          )}
        />
        <SkeletonItem
          className={mergeClasses(
            skeletonItemClasses.root,
            skeletonItemClasses.textSize300
          )}
        />
        <SkeletonItem
          className={mergeClasses(
            skeletonItemClasses.root,
            skeletonItemClasses.textSize300
          )}
        />
        <SkeletonItem
          className={mergeClasses(
            skeletonItemClasses.root,
            skeletonItemClasses.textSize300
          )}
        />
      </span>
    </div>
  );

  return aboutLoadingDisplay;
};

import React from "react";
import { Redirect, useLocation } from "react-router";
import { isUrlEncoded } from "../utilities/common/utils";
import { PortalLocationState } from "../core/history/history.interface";

export const UrlEncodedPathHandler: React.FunctionComponent = ({
  children,
}) => {
  const { pathname } = useLocation<PortalLocationState>();
  if (isUrlEncoded(pathname)) {
    return <Redirect to={decodeURIComponent(pathname)} />;
  }
  return <>{children}</>;
};

import {
  AuthError,
  InteractionRequiredAuthError,
} from "@azure/msal-browser-1p";
import { AxiosError } from "axios";
import {
  PortalAxiosError,
  PortalAxiosErrorType,
} from "../api/PortalAxiosError";
import { InvalidEventIdFormatError } from "./InvalidEventIdFormatError";
import { isGCCHOrDodCloud } from "../../configs/authConfigs";

export enum ErrorType {
  INVALID_EVENT_ID_FORMAT = "invalidEventIdFormat",
  MSAL_AUTH_ERROR = "msalAuthError", // MSAL Auth error.
  SILENT_AUTH_INTERACTION_REQUIRED = "silentAuthInteractionRequired", // MSAL silent Auth interaction error.
  SKYPE_TOKEN = "skypeToken",
  CMD_SERVICES = "cmdServices",
  ODSP = "odsp",
  UNKNOWN = "unknown",
}

export interface IError {
  action: string;
  type: ErrorType;
  status?: number;
  // Code of the error.
  errorCode?: string;
  // Message of the error.
  errorMessage: string;
  // Code of the error with in API response.
  responseErrorCode?: string;
  // Message of the error with in API response.
  responseErrorMessage?: string;
  // Error object from response body.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  responseError?: any;
  stack?: string;
}

export enum CmdServicesResponseErrorCode {
  BAD_REQUEST = "BadRequest",
  FORBIDDEN = "Forbidden",
  NOT_FOUND = "NotFound",
  UNKNOWN_ERROR = "UnknownError",
  REGISTRATION_FULL = "RegistrationFull",
  WAITLIST_FULL = "WaitlistFull",
  EXPIRED_EVENT = "ExpiredEvent",
  EVENT_CANCELED = "EventIsCanceled",
  REGISTRATION_CLOSED = "RegistrationClosed",
  PRESENTER_CANNOT_REGISTER = "PresenterCannotRegister",
}

export enum ErrorMessage {
  UNPUBLISHED = "Unpublished Event",
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const getErrorResponse = (action: string, err: any): IError => {
  if (err instanceof InvalidEventIdFormatError) {
    return {
      action,
      type: ErrorType.INVALID_EVENT_ID_FORMAT,
      errorMessage: err?.message,
    };
  } else if (err instanceof PortalAxiosError) {
    switch (err.type) {
      case PortalAxiosErrorType.SKYPE_TOKEN:
        return {
          action,
          type: ErrorType.SKYPE_TOKEN,
          status: err?.response?.status,
          errorCode: err?.code,
          errorMessage: err?.message,
          responseErrorCode:
            err?.response?.data?.errorCode || err?.response?.data?.ErrorCode,
          responseErrorMessage:
            err?.response?.data?.message || err?.response?.data?.Message,
          stack: err?.stack,
        };
      case PortalAxiosErrorType.CMD_SERVICES:
        return {
          action,
          type: ErrorType.CMD_SERVICES,
          status: err?.response?.status,
          errorCode: err?.code,
          errorMessage: err?.message,
          responseErrorCode: err?.response?.data?.ErrorCode,
          responseErrorMessage: err?.response?.data?.ErrorMessage,
          stack: err?.stack,
        };
      case PortalAxiosErrorType.ODSP:
        return {
          action,
          type: ErrorType.ODSP,
          status: err?.response?.status,
          errorCode: err?.code,
          errorMessage: err?.message,
          responseErrorCode: err?.response?.data?.error?.code,
          responseErrorMessage: err?.response?.data?.error?.message,
          responseError: {
            ...err?.response?.data?.error,
            sprequestguid: err?.response?.headers?.sprequestguid,
          },
          stack: err?.stack,
        };
    }
  } else if (err instanceof InteractionRequiredAuthError) {
    return {
      action,
      type: ErrorType.SILENT_AUTH_INTERACTION_REQUIRED,
      errorCode: err?.errorCode,
      errorMessage: err?.message,
      responseErrorMessage: err?.errorMessage,
      stack: err?.stack,
    };
  } else if (err instanceof AuthError) {
    return {
      action,
      type: ErrorType.MSAL_AUTH_ERROR,
      errorCode: err?.errorCode,
      errorMessage: err?.message,
      responseErrorMessage: err?.errorMessage,
      stack: err?.stack,
    };
  } else if (typeof err === "string") {
    // Not an error we recognize.
    return {
      action,
      type: ErrorType.UNKNOWN,
      errorMessage: err,
    };
  } else {
    // Not an error we recognize.
    let message = err?.message;
    if (!message && typeof err?.toString === "function") {
      message = err?.toString();
    }

    return {
      action,
      type: ErrorType.UNKNOWN,
      errorMessage: message,
      stack: err?.stack,
    };
  }
};

export const isErrorResponseInvalidEventIdFormat = (
  response: IError
): boolean => {
  return !!response && response.type === ErrorType.INVALID_EVENT_ID_FORMAT;
};

export const isErrorResponseUnpublishedError = (response: IError): boolean => {
  return (
    !!response &&
    response.type === ErrorType.CMD_SERVICES &&
    response.status === 404 &&
    response.responseErrorCode === CmdServicesResponseErrorCode.NOT_FOUND &&
    response.responseErrorMessage === ErrorMessage.UNPUBLISHED
  );
};

export const isErrorResponseNotFoundError = (response: IError): boolean => {
  return (
    !!response &&
    response.type === ErrorType.CMD_SERVICES &&
    (response.status === 404 ||
      response.responseErrorCode === CmdServicesResponseErrorCode.NOT_FOUND)
  );
};

export const isErrorTooManyRequestsError = (response: IError): boolean => {
  return (
    !!response &&
    (response.type === ErrorType.CMD_SERVICES ||
      response.type === ErrorType.ODSP) &&
    (response.status === 429 || response.status === 503)
  );
};

export const isErrorPreconditionFailedError = (response: IError): boolean => {
  return (
    !!response &&
    response.type === ErrorType.CMD_SERVICES &&
    response.status === 412
  );
};

export const isErrorResponseAuthError = (response: IError | null): boolean => {
  return (
    !!response &&
    response.type === ErrorType.CMD_SERVICES &&
    (response.status === 401 || response.status === 403)
  );
};

export const isErrorResponseCanceledEvent = (
  response: IError | null
): boolean => {
  return (
    !!response &&
    response.type === ErrorType.CMD_SERVICES &&
    response.responseErrorCode === CmdServicesResponseErrorCode.EVENT_CANCELED
  );
};

// Skype token error for when tenant ID is invalid or non-existent.
// This means event GUID is non-existentTenant, and this event GUID comes from the route.
export const isErrorResponseSkypeTokenBadTenantId = (
  response: IError
): boolean => {
  return (
    !!response &&
    response.type === ErrorType.SKYPE_TOKEN &&
    (response.status === 400 || // the request data (tenant ID) is invalid.
      response.status === 404) // the request data (tenant ID) is not found.
  );
};

export const isErrorCrossCloudRequestUnauthorizedError = (
  response: IError
): boolean => {
  return (
    isGCCHOrDodCloud() &&
    !!response &&
    response.type === ErrorType.SKYPE_TOKEN &&
    response.status === 401 &&
    response.errorCode === "ERR_BAD_REQUEST" &&
    response.responseErrorCode === "CrossCloudRequestUnauthorized"
  );
};

export const isErrorResponseSkypeTokenTeamsLicenseError = (
  response: IError
): boolean => {
  return (
    !!response &&
    response.type === ErrorType.SKYPE_TOKEN &&
    response.status === 403 &&
    (response.responseErrorCode === "AdminUserLicenseNotPresentForbidden" ||
      response.responseErrorCode === "TeamsDisabledForTenantForbidden" ||
      response.responseErrorCode === "UserLicenseNotPresentForbidden" ||
      response.responseErrorCode === "UserLicenseNotPresentTrialEligible")
  );
};

// Skype token error for when wrong method used for AuthZ endpoint.
// This shouldn't be possible, but seems to be happening due to bots intercepting the request
// and using the wrong method and caching the bad response.
export const isErrorResponseSkypeTokenMethodNotAllowedError = (
  response: IError
): boolean => {
  return (
    !!response &&
    response.type === ErrorType.SKYPE_TOKEN &&
    response.status === 405
  );
};

export const isErrorResponseSilentAuthInteractionRequiredError = (
  response: IError
): boolean => {
  return (
    !!response && response.type === ErrorType.SILENT_AUTH_INTERACTION_REQUIRED
  );
};

const isErrorResponseInvalidGrantAuthError = (response: IError): boolean => {
  return (
    !!response &&
    response.type === ErrorType.MSAL_AUTH_ERROR &&
    response.errorCode === "invalid_grant"
  );
};

const isErrorResponseTransientAuthError = (response: IError): boolean => {
  return (
    !!response &&
    response.type === ErrorType.MSAL_AUTH_ERROR &&
    (response.errorCode === "monitor_window_timeout" ||
      response.errorCode === "post_request_failed" ||
      response.errorCode === "no_network_connectivity")
  );
};

// Errors that will result in needing to acquire token via redirect
export const isErrorResponseAcquireTokenRedirectRequiredError = (
  response: IError
): boolean => {
  return (
    isErrorResponseSilentAuthInteractionRequiredError(response) ||
    isErrorResponseInvalidGrantAuthError(response) ||
    isErrorResponseTransientAuthError(response)
  );
};

// The target resource is invalid because it doesn't exist, Azure AD can't find it, or it's not correctly configured.
// Indicates that tenant has disabled Teams.
export const isErrorResponseInvalidResourceAuthError = (
  response: IError
): boolean => {
  return (
    !!response &&
    response.type === ErrorType.MSAL_AUTH_ERROR &&
    response.errorCode === "invalid_resource"
  );
};

export const isErrorResponsePopupWindowError = (response: IError): boolean => {
  return (
    !!response &&
    response.type === ErrorType.MSAL_AUTH_ERROR &&
    response.errorCode === "popup_window_error"
  );
};

export const isErrorResponseUserCancelledError = (
  response: IError
): boolean => {
  return (
    !!response &&
    response.type === ErrorType.MSAL_AUTH_ERROR &&
    response.errorCode === "user_cancelled"
  );
};

export const isErrorResponseAxiosError = (response: IError): boolean => {
  return (
    !!response &&
    (response.type === ErrorType.SKYPE_TOKEN ||
      response.type === ErrorType.CMD_SERVICES ||
      response.type === ErrorType.ODSP)
  );
};

// Axio error for When browser automatically aborts request.
// Happens when the page is refreshed or closed in a very specific timing.
const isErrorResponseRequestAbortedError = (response: IError): boolean => {
  return (
    !!response &&
    isErrorResponseAxiosError(response) &&
    response.errorCode === AxiosError.ECONNABORTED
  );
};

// Error for network errors.
export const isErrorResponseNetworkError = (response: IError): boolean => {
  return (
    !!response &&
    isErrorResponseAxiosError(response) &&
    response.errorCode === AxiosError.ERR_NETWORK
  );
};

export const isErrorResponseLicenseError = (response: IError): boolean => {
  return (
    isErrorResponseSkypeTokenTeamsLicenseError(response) ||
    isErrorResponseInvalidResourceAuthError(response)
  );
};

// Errors that should not fail logger scenarios in any situation.
export const isErrorHandledError = (response: IError): boolean => {
  return (
    !!response &&
    (isErrorResponseInvalidEventIdFormat(response) ||
      isErrorResponseRequestAbortedError(response) ||
      isErrorResponseNetworkError(response) ||
      isErrorResponseLicenseError(response) ||
      isErrorResponseSkypeTokenMethodNotAllowedError(response) ||
      isErrorResponsePopupWindowError(response) ||
      isErrorResponseUserCancelledError(response) ||
      isErrorTooManyRequestsError(response) ||
      isErrorCrossCloudRequestUnauthorizedError(response))
  );
};

export const getErrorResponseLoggerScenarioMessage = (
  error: IError
): string | undefined => {
  return `Error: ${JSON.stringify({
    type: error.type,
    status: error.status,
    errorCode: error.errorCode,
    errorMessage: error.errorMessage,
    responseErrorCode: error.responseErrorCode,
    responseErrorMessage: error.responseErrorMessage,
    responseError: error.responseError,
  })}`;
};

import { createEcsClient } from "@1js/ecs-client";
import {
  IEndpointsManager,
  IGlobalContext,
  ITelemetryData,
  createInstance,
} from "@1js/endpoints-manager";
import { ecsClientConfig } from "../../configs/ecsClientConfigs";
import { deploymentConfig } from "../../configs/deploymentConfigs";
import {
  IEcsClient,
  IPortalEcsConfiguration,
  IEcsFilters,
  IEcsLogger,
  IEcsParameters,
} from "./interface";
import { ILogger, LoggerLevels } from "../../common/logger/interface";

/**
 * Create an {@link IEcsClient} instance backed by local storage cache.
 */
export const createPortalEcsClient = (
  logger: ILogger,
  filters?: IEcsFilters
): IEcsClient => {
  return createEcsClient<IPortalEcsConfiguration>({
    serviceName: `${ecsClientConfig.ecsClientName}/${ecsClientConfig.ecsAgentName}`,
    loggerFactory: new EcsLoggerFactory(logger),
    ecsParameters: generateEcsParameters(filters),
    endpointsManager: createEndpointsManager(logger),
  });
};

/**
 * {@link IEndpointsManager} provides the network stack for {@link IEcsClient}.
 * It provides a strongly-typed API for ECS endpoints and includes aspects such
 * as retries with backoff, telemetry, etc.
 */
const createEndpointsManager = (
  logger: ILogger
): IEndpointsManager<unknown> => {
  /* istanbul ignore next */
  return createInstance<unknown>(
    {},
    {
      globalContext: new GlobalContext(),
      telemetryProvider: (data: ITelemetryData) => {
        logger.logTrace(
          LoggerLevels.log,
          `[ecsClient] [EndpointsManager] - ${data.apiName} - ${data.result}`,
          {}
        );
      },
      configuration: {
        modelInitializationTimeoutInMs: 1000,
        baseUrlResolutionTimeoutInMs: 3000,
        defaultRequestTimeoutInMs: 10000,
      },
    }
  );
};

/* istanbul ignore next */
/**
 * A simple {@link IGlobalContext} implementation that uses the browser's
 * setTimeout and clearTimeout functions.
 */
class GlobalContext implements IGlobalContext {
  setTimeout(callback: () => void, timeInMs: number): number {
    return window.setTimeout(callback, timeInMs);
  }
  clearTimeout(timeOutKey: number): void {
    window.clearTimeout(timeOutKey);
  }
}

/**
 * A simple {@link ILoggerFactory} implementation that creates a {@link EcsLogger}.
 */
class EcsLoggerFactory {
  constructor(private readonly logger: ILogger) {}

  newLogger(label: string): IEcsLogger {
    return new EcsLogger(this.logger, label);
  }
}

/**
 * A simple {@link ILogger} implementation that logs to Portal's {@link IPortalLogger}.
 */
class EcsLogger implements IEcsLogger {
  constructor(
    private readonly logger: ILogger,
    private readonly label: string
  ) {}

  error(message: string): void {
    this.logger.logTrace(
      LoggerLevels.error,
      `[ecsClient] [logger] - ${this.label}: ${message}`
    );
  }
  warn(message: string): void {
    this.logger.logTrace(
      LoggerLevels.warn,
      `[ecsClient] [logger] - ${this.label}: ${message}`
    );
  }
  debug(message: string): void {
    this.logger.logTrace(
      LoggerLevels.debug,
      `[ecsClient] [logger] - ${this.label}: ${message}`
    );
  }
  log(message: string): void {
    this.logger.logTrace(
      LoggerLevels.log,
      `[ecsClient] [logger] - ${this.label}: ${message}`
    );
  }
}

export const generateEcsParameters = (
  filters?: IEcsFilters
): IEcsParameters => ({
  client: ecsClientConfig.ecsClientName,
  version: ecsClientConfig.ecsClientVersion,
  domain: ecsClientConfig.ecsHost,
  agents: [ecsClientConfig.ecsAgentName],
  filters: { Environment: deploymentConfig.deployment, ...filters },
});

import React, { useEffect, useState, ReactElement } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TextV9 as Text } from "../shared";
import {
  registrationDetailSelector,
  eventSelector,
  userIsRegistered,
  IEventUserRegistrationStatus,
  getRegistrationStatusFromUserIdAsyncAction,
  registeredSelector,
  isUserRegistrationStatusKnown,
  isUserAwaitingApprovalSelector,
  isUserRejectedSelector,
  isOrganizerSelector,
} from "../core/slices/eventSlice";
import {
  EventType,
  SessionType,
  UserRegistrationStatus,
} from "../core/slices/eventTypes.interface";
import { useTranslation } from "react-i18next";
import {
  getRegistrationButtonState,
  RegistrationState,
  getWebinarLifecyleState,
  WebinarLifecycle,
} from "./registration/RegistrationStateInternal";
import { authenticatedSelector, userSelector } from "../core/slices/userSlice";
import {
  IRegisterCardOptions,
  RegistrationCard,
} from "./registration/RegistrationCard";
import HourGlass from "../assets/hour_glass.svg";
import UserRejected from "../assets/user-rejected.svg";
import ApprovalPending from "../assets/approval_pending.svg";
import Waitlisted from "../assets/waitlist_success.svg";
import { millisToMinutesAndSeconds } from "../utilities/common/utils";
import { useLogger } from "../common/logger/LoggerContext";
import { Scenario } from "../common/logger/Logger";

export const EventRegistrationButton: React.FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { t: i18n } = useTranslation();
  const logger = useLogger()?.logger;
  const currentEvent = useSelector(eventSelector);
  const isAuthenticated = useSelector(authenticatedSelector);
  const user = useSelector(userSelector);
  const userObjectId = user && user.id ? user.id : "";
  const isUserRegistered = useSelector(userIsRegistered);
  const isRegistrationStatusKnown = useSelector(isUserRegistrationStatusKnown);
  const registrationDetails = useSelector(registrationDetailSelector);
  const registrationStatus = useSelector(registeredSelector);
  const [countdown, setCountdown] = useState("");
  const userInAwaitingApprovalStatus = useSelector(
    isUserAwaitingApprovalSelector
  );
  const isUserRejected = useSelector(isUserRejectedSelector);
  const isOrganizer = useSelector(isOrganizerSelector);

  useEffect(() => {
    const userIdentity = user && user.identity ? user.identity : undefined;
    const userUPN = userIdentity && userIdentity.upn ? userIdentity.upn : "";
    if (!isOrganizer && (userObjectId.length > 0 || userUPN.length > 0)) {
      const userTenantID = userIdentity?.tenantId || "";
      const userRegistrationStatusAndId: IEventUserRegistrationStatus = {
        eventId: id,
        userObjectId: userObjectId,
        userUPN: userUPN,
        userTenantId: userTenantID,
      };
      dispatch(
        getRegistrationStatusFromUserIdAsyncAction(userRegistrationStatusAndId)
      );
    }
  }, [dispatch, id, user, userObjectId, isOrganizer]);

  useEffect(() => {
    if (currentEvent && currentEvent?.type === EventType.WEBINAR) {
      const currentTime = new Date();
      const eventStartTime = new Date(currentEvent?.eventTime?.startTime);
      if (currentTime.getTime() < eventStartTime.getTime()) {
        const timerId = setInterval(
          /* istanbul ignore next */
          () =>
            setCountdown(
              millisToMinutesAndSeconds(
                eventStartTime.getTime() - currentTime.getTime()
              )
            ),
          1000
        );
        return () => clearInterval(timerId);
      }
    }
  }, [currentEvent, countdown]);

  const isEventFull: boolean =
    !!registrationDetails?.registrationProperties?.isFull;
  const isEventWaitlistOpen: boolean = !!(
    registrationDetails?.registrationProperties?.isWaitlistEnabled &&
    !registrationDetails?.registrationProperties?.isWaitlistFull
  );

  //default state
  let registrationCardProps: IRegisterCardOptions = {
    showImage: false,
    isFull: false,
    primaryButtonText: "",
    buttonDisabled: false,
    waitlistOpen: false,
    ariaLabel: "",
  };

  const buttonText =
    currentEvent?.sessionType === SessionType.MULTI
      ? i18n("button_register_multi")
      : i18n("button_register");

  const buttonDescription =
    currentEvent?.sessionType === SessionType.MULTI
      ? i18n("button_register_multi_description")
      : i18n("button_register_description");

  if (
    (!isUserRegistered && !isUserRejected) ||
    !isAuthenticated ||
    !isRegistrationStatusKnown
  ) {
    const scenarioRegistrationDetails = logger?.findScenario(
      Scenario.RegistrationDetails
    );
    const registrationState = getRegistrationButtonState(
      currentEvent,
      registrationDetails
    );
    switch (registrationState) {
      case RegistrationState.DRAFT:
        registrationCardProps = {
          isFull: isEventFull,
          primaryButtonText: buttonText,
          buttonDisabled: !isOrganizer,
          ariaLabel: buttonDescription,
        };
        break;
      case RegistrationState.FULL:
      case RegistrationState.FULL_WAITLIST_CLOSED:
        registrationCardProps = {
          isFull: isEventFull,
          primaryButtonText: buttonText,
          buttonDisabled: true,
          waitlistOpen: isEventWaitlistOpen,
          ariaLabel: buttonDescription,
        };
        break;
      case RegistrationState.FULL_WAITLIST_OPEN:
        if (!scenarioRegistrationDetails) {
          logger?.createScenario(Scenario.RegistrationDetails);
        }
        registrationCardProps = {
          isFull: isEventFull,
          primaryButtonText: i18n("join_waitlist"),
          buttonDisabled: false,
          waitlistOpen: isEventWaitlistOpen,
          ariaLabel: i18n("join_waitlist_description"),
        };
        break;
      case RegistrationState.EVENT_ENDED:
        registrationCardProps = {
          isFull: isEventFull,
          primaryButtonText: buttonText,
          buttonDisabled: true,
          ariaLabel: buttonDescription,
        };
        break;
      case RegistrationState.REGISTRATION_NOT_STARTED:
        registrationCardProps = {
          isFull: isEventFull,
          primaryButtonText: buttonText,
          buttonDisabled: true,
          waitlistOpen: isEventWaitlistOpen,
          ariaLabel: buttonDescription,
        };
        break;
      case RegistrationState.REGISTRATION_ENDED:
        registrationCardProps = {
          isFull: isEventFull,
          primaryButtonText: buttonText,
          buttonDisabled: true,
          waitlistOpen: isEventWaitlistOpen,
          ariaLabel: buttonDescription,
        };
        break;
      case RegistrationState.OPEN:
      default:
        if (!scenarioRegistrationDetails) {
          logger?.createScenario(Scenario.RegistrationDetails);
        }
        registrationCardProps = {
          isFull: isEventFull,
          primaryButtonText: buttonText,
          buttonDisabled: false,
          waitlistOpen: isEventWaitlistOpen,
          ariaLabel: buttonDescription,
        };
        break;
    }
  } else if (
    (isUserRegistered || isUserRejected) &&
    currentEvent?.type === EventType.WEBINAR &&
    isAuthenticated &&
    isRegistrationStatusKnown
  ) {
    const webinarState = getWebinarLifecyleState(
      currentEvent,
      registrationStatus ? registrationStatus.status : ""
    );
    switch (webinarState) {
      case WebinarLifecycle.FIFTEEN_MINUTES:
        const currentTime = new Date();
        const eventStartTime = new Date(currentEvent?.eventTime?.startTime);
        let minutesDisplay = i18n("minutes_display");
        if (eventStartTime.getTime() - currentTime.getTime() < 1 * 60 * 1000) {
          minutesDisplay = i18n("seconds_display");
        }
        const webinarInfoDisplay: ReactElement = (
          <div>
            <Text id="event-countdown">
              <Text>{i18n("webinar_5_minutes_before")}</Text>
              &nbsp;
              <Text weight="semibold">{countdown}</Text>
              &nbsp;
              <Text>{minutesDisplay}</Text>
            </Text>
          </div>
        );
        registrationCardProps = {
          webinarInfoText: webinarInfoDisplay,
          showImage: true,
          isFull: false,
          primaryButtonText: i18n("join_webinar"),
          buttonDisabled: false,
          waitlistOpen: false,
          imgSrc: HourGlass,
          meetingJoinUrl: registrationStatus?.meetingJoinUrl,
          ariaLabel: i18n("join_webinar_description"),
        };
        break;
      case WebinarLifecycle.WEBINAR_ENDED:
        registrationCardProps = {
          webinarInfoText: i18n("this_event_ended"),
          isFull: false,
          defaultButtonText: i18n("join_webinar"),
          primaryButtonText: "",
          buttonDisabled: true,
          waitlistOpen: false,
          ariaLabel: i18n("join_webinar_description"),
        };
        break;
      case WebinarLifecycle.USER_COMPLETED_REGISTRATION:
        registrationCardProps = {
          webinarInfoText:
            registrationStatus &&
            registrationStatus.status === UserRegistrationStatus.WAITLIST
              ? i18n("waitlisted_registration")
              : registrationStatus && userInAwaitingApprovalStatus
              ? i18n("awaiting_approval_on_registration_card")
              : i18n("webinar_before_event_starts"),
          showImage: true,
          isFull: false,
          primaryButtonText: i18n("join_webinar"),
          buttonDisabled: true,
          waitlistOpen: false,
          imgSrc:
            registrationStatus &&
            registrationStatus.status === UserRegistrationStatus.WAITLIST
              ? Waitlisted
              : registrationStatus && userInAwaitingApprovalStatus
              ? ApprovalPending
              : HourGlass,
          meetingJoinUrl: registrationStatus?.meetingJoinUrl,
          ariaLabel: i18n("join_webinar_description"),
        };
        break;
      case WebinarLifecycle.USER_REJECTED:
        registrationCardProps = {
          webinarInfoText: i18n("user_rejected"),
          showImage: true,
          isFull: false,
          primaryButtonText: buttonText,
          buttonDisabled: true,
          imgSrc: UserRejected,
          ariaLabel: buttonDescription,
        };
        break;
      case WebinarLifecycle.DEFAULT:
      default:
        registrationCardProps = {
          showImage: true,
          isFull: false,
          primaryButtonText: i18n("join_webinar"),
          buttonDisabled: !registrationStatus?.meetingJoinUrl,
          waitlistOpen: false,
          imgSrc: HourGlass,
          meetingJoinUrl: registrationStatus?.meetingJoinUrl,
          ariaLabel: i18n("join_webinar_description"),
        };
        break;
    }
  }

  return <RegistrationCard props={registrationCardProps} />;
};

// Map with language mapping
export const localeMap: { [key: string]: string } = {
  ar: "ar-SA",
  az: "az-Latn-AZ",
  bg: "bg-BG",
  ca: "ca-ES",
  "ca-Es-VALENCIA": "ca-Es-VALENCIA",
  cs: "cs-CZ",
  cy: "cy-GB",
  da: "da-DK",
  de: "de-DE",
  el: "el-GR",
  "en-GB": "en-GB",
  en: "en-US",
  es: "es-ES",
  "es-MX": "es-MX",
  et: "et-EE",
  eu: "eu-ES",
  fi: "fi-FI",
  fil: "fil-PH",
  "fr-CA": "fr-CA",
  fr: "fr-FR",
  gl: "gl-ES",
  he: "he-IL",
  hi: "hi-IN",
  hr: "hr-HR",
  hu: "hu-HU",
  id: "id-ID",
  is: "is-IS",
  it: "it-IT",
  ja: "ja-JP",
  ka: "ka-GE",
  kk: "kk-KZ",
  ko: "ko-KR",
  lt: "lt-LT",
  lv: "lv-LV",
  mk: "mk-MK",
  nb: "nb-NO",
  nl: "nl-NL",
  nn: "nn-NO",
  pl: "pl-PL",
  "pt-BR": "pt-BR",
  pt: "pt-PT",
  ro: "ro-RO",
  ru: "ru-RU",
  sk: "sk-SK",
  sl: "sl-SI",
  sq: "sq-AL",
  sr: "sr-Latn-RS",
  sv: "sv-SE",
  th: "th-TH",
  tr: "tr-TR",
  uk: "uk-UA",
  vi: "vi-VN",
  zh: "zh-CN",
};
